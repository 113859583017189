import React from 'react';
import * as _ from 'lodash';
import './checkbox.scss';

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label?: string;
  checked?: boolean;
  disabled?: boolean;
  handleClick(e): void;
}

export const Checkbox = (props: CheckboxProps) => {
  return (
    <div className="checkbox">
      <input
        type="checkbox"
        id={props.id}
        checked={props.checked}
        onChange={(e) => props.handleClick(e)}
        disabled={props.disabled}
        {..._.omit(props, ['id', 'checked', 'handleClick', 'disable'])}
      />
      <label htmlFor={props.id}>{props.label}</label>
    </div>
  );
};
