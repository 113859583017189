import React from 'react';
import _ from 'lodash';
import { format, isYesterday, isToday } from 'date-fns';
import { NavLink } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import ContentStore from 'stores/next-retailer/contentStore';
import { getLink } from 'components/retailer/next/routes';
import Button from '../../../../common/next/form/button';
import type { ContentItem } from 'types/next';
import { getImageVariantUrl } from '../../../../../utils/helpers';
import { marked } from 'marked';
import './newsPage.scss';
import { ContentType } from 'enums/common';

interface Props {}

interface InjectedProps extends Props {
  contentStore: ContentStore;
}

@inject('contentStore')
@observer
class NewsPage extends React.Component<Props> {
  get injected() {
    return this.props as InjectedProps;
  }

  get news() {
    const { contentStore } = this.injected;
    return _.filter(contentStore.content, { type: ContentType.News });
  }

  componentDidMount() {
    const { contentStore } = this.injected;
    contentStore.searchNews({ type: ContentType.News });
  }

  formatDate = (createdAt: string) => {
    const date = new Date(createdAt);
    if (isToday(date)) {
      return 'Tänään';
    }

    if (isYesterday(date)) {
      return 'Eilen';
    }

    return format(date, 'd.M.yyyy');
  };

  loadMore = () => {
    const { contentStore } = this.injected;
    const { offset, limit } = this.injected.contentStore;
    contentStore.searchNews({ type: ContentType.News, offset: offset + limit });
  };

  renderNews = () => {
    const news = this.news;

    if (news.length === 0) {
      return null;
    }

    return _.map(news, (newsItem: ContentItem, i: number) => {
      const imageSrc = _.get(newsItem, ['meta', 'image']);
      const hasImage = !_.isNil(imageSrc);
      const thumbSrc = hasImage ? getImageVariantUrl(imageSrc, 'thumb') : null;

      return (
        <NavLink to={getLink('newsItem', newsItem.id)} key={`news-${newsItem.id}`} className="news-item">
          {hasImage && (
            <div className="news-item--left">
              <img src={thumbSrc} alt="news-item" />
            </div>
          )}
          <div className="news-item--right">
            <div className="news-item--header">
              <div className="header--title">{newsItem.title.fi}</div>
              <div className="header--timestamp">{this.formatDate(newsItem.createdAt)}</div>
            </div>
            <div className="news-item--content" dangerouslySetInnerHTML={{ __html: marked(newsItem.content.fi) }} />
          </div>
        </NavLink>
      );
    });
  };

  render() {
    const { contentStore } = this.injected;
    const { count } = contentStore;

    return (
      <div className="news-page">
        <div className="main-content">
          <div className="content">
            <h2>Uutiset</h2>
            <div className="news">{this.renderNews()}</div>
            <div className="actions">
              <Button color="bordered" onClick={this.loadMore} disabled={this.news.length >= count}>
                Näytä seuraavat 5 uutista
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewsPage;
