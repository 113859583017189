import React, { useEffect, useState } from 'react';
import { inject, Provider } from 'mobx-react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { isMobile } from './utils/helpers';
import * as api from 'utils/next-api';

import Layout from './layout';
import Drawer from './components/wrappers/drawer';
import Modal from './components/wrappers/modal';
import Preview from 'components/common/next/contentPreview';
import Logout from 'components/auth/logout';
import PushNotificationsPage from './components/pages/pushNotificationsPage';
import MobileOffersPage from './components/pages/mobileOffersPage';
import MobileOffersForm from './components/mobileOffers/mobileOffersForm';
import FrontPage from './components/pages/frontPage';
import HelpPage from './components/pages/helpPage';
import ProfilePage from './components/pages/profilePage';
import NewsPage from './components/pages/newsPage';
import NewsItemPage from './components/pages/newsItemPage';
import DeliveriesListView from './components/pages/deliveriesPage';
import SeasonsListView from './components/pages/seasonsListView';
import ProgramsListView from './components/pages/programsListView';
import B2BListView from './components/pages/b2bListView';
import ConceptView from './components/concept/conceptView';
import ConceptInfo from './components/concept/conceptInfo';
import DeliveryForm from './components/delivery/deliveryForm';
import TargetGroupForm from './components/delivery/deliveryForm/subforms/targetGroupForm';
import ContentForm from './components/delivery/deliveryForm/subforms/contentForm';
import OfferForm from './components/delivery/deliveryForm/subforms/offerForm';
import PushForm from './components/push/pushForm';
import StampCardList from './components/stampCard/stampCardList';
import StampCardViewer from './components/stampCard/stampCardViewer';
// new stamp card editor
import StampCardEditor from './components/stampCard/stampCardEditor/stampCardEditor';
import StampCardImageEditor from './components/stampCard/stampCardImageEditor';
import StampCardProductPicker from './components/stampCard/stampCardProductPicker';

import { getRoute } from './routes';

import DimensionsStore from 'stores/next/dimensions';
import AlertStore from 'stores/next/alerts';
import SettingsStore from 'stores/next/setting';
import StampCardStore from 'stores/next/stampCard';

import AnalyticsStore from 'stores/next-retailer/analyticsStore';
import ContentStore from 'stores/next-retailer/contentStore';
import ResultsStore from 'stores/next-retailer/resultsStore';
import ConceptStore from 'stores/next-retailer/conceptStore';
import TemplateStore from 'stores/next-retailer/templateStore';
import DeliveryStore from 'stores/next-retailer/deliveryStore';
import StoreStore from 'stores/next-retailer/storeStore';
import PushNotificationsStore from 'stores/next-retailer/pushNotificationsStore';
import StampCardStoreNew from 'stores/next-retailer/stampCardStore';

import './styles/index.scss';
import DeliveryView from './components/delivery/deliveryView';

import orange from '@material-ui/core/colors/deepOrange';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import fiLocale from 'date-fns/locale/fi';
import DateFnsUtils from '@date-io/date-fns';
import UserStore from 'stores/userStore';
import SiteClosed from '../siteClosed';
import InvoicesStore from 'stores/next/invoices';
import { siteDisabled, siteDisabledText } from 'constants/common';
import { Chain, ChainCode, ContentType } from 'enums/common';
import Spinner from 'components/common/next/spinner';
import MobileOfferForm from './components/mobileOffers/mobileOfferForm';
import MobileOffersDeliveryView from './components/mobileOffers/mobileOffersDeliveryView';
import { getChain } from 'utils/helpers';

const defaultMaterialTheme = createTheme({
  palette: {
    primary: orange,
  },
});

interface InjectedProps {
  userStore?: UserStore;
}

const conceptStore = new ConceptStore();
const templateStore = new TemplateStore();
const deliveryStore = new DeliveryStore();
const resultsStore = new ResultsStore();
const storeStore = new StoreStore();
const alertStore = new AlertStore();
const settingsStore = new SettingsStore();
const dimensionsStore = new DimensionsStore();
const contentStore = new ContentStore();
const analyticsStore = new AnalyticsStore();
const pushNotificationsStore = new PushNotificationsStore();
const stampCardStore = new StampCardStore();
const invoicesStore = new InvoicesStore();
const stampCardStoreNew = new StampCardStoreNew();

const App = ({ userStore }: InjectedProps) => {
  const navigate = useNavigate();
  const [isReady, setIsReady] = useState(false);
  const [isSiteDisabled, setIsSiteDisabled] = useState(false);
  const [siteDisabledInfo, setSiteDisabledInfo] = useState('');
  const isKRuokaChain = getChain([userStore.me.chainId]) === Chain.kRuoka;
  const isKCM = userStore.me.chainId === ChainCode.KCM.toString();

  const stores = {
    conceptStore,
    templateStore,
    deliveryStore,
    resultsStore,
    storeStore,
    alertStore,
    settingsStore,
    dimensionsStore,
    contentStore,
    analyticsStore,
    pushNotificationsStore,
    stampCardStore,
    invoicesStore,
    stampCardStoreNew,
  };

  useEffect(() => {
    const init = async () => {
      const client = await api.getClient();
      // give client instance to stores
      conceptStore.client = client;
      templateStore.client = client;
      deliveryStore.client = client;
      resultsStore.client = client;
      storeStore.client = client;
      dimensionsStore.client = client;
      settingsStore.client = client;
      contentStore.client = client;
      analyticsStore.client = client;
      pushNotificationsStore.client = client;
      stampCardStore.client = client;
      invoicesStore.client = client;
      stampCardStoreNew.client = client;

      // link stores to each other
      conceptStore.stores = stores;
      templateStore.stores = stores;
      deliveryStore.stores = stores;
      stampCardStore.stores = { alertStore: alertStore };
      stampCardStore.navigate = navigate;
      stampCardStoreNew.stores = { alertStore: alertStore };
      stampCardStoreNew.navigate = navigate;

      // fetch initial stuff
      await storeStore.fetchStore(); // Users store is needed for example in collection stamp cards right away.
      // TODO separate logic for K-Rauta / K-Ruoka? E.g. pushnotifications not needed in rauta
      void analyticsStore.getAnalytics();
      void dimensionsStore.getDimensionsByChain([userStore.me.chainId]);
      void dimensionsStore.getB2BDimensionsByChain([userStore.me.chainId]);
      void resultsStore.searchDeliveryResults();
      void resultsStore.getDeliveryTemplateStats();
      void contentStore.search({ type: ContentType.Help });
      void pushNotificationsStore.search();

      // UI texts are needed right away
      await contentStore.search({ type: ContentType.UI });
      const disabled = await settingsStore.getSetting(siteDisabled);
      const text = await settingsStore.getSetting(siteDisabledText);
      setIsReady(true);
      setIsSiteDisabled(disabled.value === 'true');
      setSiteDisabledInfo(text.value);
    };
    init();
  }, []);

  const banner = process.env.REACT_APP_RETAILER_BANNER;

  if (!isReady) {
    return <Spinner />;
  }

  if (isSiteDisabled) {
    return <SiteClosed infoText={siteDisabledInfo} />;
  }

  const DeliveryPreviewModal = () => (
    <Modal closeTo="./../../../..">
      <Preview />
    </Modal>
  );

  return (
    <div className="app retailer-app" lang="fi">
      <Provider {...stores}>
        <ThemeProvider theme={defaultMaterialTheme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fiLocale}>
            <div className="app next-retailer">
              {banner && <div className="alert-banner">{banner}</div>}
              <Routes>
                <Route path={getRoute('logout')} element={<Logout />} />
                {/* Full page views */}
                <Route path={`${getRoute('editDelivery', ':deliveryId', ':templateId')}/*`} element={<DeliveryForm />}>
                  <Route
                    path="offer/:offerId"
                    element={
                      <Drawer open navigateBack>
                        <OfferForm />
                      </Drawer>
                    }
                  />
                  <Route
                    path="target"
                    element={
                      <Drawer open navigateBack>
                        <TargetGroupForm />
                      </Drawer>
                    }
                  />
                  <Route
                    path="content/:channel"
                    element={
                      <Drawer open>
                        <ContentForm />
                      </Drawer>
                    }
                  >
                    <Route path="preview/:content/:channel/:id" element={<DeliveryPreviewModal />} />
                  </Route>
                  <Route path="preview/:content/:channel/:id" element={<DeliveryPreviewModal />} />
                </Route>
                <Route path={getRoute('editPushNotification', ':notificationId')} element={<PushForm />} />
                {isKRuokaChain && (
                  <Route path={`${getRoute('editMobileOffers', ':mobileOffersId')}/*`} element={<MobileOffersForm />} />
                )}
                {isKRuokaChain && (
                  <Route
                    path={`${getRoute('editMobileOffers', ':mobileOffersId')}/offer/:offerId`}
                    element={
                      <Drawer open navigateBack>
                        <MobileOfferForm />
                      </Drawer>
                    }
                  />
                )}
                {isKRuokaChain && (
                  <Route
                    path={`${getRoute('editMobileOffers', ':mobileOffersId')}/target`}
                    element={
                      <Drawer open navigateBack>
                        <TargetGroupForm />
                      </Drawer>
                    }
                  />
                )}
                {/* New stamp card editor */}
                <Route path={getRoute('stampCardEditor', ':mode', ':id', ':tab')} element={<StampCardEditor />}>
                  <Route
                    path="image"
                    element={
                      <Drawer open navigateBack>
                        <StampCardImageEditor newStore={true} />
                      </Drawer>
                    }
                  />
                  <Route
                    path="picker/:type"
                    element={
                      <Drawer open navigateBack>
                        <StampCardProductPicker newStore={true} />
                      </Drawer>
                    }
                  />
                </Route>
                {/* Regular views */}
                <Route element={<Layout isMobile={isMobile()} />}>
                  <Route index element={<FrontPage />} />
                  <Route path={getRoute('concept', ':conceptId')} element={<ConceptView />}>
                    <Route
                      path="info"
                      element={
                        <Drawer open navigateBack>
                          <ConceptInfo />
                        </Drawer>
                      }
                    />
                  </Route>
                  <Route path={getRoute('concepts', 'season')} element={<SeasonsListView />} />
                  <Route path={getRoute('concepts', 'program')} element={<ProgramsListView />} />
                  <Route path={getRoute('concepts', 'b2b')} element={<B2BListView />} />
                  <Route path={`${getRoute('delivery', ':deliveryId')}/*`} element={<DeliveryView isKCM={isKCM} />}>
                    <Route path="preview/:content/:channel/:id" element={<DeliveryPreviewModal />} />
                  </Route>
                  <Route path={getRoute('deliveries')} element={<DeliveriesListView />} />
                  <Route path={getRoute('stampCards')} element={<StampCardList />} />
                  <Route path={getRoute('viewStampCard', ':cardId')} element={<StampCardViewer />} />
                  <Route path={getRoute('news')} element={<NewsPage />} />
                  <Route path={getRoute('newsItem', ':newsItemId')} element={<NewsItemPage />} />
                  <Route path={getRoute('profile')} element={<ProfilePage />} />
                  <Route path={`${getRoute('help')}/*`} element={<HelpPage />} />
                  <Route path={getRoute('pushNotifications')} element={<PushNotificationsPage />} />
                  {isKRuokaChain && <Route path={getRoute('mobileOffers')} element={<MobileOffersPage />} />}
                  {isKRuokaChain && (
                    <Route
                      path={`${getRoute('mobileOffer', ':mobileOfferId')}/*`}
                      element={<MobileOffersDeliveryView isKCM={isKCM} />}
                    />
                  )}
                  {isKRuokaChain && (
                    <Route
                      path={`${getRoute('mobileOffer', ':mobileOfferId')}/offer/:offerId`}
                      element={
                        <Drawer open navigateBack>
                          <MobileOfferForm disabled={true} />
                        </Drawer>
                      }
                    />
                  )}
                </Route>
              </Routes>

              {/* Drawers */}
              <aside className="aside-drawer" key="aside">
                <Routes>
                  {/* Old stamp card editor */}
                  <Route
                    path={getRoute('stampCardImageEditor', ':mode', ':id')}
                    element={
                      <Drawer open navigateBack>
                        <StampCardImageEditor />
                      </Drawer>
                    }
                  />
                  <Route
                    path={getRoute('stampCardProductPicker', ':mode', ':id')}
                    element={
                      <Drawer open navigateBack>
                        <StampCardProductPicker />
                      </Drawer>
                    }
                  />
                  <Route
                    path={getRoute('stampCardRewardPicker', ':mode', ':id')}
                    element={
                      <Drawer open navigateBack>
                        <StampCardProductPicker />
                      </Drawer>
                    }
                  />
                  {/* New stamp card editor */}
                </Routes>
              </aside>
            </div>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </Provider>
    </div>
  );
};

export default inject('userStore')(App);
