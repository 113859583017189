import React, { useEffect, useState } from 'react';
import page from 'components/next/pages/page/page';
import DeliveryTemplateStore from 'stores/next/deliveryTemplates';
import MetadataStore from 'stores/next/metaData';
import ContentBlockTemplateStore from 'stores/next/contentBlockTemplates';
import LanguageSelector from 'components/common/next/languageSelector';
import PricingStore from 'stores/next/pricing';
import { inject, observer } from 'mobx-react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import './template.scss';
import TemplateEditor from './templateEditor';
import * as _ from 'lodash';
import { getDeliveryTemplateDefaults, payloadDateFormat, timestampFormat } from 'components/next/utils';
import * as date from 'date-fns';
import * as dateFns from 'date-fns';
import type {
  ContentTemplate,
  DeliveryLanguage,
  DeliveryTemplate as DeliveryTemplateEntity,
} from 'components/next/types';
import Dropdown from 'components/next/components/dropdown';
import Drawer from 'components/retailer/next/components/wrappers/drawer';
import { ReactComponent as IconUnhappy } from '@kesko/icons/mood/icon-mood_unhappy.svg';
import { ReactComponent as IconRefresh } from '@kesko/icons/action/icon-refresh.svg';
import { ReactComponent as IconDownload } from '@kesko/icons/action/icon-download.svg';
import { ReactComponent as IconClose } from '@kesko/icons/action/icon-close.svg';
import DrawerContent from 'components/retailer/next/components/wrappers/drawerContent';
import { castDate, isKRuokaOnlyChain, useDebouncedEffect } from 'utils/helpers';
import ConceptsStore from 'stores/next/programs';
import { Checkbox } from 'components/next/components/checkbox';
import PricingGroupStore from 'stores/next/pricingGroup';
import Button from 'components/common/next/form/button';
import { ChainFilters } from 'constants/common';
import { ConceptType, BusinessType, DeliveryChannelName, PreviewFormat } from 'enums/common';
import Spinner from 'components/common/next/spinner';

interface DeliveryTemplateProps {
  pricingGroupStore?: PricingGroupStore;
  metadataStore?: MetadataStore;
  deliveryTemplateStore?: DeliveryTemplateStore;
  conceptsStore?: ConceptsStore;
  contentBlockTemplateStore?: ContentBlockTemplateStore;
  pricingStore?: PricingStore;
  informListPage?(data: DeliveryTemplateEntity): void;
  unsavedChanges?: boolean;
  chainIds: string[];
}

const DeliveryTemplate = ({
  pricingGroupStore,
  metadataStore,
  deliveryTemplateStore,
  conceptsStore,
  contentBlockTemplateStore,
  pricingStore,
  informListPage,
  unsavedChanges,
  chainIds,
}: DeliveryTemplateProps) => {
  const { id: idParam, tab: tabParam, path: pathParam } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [changeSet, setChangeSet] = useState(
    getDeliveryTemplateDefaults(undefined, conceptsStore.concept.id, conceptsStore.concept.type as ConceptType),
  );
  const [create, setCreate] = useState<boolean>(undefined);
  const [dataReceived, setDataReceived] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [previewChain, setPreviewChain] = useState<string>(undefined);
  const [previewPdf, setPreviewPdf] = useState<string>(null);
  const [previewImage, setPreviewImage] = useState<string>(null);
  const [previewLink, setPreviewLink] = useState<string>(null);
  const [previewError, setPreviewError] = useState<string>(null);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [previewChainDeliveryMode, setPreviewChainDeliveryMode] = useState(false);
  const [defaultsOnly, setDefaultsOnly] = useState(false);
  const [previewLanguage, setPreviewLanguage] = useState<DeliveryLanguage>('fi');
  const [previewContentIndex, setPreviewContentIndex] = useState(0);

  const tabs = [
    {
      name: 'Details',
      tab: 0,
    },
    {
      name: 'Target Group',
      tab: 1,
    },
    {
      name: 'Templates',
      tab: 2,
    },
    {
      name: 'Blocks',
      tab: 3,
    },
    {
      name: 'Fields',
      tab: 4,
    },
    ...(isKRuokaOnlyChain(chainIds)
      ? [
          {
            name: 'Offers',
            tab: 5,
          },
        ]
      : []),
  ];

  const getCurrentTab = () => {
    return tabParam ? Number(tabParam) : 0;
  };

  const getPreviewContentTemplate = () => {
    const { contentTemplates } = changeSet;
    if (contentTemplates.length === 0) {
      return null;
    }
    return contentTemplates && contentTemplates[previewContentIndex || 0];
  };

  useEffect(() => {
    const init = async () => {
      const targetGroupType = conceptsStore.concept.type === ConceptType.B2b ? BusinessType.B2b : BusinessType.B2c;
      await metadataStore.getDimensionsByChain(chainIds, targetGroupType);
      contentBlockTemplateStore.search({});
      pricingStore.searchPricingItems({});
      pricingGroupStore.searchPricingGroups({});

      if (!deliveryTemplateStore.template || deliveryTemplateStore.template.id !== changeSet.id) {
        const template = await deliveryTemplateStore.getTemplateById(idParam);
        const concept = conceptsStore.concept;
        deliveryTemplateStore.setTemplate(template);

        setChangeSet(
          getDeliveryTemplateDefaults(deliveryTemplateStore.template, concept.id, concept.type as ConceptType),
        );
        setDataReceived(true);
        const previewContentTemplate = getPreviewContentTemplate();
        if (previewContentTemplate && previewContentTemplate.chainIds) {
          setPreviewChain(previewContentTemplate.chainIds[0]);
        }
      }
    };
    init();
    return () => {
      deliveryTemplateStore.clearDeliveryTemplate();
    };
  }, []);

  const handleBack = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (
      !unsavedChanges ||
      window.confirm(
        `Some changes you made to this delivery template have not been saved. If you move away from this page, these changes will be lost. Are you sure you want to continue?`,
      )
    ) {
      const conceptPage = location.pathname.replace(/\/delivery-templates.*/g, '');
      navigate(conceptPage);
    }
  };

  const handleSave = () => {
    deliveryTemplateStore.updateDeliveryTemplate(changeSet);
  };

  const handleDelete = () => {
    const confirmed = window.confirm(
      'Are you sure you want to delete this delivery template? You cannot undo this action',
    );
    if (confirmed) {
      deliveryTemplateStore.deleteDeliveryTemplate(changeSet.id);
    }
  };

  const changeTab = (tab: number) => () => {
    const basePath = location.pathname.replace(/(\/delivery-templates\/\d*)(.*)/, '$1');
    navigate(`${basePath}/${tab}`);
  };

  const getAvailableChannels = (contentTemplates: ContentTemplate[]) => {
    if (contentTemplates.length === 0) {
      return [];
    }
    const availableChannels = _.uniq(contentTemplates.map((c) => c.channel));

    return availableChannels.map((c) => {
      return changeSet.channels.find((current) => current.name === c) || { name: c, default: true, locked: false };
    });
  };

  const updateChangeSet = (value: any, fieldName: string) => {
    let newChangeSet = { ...changeSet };
    newChangeSet = _.set(newChangeSet, fieldName, value);

    if (fieldName === 'contentTemplates') {
      newChangeSet = _.set(newChangeSet, 'channels', getAvailableChannels(value));
    }
    const conceptType = conceptsStore.concept.type || ConceptType.Program;
    if (fieldName === 'firstStartDate' && conceptType === ConceptType.Program) {
      const offerLength = _.get(newChangeSet, 'offerOpts.defaultLength');
      const offerStartDate = new Date(value);
      const offerEndDate = dateFns.addDays(offerStartDate, offerLength - 1);
      newChangeSet = _.set(newChangeSet, 'offerOpts.startDate', dateFns.format(offerStartDate, payloadDateFormat));
      newChangeSet = _.set(newChangeSet, 'offerOpts.endDate', dateFns.format(offerEndDate, payloadDateFormat));
    } else if (fieldName === 'firstStartDate' && conceptType === ConceptType.Season) {
      // this is useful only for templates that have been created with older K Markkinointi versions allowing admin to
      // set the offer start and end dates. Changes to delivery date (firstStartDate) removes offer dates
      newChangeSet = _.set(newChangeSet, 'offerOpts.startDate', undefined);
      newChangeSet = _.set(newChangeSet, 'offerOpts.endDate', undefined);
    }

    setChangeSet(newChangeSet);

    if (informListPage) {
      informListPage(newChangeSet);
    }
  };

  const renderError = () => {
    const error = deliveryTemplateStore.error;
    return (
      <div className="error-message">
        <h3>{error.title}</h3>
        <h4>{error.message}</h4>
        <button className="go-back" onClick={handleBack}>
          <img src={require('images/arrow-back.svg').default} alt="arrow-back" />
          <span>Go back</span>
        </button>
      </div>
    );
  };

  const renderDeleted = () => {
    return (
      <div className="error-message">
        <h3>Delivery template deleted succesfully</h3>
        <button className="go-back" onClick={handleBack}>
          <img src={require('images/arrow-back.svg').default} alt="arrow-back" />
          <span>Go back</span>
        </button>
      </div>
    );
  };

  const handleLoadPreview = async () => {
    if (changeSet.contentTemplates.length === 0 || !showPreview) {
      // do nothing if no content templates exist
      return;
    }

    const previewContentTemplate = getPreviewContentTemplate();
    const payload = { ...changeSet };
    _.set(payload, 'contentTemplates', [previewContentTemplate]);

    deliveryTemplateStore.setPreviewPayload(payload);
    setPreviewLoading(true);
    setPreviewError(undefined);
    try {
      if (previewContentTemplate.channel === 'email') {
        const { data: html } = await deliveryTemplateStore.previewDeliveryTemplate({
          language: previewLanguage,
          channel: DeliveryChannelName.Email,
          format: PreviewFormat.Html,
          chainDelivery: previewChainDeliveryMode,
          defaultsOnly,
          testStoreChain: previewChain,
        });
        setPreviewLink(URL.createObjectURL(html));
        const { data: image } = await deliveryTemplateStore.previewDeliveryTemplate({
          language: previewLanguage,
          channel: DeliveryChannelName.Email,
          format: PreviewFormat.Image,
          chainDelivery: previewChainDeliveryMode,
          defaultsOnly,
          testStoreChain: previewChain,
        });
        setPreviewImage(URL.createObjectURL(image));
      }
      if (previewContentTemplate.channel === 'print') {
        const { data: html } = await deliveryTemplateStore.previewDeliveryTemplate({
          channel: DeliveryChannelName.Print,
          format: PreviewFormat.Pdf,
          testStoreChain: previewChain,
          defaultsOnly,
          language: previewLanguage,
        });
        setPreviewPdf(URL.createObjectURL(html));
        setPreviewLink(URL.createObjectURL(html));
      }
    } catch (error) {
      console.error(error);
      setPreviewError('The content template seems to be invalid. See console for more details');
    }
    setPreviewLoading(false);
  };

  useDebouncedEffect(
    handleLoadPreview,
    [showPreview, previewContentIndex, previewChain, previewChainDeliveryMode, defaultsOnly, previewLanguage],
    500,
  );

  const handleShowPreview = () => {
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
  };

  const handleOpenPreviewLink = () => {
    const win = window.open(previewLink, '_blank');
    win.focus();
  };

  const selectPreviewTemplate = (index: number) => {
    const previewChain = _.get(changeSet.contentTemplates, [index, 'chainIds', '0'], '4');
    setPreviewContentIndex(index);
    setPreviewChain(previewChain);
  };

  const selectPreviewChain = (chain: string) => {
    setPreviewChain(chain);
  };

  const renderHeader = () => {
    const concept = conceptsStore.concept;
    const template = getDeliveryTemplateDefaults(
      deliveryTemplateStore.template,
      concept.id,
      concept.type as ConceptType,
    );

    const showPreviewButton = changeSet.contentTemplates.length > 0;
    const isChainDelivery = !!changeSet.chainDelivery;

    const statuses = [
      {
        name: 'Draft',
        value: false,
      },
      {
        name: 'Published',
        value: true,
      },
    ];

    return (
      <header className="editor-header">
        <div className="editor-header__controls">
          <button className="go-back" onClick={handleBack}>
            <img src={require('images/cross.svg').default} alt="go-back" />
          </button>
          <div className="editor-header__details">
            <h3>{template.title.fi}</h3>
            <h4>
              <span>ID: {template.id}</span>
              <span>Status: {`${changeSet.published ? 'published' : 'draft'}`}</span>
              <span>Last saved: {date.format(castDate(template.updatedAt), timestampFormat)}</span>
              <span>Languages: {changeSet.languages.join(', ')}</span>
            </h4>
          </div>
          <div className="editor-header__actions">
            <Dropdown
              data={statuses}
              notSelectedText="Template status"
              select={(value) => updateChangeSet(value, 'published')}
              selectedItem={statuses.find((s) => s.value === changeSet.published)}
            />
            {showPreviewButton && (
              <button className="preview" onClick={() => handleShowPreview()}>
                Preview
              </button>
            )}
            {showPreviewButton && isChainDelivery && (
              <button
                className="preview"
                onClick={() => {
                  setPreviewChainDeliveryMode(true);
                  handleShowPreview();
                }}
              >
                Chain Delivery Preview
              </button>
            )}
          </div>
        </div>
        <nav className="editor-header__tabs">
          {tabs.map(({ name, tab }) => (
            <button
              key={`tab-${tab}`}
              className={`tab-selector ${getCurrentTab() === tab ? 'is-selected' : ''}`}
              onClick={changeTab(tab)}
            >
              {name}
            </button>
          ))}
        </nav>
      </header>
    );
  };

  const togglePreviewLanguage = (previewLanguage: DeliveryLanguage) => {
    setPreviewLanguage(previewLanguage);
  };

  const renderPreview = () => {
    const previewContentTemplate = getPreviewContentTemplate();
    if (!previewContentTemplate) {
      return null;
    }
    if (previewContentTemplate.channel === 'email') {
      if (previewLoading && !previewImage) {
        return <Spinner />;
      }
      return (
        <a href={previewLink} target="_blank" rel="noopener noreferrer">
          <img className="preview-image" src={previewImage} alt="preview" />
        </a>
      );
    }
    if (previewContentTemplate.channel === 'print') {
      if (previewLoading && !previewPdf) {
        return <Spinner />;
      }
      return <embed className="preview-pdf" src={previewPdf} type="application/pdf" />;
    }
  };

  const getEmailPreviewLink = async () => {
    const previewContentTemplate = getPreviewContentTemplate();
    const payload = { ...changeSet };
    _.set(payload, 'contentTemplates', [previewContentTemplate]);

    try {
      setPreviewLoading(true);
      await deliveryTemplateStore.previewDeliveryTemplate({
        language: previewLanguage,
        channel: DeliveryChannelName.Email,
        format: PreviewFormat.Image,
        chainDelivery: previewChainDeliveryMode,
        defaultsOnly,
        testStoreChain: previewChain,
        returnUrl: true,
      });
      setPreviewLoading(false);
    } catch (e) {
      setPreviewError(e.message);
      setPreviewLoading(false);
      return;
    }
  };

  const renderPreviewPane = () => {
    const contentTemplatePreviewSelect = changeSet.contentTemplates.map((t, i) => ({
      name: `${t.title.fi}`.substr(0, 28),
      value: i,
    }));
    const previewContentTemplate = getPreviewContentTemplate();
    const isChainDelivery = !!changeSet.chainDelivery;
    const chainSelect = Object.keys(ChainFilters)
      .map((k) => ({
        name: ChainFilters[k],
        value: k,
      }))
      .filter((chain) => {
        return previewContentTemplate?.chainIds.includes(chain.value);
      });

    return (
      <Drawer open={showPreview} className="preview-pane" onClose={handleClosePreview}>
        <div className="preview-options">
          <Dropdown
            data={contentTemplatePreviewSelect}
            notSelectedText="Preview template"
            select={(contentTemplateId) => selectPreviewTemplate(contentTemplateId as number)}
            selectedItem={_.find(contentTemplatePreviewSelect, { value: previewContentIndex })}
          />
          <Dropdown
            data={chainSelect}
            notSelectedText="Chain"
            select={(chain) => selectPreviewChain(chain as string)}
            selectedItem={_.find(chainSelect, { value: previewChain })}
          />
          <LanguageSelector
            language={previewLanguage}
            languages={changeSet.languages}
            toggleLanguage={togglePreviewLanguage}
          />
          {previewLoading ? (
            <div>
              <Spinner />
            </div>
          ) : null}
        </div>
        <div className="preview-actions">
          {previewContentTemplate &&
            previewContentTemplate.channel === DeliveryChannelName.Email &&
            !deliveryTemplateStore.emailPreviewUrl && (
              <Button className="generate-link" color="bordered" onClick={getEmailPreviewLink}>
                Luo jaettava linkki
              </Button>
            )}
          {previewContentTemplate &&
            previewContentTemplate.channel === DeliveryChannelName.Email &&
            deliveryTemplateStore.emailPreviewUrl && (
              <div className="preview-url-container">
                <a
                  title="Shareable link"
                  className="preview-url"
                  href={deliveryTemplateStore.emailPreviewUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {deliveryTemplateStore.emailPreviewUrl}
                </a>
                <Button
                  className="generate-link"
                  color="bordered"
                  title="Regenerate shareable link"
                  onClick={getEmailPreviewLink}
                >
                  <IconRefresh />
                </Button>
              </div>
            )}

          {!previewLoading && previewLink && (
            <button title="Download preview" className="preview-link styled-button" onClick={handleOpenPreviewLink}>
              <IconDownload />
            </button>
          )}
          <button title="Refresh preview" className="preview-refresh styled-button" onClick={handleLoadPreview}>
            <IconRefresh />
          </button>
          <button title="Close preview" className="preview-close styled-button" onClick={handleClosePreview}>
            <IconClose />
          </button>
        </div>
        <div className="preview-additional-options">
          {isChainDelivery && (
            <Checkbox
              id={`preview-chain-delivery-show`}
              checked={previewChainDeliveryMode || false}
              handleClick={(e) => {
                setPreviewChainDeliveryMode(e.currentTarget.checked);
                setDefaultsOnly(e.currentTarget.checked);
              }}
              label="Chain version"
            />
          )}
          <Checkbox
            id={`preview-defaults-only-show`}
            checked={defaultsOnly || false}
            handleClick={(e) => {
              setDefaultsOnly(e.currentTarget.checked);
            }}
            label="Show only default slots"
          />
        </div>
        <DrawerContent>
          <div className="content-preview">
            {previewError && (
              <div className="error">
                <IconUnhappy />
                <br />
                {previewError}
              </div>
            )}
            {!previewError && renderPreview()}
          </div>
        </DrawerContent>
      </Drawer>
    );
  };

  const shouldRenderEditor = !deliveryTemplateStore.error && !deliveryTemplateStore.deleted && dataReceived;

  return (
    <div className="template">
      {shouldRenderEditor ? (
        <React.Fragment>
          {renderHeader()}
          <div className="content">
            {shouldRenderEditor && (
              <TemplateEditor
                template={changeSet}
                tab={getCurrentTab()}
                pricingItems={pricingStore.pricingItems}
                updateChangeSet={updateChangeSet}
                create={create}
                dimensions={metadataStore.dimensions}
                contentBlockTemplates={contentBlockTemplateStore.contentBlockTemplates}
                chainIds={chainIds}
              />
            )}
            {deliveryTemplateStore.error && renderError()}
            {deliveryTemplateStore.deleted && renderDeleted()}
          </div>
        </React.Fragment>
      ) : (
        <Spinner />
      )}
      {renderPreviewPane()}
    </div>
  );
};

export default page(
  inject(
    'deliveryTemplateStore',
    'metadataStore',
    'contentBlockTemplateStore',
    'pricingStore',
    'conceptsStore',
    'pricingGroupStore',
  )(observer(DeliveryTemplate)),
);
