import React from 'react';
import { inject, observer } from 'mobx-react';
import { isKRautaChain, isOnninenChain } from 'utils/helpers';
import { ConceptType } from 'enums/common';
import ConceptListView from '../concept/conceptList';
import UserStore from 'stores/userStore';

interface Props {
  userStore?: UserStore;
}

const SeasonsListView = ({ userStore }: Props) => {
  const isKRauta = isKRautaChain(userStore.me.chainId);
  const season_header = isKRauta ? 'Omat Lähetykset' : 'Lähetykset';
  const season_content = isKRauta
    ? `Omat lähetykset ovat sesonkien tai kaupan palveluiden teemoihin sopivia lähetyksiä valitsemanasi ajankohtana, joihin kauppa voi muokata sisältöä kohderyhmälle sopivaksi.
    `
    : `Kaupan lähetykset ovat kertalähetyksiä, joissa kauppias voi kertaluontoisesti kohdentaa itse valitsemalleen kohderyhmälle etuja ja sesonkisisältöä. Lähetyksen kanaviksi voi valita sähkösuoran ja/tai osoitteellisen printtipostituksen.
    `;

  return (
    <div className="front-page ">
      <div className="main-content">
        <div className="content">
          <h2>{season_header}</h2>
          <p>{season_content}</p>
          <ConceptListView filters={{ type: ConceptType.Season }} />
        </div>
      </div>
    </div>
  );
};

export default inject('userStore')(observer(SeasonsListView));
