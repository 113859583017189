import React from 'react';
import { CustomField } from 'components/next/components/form/input';
import './chainSelector.scss';
import { ChainAbbreviations } from 'constants/common';

interface Props {
  label?: string;
  detail?: string;
  chainSelection: string[];
  handleChainChange(key: string): void;
  excludeChains?: string[];
}

const ChainSelector = (props: Props) => {
  const { chainSelection, excludeChains } = props;
  const chainClassName = (key: string) => {
    if (!chainSelection) {
      return 'chain-name not-selected';
    }
    return chainSelection.includes(key) ? `chain-name ${ChainAbbreviations[key]}` : 'chain-name not-selected';
  };
  const handleChainChange = (key: string) => {
    props.handleChainChange(key);
  };

  let chains = Object.keys(ChainAbbreviations);
  if (excludeChains && excludeChains.length > 0) {
    chains = chains.filter((chainId) => !excludeChains.includes(chainId));
  }

  return (
    <div className="chain-selector">
      <CustomField label={props.label} detail={props.detail}>
        <div className="chain-picker">
          {chains.map((key, i) => (
            <span className={chainClassName(key)} key={i} onClick={() => handleChainChange(key)}>
              {ChainAbbreviations[key]}
            </span>
          ))}
        </div>
      </CustomField>
    </div>
  );
};

export default ChainSelector;
