import { observable, action, runInAction } from 'mobx';
import type { ContentItem, ContentData } from 'types/content';
import * as api from 'utils/api/other';

export default class ContentStore {
  @observable public content: ContentItem[] = [];
  @observable public contentLoaded = false;

  @action
  public async getContent() {
    const content = await api.getContent();
    runInAction(() => {
      this.content = content;
      this.contentLoaded = true;
    });
  }

  @action
  public reset() {
    this.content = [];
    this.contentLoaded = false;
  }

  @action
  public async postContent(contentData: ContentData) {
    await api.postContent(contentData);
    await this.getContent();
  }
}
