import _ from 'lodash';
import type { Many } from 'lodash';
import * as React from 'react';
import { action, observable } from 'mobx';
import { addMonths, addWeeks, endOfDay, startOfDay } from 'date-fns';
import type Api from 'types/next-api';
import { getLink } from '../../components/retailer/next/routes';
import AlertStore from './alerts';
import {
  CollectionStampCardImageTextType,
  CollectionStampCardRewardDiscountMethod,
  CollectionStampCardRewardDiscountType,
  CollectionStampCardRewardType,
  CollectionStampCardStrategy,
  CollectionStampCardType,
} from 'enums/stampCard';
import type {
  CollectionStampCardSearchPayload,
  CollectionStampCardSearchQueryParameters,
  CollectionStampCardTemplateSearchPayload,
  CollectionStampCardTemplateSearchQueryParameters,
  CollectionStampCardStatisticsPayload,
  CollectionStampCardPostPayload,
  CollectionStampCardTemplatePostPayload,
} from 'types/next';
import type { User } from 'types/user';
import { ProductPricing } from 'enums/product';
import { getCollectionStampCardImageHTML, initializeCollectionStampCard } from 'utils/stampCard';
import {
  BASKET_DISCOUNT_PERCENTAGES,
  DEFAULT_DESCRIPTION,
  PRODUCT_IMAGE_PLACEHOLDER,
  STAMP_CARD_BACKGROUND_IMAGES,
} from 'constants/stampCard';
import { EditState } from 'enums/common';
import type { NavigateFunction } from 'react-router-dom';

export const getDefaultStampCardTemplate = () => {
  return {
    uiData: {
      uiName: {
        fi: 'Uusi keräilypassi',
      },
      imageUrl: {
        fi: STAMP_CARD_BACKGROUND_IMAGES[0],
      },
      description: {
        fi: DEFAULT_DESCRIPTION,
      },
    },
    templateDescription: {
      fi: 'Kauppiaan keräilypassinäkymässä näkyvä kuvaus',
    },
    type: CollectionStampCardType.Product,
    strategy: CollectionStampCardStrategy.SimpleProductCollecting,
    oneTimePurchase: '',
    cardLimit: 0,
    products: [],
    rewardProducts: [],
    reward: {
      type: CollectionStampCardRewardType.ProductEur,
      discountType: CollectionStampCardRewardDiscountType.Product,
      discountMethod: CollectionStampCardRewardDiscountMethod.ProductEuro,
      discount: 0,
      itemLimit: 1,
      minimumPurchase: 0,
      title: {
        fi: 'Tuote veloituksetta',
      },
      image: PRODUCT_IMAGE_PLACEHOLDER,
    },
    chains: ['3', '4', '5'],
    stores: null,
    stampConfiguration: 6,
    imageData: {
      text: {
        fi: 'Kerää leimoja ostoksista',
      },
      textType: CollectionStampCardImageTextType.None,
      backgroundUrl: STAMP_CARD_BACKGROUND_IMAGES[0],
      backgroundUrls: STAMP_CARD_BACKGROUND_IMAGES,
      textBackgroundColor: '#00205b',
      textForegroundColor: '#ffffff',
    },
    published: false,
  };
};
export default class StampCardStore {
  client: Api.Client;
  public stores: {
    alertStore: AlertStore;
  };

  public navigate?: NavigateFunction;

  stampCardBackgrounds = STAMP_CARD_BACKGROUND_IMAGES;

  @observable isLoadingStampCard: boolean = false;
  @observable isLoadingStampCards: boolean = false;
  @observable isLoadingStampCardTemplate: boolean = false;
  @observable isLoadingStampCardTemplates: boolean = false;
  @observable isLoadingStampCardStatistics: boolean = false;
  @observable isLoadingStampCardImage: boolean = false;
  @observable stampCards: Api.Components.Responses.CollectionStampCardResponse[] = [];
  @observable stampCardTemplates: Api.Components.Responses.CollectionStampCardTemplateResponse[] = [];
  @observable stampCardStatistics: {
    [cardId: string]: Api.Components.Responses.CollectionStampCardStatisticsResponse;
  } = {};
  @observable current: Api.Components.Responses.CollectionStampCardResponse = null;
  @observable original: Api.Components.Responses.CollectionStampCardResponse = null;
  @observable currentTemplate: Api.Components.Responses.CollectionStampCardTemplateResponse = null;
  @observable currentTemplateState: EditState = EditState.Pristine;
  @observable isCurrentValid: boolean = true;
  @observable isCurrentTemplateValid: boolean = true;
  @observable validationError: string = null;
  @observable invalidField: string = null;

  @action async initializeCard({ cardTemplateId, me }: { cardTemplateId?: string; me: User }) {
    const defaultStartDate = startOfDay(new Date());
    const defaultEndDate = endOfDay(addMonths(new Date(), 12));

    const defaultStore = {
      storeId: _.get(me, ['store']),
      chainId: _.get(me, ['chainId']),
      name: _.get(me, ['storeData', 'name']),
    };

    if (!cardTemplateId) {
      this.current = {
        visibleFrom: defaultStartDate.toISOString(),
        visibleTo: defaultEndDate.toISOString(),
        activeFrom: defaultStartDate.toISOString(),
        activeTo: defaultEndDate.toISOString(),
        redeemableTo: endOfDay(addWeeks(defaultEndDate, 2)).toISOString(),
        uiData: {
          uiName: {
            fi: '',
          },
          imageUrl: {
            fi: this.stampCardBackgrounds[0],
          },
          description: {
            fi: DEFAULT_DESCRIPTION,
          },
        },
        type: CollectionStampCardType.Product,
        strategy: CollectionStampCardStrategy.SimpleProductCollecting,
        oneTimePurchase: '',
        stores: [defaultStore],
        stampConfiguration: 6,
        cardLimit: 0,
        products: [],
        rewardProducts: [],
        reward: {
          type: CollectionStampCardRewardType.ProductEur,
          discountType: CollectionStampCardRewardDiscountType.Product,
          discountMethod: CollectionStampCardRewardDiscountMethod.ProductEuro,
          discount: 0,
          itemLimit: 1,
          minimumPurchase: 0,
          title: {
            fi: 'Tuote veloituksetta',
          },
          image: PRODUCT_IMAGE_PLACEHOLDER,
          pricingUnit: ProductPricing.PerPiece,
        },
        imageData: {
          text: {
            fi: 'Kerää leimoja ostoksista',
          },
          textType: CollectionStampCardImageTextType.None,
          backgroundUrl: this.stampCardBackgrounds[0],
          textBackgroundColor: '#00205b',
          textForegroundColor: '#ffffff',
        },
      };
    } else {
      try {
        await this.getCollectionStampCardTemplate(cardTemplateId);
      } catch (err) {
        this.navigate(getLink('stampCards'));
      }
      this.current = initializeCollectionStampCard({
        template: cardTemplateId,
        visibleFrom: defaultStartDate.toISOString(),
        visibleTo: defaultEndDate.toISOString(),
        activeFrom: defaultStartDate.toISOString(),
        activeTo: defaultEndDate.toISOString(),
        redeemableTo: endOfDay(addWeeks(defaultEndDate, 2)).toISOString(),
        uiData: _.get(this.currentTemplate, ['uiData']),
        type: _.get(this.currentTemplate, ['type']),
        strategy: _.get(this.currentTemplate, ['strategy']),
        oneTimePurchase: _.get(this.currentTemplate, ['oneTimePurchase']),
        stores: [defaultStore],
        stampConfiguration: _.get(this.currentTemplate, ['stampConfiguration']),
        cardLimit: _.get(this.currentTemplate, ['cardLimit']),
        products: _.get(this.currentTemplate, ['products']),
        rewardProducts: _.get(this.currentTemplate, ['rewardProducts']),
        reward: _.get(this.currentTemplate, ['reward']),
        imageData: _.omit(_.get(this.currentTemplate, ['imageData']), ['backgroundUrls']),
      });
    }

    this.validateCurrent();
  }

  @action async initializeCardTemplate(id?: string) {
    if (id) {
      await this.getCollectionStampCardTemplate(id);
    } else {
      this.currentTemplate = getDefaultStampCardTemplate();
    }

    this.currentTemplateState = EditState.Pristine;
    this.validateCurrentTemplate();
  }

  @action updateValue = (path: Many<number | string | symbol>, value: any) => {
    // Do not update anything while card is either loading or saving.
    if (this.isLoadingStampCard) {
      return false;
    }
    const rewardPricingUnitPath = ['reward', 'pricingUnit'];
    const rewardItemLimitPath = ['reward', 'itemLimit'];

    // Reset itemLimit to default when pricingUnit is changed
    if (
      JSON.stringify(path) === JSON.stringify(rewardPricingUnitPath) &&
      _.get(this.current, rewardPricingUnitPath) !== value
    ) {
      if (value === ProductPricing.PerPiece) {
        _.set(this.current, rewardItemLimitPath, 1);
      }
      if (value === ProductPricing.Weight) {
        _.set(this.current, rewardItemLimitPath, 100);
      }
    }

    _.set(this.current, path, value);

    // Force itemLimit to 1 when there's unit based products selected
    const hasPerPiecePricedProducts =
      JSON.stringify(path) === '["rewardProducts"]' &&
      value.some((product) => product.pricingUnit === ProductPricing.PerPiece);

    if (hasPerPiecePricedProducts || _.get(this.current, rewardPricingUnitPath) === ProductPricing.PerPiece) {
      // if stamp card has product priced in pieces, the reward item limit is changed to 1. Only variable weight products may have other limits
      _.set(this.current, rewardItemLimitPath, 1);
      _.set(this.current, rewardPricingUnitPath, ProductPricing.PerPiece);
    }

    this.validateCurrent();
  };

  @action updateTemplateValue = (path: Many<number | string | symbol>, value: any) => {
    // Do not update anything while card template is either loading or saving.
    if (this.isLoadingStampCardTemplate) {
      return false;
    }

    if (this.currentTemplateState !== EditState.Changed) {
      this.currentTemplateState = EditState.Changed;
    }

    const rewardPricingUnitPath = ['reward', 'pricingUnit'];
    const rewardItemLimitPath = ['reward', 'itemLimit'];

    // Reset itemLimit to default when pricingUnit is changed
    if (
      JSON.stringify(path) === JSON.stringify(rewardPricingUnitPath) &&
      _.get(this.currentTemplate, rewardPricingUnitPath) !== value
    ) {
      if (value === ProductPricing.PerPiece) {
        _.set(this.currentTemplate, rewardItemLimitPath, 1);
      }
      if (value === ProductPricing.Weight) {
        _.set(this.currentTemplate, rewardItemLimitPath, 100);
      }
    }

    _.set(this.currentTemplate, path, value);
    // FIXME: hacky way to force MobX observable to notice change done by Lodash set()
    this.currentTemplate.templateDescription = { ...this.currentTemplate.templateDescription };
    this.validateCurrentTemplate();
  };

  @action validateCurrent = () => {
    this.isCurrentValid = true;
    this.validationError = null;
    this.invalidField = null;

    const requiredFields = [
      ['visibleFrom'],
      ['visibleTo'],
      ['activeFrom'],
      ['activeTo'],
      ['redeemableTo'],
      ['uiData', 'imageUrl', 'fi'],
      ['uiData', 'uiName', 'fi'],
      ['uiData', 'description', 'fi'],
      ['stores'],
      ['stampConfiguration'],
      ['imageData', 'textType'],
      ['imageData', 'backgroundUrl'],
      ['imageData', 'textBackgroundColor'],
      ['imageData', 'textForegroundColor'],
      ['reward', 'type'],
      ['reward', 'discountType'],
      ['reward', 'discountMethod'],
      ['reward', 'discount'],
      ['reward', 'itemLimit'],
      ['reward', 'minimumPurchase'],
      ['reward', 'title', 'fi'],
      ['reward', 'image'],
    ];

    const requiredSimpleCollectionFields = [['products']];

    _.each(requiredFields, (requiredPath) => {
      const required = _.get(this.current, requiredPath, '');
      if (_.isNil(required) || required.length === 0) {
        this.invalidField = requiredPath.join('.');
        this.validationError = 'Pakollinen kenttä puuttuu';
        this.isCurrentValid = false;
        return false;
      }
    });

    if (!this.isCurrentValid) {
      return false;
    }

    const rewardProducts: Api.Components.Schemas.CollectionStampCardProduct[] = _.get(this.current, ['rewardProducts']);

    if (_.get(this.current, ['strategy']) === CollectionStampCardStrategy.SimpleProductCollecting) {
      if (_.get(this.current, ['stampConfiguration']) < 1) {
        this.validationError = 'Ostettavien tuotteiden lukumäärä on oltava vähintään 1';
        this.invalidField = 'stampConfiguration';
        this.isCurrentValid = false;
        return false;
      }

      if (_.some(_.get(this.current, ['products']), (product) => product.ean.length !== 13)) {
        this.validationError = 'EAN-koodien on oltava 13 merkkiä pitkiä';
        this.invalidField = 'products';
        this.isCurrentValid = false;
      }

      _.each(requiredSimpleCollectionFields, (requiredPath) => {
        const required = _.get(this.current, requiredPath, '');
        if (_.isNil(required) || required.length === 0) {
          if (rewardProducts.length > 0) {
            // show validation error only if user has defined other configuration already
            this.validationError = 'Kerättävä tuote puuttuu';
            this.invalidField = 'collectedProducts';
          }
          this.isCurrentValid = false;
          return false;
        }
      });

      if (!this.isCurrentValid) {
        return false;
      }
    } else {
      if (Number(_.get(this.current, ['oneTimePurchase'])) < 1) {
        this.validationError = 'Kertaostoksen suuruus on oltava vähintään 1€';
        this.invalidField = 'oneTimePurchase';
        this.isCurrentValid = false;
        return false;
      }
    }

    const discountType = _.get(this.current, ['reward', 'discountType']);
    const discountMethod = _.get(this.current, ['reward', 'discountMethod']);
    const discount = _.get(this.current, ['reward', 'discount']);
    const itemLimit = _.get(this.current, ['reward', 'itemLimit']);
    const minimumPurchase = _.get(this.current, ['reward', 'minimumPurchase']);
    const hasVariableWeightProducts = rewardProducts.some(
      (product) => !product.pricingUnit || product.pricingUnit === ProductPricing.Weight,
    );
    // does all the selected products have the same pricing unit. Compare only weight and pieces, because it's possible
    // that the pricing unit is missing. And missing pricing unit product may be mixed with others
    const piecesAndMassRewardProduct = rewardProducts.filter(
      (prod) => prod.pricingUnit === ProductPricing.Weight || prod.pricingUnit === ProductPricing.PerPiece,
    );

    const hasSingleCategoryProductPricingUnits =
      piecesAndMassRewardProduct.length > 0 &&
      piecesAndMassRewardProduct.every((prod) => prod.pricingUnit === piecesAndMassRewardProduct[0].pricingUnit);

    if (piecesAndMassRewardProduct.length > 0 && !hasSingleCategoryProductPricingUnits) {
      this.validationError = 'Palkintotuotteissa ei saa olla yhtä aikaa kg- ja kpl-määräisiä tuotteita';
      this.invalidField = 'productPicker';
      this.isCurrentValid = false;
      return false;
    }

    if (discountType === CollectionStampCardRewardDiscountType.Product) {
      if (rewardProducts.length === 0) {
        this.validationError = 'Palkintotuotteet puuttuu';
        this.invalidField = 'rewardProducts';
        this.isCurrentValid = false;
      }

      if (hasVariableWeightProducts && (!itemLimit || itemLimit <= 0)) {
        this.validationError = 'Määrittele alennettu määrä';
        this.invalidField = 'reward.itemLimit';
        this.isCurrentValid = false;
        return false;
      }

      if (_.some(_.get(this.current, ['rewardProducts']), (product) => product.ean.length !== 13)) {
        this.validationError = 'EAN-koodien on oltava 13 merkkiä pitkiä';
        this.invalidField = 'rewardProducts';
        this.isCurrentValid = false;
      }

      // discountType = 241, product
      if (discountMethod === CollectionStampCardRewardDiscountMethod.ProductEuro) {
        // discountMethod = 3, product eur discount
        if (discount < 0) {
          this.validationError = 'Alennettu hinta ei voi olla alle 0 €';
          this.invalidField = 'reward.discount';
          this.isCurrentValid = false;
          return false;
        }
      }
      if (discountMethod === CollectionStampCardRewardDiscountMethod.ProductPercent) {
        // discountMethod = 2, product percent discount
        if (discount < 0 || discount > 100) {
          this.validationError = 'Alennusprosentin täytyy olla väliltä 0 - 100';
          this.invalidField = 'reward.discount';
          this.isCurrentValid = false;
          return false;
        }
      }
    } else if (discountType === CollectionStampCardRewardDiscountType.BasketEuro) {
      // discountType = 248, basket euro discount
      if (
        _.some(
          this.current.stores,
          (store) => _.startsWith(store.storeId.toLowerCase(), 'n') || _.startsWith(store.storeId.toLowerCase(), 'c'),
        )
      ) {
        this.validationError = 'Kuitin loppusumma-alennukset eivät ole käytössä K-Citymarketeilla';
        this.invalidField = 'reward.type';
        this.isCurrentValid = false;
      }

      if (discount < 0 || discount > 999) {
        this.validationError = 'Kuitin loppusumma-alennuksen täytyy olla väliltä 0 - 999 €';
        this.invalidField = 'reward.discount';
        this.isCurrentValid = false;
        return false;
      }

      if (minimumPurchase < discount) {
        this.validationError = 'Kertaostoksen suuruus ei voi olla alle euromääräisen alennuksen';
        this.invalidField = 'reward.minimumPurchase';
        this.isCurrentValid = false;
        return false;
      }
    } else if (discountType === CollectionStampCardRewardDiscountType.BasketPercent) {
      // discountType = 249, basket percent discount
      if (
        _.some(
          this.current.stores,
          (store) => _.startsWith(store.storeId.toLowerCase(), 'n') || _.startsWith(store.storeId.toLowerCase(), 'c'),
        )
      ) {
        this.validationError = 'Kuitin loppusumma-alennukset eivät ole käytössä K-Citymarketeilla';
        this.invalidField = 'reward.type';
        this.isCurrentValid = false;
      }

      if (!_.includes([11, 12, 13, 14, 15, 16], discount)) {
        this.isCurrentValid = false;
        return false;
      }

      if (minimumPurchase < 3) {
        this.invalidField = 'reward.minimumPurchase';
        this.validationError = 'Kertaostoksen suuruus on oltava vähintään 3 €';
        this.isCurrentValid = false;
        return false;
      }
    }
  };

  @action validateCurrentTemplate = () => {
    this.isCurrentTemplateValid = true;

    const requiredFields = [
      ['templateDescription', 'fi'],
      ['uiData', 'imageUrl', 'fi'],
      ['uiData', 'uiName', 'fi'],
      ['uiData', 'description', 'fi'],
      ['stampConfiguration'],
      ['imageData', 'textType'],
      ['imageData', 'backgroundUrl'],
      ['imageData', 'backgroundUrls'],
      ['imageData', 'textBackgroundColor'],
      ['imageData', 'textForegroundColor'],
      ['reward', 'type'],
      ['reward', 'discountType'],
      ['reward', 'discountMethod'],
      ['reward', 'discount'],
      ['reward', 'itemLimit'],
      ['reward', 'minimumPurchase'],
    ];

    _.each(requiredFields, (requiredPath) => {
      const required = _.get(this.currentTemplate, requiredPath, '');
      if (_.isNil(required) || required.length === 0) {
        this.isCurrentTemplateValid = false;
        return false;
      }
    });

    if (!this.isCurrentTemplateValid) {
      return false;
    }

    if (_.get(this.currentTemplate, ['strategy']) === CollectionStampCardStrategy.SimpleProductCollecting) {
      if (_.get(this.currentTemplate, ['stampConfiguration']) < 1) {
        this.isCurrentTemplateValid = false;
        return false;
      }
    } else {
      if (Number(_.get(this.currentTemplate, ['oneTimePurchase'])) < 1) {
        this.isCurrentTemplateValid = false;
        return false;
      }
    }

    const discountType = _.get(this.currentTemplate, ['reward', 'discountType']);
    const discountMethod = _.get(this.currentTemplate, ['reward', 'discountMethod']);
    const discount = _.get(this.currentTemplate, ['reward', 'discount']);
    const minimumPurchase = _.get(this.currentTemplate, ['reward', 'minimumPurchase']);

    if (discountType === CollectionStampCardRewardDiscountType.Product) {
      // discountType = 241, product
      if (discountMethod === CollectionStampCardRewardDiscountMethod.ProductEuro) {
        // discountMethod = 3, product eur discount
        if (discount < 0) {
          this.isCurrentTemplateValid = false;
          return false;
        }
      }
      if (discountMethod === CollectionStampCardRewardDiscountMethod.ProductPercent) {
        // discountMethod = 2, product percent discount
        if (discount < 0 || discount > 100) {
          this.isCurrentTemplateValid = false;
          return false;
        }
      }
    } else if (discountType === CollectionStampCardRewardDiscountType.BasketEuro) {
      // discountType = 248, basket euro discount
      if (discount < 0) {
        this.isCurrentTemplateValid = false;
        return false;
      }

      if (minimumPurchase < discount) {
        this.isCurrentTemplateValid = false;
        return false;
      }
    } else if (discountType === CollectionStampCardRewardDiscountType.BasketPercent) {
      // discountType = 249, basket percent discount
      if (!_.includes(_.keys(BASKET_DISCOUNT_PERCENTAGES), String(discount))) {
        this.isCurrentTemplateValid = false;
        return false;
      }

      if (minimumPurchase < 3) {
        this.isCurrentTemplateValid = false;
        return false;
      }
    }

    if (this.currentTemplate?.rewardProducts?.length !== 0 && this.currentTemplate.reward?.pricingUnit === null) {
      // if reward products exists in the template, the pricing unit for them has to be defined. If pricing unit
      // is not defined, there must be
      this.isCurrentTemplateValid = false;
      return false;
    }
  };

  @action async searchCollectionStampCards({
    payload = {},
    params = {},
  }: {
    payload?: CollectionStampCardSearchPayload;
    params?: CollectionStampCardSearchQueryParameters;
  }) {
    try {
      this.isLoadingStampCards = true;

      const res = await this.client.searchCollectionStampCards(params, payload);
      this.stampCards = res.data.result;
      return res;
    } catch (err) {
      console.error(err);
    } finally {
      this.isLoadingStampCards = false;
    }
  }

  @action async searchCollectionStampCardTemplates({
    payload = {},
    params = {},
  }: {
    payload?: CollectionStampCardTemplateSearchPayload;
    params?: CollectionStampCardTemplateSearchQueryParameters;
  }) {
    try {
      this.isLoadingStampCardTemplates = true;

      const res = await this.client.searchCollectionStampCardTemplates(params, payload);
      this.stampCardTemplates = res.data.result;
    } catch (err) {
      console.error(err);
    } finally {
      this.isLoadingStampCardTemplates = false;
    }
  }

  @action async getCollectionStampCard(cardId: string) {
    try {
      this.isLoadingStampCard = true;
      this.current = null;
      const res = await this.client.getCollectionStampCard({ cardId });
      this.original = initializeCollectionStampCard(res.data);
      this.current = initializeCollectionStampCard(res.data);
    } catch (err) {
      console.error(err);
    } finally {
      this.isLoadingStampCard = false;
    }
  }

  @action async getCollectionStampCardStatistics(payload: CollectionStampCardStatisticsPayload = {}) {
    try {
      this.isLoadingStampCardStatistics = true;

      const res = await this.client.getCollectionStampCardStatistics(null, payload);
      this.stampCardStatistics = _.keyBy(
        res.data as Api.Components.Responses.CollectionStampCardStatisticsResponse[],
        'id',
      );
    } catch (err) {
      console.error(err);
    } finally {
      this.isLoadingStampCardStatistics = false;
    }
  }

  @action async createCollectionStampCard(payload: CollectionStampCardPostPayload) {
    try {
      this.isLoadingStampCard = true;
      await this.client.createCollectionStampCard(null, payload);
      this.current = null;
      this.navigate(getLink('stampCards'));
      this.stores.alertStore.success({
        message: 'Kiitos, keräilypassi on luotu. Muista markkinoida passia esim. push-viestillä.',
      });
    } catch (err) {
      console.error(err);

      const showOnUI = _.get(err, ['response', 'data', 'data', 'ui'], false);
      const key = new Date().getTime();
      const message = showOnUI
        ? _.get(err, ['response', 'data', 'message'])
        : React.createElement('span', { key: `wrapper-${key}` }, [
            'Virhe keräilypassin luomisessa. Ota yhteyttä: ',
            React.createElement(
              'a',
              { href: 'mailto:tuki.kmarkkinointi@kesko.fi', key: `link-${key}` },
              'tuki.kmarkkinointi@kesko.fi',
            ),
          ]);

      this.stores.alertStore.error({
        message,
        ttl: 10000,
      });
      document.querySelector('.alerts').scrollIntoView();
    } finally {
      this.isLoadingStampCard = false;
    }
  }

  @action async replaceCollectionStampCard(cardId: string, payload: CollectionStampCardPostPayload) {
    try {
      this.isLoadingStampCard = true;
      await this.client.replaceCollectionStampCard({ cardId }, payload);
      this.current = null;
      this.navigate(getLink('viewStampCard', cardId));
      this.stores.alertStore.success({
        message: 'Keräilypassi päivitetty!',
      });
    } catch (err) {
      console.error(err);

      const showOnUI = _.get(err, ['response', 'data', 'data', 'ui'], false);
      const key = new Date().getTime();
      const message = showOnUI
        ? _.get(err, ['response', 'data', 'message'])
        : React.createElement('span', { key: `wrapper-${key}` }, [
            'Virhe keräilypassin päivittämisessä. Ota yhteyttä: ',
            React.createElement(
              'a',
              { href: 'mailto:tuki.kmarkkinointi@kesko.fi', key: `link-${key}` },
              'tuki.kmarkkinointi@kesko.fi',
            ),
          ]);

      this.stores.alertStore.error({
        message,
        ttl: 10000,
      });
      document.querySelector('.alerts').scrollIntoView();
    } finally {
      this.isLoadingStampCard = false;
    }
  }

  @action async deleteCollectionStampCard(cardId: string, asRetailer = true) {
    try {
      if (asRetailer) {
        this.isLoadingStampCard = true;
        await this.client.deleteCollectionStampCard({ cardId });
        this.current = null;
        this.navigate(getLink('stampCards'));
        this.stores.alertStore.success({
          message: 'Keräilypassi poistettu!',
        });
      } else {
        await this.client.deleteCollectionStampCard({ cardId });
      }
    } catch (err) {
      console.error(err);

      if (asRetailer) {
        const showOnUI = _.get(err, ['response', 'data', 'data', 'ui'], false);
        const key = new Date().getTime();
        const message = showOnUI
          ? _.get(err, ['response', 'data', 'message'])
          : React.createElement('span', { key: `wrapper-${key}` }, [
              'Virhe keräilypassin poistamisessa. Ota yhteyttä: ',
              React.createElement(
                'a',
                { href: 'mailto:tuki.kmarkkinointi@kesko.fi', key: `link-${key}` },
                'tuki.kmarkkinointi@kesko.fi',
              ),
            ]);

        this.stores.alertStore.error({
          message,
          ttl: 10000,
        });
        document.querySelector('.alerts').scrollIntoView();
      }
    } finally {
      this.isLoadingStampCard = false;
    }
  }

  @action async getCollectionStampCardTemplate(templateId: string) {
    try {
      this.isLoadingStampCardTemplate = true;
      this.currentTemplate = null;
      const res = await this.client.getCollectionStampCardTemplate({ templateId });
      this.currentTemplate = res.data;
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      this.isLoadingStampCardTemplate = false;
    }
  }

  @action async createCollectionStampCardTemplate(payload: CollectionStampCardTemplatePostPayload) {
    try {
      this.isLoadingStampCardTemplate = true;

      const text = _.get(payload, ['imageData', 'text', 'fi']);
      const textType = _.get(payload, ['imageData', 'textType']) as CollectionStampCardImageTextType;
      const backgroundUrl = _.get(payload, ['imageData', 'backgroundUrl']);
      const textBackgroundColor = _.get(payload, ['imageData', 'textBackgroundColor']);
      const textForegroundColor = _.get(payload, ['imageData', 'textForegroundColor']);

      const html = getCollectionStampCardImageHTML({
        preview: false,
        text,
        textType,
        backgroundUrl,
        textBackgroundColor,
        textForegroundColor,
      });

      const url = await this.createCollectionStampCardTemplateImage(html, {
        textType,
        backgroundUrl,
      });

      payload.uiData.imageUrl.fi = url;
      const res = await this.client.createCollectionStampCardTemplate(null, payload);
      this.currentTemplate = res.data;
      this.currentTemplateState = EditState.Saved;
    } catch (err) {
      console.error(err);
      this.currentTemplateState = EditState.Error;
      throw err;
    } finally {
      this.isLoadingStampCardTemplate = false;
    }
  }

  @action async replaceCollectionStampCardTemplate(
    templateId: string,
    payload: CollectionStampCardTemplatePostPayload,
  ) {
    try {
      this.isLoadingStampCardTemplate = true;

      const text = _.get(payload, ['imageData', 'text', 'fi']);
      const textType = _.get(payload, ['imageData', 'textType']) as CollectionStampCardImageTextType;
      const backgroundUrl = _.get(payload, ['imageData', 'backgroundUrl']);
      const textBackgroundColor = _.get(payload, ['imageData', 'textBackgroundColor']);
      const textForegroundColor = _.get(payload, ['imageData', 'textForegroundColor']);

      const html = getCollectionStampCardImageHTML({
        preview: false,
        text,
        textType,
        backgroundUrl,
        textBackgroundColor,
        textForegroundColor,
      });

      const url = await this.createCollectionStampCardTemplateImage(html, {
        textType,
        backgroundUrl,
      });

      payload.uiData.imageUrl.fi = url;
      const res = await this.client.replaceCollectionStampCardTemplate({ templateId }, payload);
      this.currentTemplate = res.data;
      this.currentTemplateState = EditState.Saved;
    } catch (err) {
      console.error(err);
      this.currentTemplateState = EditState.Error;
    } finally {
      this.isLoadingStampCardTemplate = false;
    }
  }

  @action async createCollectionStampCardImage(
    html: string,
    {
      text,
      textType,
      backgroundUrl,
      textBackgroundColor,
      textForegroundColor,
    }: {
      text: string;
      textType: CollectionStampCardImageTextType;
      backgroundUrl: string;
      textBackgroundColor: string;
      textForegroundColor: string;
    },
  ) {
    try {
      this.isLoadingStampCardImage = true;
      let url = backgroundUrl;

      // We only need to generate card image if there is text to display. Otherwise we can use the background.
      if (textType !== CollectionStampCardImageTextType.None) {
        const { data } = await this.client.createCollectionStampCardImage(null, { html });
        url = data.url;
      }

      this.current.uiData.imageUrl.fi = url;
      this.current.imageData = {
        text: { fi: text },
        textType,
        backgroundUrl,
        textBackgroundColor,
        textForegroundColor,
      };
    } catch (err) {
      console.error(err);
    } finally {
      this.isLoadingStampCardImage = false;
    }
  }

  @action async createCollectionStampCardTemplateImage(
    html: string,
    {
      textType,
      backgroundUrl,
    }: {
      textType: CollectionStampCardImageTextType;
      backgroundUrl: string;
    },
  ) {
    try {
      let url = backgroundUrl;

      // We only need to generate card image if there is text to display. Otherwise we can use the background.
      if (textType !== CollectionStampCardImageTextType.None) {
        const { data } = await this.client.createCollectionStampCardImage(null, { html });
        url = data.url;
      }

      return url;
    } catch (err) {
      console.error(err);
    }
  }

  @action async deleteCollectionStampCardTemplate(templateId: string) {
    try {
      await this.client.deleteCollectionStampCardTemplate({ templateId });
    } catch (err) {
      console.error(err);
    } finally {
    }
  }
}
