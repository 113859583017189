import React, { useContext, useEffect, useState } from 'react';
import { Navigate, useNavigate, useLocation, Route, Routes } from 'react-router-dom';
import UserStore from 'stores/userStore';
import { inject, MobXProviderContext, observer } from 'mobx-react';
import * as _ from 'lodash';
import * as routes from './routes';
import * as api from 'utils/next-api';
import './styles/index.scss';
import './styles/app.scss';
import AppStore from 'stores/next/app';
import DeliveriesStore from 'stores/next/deliveries';
import DeliveryTemplateStore from 'stores/next/deliveryTemplates';
import ConceptsStore from 'stores/next/programs';
import ContentBlockTemplateStore from 'stores/next/contentBlockTemplates';
import MetadataStore from 'stores/next/metaData';
import StoreStore from 'stores/next/stores';
import PricingStore from 'stores/next/pricing';
import HelpStore from 'stores/next/help';
import AlertStore from 'stores/next/alerts';
import ReportsStore from 'stores/next/reports';
import ResultsStore from 'stores/next-retailer/resultsStore';
import DeliveryStore from 'stores/next-retailer/deliveryStore';
import PushNotificationsStore from 'stores/next-retailer/pushNotificationsStore';
import StampCardStore from 'stores/next/stampCard';
import SettingsStore from 'stores/next/setting';
import orange from '@material-ui/core/colors/deepOrange';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import fiLocale from 'date-fns/locale/fi';
import DateFnsUtils from '@date-io/date-fns';

/* Pages */
import Home from './pages/home/home';
// import Customers from './pages/customers/customers';
// import Offers from './pages/offers/offers';
import Deliveries from './pages/deliveries/deliveries';
import Stores from './pages/stores';
import PricingItems from './pages/pricingItems';
import PricingItem from './pages/pricingItem';
// import DeliveryTemplates from './pages/deliveryTemplates';
import Delivery from './pages/delivery/delivery';
// import DeliveryTemplate from './pages/deliveryTemplate';
import Concepts from './pages/concepts';
import Concept from './pages/concept';
// import Error from './pages/error';
import ContentBlockTemplates from './pages/contentBlockTemplates';
import ContentBlockTemplate from './pages/contentBlockTemplate';
import ContentManager from './pages/contentManager';
import PushNotifications from './pages/pushNotifications';
import CollectionStampCards from './pages/collectionStampCards';
import CollectionStampCardTemplates from './pages/collectionStampCardTemplates';
import CollectionStampCardTemplateEditor, {
  StampCardTemplateEditorMode,
} from './pages/collectionStampCardTemplateEditor';
import Reports from './pages/reports';
import Statistics from './pages/statistics';

/* Components */
import Header from './components/header/header';
import Navigation from './components/navigation/navigation';
import ContentStore from 'stores/next-retailer/contentStore';
import { OpenAPIClient } from 'openapi-client-axios';
import HelpOverlay from 'components/common/next/help/helpOverlay';
import StatisticsStore from '../../stores/statisticsStore';
import SettingsPage from './pages/settings/index';
import DimensionsStore from '../../stores/next/dimensions';
import DimensionPage from './pages/dimension/index';
import PricingGroupStore from 'stores/next/pricingGroup';
import InvoicesStore from '../../stores/next/invoices';
import { Chain } from 'enums/common';
import Spinner from 'components/common/next/spinner';
import StampCardEditor, {
  StampCardEditorMode,
} from 'components/retailer/next/components/stampCard/stampCardEditor/stampCardEditor';
import StampCardStoreNew from 'stores/next-retailer/stampCardStore';
import StampCardImageEditor from 'components/retailer/next/components/stampCard/stampCardImageEditor';
import StampCardProductPicker from 'components/retailer/next/components/stampCard/stampCardProductPicker';
import Drawer from 'components/retailer/next/components/wrappers/drawer';
import StampCardViewer from 'components/retailer/next/components/stampCard/stampCardViewer';

interface AppProps {
  userStore?: UserStore;
}
interface AppState {
  ready: boolean;
}

const defaultMaterialTheme = createTheme({
  palette: {
    primary: orange,
  },
});

const appStore = new AppStore();
const deliveriesStore = new DeliveriesStore();
const pricingGroupStore = new PricingGroupStore();
const deliveryTemplateStore = new DeliveryTemplateStore();
const conceptsStore = new ConceptsStore();
const contentBlockTemplateStore = new ContentBlockTemplateStore();
const metadataStore = new MetadataStore();
const contentStore = new ContentStore();
const storeStore = new StoreStore();
const pricingStore = new PricingStore();
const helpStore = new HelpStore();
const alertStore = new AlertStore();
const resultsStore = new ResultsStore();
const deliveryStore = new DeliveryStore();
const pushNotificationsStore = new PushNotificationsStore();
const stampCardStore = new StampCardStore();
const reportsStore = new ReportsStore();
const settingsStore = new SettingsStore();
const statisticsStore = new StatisticsStore();
const dimensionsStore = new DimensionsStore();
const invoicesStore = new InvoicesStore();
const stampCardStoreNew = new StampCardStoreNew();

const App = ({ userStore }: AppProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const setApiClients = async () => {
      const untypedClient = await api.getClient<OpenAPIClient>();
      const typedClient = await api.getClient();

      deliveriesStore.client = untypedClient;
      deliveryTemplateStore.client = untypedClient;
      conceptsStore.client = untypedClient;
      contentBlockTemplateStore.client = untypedClient;

      metadataStore.client = typedClient;
      contentStore.client = typedClient;
      storeStore.client = typedClient;
      pricingStore.client = typedClient;
      pricingGroupStore.client = typedClient;
      resultsStore.client = typedClient;
      deliveryStore.client = typedClient;
      pushNotificationsStore.client = typedClient;
      stampCardStore.client = typedClient;
      stampCardStore.navigate = navigate;
      reportsStore.client = typedClient;
      reportsStore.stores = { alertStore: alertStore };
      settingsStore.client = typedClient;
      statisticsStore.client = typedClient;
      dimensionsStore.client = typedClient;
      invoicesStore.client = typedClient;

      deliveriesStore.stores = {
        deliveryTemplatesStore: deliveryTemplateStore,
      };
      stampCardStoreNew.client = typedClient;
      stampCardStoreNew.stores = { alertStore: alertStore };
      stampCardStoreNew.navigate = navigate;

      setIsReady(true);
    };
    setApiClients();
  }, []);

  // Change Chain with location changes
  useEffect(() => {
    const chainKey = _.find(Object.keys(Chain), (k) => location.pathname.indexOf(Chain[k]) > -1);
    const chain = Chain[chainKey] || Chain.kRuoka;
    if (appStore.chain !== chain) {
      appStore.setChain(chain);
    }
  }, [location]);

  const renderNavigation = () => {
    return <Navigation type="pages" />;
  };

  const ChainRedirect = () => {
    const { chain } = appStore;
    return <Navigate to={routes.home.link.replace(/:chain/, chain)} replace />;
  };

  const renderMain = () => {
    const { chainRoute } = appStore;
    const { chainIds } = chainRoute;
    return (
      <div className="app-main-content">
        <Routes>
          <Route path="/" element={<ChainRedirect />} />

          <Route path={routes.home.route} element={<Home chainIds={chainIds} />} />
          <Route path={routes.concepts.route} element={<Concepts chainIds={chainIds} />} />
          <Route path={`${routes.concept.route}/*`} element={<Concept chainIds={chainIds} />} />
          <Route path={routes.deliveries.route} element={<Deliveries chainIds={chainIds} />} />
          <Route path={routes.delivery.route} element={<Delivery />} />
          <Route path={routes.contentBlocks.route} element={<ContentBlockTemplates />} />
          <Route path={routes.contentBlock.route} element={<ContentBlockTemplate />} />
          <Route path={routes.content.route} element={<ContentManager />} />
          <Route path={routes.pricingItems.route} element={<PricingItems />} />
          <Route path={routes.pricingItem.route} element={<PricingItem />} />
          <Route path={routes.stores.route} element={<Stores chainIds={chainIds} />} />
          <Route path={routes.settings.route} element={<SettingsPage chainIds={chainIds} />} />
          <Route path={`${routes.dimension.route}/*`} element={<DimensionPage chainIds={chainIds} />} />
          <Route path={routes.pushNotifications.route} element={<PushNotifications />} />
          {/* The keys are imortant here, will cause the other CollectionStampCards component to unmount */}
          <Route
            path={routes.keskoStampCards.route}
            element={<CollectionStampCards key={'chain-stamp-cards'} keskoStampCard={true} />}
          />
          <Route path={routes.collectionStampCards.route} element={<CollectionStampCards key={'stamp-cards'} />} />
          <Route path={routes.collectionStampCardTemplates.route} element={<CollectionStampCardTemplates />} />
          <Route
            path={routes.viewKeskoStampCard.route}
            element={
              <div className="app next-retailer" style={{ width: '100%' }}>
                <StampCardViewer keskoStampCard={true} />
              </div>
            }
          />
          <Route
            path={routes.createKeskoStampCard.route}
            element={
              <div className="app next-retailer" style={{ width: '100%' }}>
                <StampCardEditor keskoStampCard={true} mode={StampCardEditorMode.Create} />
              </div>
            }
          >
            <Route
              path="image"
              element={
                <Drawer open navigateBack>
                  <StampCardImageEditor keskoStampCard={true} newStore={true} />
                </Drawer>
              }
            />
            <Route
              path="picker/:type"
              element={
                <Drawer open navigateBack>
                  <StampCardProductPicker newStore={true} />
                </Drawer>
              }
            />
          </Route>
          <Route
            path={routes.editKeskoStampCard.route}
            element={
              <div className="app next-retailer" style={{ width: '100%' }}>
                <StampCardEditor keskoStampCard={true} mode={StampCardEditorMode.Edit} />
              </div>
            }
          >
            <Route
              path="image"
              element={
                <Drawer open navigateBack>
                  <StampCardImageEditor keskoStampCard={true} newStore={true} />
                </Drawer>
              }
            />
            <Route
              path="picker/:type"
              element={
                <Drawer open navigateBack>
                  <StampCardProductPicker newStore={true} />
                </Drawer>
              }
            />
          </Route>
          <Route
            path={routes.createCollectionStampCardTemplate.route}
            element={<CollectionStampCardTemplateEditor mode={StampCardTemplateEditorMode.Create} />}
          />
          <Route
            path={routes.editCollectionStampCardTemplate.route}
            element={<CollectionStampCardTemplateEditor mode={StampCardTemplateEditorMode.Edit} />}
          />
          <Route path={routes.reports.route} element={<Reports chainIds={chainIds} />} />
          <Route path={routes.statistics.route} element={<Statistics chainIds={chainIds} />} />

          {/* ROUTES NOT IN USE */}
          {/* <Route path={routes.deliveryTemplates.route} element={<DeliveryTemplates />} />
            <Route path={routes.createDeliveryTemplate.route} element={<DeliveryTemplate />} />
            <Route path={routes.customers.route} element={<Customers />} />
            <Route path={routes.offers.route} element={<Offers />} /> */}
        </Routes>
      </div>
    );
  };

  const renderContent = () => {
    if (!isReady) {
      return <Spinner />;
    }
    return (
      <>
        {renderNavigation()}
        {renderMain()}
      </>
    );
  };

  const stores = {
    deliveriesStore,
    deliveryTemplateStore,
    appStore,
    conceptsStore,
    contentBlockTemplateStore,
    metadataStore,
    contentStore,
    storeStore,
    pricingStore,
    helpStore,
    alertStore,
    resultsStore,
    deliveryStore,
    pushNotificationsStore,
    stampCardStore,
    reportsStore,
    settingsStore,
    statisticsStore,
    dimensionsStore,
    pricingGroupStore,
    invoicesStore,
    stampCardStoreNew,
  };

  const banner = process.env.REACT_APP_ADMIN_BANNER;
  const isIE = navigator.userAgent.indexOf('MSIE ') > -1 || navigator.userAgent.indexOf('Trident/') > -1; // Should detect IEs <= 11

  const contextValue = useContext(MobXProviderContext);
  return (
    <MobXProviderContext.Provider value={{ ...contextValue, ...stores }}>
      <ThemeProvider theme={defaultMaterialTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fiLocale}>
          <main className="next" lang="en">
            {(banner || isIE) && (
              <div className="env-banner">
                <span>{banner}</span>
                {isIE && (
                  <span className="ie-warn">
                    Note: Internet Explorer is not fully supported on this page and some features may not function
                    properly. Please consider using another browser.
                  </span>
                )}
              </div>
            )}
            <Header user={userStore.me} chain={appStore.chain} />
            {renderContent()}
            <HelpOverlay />
          </main>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </MobXProviderContext.Provider>
  );
};

export default inject('userStore')(observer(App));
