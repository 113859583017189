import React from 'react';
import { inject, observer } from 'mobx-react';
import { ReactComponent as IconClose } from '@kesko/icons/action/icon-close.svg';
import HelpStore from 'stores/next/help';
import './helpOverlay.scss';

interface Props {}

interface InjectedProps {
  helpStore: HelpStore;
}

@inject('helpStore')
@observer
class HelpOverlay extends React.Component<Props> {
  get injected() {
    return this.props as InjectedProps;
  }

  get helpStore() {
    return this.injected.helpStore;
  }

  componentDidMount() {
    window.addEventListener('click', this.outsideClose);
    document.addEventListener('keyup', this.escapeClose);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.outsideClose);
    document.removeEventListener('keyup', this.escapeClose);
  }

  escapeClose = (event: KeyboardEvent) => {
    if (event.keyCode === 27) {
      this.close();
    }
  };

  outsideClose = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (target.className === 'help-overlay') {
      this.close();
    }
  };

  close = () => {
    this.helpStore.closeHelp();
  };

  render() {
    const classes = ['help-overlay'];
    if (!this.helpStore.overlay) {
      classes.push('hidden');
    }
    return (
      <div className={classes.join(' ')}>
        <div className="help-overlay-content">
          <div className="help-close">
            <button onClick={this.close}>
              <IconClose />
            </button>
          </div>
          {this.injected.helpStore.content}
        </div>
      </div>
    );
  }
}

export default HelpOverlay;
