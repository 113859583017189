import React, { SyntheticEvent } from 'react';
import Button from '../../../../../../common/next/form/button';
import { getLink } from 'components/retailer/next/routes';
import DeliveryStore, { DeliveryWithMeta } from 'stores/next-retailer/deliveryStore';
import './offersBox.scss';
import { inject, observer } from 'mobx-react';
import { imageSrc } from 'utils/helpers';
import { ReactComponent as IconInfo } from '@kesko/icons/attention/icon-info.svg';
import { ReactComponent as IconCreditCard } from '@kesko/icons/shopping/icon-payment_creditCard.svg';
import { DeliveryTemplateWithMeta } from 'stores/next-retailer/templateStore';
import type { DeliveryOffer, OfferOption } from 'types/next';
import _ from 'utils/lodash';
import { useNavigate } from 'react-router-dom';

interface InjectedProps {
  deliveryStore?: DeliveryStore;
}

interface Props extends InjectedProps {
  deliveryOffers: DeliveryOffer[];
  delivery: DeliveryWithMeta; // Delivery & DeliveryRelations
  template: Partial<DeliveryTemplateWithMeta>;
  disabled?: boolean;
  addingDisabled?: boolean;
}

export const isRecommendedOffer = (offer: DeliveryOffer, offerOptions: OfferOption[]): boolean =>
  Boolean(offer && offer.offerOptionId && _.find(offerOptions, { id: offer.offerOptionId }));

export const isLockedOffer = (offer: DeliveryOffer, offerOptions: OfferOption[]): boolean => {
  if (offer) {
    if (offer.required) {
      return true;
    }
    if (offer.offerOptionId) {
      const offerOption = _.find(offerOptions, { id: offer.offerOptionId });
      return offerOption && offerOption.required;
    }
  }
  return false;
};

const OffersBox = ({ deliveryStore, deliveryOffers, delivery, template, disabled, addingDisabled }: Props) => {
  const navigate = useNavigate();

  const getDeliveryOffers = () => {
    return deliveryOffers || [];
  };

  const getRequiredRecommendations = () => {
    return template.offerOpts.requiredRecommendations || 0;
  };

  const handleNewOffer = () => {
    navigate('offer/new');
  };

  const handleRemoveOffer = (id: string) => {
    return (e: SyntheticEvent) => {
      e.preventDefault();
      const offer = _.find(getDeliveryOffers(), { id });
      if (window.confirm(`Haluatko varmasti poistaa edun "${offer.title.fi}"?`)) {
        const deliveryOffers = delivery.deliveryOffers.filter((o) => o.id !== id);
        deliveryStore.setCurrent({ ...delivery, deliveryOffers });
      }
    };
  };

  const recommendedOffersCount = (deliveryOffers: DeliveryOffer[]): number =>
    deliveryOffers.reduce((count, offer) => {
      return isRecommendedOffer(offer, template.offerOptions) ? ++count : count;
    }, 0);

  const chooseDeliveryOfferEditMode = (offer: DeliveryOffer): string => {
    if (isLockedOffer(offer, template.offerOptions)) {
      return 'Tarkastele';
    }
    return isRecommendedOffer(offer, template.offerOptions) ? 'Vaihda' : 'Muokkaa';
  };

  const isDeliveryOfferEditable = (offer: DeliveryOffer) => {
    // Offer options might be locked thus required.
    if (offer.required) {
      return false;
    }

    // After delivery offer has been made based on offer option, it loses the "required" info so check if delivery offer
    // is based on some offer option and check if that one was required.
    const offerOptionId = offer.offerOptionId;
    if (offerOptionId) {
      const offerOption = _.find(template.offerOptions, { id: offerOptionId });
      if (offerOption && offerOption.required) {
        return false;
      }
    }

    return true;
  };

  return (
    <div className="box delivery-offers">
      {getRequiredRecommendations() > 0 && (
        <span className="info-text">
          Suosituseduista valittu
          {` ${recommendedOffersCount(getDeliveryOffers())}/${getRequiredRecommendations()}`}
        </span>
      )}
      {getDeliveryOffers().map((offer) => (
        <div key={offer.id} className="delivery-offer">
          <div className="offer-image">
            {offer.createTosOffer && (
              <div title="Tuotteesta luodaan OmaPlussa-etu">
                <IconCreditCard className="plussa-offer" />
              </div>
            )}
            <img src={imageSrc(offer.image, { fm: 'png', w: 100 })} alt="offer" />
          </div>
          <div className="offer-info">
            <span className="offer-title">{offer.title.fi}</span>
            {isRecommendedOffer(offer, template.offerOptions) && (
              <span className="info-text">
                <IconInfo /> Suositeltu
              </span>
            )}
            {isLockedOffer(offer, template.offerOptions) && (
              <span className="info-text">
                <IconInfo /> Lukittu
              </span>
            )}
          </div>
          {!disabled && (
            <div className="offer-actions">
              <Button color="bordered" link={`offer/${offer.id}`}>
                {chooseDeliveryOfferEditMode(offer)}
              </Button>
              <Button
                color="bordered"
                disabled={isLockedOffer(offer, template.offerOptions)}
                onClick={handleRemoveOffer(offer.id)}
              >
                Poista
              </Button>
            </div>
          )}
        </div>
      ))}
      {!disabled && !addingDisabled && (
        <div className="delivery-offers-footer">
          {getDeliveryOffers().length < template.offerOpts.max && (
            <Button color="bordered" onClick={handleNewOffer}>
              {/* Lisää etu */}
              VALITSE TUOTTEET
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default inject('deliveryStore')(observer(OffersBox));
