import axios from 'axios';
import { omitBy, isNil } from 'lodash';
import type { Product, BTTProduct } from 'types/campaign';
import type { DateString } from 'types/string';

const API_URL = process.env.REACT_APP_API_URL;

// Needed in NEXT, route changed on backend side also
const productSearchUrl = `${API_URL}/next/products/actions/search`;
const bttProductSearchUrl = `${API_URL}/next/products-btt/actions/search`;
// Needed in NEXT, route changed on backend side also
export const discountImageUrl = (chain: number, price?: number, percent?: number) => {
  let text;
  if (!isNil(price)) {
    text = `-${price}€`;
  } else if (!isNil(percent)) {
    text = `-${percent}%`;
  }
  return !isNil(chain) && !isNil(text)
    ? `${API_URL}/next/promotions/discount/${chain}_${encodeURIComponent(text)}.png`
    : null;
};

// This function is used also by NEXT
export async function productSearch(
  query?: string,
  limit?: number,
  offset?: number,
  startTime?: DateString,
  endTime?: DateString,
  filters?: { ean: string[] },
): Promise<Product[]> {
  const searchData = omitBy({ query, filters, limit, offset }, isNil);
  if (startTime && endTime) {
    const start = startTime.slice(0, 10);
    const end = endTime.slice(0, 10);
    searchData.campaignDates = { start, end };
  }
  const { data } = await axios.post(productSearchUrl, searchData, {
    withCredentials: true,
  });
  const { result } = data;
  return result || [];
}

export async function bttProductSearch(
  query?: string,
  limit?: number,
  offset?: number,
  filters?: { ean: string[] },
): Promise<BTTProduct[]> {
  const searchData = omitBy({ query, filters, limit, offset }, isNil);
  const { data } = await axios.post(bttProductSearchUrl, searchData, {
    withCredentials: true,
  });
  const { result } = data;
  return result || [];
}
