import React from 'react';
import { ReactComponent as IconInfo } from '@kesko/icons/attention/icon-info.svg';

import './helpButton.scss';
import { inject, observer } from 'mobx-react';
import HelpStore from 'stores/next/help';

interface Props {
  renderContent?: () => any;
}

interface InjectedProps {
  helpStore: HelpStore;
}

@inject('helpStore')
@observer
class HelpButton extends React.Component<Props> {
  get injected() {
    return this.props as InjectedProps;
  }

  get helpStore() {
    return this.injected.helpStore;
  }

  click = () => {
    const { renderContent } = this.props;
    this.helpStore.openHelp(renderContent());
  };

  render() {
    return (
      <button className="help-button" onClick={this.click}>
        <IconInfo />
      </button>
    );
  }
}

export default HelpButton;
