import React from 'react';
import { inject, observer } from 'mobx-react';
import { isKRautaChain, isOnninenChain } from 'utils/helpers';
import { ConceptType } from 'enums/common';
import ConceptListView from '../concept/conceptList';
import UserStore from 'stores/userStore';

interface Props {
  userStore?: UserStore;
}

const ProgramsListView = ({ userStore }: Props) => {
  const isKRauta = isKRautaChain(userStore.me.chainId);

  const program_header = isKRauta ? 'Ketjun lähetykset' : 'Sesonkilähetykset 2024';
  const program_content = isKRauta ? (
    `Ketjun lähetykset ovat ketjun sähköpostisuoria joiden sisällöt on ennakkoon määritelty lähestyttäville asiakasryhmille sopiviksi. Kauppa voi lisätä ketjun lähetyksiin kauppakohtaista sisältöä ja ne lähetetään kaupan asiakkaille kaupan allekirjoituksella.
    `
  ) : (
    <ul>
      <li>pääsiäinen</li>
      <li>kesäasiakaslähetykset x 4</li>
      <li>pyhäinpäivä</li>
      <li>joulu</li>
    </ul>
  );

  return (
    <div className="front-page">
      <div className="main-content">
        <div className="content programs-list-view">
          <h2>{program_header}</h2>
          {isKRauta ? <p>{program_content}</p> : <strong>{program_content}</strong>}
          <ConceptListView filters={{ type: ConceptType.Program }} />
        </div>
      </div>
    </div>
  );
};

export default inject('userStore')(observer(ProgramsListView));
