import React from 'react';
import type { ContentField } from 'components/next/types';
import ContentFieldEditor from 'components/next/components/fieldEditor';
import { getContentFieldDefaults, changeOrder } from 'components/next/utils';
import * as _ from 'lodash';

interface ContentFieldsProps {
  contentFields: ContentField[];
  update(c: ContentField[]);
}

export default class ContentFields extends React.Component<ContentFieldsProps> {
  add = () => {
    this.props.update([
      ...this.props.contentFields,
      {
        ...getContentFieldDefaults(this.props.contentFields.length),
        order: this.props.contentFields.length, // set as last field
      },
    ]);
  };

  delete = (id: string) => {
    const { update, contentFields } = this.props;
    const confirmed = window.confirm('Are you sure you want to delete this field?');
    if (confirmed) {
      update(_.reject(contentFields, { id }));
    }
  };

  update = (contentField: ContentField) => {
    const { update, contentFields } = this.props;
    const index = _.findIndex(contentFields, (f) => f.id === contentField.id);
    contentFields[index] = contentField;
    update(contentFields);
  };

  changeOrder = (index: number, shiftUp: boolean) => {
    const { update, contentFields } = this.props;
    update(changeOrder(contentFields, index, shiftUp));
  };

  render() {
    const { contentFields } = this.props;
    return (
      <div className="editor-tab content-fields">
        <button className="add-content-field" onClick={this.add}>
          Add content field
          <img src={require('images/add.svg').default} alt="add" />
        </button>
        {_.orderBy(contentFields, ['order']).map((c, i) => (
          <ContentFieldEditor
            key={`field-${i}`}
            index={i}
            contentField={getContentFieldDefaults(i, c)}
            update={this.update}
            deleteField={this.delete}
            changeOrder={this.changeOrder}
          />
        ))}
      </div>
    );
  }
}
