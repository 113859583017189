import React, { useState, useEffect, useRef } from 'react';
import Navigation from '../navigation/navigation';
import NameLogo from 'components/common/nameLogo';
import GitHash from 'components/common/next/gitHash';
import { home } from 'components/next/routes';
import type { User } from 'types/user';

import './header.scss';
import { useNavigate } from 'react-router-dom';

interface HeaderProps {
  user?: User;
  chain: string;
}

const Header = ({ user, chain }: HeaderProps) => {
  const navigate = useNavigate();
  const menuRef = useRef<HTMLDivElement>();

  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const toggleUserMenu = () => setUserMenuOpen(!userMenuOpen);

  const outsideClick = (e: MouseEvent) => {
    if (userMenuOpen && menuRef.current && !menuRef.current.contains(e.target as Node)) {
      toggleUserMenu();
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', outsideClick);
    return () => {
      document.removeEventListener('mousedown', outsideClick);
    };
  });

  return (
    <header className={`next-header ${chain}`}>
      <NameLogo admin={true} toPath={home.link.replace(/:chain/, chain)} />
      <Navigation type="header" />
      <div className="user-menu" ref={menuRef}>
        <GitHash />
        <span onClick={toggleUserMenu}>{user.email}</span>
        {userMenuOpen && (
          <nav className="user-dropdown">
            <div className="dropdown-item" onClick={() => navigate('/logout')}>
              Log out
            </div>
          </nav>
        )}
      </div>
    </header>
  );
};

export default Header;
