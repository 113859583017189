import _ from 'lodash';
import { observable, action } from 'mobx';
import { OpenAPIClient } from 'openapi-client-axios';
import { StoreState } from 'enums/common';
import type { ContentBlockTemplate, ContentBlockTemplateSearchPayload } from 'types/next';

export default class ContentBlockStore {
  client: OpenAPIClient;

  @observable collection: ContentBlockTemplate[] = [];

  @observable contentBlockTemplate: ContentBlockTemplate = undefined;
  @observable error = undefined;
  @observable count = 0;
  @observable status: StoreState = StoreState.ready;

  get contentBlockTemplates() {
    return this.collection;
  }

  @action async search(payload: ContentBlockTemplateSearchPayload = {}) {
    this.status = StoreState.loading;
    const result = await this.client.searchContentBlockTemplates(null, payload);
    this.count = result.data.count;
    this.updateCollection(result.data.result);
  }

  @action async findById(id: number | string) {
    const result = await this.client.getContentBlockTemplate(id);
    if (result.status === 200) {
      this.setContentBlock(result.data);
    }
  }

  // eslint-disable-next-line
  @action async updateCollection(contentBlockTemplates: ContentBlockTemplate[]) {
    for (const template of contentBlockTemplates) {
      const index = _.findIndex(this.collection, { id: template.id });
      if (index !== -1) {
        // update
        if (!_.isEqual(this.collection[index], template)) {
          this.collection[index] = template;
        } else {
          // no need to update
        }
      } else {
        this.collection = [...this.collection, template];
      }
    }
    this.status = StoreState.ready;
  }

  @action public deleteContentBlockTemplateFromCollection(id: string | number) {
    const reportIndex = _.findIndex(this.collection, { id: String(id) });
    const templates = [...this.collection];
    templates.splice(reportIndex, 1);
    this.collection = templates;
    return this.collection;
  }

  @action async createContentBlockTemplate(contentBlockTemplate: ContentBlockTemplate) {
    this.status = StoreState.loading;
    try {
      const result = await this.client.createContentBlockTemplate(null, contentBlockTemplate);
      if (result.status === 201) {
        this.search();
        return result.data as ContentBlockTemplate;
      }
    } catch (error) {
      this.status = StoreState.error;
    }
  }

  @action async deleteContentBlockTemplate(id: string) {
    const result = await this.client.deleteContentBlockTemplate(parseInt(id, 10));
    if (result.status === 200) {
      this.deleteContentBlockTemplateFromCollection(id);
      this.status = StoreState.ready;
    } else {
      this.status = StoreState.error;
    }
  }

  @action setContentBlocks(contentBlocks) {
    this.collection = contentBlocks;
  }

  @action setContentBlock(contentBlock) {
    this.contentBlockTemplate = contentBlock;
  }

  @action setError() {
    // @TODO actual error messages?
    this.error = 'updating content block failed';
  }

  @action async updateContentBlock(contentBlock) {
    const result = await this.client.replaceContentBlockTemplate(contentBlock.id, contentBlock);
    if (result.status === 200) {
      this.contentBlockTemplate = result.data;
    } else {
      this.setError();
    }
  }
}
