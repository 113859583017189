import React, { useEffect, useState } from 'react';
import type {
  ContentTemplate,
  ContentSlot,
  ContentBlock,
  ContentField,
  EmailContentTemplate,
  PrintContentTemplate,
  PrintRecipe,
  DeliveryLanguage,
  RecipeHTMLElement,
} from 'components/next/types';
import { RadioButton } from 'components/next/components/radioButton/radioButton';
import LanguageSelector from 'components/common/next/languageSelector';
import SlotEditor from './slotEditor';
import EditorBody from './editorBody';
import * as _ from 'lodash';
import { getContentSlotDefaults, mapHTMLContentToFields, parseEntitiesByName } from 'components/next/utils';
import './contentTemplateEditor.scss';
import { ReactComponent as IconMinus } from '@kesko/icons/action/icon-minus.svg';
import { ReactComponent as IconPlus } from '@kesko/icons/action/icon-plus.svg';
import { ReactComponent as IconUp } from '@kesko/icons/action/icon-push_up.svg';
import { ReactComponent as IconDown } from '@kesko/icons/action/icon-push_down.svg';
import { ReactComponent as IconDelete } from '@kesko/icons/action/icon-delete.svg';
import { ReactComponent as IconCopy } from '@kesko/icons/action/icon-copy.svg';
import { ConceptType, DeliveryChannelName } from 'enums/common';

export interface ContentTemplateEditorProps {
  template: ContentTemplate;
  index: number;
  contentBlocks: ContentBlock[];
  contentFields: ContentField[];
  isExpanded: boolean;
  conceptType: ConceptType;
  edit(index: number, f: string, v: string | string[] | ContentSlot[] | boolean | number | PrintRecipe): void;
  delete(i: number): void;
  changeOrder(index: number, shiftUp: boolean): void;
  toggleExpanded(index: number): void;
  duplicate(id: string): void;
}

const ContentTemplateEditor = ({
  template,
  index,
  contentBlocks,
  contentFields,
  isExpanded,
  conceptType,
  edit,
  delete: deleteTemplate,
  changeOrder,
  toggleExpanded,
  duplicate,
}: ContentTemplateEditorProps) => {
  const [contentSlots, setContentSlots] = useState<ContentSlot[]>([]);
  const [matchingFields, setMatchingFields] = useState([]);
  const [nonMatchingFields, setNonMatchingFields] = useState([]);
  const [language, setLanguage] = useState<DeliveryLanguage>('fi');

  const emailTemplate = template.channel === 'email' ? (template as EmailContentTemplate) : null;
  const printTemplate =
    template.channel === 'print'
      ? (template as PrintContentTemplate & { recipeField: { fi?: string; sv?: string } })
      : null;

  useEffect(() => {
    if (emailTemplate && emailTemplate.html) {
      mapHtmlToSlots(false);
      mapHtmlToFields(emailTemplate.html[language]);
    }
  }, []);

  const templateHtml = () => {
    if (emailTemplate) {
      return emailTemplate.html.fi.concat(emailTemplate.html.sv);
    }
    const elems = [...printTemplate.recipe.fi.elements, ...printTemplate.recipe.sv.elements];
    return elems
      .filter((element) => element.type === 'html')
      .map((element) => (element as RecipeHTMLElement).html)
      .join('');
  };

  const mapHtmlToFields = (html: string, shouldCallUpdate = false) => {
    const { matchingFields, nonMatchingFields } = mapHTMLContentToFields(html, contentFields);

    if (nonMatchingFields) {
      setNonMatchingFields(nonMatchingFields);
    }

    if (matchingFields) {
      setMatchingFields(matchingFields);

      if (shouldCallUpdate) {
        onEdit(
          'contentFieldIdentifiers',
          matchingFields.map((field) => field.identifier),
        );
      }
    }
  };

  const mapHtmlToSlots = (shouldCallUpdate = false) => {
    const html = templateHtml();
    const slotIdentifiers = parseEntitiesByName(html, 'slot');
    if (!slotIdentifiers) {
      setContentSlots([]);
      return;
    }

    const contentSlots = slotIdentifiers.map((identifier) => {
      const contentSlot =
        template.contentSlots.find((contentSlot) => contentSlot.identifier === identifier) ||
        getContentSlotDefaults(identifier);
      return contentSlot;
    });

    setContentSlots(contentSlots);
    if (shouldCallUpdate) {
      onEdit('contentSlots', contentSlots);
    }
  };

  const onEdit = (fieldName: string, value: any) => {
    edit(index, fieldName, value);
  };

  const editHtml = (value: string, shouldCallUpdate = true) => {
    if (shouldCallUpdate) {
      onEdit(`html.${language}`, value);
    }
    mapHtmlToSlots(shouldCallUpdate);
    mapHtmlToFields(value, shouldCallUpdate);
  };

  const onUpdateSlot = (index: number, slot: ContentSlot) => {
    const contentSlots = [...template.contentSlots];
    contentSlots[index] = slot;
    setContentSlots(contentSlots);
    onEdit('contentSlots', contentSlots);
  };

  const onDelete = () => {
    const confirmed = window.confirm('Are you sure you want to delete this content template?');
    if (confirmed) {
      deleteTemplate(index);
    }
  };

  const onChangeOrder = (index: number, shiftUp: boolean) => {
    toggleExpanded(undefined);
    changeOrder(index, shiftUp);
  };

  const { title } = template;
  const editorBodyClass = isExpanded
    ? 'content-template-editor__content is-expanded'
    : 'content-template-editor__content';

  return (
    <section className="editor-section content-template-editor">
      <header className="editor-section__header">
        <div className="title-row">
          <h3>Template: {title ? title.fi : 'template title'}</h3>
          <div className="title-row__buttons">
            <LanguageSelector language={language} languages={['fi', 'sv']} toggleLanguage={setLanguage} />
            <button onClick={() => duplicate(template.id)}>
              <IconCopy />
            </button>
            <button onClick={() => onChangeOrder(index, false)}>
              <IconDown />
            </button>
            <button onClick={() => onChangeOrder(index, true)}>
              <IconUp />
            </button>
            <button className="delete-content-template" onClick={() => onDelete()}>
              <IconDelete />
            </button>
            <button className="toggle-expander" onClick={() => toggleExpanded(index)}>
              {isExpanded ? <IconMinus /> : <IconPlus />}
            </button>
          </div>
        </div>
        {!template.id ? ( // no id === new template, allow channel selection.
          <div className="type-selector">
            Channel:
            <RadioButton
              id={`template-${index}-channel-email`}
              group={`template-${index}-group`}
              checked={template.channel === DeliveryChannelName.Email}
              label={DeliveryChannelName.Email}
              handleClick={() => onEdit('channel', DeliveryChannelName.Email)}
            />
            <RadioButton
              id={`template-${index}-channel-print`}
              group={`template-${index}-group`}
              checked={template.channel === DeliveryChannelName.Print}
              label={`${DeliveryChannelName.Print} (when print is added, pricing group must be defined in Details tab)`}
              handleClick={() => onEdit('channel', DeliveryChannelName.Print)}
            />
          </div>
        ) : (
          <div className="type-selector">Channel: {template.channel} </div>
        )}
      </header>
      <div className={editorBodyClass}>
        <EditorBody language={language} contentTemplate={template} update={onEdit} updateHTML={editHtml} />
        {nonMatchingFields.length > 0 && (
          <div className="missing-fields">
            <span>No content fields found for identifier(s)</span>
            <b>{nonMatchingFields.join(', ')}</b>
          </div>
        )}
        {contentSlots.length > 0 && (
          <div className="content-template-editor__slots">
            <header className="content-slots-title">
              <h3>Content template slots</h3>
            </header>
            {contentSlots.map((s, i) => (
              <SlotEditor
                index={i}
                language={language}
                parentIndex={index}
                slot={s}
                update={onUpdateSlot}
                key={i}
                contentBlocks={contentBlocks}
              />
            ))}
          </div>
        )}
        {matchingFields.length > 0 && (
          <div className="existing-content-fields">
            <h3>Content Fields</h3>
            {matchingFields.map((c, i) => (
              <div className="block-content-field" key={i}>
                <h4>{c.title[language]}</h4>
                <span>Type: {c.type}</span>
                <span>Identifier: {c.identifier}</span>
                {c.defaultValue && <span>Default value: "{c.defaultValue[language]}"</span>}
                {c.required && <span>Required</span>}
                {c.readOnly && <span>Read only</span>}
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default ContentTemplateEditor;
