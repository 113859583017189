import React from 'react';
import { observer } from 'mobx-react';
import _ from 'utils/lodash';
import type { StampCardEditorTabProps } from '../stampCardEditor';
import { CHAIN_LOGOS, PRODUCT_IMAGE_PLACEHOLDER, STAMP_COLLECTION_INSTRUCTIONS } from 'constants/stampCard';
import { format, isBefore, isAfter } from 'date-fns';
import { BASKET_DISCOUNT_PERCENTAGES } from 'constants/stampCard';
import {
  CollectionStampCardStrategy,
  CollectionStampCardRewardDiscountMethod,
  CollectionStampCardRewardType,
  CollectionStampCardEditorRewardType,
} from 'enums/stampCard';

interface State {}

@observer
export default class StampCardSummaryTab extends React.Component<StampCardEditorTabProps, State> {
  constructor(props: StampCardEditorTabProps) {
    super(props);
  }

  get currentStampCard() {
    return this.props.stampCardStoreNew.current;
  }

  get originalStampCard() {
    return this.props.stampCardStoreNew.original;
  }

  get validationError() {
    return this.props.stampCardStoreNew.validationError;
  }

  get invalidField() {
    return this.props.stampCardStoreNew.invalidField;
  }

  renderCardStores = () => {
    const { stores, chains } = this.currentStampCard;
    const { storesByStoreId } = this.props.userStore;

    if (this.props.keskoStampCard) {
      return (
        <div className="stampcard-details-stores">
          {_.map(chains, (chain: string) => {
            const image = <img src={CHAIN_LOGOS[chain]} alt={chain} />;

            return (
              <div key={`card-chain-${chain}`} className="stampcard-details-store">
                {image}
              </div>
            );
          })}
        </div>
      );
    }

    if (stores.length === 0) {
      return null;
    }

    return (
      <div className="stampcard-details-stores">
        {_.map(stores, ({ storeId }) => {
          const storeData = _.get(storesByStoreId, [storeId]);
          const { chainId, name, shortName } = storeData;

          const image =
            chainId && _.includes(['3', '4', '5'], chainId) ? <img src={CHAIN_LOGOS[chainId]} alt={name} /> : null;

          return (
            <div key={`card-store-${storeId}`} className="stampcard-details-store">
              {image}
              {shortName}
            </div>
          );
        })}
      </div>
    );
  };

  renderCardStatusPill = () => {
    const currentStampCard = this.currentStampCard;

    const isActive =
      !isBefore(new Date(), new Date(currentStampCard.activeFrom)) &&
      !isAfter(new Date(), new Date(currentStampCard.activeTo));

    return (
      <div className={`stampcard-status ${isActive ? 'active' : 'inactive'}`}>
        {isActive ? 'Aktiivinen' : 'Ei aktiivinen'}
      </div>
    );
  };

  render() {
    const discountEuros = Math.floor(this.currentStampCard.reward.discount) || 0;
    const discountCents = Math.round((this.currentStampCard.reward.discount - discountEuros) * 100) || 0;
    const activeFrom = format(new Date(this.currentStampCard.activeFrom), 'd.M.yyyy');
    const activeTo = format(new Date(this.currentStampCard.activeTo), 'd.M.yyyy');

    return (
      <div>
        <div className="section-header">
          <h2>Keräilypassin yhteenveto</h2>
        </div>
        <div className="section-split">
          <div className="left">
            <div className="box stampcard-summary">
              <div className="stampcard-preview-container">
                <div className="stampcard-preview">
                  <img
                    className="stampcard-preview-image"
                    src={this.currentStampCard.uiData.imageUrl.fi}
                    alt="stamp-card"
                  />
                  <div className="stampcard-details-container">
                    <div className="stampcard-details-name">{this.currentStampCard.uiData.uiName.fi}</div>
                    <div className="stampcard-details-active">{`Keräilyaika: ${activeFrom} - ${activeTo}`}</div>
                    {this.renderCardStores()}
                  </div>
                </div>
              </div>
              <hr />
              <h2>{this.currentStampCard.uiData.uiName.fi}</h2>
              <p>{this.currentStampCard.uiData.description.fi}</p>
              <p>{STAMP_COLLECTION_INSTRUCTIONS[this.currentStampCard.type]}</p>
              <h2>Leimojen keräysaika</h2>
              <p>
                {format(new Date(this.currentStampCard.activeFrom), 'd.M.yyyy')} -{' '}
                {format(new Date(this.currentStampCard.activeTo), 'd.M.yyyy')}{' '}
              </p>
              <h2>Edut lunastettava viimeistään</h2>
              <p>{format(new Date(this.currentStampCard.redeemableTo), 'd.M.yyyy')}</p>
              <hr />
              <div className="stampcard-reward-summary">
                <div className="condition">
                  {/* ONE_TIME_PURCHASE strategy */}
                  {this.currentStampCard.strategy === CollectionStampCardStrategy.OneTimePurchase && (
                    <>
                      <h3>Tee</h3>
                      <h3 className="highlight">
                        {this.currentStampCard.stampConfiguration} kpl {this.currentStampCard.oneTimePurchase}€
                      </h3>
                      <h3>kertaostosta</h3>
                    </>
                  )}
                  {/* SIMPLE_PRODUCT_COLLECTING strategy */}
                  {this.currentStampCard.strategy === CollectionStampCardStrategy.SimpleProductCollecting && (
                    <>
                      <h3>Osta</h3>
                      <h3 className="highlight">{this.currentStampCard.stampConfiguration}</h3>
                      <h3>tuotetta</h3>
                    </>
                  )}
                </div>
                {/* Discounted product reward */}
                <div className="reward">
                  {this.currentStampCard.reward.discountMethod ===
                    CollectionStampCardRewardDiscountMethod.ProductEuro &&
                    this.currentStampCard.reward.discount > 0 && (
                      <>
                        <h3>Saat tuotteen hintaan</h3>
                        <h3 className="highlight">{this.currentStampCard.reward.discount.toFixed(2)}€</h3>
                        <h3>palkinnoksi</h3>
                      </>
                    )}
                  {/* Free product reward */}
                  {this.currentStampCard.reward.discountMethod ===
                    CollectionStampCardRewardDiscountMethod.ProductEuro &&
                    this.currentStampCard.reward.discount === 0 && (
                      <>
                        <h3>Saat tuotteen</h3>
                        <h3 className="highlight">veloituksetta</h3>
                        <h3>palkinnoksi</h3>
                      </>
                    )}
                  {/* Euro discount from basket reward */}
                  {this.currentStampCard.reward.discountMethod ===
                    CollectionStampCardRewardDiscountMethod.BasketEuro && (
                    <>
                      <h3>Saat</h3>
                      <h3 className="highlight">{this.currentStampCard.reward.discount}€ alennusta</h3>
                      <h3 className="highlight">
                        vähintään {this.currentStampCard.reward.minimumPurchase}€ ostoksesta
                      </h3>
                      <h3>palkinnoksi</h3>
                    </>
                  )}
                  {/* Percentage discount from basket reward */}
                  {this.currentStampCard.reward.discountMethod ===
                    CollectionStampCardRewardDiscountMethod.BasketPercent && (
                    <>
                      <h3>Saat</h3>
                      <h3 className="highlight">
                        {_.get(BASKET_DISCOUNT_PERCENTAGES, this.currentStampCard.reward.discount)}% alennusta
                      </h3>
                      <h3 className="highlight">
                        vähintään {this.currentStampCard.reward.minimumPurchase}€ ostoksesta
                      </h3>
                      <h3>palkinnoksi</h3>
                    </>
                  )}
                </div>
              </div>
              {/* Reward preview: product */}
              {this.currentStampCard.reward.discountMethod === CollectionStampCardRewardDiscountMethod.ProductEuro && (
                <div className="reward-configuration">
                  <div className="reward-image-preview reward-type-product">
                    <div className="reward-image-container">
                      <img src={this.currentStampCard.reward.image} alt="reward" />
                    </div>
                    <div className="reward-name-container">{this.currentStampCard.reward.title.fi}</div>
                    <div className="reward-discount-container">
                      <div className="reward-discount-text-top">Plussa-kortilla</div>
                      {this.props.stampCardStoreNew.editorRewardType ===
                        CollectionStampCardEditorRewardType.ProductFree && (
                        <div className="reward-discount-text-free">Veloituksetta</div>
                      )}
                      {this.props.stampCardStoreNew.editorRewardType ===
                        CollectionStampCardEditorRewardType.ProductEur && (
                        <div className="reward-discount-value-container">
                          <div className="reward-discount-value-euros">{discountEuros}</div>
                          <div className="reward-discount-cents-container">
                            <div className="reward-discount-value-cents">{discountCents}</div>
                            <div className="reward-discount-value-unit">/kpl</div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {/* Reward preview: basket */}
              {(this.currentStampCard.reward.discountMethod === CollectionStampCardRewardDiscountMethod.BasketEuro ||
                this.currentStampCard.reward.discountMethod ===
                  CollectionStampCardRewardDiscountMethod.BasketPercent) && (
                <div className="reward-configuration">
                  <div className="reward-image-preview reward-type-basket">
                    <div className="reward-image-container">
                      <img src={this.currentStampCard.reward.image} alt="reward" />
                    </div>
                    <div className="reward-name-container">{this.currentStampCard.reward.title.fi}</div>
                  </div>
                </div>
              )}
              <hr />

              {this.currentStampCard.strategy === CollectionStampCardStrategy.SimpleProductCollecting && (
                <>
                  <h2>Keräiltävät tuotteet</h2>
                  <div className="stampcard-products">
                    {!this.currentStampCard.products.length
                      ? 'Ei tuotteita'
                      : _.map(this.currentStampCard.products, (product) => (
                          <div key={`product-${_.get(product, ['ean'])}`} className="product-item">
                            <div className="image-container">
                              <img
                                src={
                                  _.get(product, ['image'])
                                    ? `${_.get(product, ['image'])}?h=50&fm=png`
                                    : PRODUCT_IMAGE_PLACEHOLDER
                                }
                                alt={_.get(product, ['title', 'fi'])}
                              />
                            </div>
                            <div className="item-details">
                              <div className="title">{_.get(product, ['title', 'fi'])}</div>
                              <div className="label">
                                <span className="emph">EAN </span>
                                {_.get(product, ['ean'])}
                              </div>
                            </div>
                            <div className="price-container">
                              <div className="title">
                                {product?.price ? parseFloat(String(product.price)).toFixed(2) : '-'}
                              </div>
                              <div className="label">{product?.pricingUnit ? `€ / ${product.pricingUnit}` : ''}</div>
                            </div>
                          </div>
                        ))}
                  </div>
                </>
              )}
              {this.currentStampCard.reward.discountMethod === CollectionStampCardRewardDiscountMethod.ProductEuro && (
                <>
                  <h2>Palkintotuotteet</h2>
                  <div className="stampcard-products">
                    {!this.currentStampCard.rewardProducts.length
                      ? 'Ei tuotteita'
                      : _.map(this.currentStampCard.rewardProducts, (product) => (
                          <div key={`product-${_.get(product, ['ean'])}`} className="product-item">
                            <div className="image-container">
                              <img
                                src={
                                  _.get(product, ['image'])
                                    ? `${_.get(product, ['image'])}?h=50&fm=png`
                                    : PRODUCT_IMAGE_PLACEHOLDER
                                }
                                alt={_.get(product, ['title', 'fi'])}
                              />
                            </div>
                            <div className="item-details">
                              <div className="title">{_.get(product, ['title', 'fi'])}</div>
                              <div className="label">
                                <span className="emph">EAN </span>
                                {_.get(product, ['ean'])}
                              </div>
                            </div>
                            <div className="price-container">
                              <div className="title">
                                {parseFloat(String(this.currentStampCard?.reward?.discount)).toFixed(2)}
                              </div>
                              <div className="label">{product?.pricingUnit ? `€ / ${product.pricingUnit}` : ''}</div>
                            </div>
                          </div>
                        ))}
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="right">
            <div className="box">
              <h3>Ohjeet</h3>
              <p>
                Tarkista tästä yhteenvedosta, että keräilypassisi tiedot ovat oikein ja olet muistanut päivittää kaikki
                tarvittavat tiedot passille.
              </p>
              <p>
                Katso, että keräilypassisi ulkoasu vastaa haluamaasi ja olet valinnut sopivan taustakuvan passillesi,
                antanut passille kuvaavan nimen, sekä lisännyt kuvaustekstiin, mistä tuotteista/ostoista saa leiman, ja
                mikä on palkintoetuna.
              </p>
              <p>
                Varmista myös, että leimojen keräys- ja lunastusaika on oikein, sillä keräilypassin alkamispäivämäärää
                ei voi muokata enää passin aktivoitumisen jälkeen.
              </p>
              <p>
                Katso, että kerättävien leimojen sekä palkinnon tiedot ovat oikein ja olet muistanut päivittää ne samoin
                määrittelyyn, kuvaustekstiin ja mahdolliseen kuvabanneriin. Tarkista myös asiakkaalle näkyvä
                palkintoedun otsikko ja kuva tietoineen.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
