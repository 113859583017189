import React from 'react';
import { Link } from 'react-router-dom';
import { routes } from './../routes';

interface Props {
  infoText: string;
}

const SiteClosed = ({ infoText }: Props) => (
  <div className="lander closed">
    <div className="lander-container">
      <div className="lander-content">
        <div className="lander-box">
          <h1>K&nbsp;Markkinointi</h1>
          <h2>Kauppiaiden sivusto on pois käytöstä</h2>
          <p>{infoText}</p>
          <div className="buttons-container">
            <Link to={routes.logout} className="button button-inverse">
              Poistu
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default SiteClosed;
