import React, { useEffect, useState } from 'react';
import _ from 'utils/lodash';
import { isKRautaChain } from 'utils/helpers';
import type { Dimension } from 'types/next';
import { inject, observer } from 'mobx-react';
import DimensionsStore from 'stores/next/dimensions';
import { DeliveryWithMeta } from 'stores/next-retailer/deliveryStore';
import './deliveryTarget.scss';
import { ConceptType } from 'enums/common';

interface Props {
  delivery: DeliveryWithMeta;
  renderActions?: () => JSX.Element;
  conceptType?: ConceptType;
  dimensionsStore?: DimensionsStore;
}

// TODO: Check for Onninen
const DeliveryTarget = ({ delivery, renderActions, conceptType, dimensionsStore }: Props) => {
  const [selectionsExpanded, setSelectionsExpanded] = useState(false);

  useEffect(() => {
    const init = async () => {
      if (conceptType === ConceptType.B2b && !dimensionsStore.b2bEmailRecipients) {
        await dimensionsStore.getSelectionEmails(delivery.targetGroup);
      }
    };
    init();
  }, []);

  const toggleSelections = () => {
    setSelectionsExpanded((selectionsExpanded) => !selectionsExpanded);
  };

  const isKRauta = isKRautaChain(delivery.chainId);
  const MAIN_SEGMENT = isKRauta ? 'segmenttirauta' : 'segmentti';
  const SEASONAL_SEGMENT = isKRauta ? 'adminSegments' : 'sesonki';
  const isB2B = conceptType === ConceptType.B2b;
  const groupedEmailRecipients = dimensionsStore.groupedB2bEmailRecipients;

  const mainSegment = _.find(delivery.targetGroup.dimensions, { name: MAIN_SEGMENT });
  const mainDimension = _.find(dimensionsStore.dimensions, { name: MAIN_SEGMENT }) as Dimension;
  const mainSegmentSelections = mainSegment
    ? _.chain(mainSegment.selection)
        .map((selection) => _.find(mainDimension.options, { name: selection }))
        .compact()
        .value()
    : [];

  const seasonalSegment = _.find(delivery.targetGroup.dimensions, { name: SEASONAL_SEGMENT });
  const seasonalDimension = _.find(dimensionsStore.dimensions, { name: SEASONAL_SEGMENT }) as Dimension;
  const seasonalSegmentSelections = seasonalSegment
    ? _.chain(seasonalSegment.selection)
        .map((selection) => _.find(seasonalDimension.options, { name: selection }))
        .compact()
        .value()
    : [];

  const otherSelections = _.flatMap(delivery.targetGroup.dimensions, ({ name, selection }) => {
    if ([MAIN_SEGMENT, SEASONAL_SEGMENT].includes(name)) {
      return [];
    }

    // TOOD: Separate Onninen dimensions??
    const availableDimensions = !isB2B ? dimensionsStore.dimensions : dimensionsStore.b2bDimensions;

    const dimension = _.find(availableDimensions, { name });
    if (!dimension) {
      return [];
    }
    return dimension.options.filter(({ name }) => selection.includes(name));
  });

  const areas = delivery.targetGroup.areas;

  return (
    <div className="box delivery-target">
      <div className="box-sections">
        {(mainSegment || seasonalSegment) && !isB2B && (
          <div className="box-section main">
            <h4>Kohderyhmät</h4>
            <ul>
              {mainSegmentSelections.length < 4
                ? _.orderBy(mainSegmentSelections, 'order').map(({ title, name }) => <li key={name}>{title}</li>)
                : 'Kaupan asiakkaat'}
              {_.orderBy(seasonalSegmentSelections, 'order').map(({ title, name }) => (
                <li key={name}>{title}</li>
              ))}
            </ul>
          </div>
        )}
        {isB2B && (
          <div className="box-section main">
            <h4>Kohderyhmät</h4>
            <ul>
              {!selectionsExpanded
                ? _.take(_.orderBy(otherSelections, 'order'), 3).map(({ title, name }) => <li key={name}>{title}</li>)
                : _.orderBy(otherSelections, 'order').map(({ title, name }) => <li key={name}>{title}</li>)}
              {otherSelections.length > 3 && !selectionsExpanded ? (
                <li>
                  <strong>{otherSelections.length - 3}</strong> muuta tarkennusta
                </li>
              ) : null}
              {otherSelections.length > 3 && (
                <strong className="button" onClick={toggleSelections}>
                  {selectionsExpanded ? 'Näytä vähemmän' : 'Näytä kaikki'}
                </strong>
              )}
            </ul>
          </div>
        )}

        {!isB2B && (
          <div className="box-section additional">
            <h4>Tarkennukset</h4>
            <ul>
              {!selectionsExpanded
                ? _.take(_.orderBy(otherSelections, 'order'), 3).map(({ title, name }) => <li key={name}>{title}</li>)
                : _.orderBy(otherSelections, 'order').map(({ title, name }) => <li key={name}>{title}</li>)}
              {otherSelections.length > 3 && !selectionsExpanded ? (
                <li>
                  <strong>{otherSelections.length - 3}</strong> muuta tarkennusta
                </li>
              ) : null}
              {areas && !selectionsExpanded && (
                <li>
                  <strong>{areas.length}</strong> postinumeroaluetta
                </li>
              )}
              {areas && selectionsExpanded && _.sortBy(areas).map((area) => <li key={area}> {area} </li>)}
              {(otherSelections.length > 3 || areas) && (
                <strong className="button" onClick={toggleSelections}>
                  {selectionsExpanded ? 'Näytä vähemmän' : 'Näytä kaikki'}
                </strong>
              )}
              {!areas && !otherSelections.length && <li>Ei tarkennuksia.</li>}
            </ul>
          </div>
        )}
        {isB2B ? (
          <div className="box-section main">
            <h4>Sähköpostilista</h4>
            <ul>
              <li>{groupedEmailRecipients ? groupedEmailRecipients.length : 0} sähköpostiosoitetta</li>
              <li>
                {delivery.emailRecipients && delivery.emailRecipients.additionalEmails.length > 0
                  ? delivery.emailRecipients.additionalEmails.length
                  : 0}{' '}
                itse lisättyä
              </li>
              <li>
                {delivery.emailRecipients && delivery.emailRecipients.bannedEmails.length > 0
                  ? delivery.emailRecipients.bannedEmails.length
                  : 0}{' '}
                poistettua
              </li>
            </ul>
          </div>
        ) : (
          <div className="box-section" />
        )}
        {renderActions && <div className="box-section actions">{renderActions()}</div>}
      </div>

      <pre className="debug">{JSON.stringify(delivery.targetGroup, null, 2)}</pre>
    </div>
  );
};

export default inject('dimensionsStore')(observer(DeliveryTarget));
