import React, { useEffect } from 'react';
import * as date from 'date-fns';
import _ from 'utils/lodash';
import Button from '../../../../common/next/form/button';
import { getLink } from '../../routes';
import { ReactComponent as IconNewsletter } from '@kesko/icons/comm/icon-newsLetter.svg';
import { observer, inject } from 'mobx-react';
import UserStore from 'stores/userStore';
import './templateBox.scss';
import TemplateStore, { DeliveryTemplateWithMeta } from 'stores/next-retailer/templateStore';
import Spinner from 'components/common/next/spinner';
import DeliveryStore from 'stores/next-retailer/deliveryStore';
import ToggleButton from 'components/common/next/form/toggleButton';
import { castDate } from 'utils/helpers';
import { Link } from 'react-router-dom';
import { CreateMode } from '../delivery/deliveryForm/deliveryForm';
import { getPrintRecipientCount } from '../../utils/helpers';
import { ConceptType, DeliveryChannelName } from 'enums/common';

interface Props {
  template: DeliveryTemplateWithMeta;
  conceptType?: ConceptType;
  action?: 'toggle' | 'participate';
  isSelected?: boolean;
  toggle?: () => void;
  toggleDisabled?: boolean;
  disabled?: boolean;
  maxTemplateParticipations?: number;
  allAvailableTemplatesSelected?: boolean;
  isParticipatingAutomatically: boolean;
  isParticipatingAutomaticallyDisabled: boolean;
  isHalvePrintRecipientCount: boolean;
}

interface InjectedProps extends Props {
  templateStore?: TemplateStore;
  deliveryStore?: DeliveryStore;
  userStore?: UserStore;
}

const TemplateBox = ({
  templateStore,
  deliveryStore,
  userStore,
  template,
  conceptType,
  action,
  isSelected,
  toggle,
  toggleDisabled,
  disabled,
  maxTemplateParticipations,
  allAvailableTemplatesSelected,
  isHalvePrintRecipientCount,
  isParticipatingAutomatically,
  isParticipatingAutomaticallyDisabled,
}: InjectedProps) => {
  useEffect(() => {
    if (!template.defaultDelivery) {
      const me = userStore.me;
      templateStore.getDefaultDeliveryForTemplate(template, me);
    }
  }, []);

  const renderButton = () => {
    const noAutomaticParticipationText = template.maxDeliveries === 1 ? 'Osallistu' : 'Tee lähetys';
    const buttonText =
      isParticipatingAutomatically && !isParticipatingAutomaticallyDisabled
        ? 'Osallistuttu'
        : noAutomaticParticipationText;
    const color = isParticipatingAutomatically && !isParticipatingAutomaticallyDisabled ? 'bordered' : undefined;
    const editingMode = isParticipatingAutomaticallyDisabled
      ? CreateMode.automaticDisabled
      : isParticipatingAutomatically
        ? CreateMode.automatic
        : CreateMode.new;
    return (
      <>
        <Button
          className={'participation-button'}
          color={color}
          link={getLink('editDelivery', editingMode, template.id)}
        >
          {buttonText}
        </Button>
      </>
    );
  };

  const renderToggle = () => {
    return (
      <>
        <ToggleButton
          isSelected={isSelected}
          toggle={toggle}
          disabled={toggleDisabled || (disabled === true && !isSelected)}
        />
      </>
    );
  };

  const render = () => {
    const title = _.get(template, ['title', 'fi']) || `Lähetys ${template.firstStartDate}`;
    const defaultOffer = _.filter(template.offerOptions, { default: true });
    const channelNames = template.channels.map((c) => {
      if ((c.name as DeliveryChannelName) === DeliveryChannelName.Email) {
        return 'Sähköposti';
      }
      if ((c.name as DeliveryChannelName) === DeliveryChannelName.Print) {
        return 'Printti';
      }
      return null;
    });

    // Deadline is until the end of day so add 1.
    const daysUntilDeadline = date.differenceInDays(castDate(template.deadline), date.startOfToday()) + 1;
    return (
      <div className="template-box box" key={template.id}>
        <div className="template-header">
          <div className="icon">
            <IconNewsletter />
          </div>
          <div className="basic-info">
            <h3>{title}</h3>
            <ul className="text-small">
              <li>
                {template.firstStartDate === template.lastStartDate ? (
                  <span>
                    Lähetysaika: <strong>{date.format(castDate(castDate(template.firstStartDate)), 'd.M.yyyy')}</strong>
                  </span>
                ) : (
                  <span>
                    Lähetysikkuna:{' '}
                    <strong>
                      {date.format(castDate(template.firstStartDate), 'd.M.')}-
                      {date.format(castDate(castDate(template.lastStartDate)), 'd.M.yyyy')}
                    </strong>
                  </span>
                )}
              </li>
              <li>{channelNames.sort().join(', ')}</li>
            </ul>
          </div>
        </div>
        {conceptType === ConceptType.Program && defaultOffer.length > 0 && isParticipatingAutomatically && (
          <div className="template-default-offer">
            <div className="offer-container">
              Valittu etu
              <br />
              <strong>
                <Link to={getLink('editDelivery', CreateMode.new, template.id)}>
                  {defaultOffer[0].title.fi}
                  {defaultOffer.length > 1 && ` ja ${defaultOffer.length - 1} muuta`}
                </Link>
              </strong>
            </div>
          </div>
        )}
        <div className="target-group">
          {template.defaultDelivery && template.defaultDelivery.maxSelectionCounts ? (
            <React.Fragment>
              {Boolean(template.defaultDelivery.targetGroup.channels.email) && (
                <div className="channel channel-email">
                  <span className="hl">{template.defaultDelivery.maxSelectionCounts.channels.email}</span>
                  <br />
                  sähköpostia
                </div>
              )}
              {Boolean(template.defaultDelivery.targetGroup.channels.print) && (
                <div className="channel channel-print">
                  <span className="hl">
                    {getPrintRecipientCount({
                      maxCount: template.defaultDelivery.maxSelectionCounts.channels.print,
                      isHalvePrintRecipientCount: isHalvePrintRecipientCount,
                    })}
                  </span>
                  <br />
                  printtiä
                </div>
              )}
            </React.Fragment>
          ) : (
            <div>
              <Spinner />
            </div>
          )}
        </div>
        <div className="template-status">
          <div className="">
            <span className="hl">{daysUntilDeadline > 1 ? daysUntilDeadline : '<1'} päivää</span>
            <br />
            aikaa osallistua
          </div>
        </div>
        {maxTemplateParticipations !== -1 ? (
          <div className="actions text-right">
            {renderToggle()}
            {isSelected && renderButton()}
          </div>
        ) : (
          <div className="actions text-right">
            {allAvailableTemplatesSelected || action === 'toggle' ? renderToggle() : renderButton()}
          </div>
        )}
        <pre className="debug">{JSON.stringify(deliveryStore.collection)}</pre>
      </div>
    );
  };

  return render();
};

export default inject('templateStore', 'deliveryStore', 'userStore')(observer(TemplateBox));
