import React from 'react';
import { inject, observer } from 'mobx-react';

import AlertStore, { AlertLevel } from 'stores/next/alerts';
import Alert from './alert';
import { Link } from 'react-router-dom';

interface Props {}

interface InjectedProps extends Props {
  alertStore: AlertStore;
}

@inject('alertStore')
@observer
class Alerts extends React.Component<Props> {
  get injected() {
    return this.props as InjectedProps;
  }

  get alertStore() {
    return this.injected.alertStore;
  }

  get alerts() {
    return this.alertStore.alerts;
  }

  dismissAlert = (id: string) => {
    return () => this.alertStore.dismissAlert(id);
  };

  render() {
    if (this.alerts.length === 0) {
      return null;
    }

    return (
      <div className="alerts">
        {this.alerts.map(({ id, link, message, level }) => {
          let prefix: JSX.Element;
          if (level === AlertLevel.Error) {
            prefix = <strong>Error: </strong>;
          }
          const preview = link ? 'Esikatsele' : '';
          return (
            <Alert key={id} level={level} onDismiss={this.dismissAlert(id)}>
              {prefix && <span key="prefix">{prefix}</span>}
              <span key="message">{message}</span>
              <br />
              {link && (
                <Link key="link" to={link}>
                  {preview}
                </Link>
              )}
            </Alert>
          );
        })}
      </div>
    );
  }
}

export default Alerts;
