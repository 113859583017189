import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import withPage from 'components/next/pages/page/page';
import Timeline, { TimelineElement } from 'components/common/next/timeline';
import ConceptsStore from 'stores/next/programs';
import DeliveryTemplateStore from 'stores/next/deliveryTemplates';
import SidebarWrapper from 'components/next/components/sidebar/sidebar';
import SearchBox from 'components/next/components/sidebar/searchBox';
import ChainSelector from 'components/next/components/chainSelector';
import { ReactComponent as IconArrowRight } from '@kesko/icons/nav/icon-arrow-right.svg';
import IconHeart from '@kesko/icons/misc/icon-heart.svg';
import IconSend from '@kesko/icons/action/icon-msg-send.svg';
import IconB2B from '@kesko/icons/people/icon_userB2B.svg';
import * as date from 'date-fns';
import { dateFormat } from 'components/next/utils';
import { concepts } from 'components/next/routes';
import Results from './results';
import './home.scss';
import type { DeliveryTemplate, HomeStatistics } from 'types/next';
import { NavLink, useParams } from 'react-router-dom';

import StatisticsStore from 'stores/statisticsStore';
import { getExcludedChains, getByType, useDebouncedEffect } from 'utils/helpers';
import Spinner from 'components/common/next/spinner';
import { Chain } from 'enums/common';

type Params = {
  chain: Chain;
};
interface Props {
  conceptsStore?: ConceptsStore;
  deliveryTemplateStore?: DeliveryTemplateStore;
  statisticsStore?: StatisticsStore;
  chainIds: string[];
}

const Home = ({ conceptsStore, deliveryTemplateStore, statisticsStore, chainIds: chainIdsProp }: Props) => {
  const [timelineData, setTimeLineData] = useState<TimelineElement[][]>([]);
  const [query, setQuery] = useState('');
  const [chainIds, setChainIds] = useState(chainIdsProp);
  const [excludedChains, setExcludedChains] = useState(getExcludedChains(chainIds));
  const [statistics, setStatistics] = useState<HomeStatistics | undefined>();
  const [isLoading, setIsLoading] = useState(true);

  const { chain: chainParam } = useParams<Params>();
  const shouldRenderTimeline = process.env.REACT_APP_TIMELINE_ON === 'true' ? timelineData.length > 0 : false;

  useEffect(() => {
    const init = async () => {
      await statisticsStore.getStatisticsHome();
    };
    init();
  }, []);

  useEffect(() => {
    setChainIds(chainIdsProp);
    setExcludedChains(getExcludedChains(chainIdsProp));
  }, [chainIdsProp]);

  const getTooltipContent = (element: TimelineElement, conceptId: string, deliveryTemplateId: string) => {
    const editUrl = `${concepts.link.replace(
      /:chain/,
      chainParam,
    )}/${conceptId}/delivery-templates/${deliveryTemplateId}`;
    return (
      <div className="tooltip__content">
        <header>
          <h4>{element.title}</h4>
          <NavLink to={editUrl}>
            <IconArrowRight />
          </NavLink>
        </header>
        <p>
          <b>
            Sign-up period: {date.format(element.signUpStarts, dateFormat)} -{' '}
            {date.format(element.signUpEnds, dateFormat)}
          </b>
          <b>Deliveries: {date.format(element.firstDelivery, dateFormat)}</b>
          <span>{element.introduction}</span>
        </p>
      </div>
    );
  };

  const initializeHomeData = async () => {
    setIsLoading(true);

    // request statistics for given chains
    const statisiticsForChains = getStatisicsForChainIds(chainIds);
    setStatistics(statisiticsForChains);

    const searchResult = await conceptsStore.search({
      query,
      chainId: chainIds.length ? [chainIds[0], ...chainIds.slice(1)] : [chainIdsProp[0], ...chainIdsProp.slice(1)],
    });
    const { result: deliveryTemplates } = searchResult.count
      ? await deliveryTemplateStore.search({
          concept: _.map(searchResult.result, 'id') as [any],
        })
      : { result: [] };

    const getConcept = (conceptId: string) => {
      return searchResult.result.find((concept) => concept.id === conceptId);
    };

    if (process.env.REACT_APP_TIMELINE_ON === 'true') {
      const now = new Date();
      let timelineData = deliveryTemplates
        .filter(
          (templa: DeliveryTemplate) =>
            date.isAfter(new Date(templa.deadline), now) &&
            date.isBefore(new Date(templa.publishDate), date.addMonths(now, 2)),
        )
        .map((templa: DeliveryTemplate): TimelineElement => {
          const result: TimelineElement = {
            signUpStarts: new Date(templa.publishDate),
            signUpEnds: new Date(templa.deadline),
            firstDelivery: new Date(templa.firstStartDate),
            title: templa.title.fi,
            editUrl: `${concepts.link.replace(/:chain/, chainParam)}/${templa.concept}/delivery-templates/${templa.id}`,
            introduction: templa.description.fi,
            concept: templa.concept,
            invertColors: !templa.published,
            icon: getByType(getConcept(templa.concept).type, IconHeart, IconSend, IconB2B),
            deliveryLine: Boolean(templa.targetGroupLockDate),
          };
          result.tooltipContent = getTooltipContent(result, templa.concept, templa.id);

          return result;
        });

      timelineData = _.values(_.groupBy(timelineData, 'concept'));
      // this setstate is slow
      setTimeLineData(_.filter(timelineData, (arr) => !_.isEmpty(arr)));
    }
    setIsLoading(false);
  };

  useDebouncedEffect(initializeHomeData, [chainIds, query], 500);

  const getStatisicsForChainIds = (chainIds: string[]): HomeStatistics => {
    const reply: HomeStatistics = {
      chainId: 'sum',
      recipients: { total: 0, opens: 0 },
      stores: { participating: 0, total: 0 },
    };
    const map = statisticsStore.homePageStatisicsMap;
    chainIds.forEach((chainId) => {
      if (map.has(chainId)) {
        const item = map.get(chainId);
        reply.recipients.total += item.recipients.total;
        reply.recipients.opens += item.recipients.opens;
        reply.stores.total = item.stores.total;
        reply.stores.participating += item.stores.participating;
      }
    });

    return reply;
  };

  const handleQueryChange = (q: string) => {
    setQuery(q);
  };

  const handleChainChange = (c: string) => {
    if (chainIds.includes(c)) {
      setChainIds(chainIds.filter((chain) => chain !== c));
    } else {
      setChainIds(chainIds.concat(c));
    }
  };

  const renderSideBar = () => {
    return (
      <div className="home-sidebar">
        <div className="sidebar__title-wrapper">
          <h3 className="sidebar__title">Timeline filter</h3>
          {isLoading && <Spinner addClassName="spinner--unset" />}
        </div>
        <SearchBox
          value={query}
          name="query"
          onChange={(e) => handleQueryChange(e.target.value)}
          placeholder="Search for concepts"
        />
        <ChainSelector chainSelection={chainIds} excludeChains={excludedChains} handleChainChange={handleChainChange} />
      </div>
    );
  };

  return (
    <SidebarWrapper renderSidebar={renderSideBar}>
      <div className="home">
        <Results
          stores={statistics ? statistics.stores : null}
          recipients={statistics ? statistics.recipients : null}
        />
        {isLoading && !shouldRenderTimeline && <Spinner />}
        {shouldRenderTimeline && (
          <Timeline data={timelineData} chain={chainParam} title="Upcoming and open delivery templates" />
        )}
      </div>
    </SidebarWrapper>
  );
};

export default withPage(inject('deliveryTemplateStore', 'statisticsStore', 'conceptsStore')(observer(Home)));
