import React, { useEffect, useState } from 'react';
import _ from 'utils/lodash';
import { getLink } from '../../routes';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as IconChevronRight } from '@kesko/icons/nav/icon-chevron-right.svg';
import { inject, observer } from 'mobx-react';
import { Chain, ConceptType } from 'enums/common';

import TemplateStore from 'stores/next-retailer/templateStore';
import ConceptStore from 'stores/next-retailer/conceptStore';
import DeliveryStore from 'stores/next-retailer/deliveryStore';
import AppStore from 'stores/next/app';

import './breadcrumb.scss';

interface BreadcrumbLevel {
  title: string;
  link: string;
}

interface InjectedProps {
  conceptStore?: ConceptStore;
  templateStore?: TemplateStore;
  deliveryStore?: DeliveryStore;
  appStore?: AppStore;
}

const Breadcrumb = ({ conceptStore, templateStore, deliveryStore, appStore }: InjectedProps) => {
  const location = useLocation();
  const [routeComponents, setRouteComponents] = useState<string[]>([]);

  useEffect(() => {
    setRouteComponents(location.pathname.split('/'));
  }, [location]);

  const mapConceptType = (type: ConceptType) => {
    if (type === ConceptType.Program) {
      return appStore.chain === Chain.kRauta ? 'Ketjun lähetykset' : 'Sesonkilähetykset';
    }
    if (type === ConceptType.Season) {
      return appStore.chain === Chain.kRauta ? 'Omat lähetykset' : 'Kaupan lähetykset';
    }
    if (type === ConceptType.B2b) {
      return appStore.chain === Chain.kRauta ? 'B2B-lähetykset' : 'Myymälän lähetykset';
    }
  };

  const render = () => {
    const levels: BreadcrumbLevel[] = [];
    for (let i = 0; i < routeComponents.length; ++i) {
      const curr = routeComponents[i];
      const prev = routeComponents[i - 1];
      const next = routeComponents[i + 1];
      // front page
      if (curr === 'next' && next) {
        levels.push({
          title: 'Etusivu',
          link: getLink('front'),
        });
      }
      if (curr === 'news') {
        // help view
        levels.push({
          title: 'Uutiset',
          link: getLink('news'),
        });
      }
      if (curr === 'help') {
        // help view
        levels.push({
          title: 'Ohjeet',
          link: getLink('help'),
        });
      }
      if (curr === 'profile') {
        // profile view
        levels.push({
          title: 'Profiili',
          link: getLink('profile'),
        });
      }
      if (prev === 'browse') {
        // concept list view
        const type = curr;
        levels.push({
          title: mapConceptType(type as ConceptType),
          link: getLink('concepts', type),
        });
      }
      if (curr === 'concept' && prev === 'next') {
        // concept view
        (() => {
          const conceptId = next;
          const concept = _.find(conceptStore.concepts, { id: conceptId });
          if (!concept) {
            return;
          }
          levels.push({
            title: mapConceptType(concept.type as ConceptType),
            link: getLink('concepts', concept.type),
          });
        })();
      }
      if (curr === 'deliveries') {
        // deliveries list view
        levels.push({
          title: 'Osallistumiseni',
          link: getLink('deliveries'),
        });
      }
      if (curr === 'push-notifications') {
        levels.push({
          title: 'Push-viestit',
          link: getLink('pushNotifications'),
        });
      }
      if (curr === 'mobile-offers') {
        levels.push({
          title: 'Aktivoitavat mobiiliedut',
          link: getLink('mobileOffers'),
        });
      }
      if (prev === 'delivery') {
        // delivery view
        (() => {
          const deliveryId = curr;
          const delivery = _.find(deliveryStore.deliveries, { id: deliveryId });
          if (!delivery) {
            return;
          }
          const template = _.find(templateStore.templates, { id: delivery.deliveryTemplate });
          if (!template) {
            return;
          }
          const concept = _.find(conceptStore.concepts, { id: template.concept });
          if (!concept) {
            return;
          }
          levels.push({
            title: mapConceptType(concept.type as ConceptType),
            link: getLink('concepts', concept.type),
          });
        })();
      }
      if (curr === 'cards') {
        // Collection stamp cards
        levels.push({
          title: 'Keräilypassit',
          link: getLink('stampCards'),
        });
      }
    }

    if (levels.length === 0) {
      return null;
    }

    return (
      <nav className="breadcrumb">
        {levels.map(({ link, title }, idx) => [
          <Link key={idx} to={link}>
            {title}
          </Link>,
          idx < levels.length - 1 && <IconChevronRight key={`${idx} >`} />, // Do not render chevron at the very end.
        ])}
      </nav>
    );
  };

  return render();
};

export default inject('conceptStore', 'templateStore', 'deliveryStore', 'appStore')(observer(Breadcrumb));
