import React from 'react';
import './drawerContent.scss';

interface Props {
  className?: string;
  children: JSX.Element | JSX.Element[];
  renderHeader?: () => JSX.Element;
  renderFooter?: () => JSX.Element;
}

const DrawerContent = ({ className, children, renderHeader, renderFooter }: Props) => {
  return (
    <div className={className}>
      {renderHeader && <header className="drawer-header">{renderHeader()}</header>}
      <div className="drawer-content-wrapper">
        <div className="drawer-content-inside">{children}</div>
      </div>
      {renderFooter && <footer className="drawer-footer">{renderFooter()}</footer>}
    </div>
  );
};

export default DrawerContent;
