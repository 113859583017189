import { CollectionStampCardType } from 'enums/stampCard';

export const CHAIN_LOGOS = {
  '3': require('images/chain-3.svg').default,
  '4': require('images/chain-4.svg').default,
  '5': require('images/chain-5.svg').default,
};

export const BASKET_DISCOUNT_PERCENTAGES = {
  11: 3,
  12: 5,
  13: 7,
  14: 10,
  15: 15,
  16: 20,
};

export const PRODUCT_IMAGE_PLACEHOLDER = 'https://www.k-ruoka.fi/assets/6335/img/ei-tuotekuvaa.svg';

export const DEFAULT_DESCRIPTION = `Leima tulee oston jälkeen näkyviin noin 5-15 minuutin jälkeen, kun käytät ostoksen yhteydessä Plussa-korttiasi. Keräilypassin täyttyessä keräilyetu päivittyy heti Plussa-kortillesi ja sovellukseesi näkyviin.

(HUOM! Lisää tähän keräilyssä olevien tuotteiden/leimojen tiedot, mistä tuotteista/ostoista saa leiman)`;

export const STAMP_CARD_BACKGROUND_IMAGES = [
  'https://k-markkinointi-api-uploads-bucket.s3.eu-west-1.amazonaws.com/stampcard/kerailypassit_tekstipohja.png',
  'https://k-markkinointi-api-uploads-bucket.s3.eu-west-1.amazonaws.com/stampcard/kerailypassit_ikonipohja.png',
  'https://k-markkinointi-api-uploads-bucket.s3.eu-west-1.amazonaws.com/stampcard/kerailypassit_kahvipassi.png',
  'https://k-markkinointi-api-uploads-bucket.s3.eu-west-1.amazonaws.com/stampcard/kerailypassit_ateriapassi.png',
  'https://k-markkinointi-api-uploads-bucket.s3.eu-west-1.amazonaws.com/stampcard/kerailypassit_ateriapassi_2.png',
  'https://k-markkinointi-api-uploads-bucket.s3.eu-west-1.amazonaws.com/stampcard/kerailypassit_ateriapassi_3.png',
  'https://k-markkinointi-api-uploads-bucket.s3.eu-west-1.amazonaws.com/stampcard/kerailypassit_ateriapassi_4.png',
  'https://k-markkinointi-api-uploads-bucket.s3.eu-west-1.amazonaws.com/stampcard/kerailypassit_kerailypassi1.png',
  'https://k-markkinointi-api-uploads-bucket.s3.eu-west-1.amazonaws.com/stampcard/kerailypassit_kerailypassi2.png',
  'https://k-markkinointi-api-uploads-bucket.s3.eu-west-1.amazonaws.com/stampcard/kerailypassit_kerailypassi3.png',
  'https://k-markkinointi-api-uploads-bucket.s3.eu-west-1.amazonaws.com/stampcard/kerailypassit_jaatelopassi.png',
];

export const STAMP_COLLECTION_INSTRUCTIONS = {
  [CollectionStampCardType.Product]:
    'Leima tulee oston jälkeen näkyviin noin 5-15 minuutin jälkeen, kun käytät ostoksen yhteydessä Plussa-korttiasi. Keräilypassin täyttyessä keräilyetu päivittyy heti Plussa-kortillesi ja sovellukseesi näkyviin.',
  [CollectionStampCardType.Basket]:
    'Leima tulee oston jälkeen näkyviin noin 5-15 minuutin jälkeen, kun käytät ostoksen yhteydessä Plussa-korttiasi. Keräilypassin täyttyessä keräilyetu päivittyy heti Plussa-kortillesi ja sovellukseesi näkyviin.',
};
