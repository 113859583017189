import React from 'react';

export interface ResultProps {
  stores: {
    participating: number;
    total: number;
  };
  recipients: {
    total: number;
    opens: number;
  };
}

// TODO return different div if no props.stores/recepients are ready, do not add conditions to every line.
const Results = (props: ResultProps) => {
  return (
    <div className="results-container">
      <div className="result">
        <span className="result__value">{props.stores ? props.stores.participating : '--'}</span>
        <span className="result__detail">/ {props.stores ? props.stores.total : '--'}</span>
        <span className="result__specifier">kauppaa osallistuu</span>
      </div>
      <div className="result">
        <span className="result__value">{props.recipients ? props.recipients.opens : '--'}</span>
        <span className="result__detail">/ {props.recipients ? props.recipients.total : '--'}</span>
        <span className="result__specifier">vastaanottajaa on klikannut jotain viestin linkkiä</span>
      </div>
    </div>
  );
};

export default Results;
