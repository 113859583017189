import { observable, action } from 'mobx';
import type Api from '../../types/next-api';

export default class StoreStore {
  client: Api.Client;
  @observable store: Api.Components.Schemas.Store = null;

  @action async fetchStore() {
    const res = await this.client.searchStores({ limit: 1, offset: 0 }, {});
    const stores = res.data.result;
    this.store = stores[0];
    return res;
  }
}
