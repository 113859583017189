export interface CroppingOption {
  value: string;
  label: string;
  queryParams: string;
}

export const croppingOptions: CroppingOption[] = [
  {
    value: 'square_crop',
    label: 'Square crop',
    queryParams: 'fm=png&w=480&h=480&fit=crop',
  },
  {
    value: 'square_fit_image',
    label: 'Square, fit image',
    queryParams: 'fit=fill&bg=fff&w=450&h=450',
  },
];

// Finnish translations
export const translations = {
  en: {
    square_crop: 'Square crop',
    square_fit_image: 'Square, fit image',
  },
  fi: {
    square_crop: 'Neliörajaus',
    square_fit_image: 'Neliö, sovita kuva',
  },
};
