import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Role } from 'types/user';
import UserStore from 'stores/userStore';

interface InjectedProps {
  userStore?: UserStore;
}

interface Props extends InjectedProps {
  children: React.ReactNode;
  role: Role;
}

const RequireAuth = ({ children, role, userStore }: Props) => {
  const location = useLocation();
  const { me } = userStore;
  const isAuthenticated = me.role === role || (me.role && role === Role.Any);
  const from = location.hash && location.hash.length > 0 ? location.pathname + location.hash : location.pathname;
  const state = !me.role ? { from } : {};
  return isAuthenticated ? (
    <>{children}</>
  ) : (
    <Navigate
      to={{
        pathname: '/',
        search: location.search,
      }}
      replace
      state={state}
    />
  );
};

export default inject('userStore')(observer(RequireAuth));
