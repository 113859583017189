import React from 'react';
import './toggleButton.scss';

interface ToggleButtonProps {
  isSelected?: boolean;
  value?: any;
  selectedText?: string;
  notSelectedText?: string;
  toggle?(value: any): void;
  disabled?: boolean;
}

const ToggleButton = (props: ToggleButtonProps) => {
  const classes = ['toggle-button'];
  if (props.disabled) {
    classes.push('disabled');
  }
  if (props.isSelected) {
    classes.push('is-selected');
  }
  const propsWithDefaults = {
    value: true,
    selectedText: 'Valittu',
    notSelectedText: 'Valitse',
    toggle: () => null,
    ...props,
  };
  const toggle = () => propsWithDefaults.toggle(propsWithDefaults.value);
  return (
    <button className={classes.join(' ')} onClick={props.disabled ? null : toggle}>
      <span>{propsWithDefaults.isSelected ? propsWithDefaults.selectedText : propsWithDefaults.notSelectedText}</span>
    </button>
  );
};

export default ToggleButton;
