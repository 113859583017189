import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Role } from 'types/user';
import { routes } from './routes';
import RequireAuth from './auth/requireAuth';
import Auth from './auth/auth';
import Logout from './auth/logout';
import Unauthorized from './auth/unauthorized';
import RetailerApp from './retailer/next/app';
import NextAdminApp from './next/app';

import '../styles/indexDefault.scss';
import NextRedirect from './next/redirect';

const App = () => {
  const location = useLocation();

  useEffect(() => {
    // Listen to url changes to send correct analytics
    if (typeof window.ga === 'function') {
      window.ga('set', 'page', location.pathname + location.search);
      window.ga('send', 'pageview', location.pathname + location.search);
    }
  }, [location]);

  return (
    <div className="app">
      <Routes>
        <Route path="/" element={<Auth />} />
        <Route path={routes.logout} element={<Logout />} />
        <Route
          path={routes.unathorized}
          element={
            <RequireAuth role={Role.Guest}>
              <Unauthorized />
            </RequireAuth>
          }
        />
        <Route path={routes.next} element={<NextRedirect />} />
        <Route
          path={routes.admin}
          element={
            <RequireAuth role={Role.Admin}>
              <NextAdminApp />
            </RequireAuth>
          }
        />
        <Route
          path={routes.retailer}
          element={
            <RequireAuth role={Role.Retailer}>
              <RetailerApp />
            </RequireAuth>
          }
        />
      </Routes>
    </div>
  );
};

export default App;
