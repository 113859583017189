import { observable, action } from 'mobx';
import type { Client, Components } from 'types/next-api';

export type StoreAnalytics = Components.Responses.AnalyticsResponse;

export default class AnalyticsStore {
  client: Client;

  @observable analytics: StoreAnalytics;

  @action async getAnalytics() {
    const { data } = await this.client.getAnalytics();
    this.setAnalytics(data);
  }

  @action setAnalytics(analytics) {
    this.analytics = analytics;
  }

  @action unsetAnalytics() {
    this.analytics = undefined;
  }
}
