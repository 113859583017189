import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { getLink } from '../../routes';
import * as _ from 'lodash';
import * as dateFns from 'date-fns';
import { PushNotification } from 'types/next';
import CloseButton from '../primitives/closeButton';
import Alerts from 'components/common/next/alert/alerts';
import Button from 'components/common/next/form/button';
import InputRadio from 'components/common/next/form/inputRadio';
import InputText from 'components/common/next/form/inputText';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import PushNotificationsStore from 'stores/next-retailer/pushNotificationsStore';
import StoreStore from 'stores/next-retailer/storeStore';
import AlertStore from 'stores/next/alerts';
import SettingsStore from 'stores/next/setting';
import {
  dateFormat,
  PushNotificationTarget,
  PushNotificationView,
  pushNotificationViewMap,
  shortTimestampFormat,
  pushNotificationTargetMap,
} from 'components/next/utils';
import { ReactComponent as IconLogo } from '@kesko/icons/logo/K-mark.svg';
import { ReactComponent as SurprisedIcon } from '@kesko/icons/mood/icon-mood_surprise.svg';
import { ReactComponent as HappyIcon } from '@kesko/icons/mood/icon-mood_happy.svg';
import Spinner from 'components/common/next/spinner';
import { ReactComponent as UnhappyIcon } from '@kesko/icons/mood/icon-mood_unhappy.svg';
import GitHash from '../../../../../components/common/next/gitHash';
import './pushForm.scss';
import { pushNotificationLimit } from 'constants/common';
import { useNavigate, useParams } from 'react-router-dom';
import type { Components } from 'types/next-api';

interface InjectedProps {
  pushNotificationsStore?: PushNotificationsStore;
  storeStore?: StoreStore;
  alertStore?: AlertStore;
  settingsStore?: SettingsStore;
}

const PushForm = ({ pushNotificationsStore, storeStore, alertStore, settingsStore }: InjectedProps) => {
  const navigate = useNavigate();
  const { notificationId: notificationIdParam } = useParams();
  const [message, setMessage] = useState<{ fi?: string; sv?: string }>({ fi: '' });
  const [additionalMessage, setAdditionalMessage] = useState<{ fi?: string; sv?: string }>({ fi: '' });
  const [storeRecipients, setStoreRecipients] = useState(0);
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState(new Date());
  // By default set the end of this day
  const [validUntilTime, setValidUntilTime] = useState(new Date(new Date().setHours(23, 59, 59, 999)));
  // const [view, setView] = useState<Components.Schemas.PushNotificationView>(null);
  const [target, setTarget] = useState<{
    view?: Components.Schemas.PushNotificationView;
    target: Components.Schemas.PushNotificationTarget[];
  }>({ view: undefined, target: [] });
  const [recipients, setRecipients] = useState(0);
  const [id, setId] = useState<string>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(0);
  const [isUpdating, setIsUpdating] = useState(false);

  const isNew = () => {
    return _.isNil(notificationIdParam) || notificationIdParam === 'new';
  };

  const getValidUntilTime = (date: Date) => {
    const validUntilTime = new Date(date.getTime());
    validUntilTime.setHours(23, 59, 59, 999);
    return validUntilTime;
  };
  const setDeliveryDate = (dateToSet: Date) => {
    setDate(dateToSet);
    setValidUntilTime(getValidUntilTime(dateToSet));
  };

  const getNotification = async (notificationId: string) => {
    setIsLoading(true);
    const notification: PushNotification = await pushNotificationsStore.get(notificationId);
    const { id, message, additionalMessage, deliveryTime, validUntilTime, view, recipients, target } = notification;
    const date = new Date(deliveryTime);
    const time = new Date(deliveryTime);
    setDate(date);
    setTime(time);
    setValidUntilTime(validUntilTime ? new Date(validUntilTime) : getValidUntilTime(date));
    // setView(view);
    setTarget({ view: view ? view : undefined, target: target || [] });
    setRecipients(recipients);
    setId(id);
    setMessage(message || { fi: '' });
    setAdditionalMessage(additionalMessage || { fi: '' });
    setIsLoading(false);
  };

  useEffect(() => {
    const init = async () => {
      const pushLimit = await settingsStore.getSetting(pushNotificationLimit);
      if (!storeStore.store) {
        await storeStore.fetchStore();
      }
      const storeId = storeStore.store.storeId;
      const recipients = await pushNotificationsStore.getRecipientCount(storeId);

      if (isNaN(parseInt(String(recipients), 10))) {
        alertStore.warning({
          message:
            'Kohderyhmän kokoa ei saatu selville, viestin lähetys ei nyt onnistu! Kokeile hetken päästä uudelleen.',
        });
      }
      setStoreRecipients(Number(recipients));
      setLimit(pushLimit && parseInt(pushLimit.value, 10));
      if (notificationIdParam && notificationIdParam !== 'new') {
        await getNotification(notificationIdParam);
      }
    };
    init();
  }, []);

  const instructionBox = () => (
    <div className="box push-notification__instructions">
      <ul>
        <li>
          Hyvä viesti <b>palvelee tilannetta.</b> Niitä kannattaa lähettää silloin kun kun kaupalla on asiakkaalle
          relevanttia ja aikaan sidottua viestittävää. Esimerkiksi kesän marjasesongin ollessa päällä voi kauppa
          lähettää ajankohtaiset päivän marjatarjoukset asiakkailleen push-viestinä.
        </li>
        <li>
          Viesti näkyy asiakkaan näytöllä vain hetken ajan, joten sen on hyvä olla <b>napakka ja lyhyt.</b> Asiakas ei
          pääse palaamaan jo avattuihin viesteihin uudelleen.
        </li>
        <li>
          Viesti ohjaa aina sovelluksen näkymään. On tärkeää, että viestin ja avatun näkymän välillä on{' '}
          <b>selkeä yhteys.</b>
        </li>
        <li>
          Kaupan nimi lisätään viestin alkuun automaattisesti. <b>60 merkkiä pitkät tai sitä lyhyemmät</b> viestit
          näkyvät kaikilla päätelaitteilla oikein. <b>Yli 60- mutta alle 160-merkkiset</b> viestit näkyvät useimmissa
          tapauksissa oikein. Tätä pidemmät viestit katkeavat useimmilla laitteilla kesken.
        </li>
        <li>
          Voit ajastaa viestisi lähtemään <b>klo 9 ja 21 välisenä aikana</b>. Jos lähetysaika{' '}
          <b>osuu tämän välin ulkopuolelle tai Push-viestijono on pitkä</b>, viesti lähetetään seuraavana aamuna klo 9
          alkaen. <b>Huomaa:</b> menneisyyteen ajastetut viestit menevät suoraan jonoon ja lähetettäväksi. Voit ajastaa
          korkeintaan kaksi viestiä lähetettäväksi yhden viikon sisään.
        </li>
        <li>
          Ketjut ja K-Ruoka lähettävät sovelluksen kautta Push-viestejä usein,{' '}
          <b>etenkin maanantaisin ja torstaisin, joten silloin viestijonot ovat pisimmät</b>. Aikaan sidotut viestit
          kannattaa siis lähettää muina päivinä.
        </li>
      </ul>
    </div>
  );

  const setNextPossibleDate = () => {
    const newDate = new Date();
    setDate(newDate);
    setTime(new Date());
    setValidUntilTime(getValidUntilTime(newDate));
  };

  const dateIsDisabled = (date: Date) => {
    const startOfWeek = new Date(dateFns.startOfISOWeek(date));
    const endOfWeek = new Date(dateFns.endOfISOWeek(date));
    const count = pushNotificationsStore.pushNotifications.reduce((acc, curr) => {
      const currentDeliveryTime = new Date(curr.deliveryTime);
      if (currentDeliveryTime >= startOfWeek && currentDeliveryTime < endOfWeek) {
        return acc + 1;
      }
      return acc;
    }, 0);

    if (count >= limit) {
      return true;
    }
    return false;
  };
  const validUntilDateIsDisabled = (dateToRender: Date) => {
    const deliveryTime = date;

    const hours = time.getHours();
    const minutes = time.getMinutes();
    deliveryTime.setHours(hours);
    deliveryTime.setMinutes(minutes);

    const minValidUntilDate = deliveryTime;
    minValidUntilDate.setHours(0, 0, 0, 0);

    const maxValidUntilDate = getValidUntilTime(addDays(deliveryTime, 29));

    if (dateToRender < minValidUntilDate) {
      return true;
    }
    if (dateToRender > maxValidUntilDate) {
      return true;
    }
    return false;
  };
  const addDays = (date: Date, days: number) => {
    const copy = new Date(date.getTime());
    copy.setDate(copy.getDate() + days);
    return copy;
  };

  const handleSend = async () => {
    if (isUpdating) {
      return;
    }

    setIsUpdating(true);
    const deliveryTime = date;

    const hours = time.getHours();
    const minutes = time.getMinutes();
    deliveryTime.setHours(hours);
    deliveryTime.setMinutes(minutes);

    const payload = {
      view: target.view,
      target: target.target,
      deliveryTime: deliveryTime.toISOString(),
      validUntilTime: validUntilTime.toISOString(),
      recipients: storeRecipients,
      message,
      additionalMessage,
    };

    let result;
    if (isNew()) {
      result = await pushNotificationsStore.create(payload);
    } else {
      result = await pushNotificationsStore.update(Object.assign(payload, { id }));
    }
    if (result.status === 200 || result.status === 201) {
      setId(result.data.id);
      alertStore.success({
        message: `Push-viesti tallennettiin onnistuneesti. ${
          dateFns.isFuture(deliveryTime)
            ? `Viesti ajastettiin lähtemään ${dateFns.format(deliveryTime, shortTimestampFormat)}`
            : ''
        }`,
      });
      navigate(getLink('pushNotifications'));
    } else {
      if (result.status === 403) {
        alertStore.warning({
          message: 'Kyseiselle viikolle on jo lähetetty tai ajastettu maksimimäärä push-viestejä',
        });
      } else {
        alertStore.error({
          message: 'Push-viestin tallennus epäonnistui',
        });
      }
    }
    setIsUpdating(false);
  };

  const handleDelete = async () => {
    const confirmed = window.confirm('Oletko varma että haluat poistaa Push-viestin? Toimintoa ei voi perua.');
    if (confirmed) {
      const result = await pushNotificationsStore.delete(id);
      if (result.status === 200) {
        alertStore.success({
          message: 'Push-viesti poistettiin onnistuneesti',
        });
        navigate(getLink('pushNotifications'));
      } else {
        alertStore.error({
          message: 'Push-viestin poistaminen epäonnistui',
        });
      }
    }
  };

  const messageStatus = () => {
    if (!message.fi.length) {
      return <></>;
    }
    const length = message.fi.length;
    if (length < 60) {
      return (
        <div className="message-status ok">
          <div className="message-status__text">
            <HappyIcon />
            <span>Hyvä! Viestin merkkimäärä näkyy kaikissa laitteissa ongelmitta.</span>
          </div>
        </div>
      );
    }
    if (length < 160) {
      return (
        <div className="message-status warn">
          <div className="message-status__text">
            <SurprisedIcon />
            <span>
              Ok. Kirjoittamasi viesti on liian pitkä pienimmille päätelaitteille. Viestisi kuitenkin näkyy suurimmassa
              osassa laitteita oikein. Lyhennä viestiä, jos mahdollista.
            </span>
          </div>
        </div>
      );
    }
    return (
      <div className="message-status error">
        <div className="message-status__text">
          <UnhappyIcon />
          <span>Lyhennä viestiä. Viestisi on liian pitkä ja tulee katkeamaan suurimmalla osalla laitteista.</span>
        </div>
      </div>
    );
  };
  const additionalMessageStatus = () => {
    if (!additionalMessage?.fi?.length) {
      return <></>;
    }
    if (!additionalMessageValid()) {
      return (
        <div className="message-status error">
          <div className="message-status__text">
            <UnhappyIcon />
            <span>Viesti on liian pitkä. Maksimi merkkimäärä on 800.</span>
          </div>
        </div>
      );
    }
    return <></>;
  };

  const additionalMessageValid = () => {
    const length = (message?.fi?.length || 0) + (additionalMessage?.fi?.length || 0);
    return length <= 800;
  };
  const pushNotificationSelected = () => {
    return _.isEqual(target.target, [
      PushNotificationTarget.NOTIFICATION_CENTER,
      PushNotificationTarget.PUSH_NOTIFICATION,
    ]);
  };

  const messageForm = () => {
    const storeName = storeStore.store ? storeStore.store.name : '';
    const length = message.fi.length + additionalMessage.fi.length;
    const isPushNotification = pushNotificationSelected();
    const isDefaultChecked = (compare: PushNotificationView) => {
      return target.view === compare;
    };
    const isCheckedTarget = (compare: PushNotificationTarget) => {
      if (
        compare === PushNotificationTarget.NOTIFICATION_CENTER &&
        _.isEqual(target.target, [PushNotificationTarget.NOTIFICATION_CENTER])
      ) {
        return true;
      }
      if (compare === PushNotificationTarget.PUSH_NOTIFICATION && isPushNotification) {
        return true;
      }
      return false;
    };
    const bannedKeys = ['SHOW_STAMP_CARDS'];
    return (
      <div className="push-form box">
        <div className="push-form__row">
          <div className="column">
            <h3>Viestin näkyvyys</h3>
            <div style={{ marginBottom: 24 }}>
              <InputRadio
                key={`target-push-notification`}
                label={`${pushNotificationTargetMap[PushNotificationTarget.PUSH_NOTIFICATION]} ja ${
                  pushNotificationTargetMap[PushNotificationTarget.NOTIFICATION_CENTER]
                }`}
                value={PushNotificationTarget.PUSH_NOTIFICATION}
                name="target"
                checked={isCheckedTarget(PushNotificationTarget.PUSH_NOTIFICATION)}
                onChange={() =>
                  setTarget({
                    view: target.view,
                    target: [PushNotificationTarget.NOTIFICATION_CENTER, PushNotificationTarget.PUSH_NOTIFICATION],
                  })
                }
                description="Viesti lähetetään push-notifikaationa laitteisiin ja se näkyy myös K-Ruoka-sovelluksen ilmoituskeskuksessa."
                fontWeight="bold"
              />
            </div>
            <InputRadio
              key={`target-notification-center`}
              label={`Vain ${pushNotificationTargetMap[PushNotificationTarget.NOTIFICATION_CENTER]}`}
              value={PushNotificationTarget.NOTIFICATION_CENTER}
              name="target"
              checked={isCheckedTarget(PushNotificationTarget.NOTIFICATION_CENTER)}
              onChange={() => setTarget({ view: target.view, target: [PushNotificationTarget.NOTIFICATION_CENTER] })}
              description="Viesti näkyy vain K-Ruoka-sovelluksen omassa ilmoituskeskuksessa, erillistä push-notifikaatiota ei lähde asiakkaalle."
              fontWeight="bold"
            />
          </div>
          <div className="column">
            <div className="recipient-count">
              Arviolta <em>{storeRecipients}</em> vastaanottajaa
            </div>
            <div className="message-preview">
              <div className="title-row">
                <IconLogo />
                <h2>{storeName}</h2>
              </div>
              <div className="message-content">
                <span>{`${message.fi}`}</span>
                <br />
                <span style={{ whiteSpace: 'pre-wrap' }}>{additionalMessage.fi}</span>
              </div>
            </div>
            <span className="message-length">{length} merkkiä</span>
          </div>
        </div>
        <div className="push-form__row">
          <div className="column">
            <h3>{isPushNotification ? 'Ohjaus push-viestistä' : 'Ohjaus ilmoituskeskuksesta'}</h3>
            {Object.keys(PushNotificationView).map((key, index) => {
              // TODO: This check should be removed once the new K-Ruoka app supports SHOW_STAMP_CARDS push notifications
              // https://jira.kesko.fi/browse/MD-1535
              if (bannedKeys.includes(key)) {
                return null;
              } else {
                return (
                  <InputRadio
                    key={`view-${index}`}
                    label={pushNotificationViewMap[key]}
                    value={PushNotificationView[key]}
                    name="view"
                    checked={isDefaultChecked(PushNotificationView[key])}
                    onChange={() =>
                      setTarget({
                        view: PushNotificationView[key],
                        target: target.target,
                      })
                    }
                  />
                );
              }
            })}
          </div>
        </div>
        <div className="push-form__row">
          <div className="column">
            <h3>Viesti</h3>
            <InputText
              textarea
              rows={4}
              value={message.fi}
              placeholder="Push-viestin sisältö. Näkyy push-viestissä sekä sovelluksen ilmoituskeskuksessa ennen Lisätietoja-tekstiä."
              name="push-contnet"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMessage({ fi: e.target.value })}
            />
          </div>
          <div className="column">{messageStatus()}</div>
        </div>
        <div className="push-form__row">
          <div className="column">
            <h3>Lisätietoja</h3>
            <InputText
              textarea
              rows={6}
              value={additionalMessage.fi}
              placeholder="Vain K-ruoka -sovelluksen ilmoituskeskuksessa näkyvä lisätietoteksti."
              name="push-contnet"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAdditionalMessage({ fi: e.target.value })}
            />
          </div>
          <div className="column">{additionalMessageStatus()}</div>
        </div>
        <div className="push-form__row">
          <div className="column">
            <h3>Lähetysaika</h3>
            <DatePicker
              disablePast
              value={date}
              format={dateFormat}
              disableToolbar
              className="datepicker"
              onChange={setDeliveryDate}
              shouldDisableDate={dateIsDisabled}
            />
            <TimePicker value={time} format="HH.mm" ampm={false} minutesStep={5} onChange={setTime} />
            <Button className="next-possible-date btn-bordered" onClick={setNextPossibleDate}>
              Seuraava mahdollinen
            </Button>
            <div className="small-text date-instruction">
              Valitse ajankohta jolloin viesti lähetetään. Huomaa, että todellinen lähetysaika saattaa poiketa
              valitsemastasi tai siirtyä seuraavaan päivään, jos samaan aikaan on lähdössä suuri määrä viestejä. Viesti
              näkyy heti valitun lähetysajan jälkeen K-ruoka -sovelluksen ilmoituskeskuksessa.
            </div>
          </div>
        </div>
        <div className="push-form__row">
          <div className="column">
            <h3>Voimassaoloaika</h3>
            <DatePicker
              disablePast
              value={validUntilTime}
              format={dateFormat}
              disableToolbar
              className="datepicker"
              onChange={setValidUntilTime}
              shouldDisableDate={validUntilDateIsDisabled}
            />
            <div className="small-text date-instruction">
              Voimassaoloaika määrittelee, kuinka pitkään viesti näkyy käyttäjille K-Ruoka mobiilin ilmoituskeskuksessa.
            </div>
          </div>
        </div>
      </div>
    );
  };

  const render = () => {
    const canSendMessage = isNew()
      ? target.target.length > 0 && message.fi.length && date && storeRecipients && additionalMessageValid()
      : target.target.length > 0 && message.fi.length && date && additionalMessageValid();

    if (isLoading) {
      return <Spinner />;
    }
    return (
      <div className="new-push-message full-page">
        <header>
          <h1 className="form-title">{isNew() ? 'Uusi Viesti' : 'Muokkaa viestiä'}</h1>
          <CloseButton fallback={getLink('pushNotifications')} />
        </header>
        <div className="full-page-content new-push-message-content">
          <h2>Hyvän push-viestin muistisäännöt</h2>
          {instructionBox()}
          <h2>Viestin sisältö</h2>
          {messageForm()}
        </div>
        <Alerts />
        <footer>
          <GitHash />
          <Button className="btn-bordered" link={getLink('pushNotifications')}>
            Palaa
          </Button>
          {!isNew() && (
            <Button className="btn-bordered" onClick={handleDelete}>
              Poista
            </Button>
          )}
          <Button disabled={!canSendMessage} onClick={handleSend}>
            Valmis
          </Button>
          {isUpdating && <Spinner addClassName="spinner--unset" />}
        </footer>
      </div>
    );
  };

  return render();
};

export default inject('pushNotificationsStore', 'storeStore', 'alertStore', 'settingsStore')(observer(PushForm));
