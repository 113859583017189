import React from 'react';
import PushNotificationsStore from 'stores/next-retailer/pushNotificationsStore';
import Button from 'components/common/next/form/button';
import { inject, observer } from 'mobx-react';
import * as dateFns from 'date-fns';
import {
  PushNotificationTarget,
  pushNotificationTargetMap,
  pushNotificationViewMap,
  shortTimestampFormat,
} from 'components/next/utils';
import './pushNotificationsPage.scss';
import { getLink } from '../../routes';
import Spinner from 'components/common/next/spinner';

interface Props {}
interface State {
  isLoading: boolean;
}

interface InjectedProps extends Props {
  pushNotificationsStore: PushNotificationsStore;
}

@inject('pushNotificationsStore')
@observer
class PushNotificationsPage extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { isLoading: false };
  }

  get injected() {
    return this.props as InjectedProps;
  }

  get pushNotifications() {
    return this.injected.pushNotificationsStore.pushNotifications;
  }

  componentDidMount = async () => {
    const { pushNotificationsStore } = this.injected;
    this.setState({ isLoading: true });
    await pushNotificationsStore.search({});
    this.setState({ isLoading: false });
  };

  getNotifications = (state: 'queued' | 'sent') => {
    const result =
      state === 'queued'
        ? this.pushNotifications.filter((notification) => dateFns.isFuture(new Date(notification.deliveryTime)))
        : this.pushNotifications.filter((notification) => !dateFns.isFuture(new Date(notification.deliveryTime)));
    return result;
  };
  getValidUntilTimeText = (deliveryTime?: string, validUntilTime?: string) => {
    if (!deliveryTime && !validUntilTime) {
      return '';
    }
    if (!deliveryTime) {
      return `Voimassa ${dateFns.format(new Date(validUntilTime), 'dd.MM.yyyy')}`;
    }
    if (!validUntilTime) {
      return '';
    }
    const formattedDeliveryTime = dateFns.format(new Date(deliveryTime), 'dd.MM.yyyy');
    const formattedValidUntilTime = dateFns.format(new Date(validUntilTime), 'dd.MM.yyyy');

    if (formattedDeliveryTime === formattedValidUntilTime) {
      return `Voimassa ${formattedValidUntilTime}`;
    }

    return `Voimassa ${formattedDeliveryTime} - ${formattedValidUntilTime}`;
  };

  notificationList = (state: 'queued' | 'sent') => {
    const notifications = this.getNotifications(state);
    if (!notifications.length) {
      return <span className="no-notifications">Ei viestejä</span>;
    }
    return this.getNotifications(state)
      .sort((a, b) => dateFns.compareDesc(new Date(a.deliveryTime), new Date(b.deliveryTime)))
      .map((notification, index) => (
        <div key={`${state}-${index}`} className="push-notification-wrapper">
          <div className="push-notification sent">
            <div className="push-notification__upper-half">
              <span className="push-notification__timestamp">
                {dateFns.format(new Date(notification.deliveryTime), shortTimestampFormat)}
              </span>
              <span className="push-notification__message">{notification.message.fi}</span>
              {notification.additionalMessage?.fi && (
                <span className="push-notification__additional-message">{notification.additionalMessage.fi}</span>
              )}
            </div>
            <div className="push-notification__lower-half">
              {notification.target && (
                <div className="push-notification__view">
                  <h4>Viestin näkyvyys</h4>
                  {notification.target.map((t: PushNotificationTarget, i: number) => (
                    <div key={`${state}-${index}-${i}`}>{`- ${pushNotificationTargetMap[t]}`}</div>
                  ))}
                </div>
              )}
              {notification.view && (
                <div className="push-notification__view">
                  <h4>Push-viestin ohjaus</h4>
                  <span>{pushNotificationViewMap[notification.view]}</span>
                </div>
              )}
              {notification.validUntilTime && notification.deliveryTime && (
                <div className="push-notification__valid-until">
                  <span>{this.getValidUntilTimeText(notification.deliveryTime, notification.validUntilTime)}</span>
                </div>
              )}
            </div>
          </div>
          {state === 'queued' && (
            <Button className="btn-bordered" link={getLink('editPushNotification', notification.id)}>
              Muokkaa
            </Button>
          )}
        </div>
      ));
  };

  render() {
    if (this.state.isLoading) {
      return <Spinner />;
    }
    return (
      <div className="next-push-notifications">
        <div className="main-content">
          <div className="content">
            <h2>Push-viestit</h2>
            <div className="push-instructions">
              <p>
                Kauppiaat voivat lähettää push-viestejä omille asiakkailleen K Markkinoinnista kolmesti viikossa.
                Viestit lähetetään kaikille niille ilmoitukset sallineille asiakkaille, jotka ovat valinneet
                sovelluksessa kaupan omaksi kaupakseen
              </p>
              <Button link={getLink('newPushNotification')}>Lähetä uusi viesti</Button>
            </div>
            <h2>Jonossa olevat</h2>
            <section className="push-notifications">{this.notificationList('queued')}</section>
            <h2>Lähetetyt</h2>
            <section className="push-notifications">{this.notificationList('sent')}</section>
          </div>
        </div>
      </div>
    );
  }
}

export default PushNotificationsPage;
