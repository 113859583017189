import React, { useEffect, useState } from 'react';
import page from 'components/next/pages/page/page';
import { inject, observer } from 'mobx-react';
import PricingStore from 'stores/next/pricing';
import AlertStore from 'stores/next/alerts';
import { pricingItems } from 'components/next/routes';
import { CustomField, FormInput } from 'components/next/components/form/input';
import * as _ from 'lodash';
import type { PricingItem as PricingItemEntity, PricingComponent, PricingStep, Route } from 'components/next/types';
import Alerts from 'components/common/next/alert/alerts';
import * as date from 'date-fns';
import { timestampFormat, getPricingItemComponentDefaults, getPricingItemStepDefaults } from 'components/next/utils';
import Dropdown, { DropdownItem } from 'components/next/components/dropdown';
import { ReactComponent as IconDelete } from '@kesko/icons/action/icon-delete.svg';
import './pricingItem.scss';
import { castDate } from 'utils/helpers';
import ToggleButton from 'components/common/next/form/toggleButton';
import { DeliveryChannelName, PricingMethod } from 'enums/common';
import { useNavigate, useParams } from 'react-router-dom';

interface PricingProps {
  pricingStore?: PricingStore;
  alertStore?: AlertStore;
  getPageLink(route: Route, id: string): string;
}

const PricingItem = ({ pricingStore, alertStore, getPageLink }: PricingProps) => {
  const channelOptions: DropdownItem[] = [
    { name: 'Email', value: DeliveryChannelName.Email },
    { name: 'Print', value: DeliveryChannelName.Print },
  ];

  const pricingOptions: DropdownItem[] = [
    { name: 'Normal (100%)', value: PricingMethod.Normal },
    { name: 'KCM Two Thirds Price (67%)', value: PricingMethod.KCMTwoThirdsPrice },
    { name: 'KCM Half Price (50%)', value: PricingMethod.KCMHalfPrice },
  ];

  const { id: idParam } = useParams();
  const navigate = useNavigate();
  const [changeSet, setChangeSet] = useState<PricingItemEntity>(null);
  const [isValid, setIsValid] = useState(false);
  const [inUse, setInUse] = useState(true);

  const validate = (pricingItem: PricingItemEntity) => {
    if (!pricingItem.title || !pricingItem.channel) {
      return false;
    }
    if (pricingItem.components.length === 0) {
      return false;
    }
    if (
      _.some(pricingItem.components, (component: PricingComponent) => !component.title || component.steps.length === 0)
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const init = async () => {
      await pricingStore.searchPricingItems({});
      await pricingStore.findById(idParam);

      const { pricingItem } = pricingStore;
      const valid = validate(pricingItem);

      if (!changeSet || pricingItem.createdAt !== changeSet.createdAt) {
        setChangeSet(pricingItem);
      }
      setIsValid(valid);
      const inUse = pricingStore.pricingItemsInUse[idParam];
      setInUse(inUse);
    };
    init();
  }, []);

  const handleSave = () => {
    if (
      changeSet.invoicingEnabled &&
      !window.confirm('Are you sure you want to enable invoicing? You cannot edit pricing anymore.')
    ) {
      return;
    }
    try {
      pricingStore.updatePricingItem({
        ...pricingStore.pricingItem,
        ...changeSet,
      });
      alertStore.success({
        message: 'Pricing saved succesfully',
      });
    } catch (err) {
      alertStore.error({ message: err.message, ttl: 0 });
    }
  };

  const handleDelete = async () => {
    if (!window.confirm('Are you sure you want to delete this pricing item? This action cannot be undone.')) {
      return;
    }

    try {
      await pricingStore.deletePricingItem(pricingStore.pricingItem.id);
      navigate(getPageLink(pricingItems, null));
    } catch (err) {
      alertStore.error({ message: err.message, ttl: 0 });
    }
  };

  const handleEdit = (fieldName: string | any[], value: string | boolean, convert = false) => {
    const newChangeSet = { ...changeSet };
    _.set(newChangeSet, fieldName, convert ? Number(value) : value);
    const valid = validate(newChangeSet);
    setChangeSet(newChangeSet);
    setIsValid(valid);
  };

  const addComponent = () => {
    const newChangeSet = { ...changeSet };
    newChangeSet.components.push(getPricingItemComponentDefaults());
    const valid = validate(newChangeSet);
    setChangeSet(newChangeSet);
    setIsValid(valid);
  };

  const removeComponent = (component: number) => {
    if (!window.confirm('Are you sure you want to delete this component? This action cannot be undone.')) {
      return;
    }
    const newChangeSet = { ...changeSet };
    newChangeSet.components.splice(component, 1);
    const valid = validate(newChangeSet);
    setChangeSet(newChangeSet);
    setIsValid(valid);
  };

  const addStep = (component: number) => {
    const newChangeSet = { ...changeSet };
    newChangeSet.components[component].steps.push(getPricingItemStepDefaults());
    const valid = validate(newChangeSet);
    setChangeSet(newChangeSet);
    setIsValid(valid);
  };

  const removeStep = (component: number, step: number) => {
    if (!window.confirm('Are you sure you want to delete this step? This action cannot be undone.')) {
      return;
    }
    const newChangeSet = { ...changeSet };
    newChangeSet.components[component].steps.splice(step, 1);
    const valid = validate(newChangeSet);
    setChangeSet(newChangeSet);
    setIsValid(valid);
  };

  const goBack = () => {
    navigate(-1);
  };

  const maybeRenderHeader = () => {
    const { pricingItem } = pricingStore;

    if (!pricingItem) {
      return null;
    }

    return (
      <header className="editor-header">
        <div className="editor-header__controls">
          <button className="go-back" onClick={goBack}>
            <img src={require('images/arrow-back.svg').default} alt="go-back" />
          </button>
          <div className="editor-header__details">
            <h3>{pricingItem.title}</h3>
            <h4>
              <span>ID: {pricingItem.id}</span>
              <span>Last updated: {date.format(castDate(pricingItem.updatedAt), timestampFormat)}</span>
            </h4>
          </div>
          {pricingItem.invoicingEnabled ? (
            <div className="editor-header__actions">Invoicing is enabled for this pricing, no editing allowed</div>
          ) : null}
          <div className="editor-header__actions">
            <button
              className={`save${isValid && !pricingItem.invoicingEnabled ? '' : ' disabled'}`}
              onClick={handleSave}
              disabled={!isValid || pricingItem.invoicingEnabled}
            >
              Save
            </button>
            <button className={`delete${!inUse ? '' : ' disabled'}`} onClick={handleDelete} disabled={inUse}>
              <img src={require('images/delete.svg').default} alt="delete" />
              Delete
            </button>
          </div>
        </div>
      </header>
    );
  };

  const renderComponents = () => {
    const { components } = changeSet;

    if (components.length === 0) {
      return 'Add at least one component.';
    }

    const isDisabled = changeSet.invoicingEnabled;

    return _.map(components, (component: PricingComponent, i: number) => {
      const title = { name: component.title || 'postitus', value: component.title || 'postitus' };
      return (
        <React.Fragment key={`component-${i}`}>
          <section className="editor-section component">
            <div className="title">
              <CustomField label="Title">
                <Dropdown
                  select={(val: string) => handleEdit(['components', i, 'title'], val)}
                  selectedItem={title}
                  data={[
                    { value: 'paino', name: 'Paino' },
                    { value: 'postitus', name: 'postitus' },
                  ]}
                  notSelectedText="Title"
                  disabled={isDisabled}
                />
              </CustomField>
              {!isDisabled && (
                <div className="actions">
                  <button onClick={() => removeComponent(i)}>
                    <span>Remove component</span>
                    <IconDelete />
                  </button>
                </div>
              )}
            </div>
            <div className="subheader">
              <h4 className="section-title">Steps</h4>
              {!isDisabled && (
                <div className="actions">
                  <button onClick={() => addStep(i)}>
                    <span>Add step</span>
                    <img src={require('images/add.svg').default} alt="add" />
                  </button>
                </div>
              )}
            </div>
            {component.steps.length === 0 && <React.Fragment>Add at least on step.</React.Fragment>}
            {_.map(component.steps, (step: PricingStep, j: number) => {
              return (
                <div key={`step-${i}-${j}`} className="step">
                  <FormInput
                    additionalClasses="step--from"
                    type="number"
                    min={0}
                    label="From (units)"
                    value={_.get(changeSet, ['components', i, 'steps', j, 'from'])}
                    handleChange={(e) => handleEdit(['components', i, 'steps', j, 'from'], e.target.value, true)}
                    readonly={isDisabled}
                  />
                  <FormInput
                    additionalClasses="step--price"
                    type="number"
                    min={0}
                    label="Price (€ / unit)"
                    value={_.get(changeSet, ['components', i, 'steps', j, 'price'])}
                    handleChange={(e) => handleEdit(['components', i, 'steps', j, 'price'], e.target.value, true)}
                    readonly={isDisabled}
                  />
                  {!isDisabled && (
                    <div className="step--actions">
                      <button onClick={() => removeStep(i, j)}>
                        <IconDelete />
                      </button>
                    </div>
                  )}
                </div>
              );
            })}
          </section>
        </React.Fragment>
      );
    });
  };

  const selectedChannel = (changeSet && _.find(channelOptions, { value: changeSet.channel })) || channelOptions[0];
  const selectedMethod = (changeSet && _.find(pricingOptions, { value: changeSet.pricingMethod })) || pricingOptions[0];
  const canDisableInvoicing = !!pricingStore.pricingItem?.invoicingEnabled;
  const isDisabled = !!changeSet?.invoicingEnabled;

  return (
    <div className="pricing-item">
      {maybeRenderHeader()}
      {changeSet && (
        <div className="content">
          <Alerts />
          <section className="editor-section">
            <CustomField label="Invoicing enabled">
              <ToggleButton
                isSelected={changeSet.invoicingEnabled}
                value={changeSet.invoicingEnabled}
                selectedText="yes"
                notSelectedText="no"
                toggle={(value) => handleEdit('invoicingEnabled', !changeSet.invoicingEnabled)}
                disabled={canDisableInvoicing}
              />
              <span className="detail-text">
                Note that once invoicing is enabled, pricing cannot be modified anymore
              </span>
            </CustomField>
            <FormInput
              label="Title"
              value={changeSet.title}
              handleChange={(e) => handleEdit('title', e.target.value)}
              readonly={isDisabled}
            />
            <div className="input-field">
              <label>Channel</label>
              <Dropdown
                data={channelOptions}
                selectedItem={selectedChannel}
                notSelectedText="Select channel"
                select={(value: string) => handleEdit('channel', value)}
                disabled={isDisabled}
              />
            </div>
            <div className="input-field">
              <label>Pricing method</label>
              <Dropdown
                data={pricingOptions}
                selectedItem={selectedMethod}
                notSelectedText="Select pricing method"
                select={(value: string) => handleEdit('pricingMethod', value)}
                disabled={isDisabled}
              />
            </div>
            <FormInput
              label="Order"
              type="number"
              min={0}
              value={changeSet.order}
              handleChange={(e) => handleEdit('order', e.target.value, true)}
              readonly={isDisabled}
            />
          </section>
          <div className="subheader">
            <h3 className="section-title">Components</h3>
            {!isDisabled && (
              <button className="add-component" onClick={addComponent}>
                <span>Add component</span>
                <img src={require('images/add.svg').default} alt="add" />
              </button>
            )}
          </div>
          {renderComponents()}
        </div>
      )}
    </div>
  );
};

export default page(inject('pricingStore', 'alertStore')(observer(PricingItem)));
