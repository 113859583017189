import React, { useEffect, useState } from 'react';
import * as dateFns from 'date-fns';
import _ from 'utils/lodash';
import { inject, observer } from 'mobx-react';
import page from 'components/next/pages/page/page';
import StoreStore from 'stores/next/stores';
import PushNotificationsStore from 'stores/next-retailer/pushNotificationsStore';
import SettingsStore from 'stores/next/setting';
import AlertStore from 'stores/next/alerts';
import Spinner from 'components/common/next/spinner';
import SidebarWrapper from 'components/next/components/sidebar/sidebar';
import StoreSelector from 'components/next/components/storeSelector';
import SearchBox from 'components/next/components/sidebar/searchBox';
import { FormInput, DateField } from 'components/next/components/form/input';
import Alerts from 'components/common/next/alert/alerts';
import {
  pushNotificationViewMap,
  timestampFormat,
  dateFormat,
  payloadDateFormat,
  pushNotificationTargetMap,
} from 'components/next/utils';
import './pushNotifications.scss';
import type { Store } from 'types/next';
import { pushNotificationLimit } from 'constants/common';
import { useDebouncedEffect } from 'utils/helpers';
import TableContainer from 'components/next/components/tableContainer';

const pageSize = 100;

interface Props {
  pushNotificationsStore?: PushNotificationsStore;
  storeStore?: StoreStore;
  settingsStore?: SettingsStore;
  alertStore?: AlertStore;
}

const PushNotifications = ({ pushNotificationsStore, storeStore, settingsStore, alertStore }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQuery] = useState('');
  const [storeIds, setStoreIds] = useState<string[]>([]);
  const [pushLimit, setPushLimit] = useState(0);
  const [periodCount, setPeriodCount] = useState(0);
  const [deliveryDateFrom, setDeliveryDateFrom] = useState<string>(null);
  const [deliveryDateTo, setDeliveryDateTo] = useState<string>(null);
  const [uniqueStoreCount, setUniqueStoreCount] = useState(0);
  const [count, setCount] = useState(0);

  const getPushNotificationLimit = () => {
    return settingsStore.settings.find((s) => s.name === pushNotificationLimit);
  };

  const getStoreForNotification = (storeId: string) => {
    const result = _.find(storeStore.stores, (store) => store.storeId === storeId);
    if (result) {
      return result.name;
    }
    return 'Unknown store';
  };

  const getPushLimitSetting = async () => {
    setIsLoading(true);
    const setting = await settingsStore.getSetting(pushNotificationLimit);
    setPushLimit(parseInt(setting.value, 10));
    setIsLoading(false);
  };

  const search = async (limit = pageSize, offset = 0) => {
    setIsLoading(true);

    const search = _.omitBy(
      {
        query,
        storeId: storeIds,
        deliveryDateFrom,
        deliveryDateTo,
      },
      _.isNil,
    );

    const res = await pushNotificationsStore.search(search, {
      limit,
      sort: 'pushNotification.deliveryTime',
      order: 'desc',
      offset,
    });
    setPeriodCount(res.data.periodCount);
    setCount(res.data.count);
    setUniqueStoreCount(res.data.uniqueStoreCount);
    setIsLoading(false);
  };

  useEffect(() => {
    const init = async () => {
      await getPushLimitSetting();
      await search();
      await storeStore.searchStores({});
    };
    init();
  }, []);

  useDebouncedEffect(search, [query, storeIds, deliveryDateFrom, deliveryDateTo], 500);

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
  };

  const handleStoresChange = (stores: Store[]) => {
    setStoreIds(stores.map((store) => store.storeId));
  };

  const handleDateChange = (setter: (value) => void) => (date: Date) => {
    const value = dateFns.isDate(date) ? dateFns.format(date, payloadDateFormat) : undefined;
    setIsLoading(true);
    setter(value || null);
  };

  const handlePushLimitValueChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (value < 0) {
      return 0;
    }
    setPushLimit(value);
    return value;
  };

  const savePushLimit = async () => {
    if (isNaN(pushLimit)) {
      alertStore.warning({
        message: 'Insert an integer above zero',
      });
    } else {
      const setting = getPushNotificationLimit();
      const newValue = pushLimit.toString();
      const result = await settingsStore.modifySetting({ ...setting, value: newValue });
      if (result.status === 200) {
        alertStore.success({
          message: 'Saved succesfully',
        });
      } else {
        alertStore.error({
          message: 'Saving failed',
        });
      }
    }
  };

  const renderPushNotificationTable = () => {
    return (
      <div className="push-notifications">
        <TableContainer loading={isLoading} pagination={{ pageSize, totalItems: count, onClick: search }}>
          <table className="styled">
            <thead>
              <tr>
                <th>Delivery time</th>
                <th>Valid until time</th>
                <th>Message</th>
                <th>Notification center message</th>
                <th>View</th>
                <th>Target</th>
                <th>Recipients</th>
                <th>Store</th>
              </tr>
            </thead>
            <tbody>
              {pushNotificationsStore.pushNotifications.map((notification, index) => (
                <tr key={`push-${index}`}>
                  <td>{dateFns.format(new Date(notification.deliveryTime), timestampFormat)}</td>
                  <td>
                    {notification.validUntilTime
                      ? dateFns.format(new Date(notification.validUntilTime), timestampFormat)
                      : ''}
                  </td>
                  <td>{notification.message.fi}</td>
                  <td>{notification.additionalMessage?.fi || ''}</td>
                  <td>{pushNotificationViewMap[notification.view]}</td>
                  <td>
                    {notification.target
                      ? _.join(
                          notification.target.map((t) => pushNotificationTargetMap[t]),
                          ', ',
                        )
                      : ''}
                  </td>
                  <td>{notification.recipients}</td>
                  <td>{getStoreForNotification(notification.storeId)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableContainer>
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="push-notifications-sidebar">
        <div className="sidebar__title-wrapper">
          <h3 className="sidebar__title">Filters</h3>
          {isLoading && <Spinner addClassName="spinner--unset" />}
        </div>
        <SearchBox value={query} name="query" onChange={handleQueryChange} />
        <StoreSelector
          defaultStoreIds={storeIds}
          stores={storeStore.stores}
          onChange={handleStoresChange}
          detail="Search for store name or business unit ID"
        />
        <div className="filter-group">
          <label>Delivery time</label>
          <DateField
            value={deliveryDateFrom ? new Date(deliveryDateFrom) : null}
            label="From"
            onChange={handleDateChange(setDeliveryDateFrom)}
            clearable
            placeholder={dateFormat}
          />
          <DateField
            value={deliveryDateTo ? new Date(deliveryDateTo) : null}
            label="To"
            onChange={handleDateChange(setDeliveryDateTo)}
            clearable
            placeholder={dateFormat}
          />
        </div>
        <div className="settings">
          <h3>Settings</h3>
          <FormInput
            type="number"
            label="Limit"
            handleChange={handlePushLimitValueChange}
            value={`${pushLimit}`}
            detail="Max number of push notifications per week"
          />
          <button className="btn" onClick={savePushLimit}>
            Save
          </button>
        </div>
      </div>
    );
  };

  return (
    <SidebarWrapper renderSidebar={renderSidebar}>
      <div className="results-view">
        <div className="results-container">
          <div className="result">
            <span className="result__value">{uniqueStoreCount}</span>
            <span className="result_detail">kauppaa lähettänyt Push-viestin</span>
          </div>
          <div className="result">
            <span className="result__value">{count}</span>
            <span className="result_detail">lähetettyä push-viestiä</span>
          </div>
          <div className="result">
            <span className="result__value">{periodCount || 0}</span>
            <span className="result_detail">
              {deliveryDateFrom || deliveryDateTo ? 'lähetystä valitulla aikavälillä' : 'kuluvan kuukauden aikana'}
            </span>
          </div>
        </div>
        {renderPushNotificationTable()}
      </div>
      <Alerts />
    </SidebarWrapper>
  );
};

export default page(
  inject('pushNotificationsStore', 'storeStore', 'settingsStore', 'alertStore')(observer(PushNotifications)),
);
