import { createElement as ReactCreateElement } from 'react';
import _ from 'lodash';
import { observable, action, computed, runInAction } from 'mobx';
import { addMonths, addWeeks, endOfDay, startOfDay } from 'date-fns';
import type { CollectionStampCard, CollectionStampCardPostPayload, CollectionStampCardTemplate } from 'types/next';
import type { Client } from 'types/next-api';
import type { User } from 'types/user';
import AlertStore from 'stores/next/alerts';
import { initializeCollectionStampCard } from 'utils/stampCard';
import { getLink } from 'components/retailer/next/routes';
import {
  CollectionStampCardEditorRewardType,
  CollectionStampCardImageTextType,
  CollectionStampCardRewardDiscountType,
  CollectionStampCardRewardType,
  CollectionStampCardType,
} from 'enums/stampCard';
import { STAMP_COLLECTION_INSTRUCTIONS } from 'constants/stampCard';
import type { NavigateFunction } from 'react-router-dom';
import { getDefaultStampCardTemplate } from 'stores/next/stampCard';
import * as routes from '../../components/next/routes';

export default class StampCardStore {
  client: Client;
  navigate?: NavigateFunction;
  stores: {
    alertStore: AlertStore;
  };

  @observable isLoadingStampCard = false;
  @observable isLoadingStampCardTemplate = false;
  @observable isLoadingStampCardImage = false;
  @observable current: CollectionStampCard = null;
  @observable original: CollectionStampCard = null;
  @observable currentTemplate: CollectionStampCardTemplate = null;
  @observable validationError: string = null;
  @observable invalidField: string = null;
  // new reward type used only in UI, might be added to actual CollectionStampCardType in the future
  @observable editorRewardType: CollectionStampCardEditorRewardType = CollectionStampCardEditorRewardType.ProductFree;
  @observable isEditorTabValid = {
    type: false,
    reward: false,
    validity: false,
    appearance: false,
    summary: false,
  };
  @observable isEditorTabVisited = {
    type: true,
    reward: false,
    validity: false,
    appearance: false,
    summary: false,
  };

  @computed
  public get isEditorValid() {
    return (
      this.isEditorTabValid.type &&
      this.isEditorTabValid.reward &&
      this.isEditorTabValid.validity &&
      this.isEditorTabValid.appearance
    );
  }

  @action reset() {
    this.isLoadingStampCard = false;
    this.isLoadingStampCardTemplate = false;
    this.isLoadingStampCardImage = false;
    this.current = null;
    this.original = null;
    this.currentTemplate = null;
    this.validationError = null;
    this.invalidField = null;
    this.editorRewardType = CollectionStampCardEditorRewardType.ProductFree;
    this.isEditorTabValid = {
      type: false,
      reward: false,
      validity: false,
      appearance: false,
      summary: false,
    };
    this.isEditorTabVisited = {
      type: true,
      reward: false,
      validity: false,
      appearance: false,
      summary: false,
    };
  }

  @action async getCollectionStampCard(cardId: string) {
    try {
      this.isLoadingStampCard = true;
      this.current = null;
      const res = await this.client.getCollectionStampCard({ cardId });
      runInAction(() => {
        this.original = initializeCollectionStampCard(res.data);
        this.current = initializeCollectionStampCard(res.data);

        // Separate description from hardcoded instructions combined on saving
        const description = _.get(this.original, ['uiData', 'description', 'fi'], '')
          .replace(STAMP_COLLECTION_INSTRUCTIONS[this.original.type], '')
          .trim();
        this.original.uiData.description.fi = description;
        this.current.uiData.description.fi = description;

        // Determine EditorRewardType
        const type = _.get(this.original, ['reward', 'type'], CollectionStampCardEditorRewardType.ProductEur);
        const discount = _.get(this.original, ['reward', 'discount'], 0);
        if (type === CollectionStampCardEditorRewardType.ProductEur && discount === 0) {
          this.editorRewardType = CollectionStampCardEditorRewardType.ProductFree;
        } else {
          this.editorRewardType = type as CollectionStampCardEditorRewardType;
        }
      });
    } catch (err) {
      console.error(err);
    } finally {
      runInAction(() => {
        this.isLoadingStampCard = false;
      });
    }
  }

  @action async createCollectionStampCard(payload: CollectionStampCardPostPayload, keskoStampCard?: boolean) {
    try {
      this.isLoadingStampCard = true;
      // Combine description and instructions
      const combinedDescription = `${payload.uiData.description.fi}\n\n${STAMP_COLLECTION_INSTRUCTIONS[payload.type]}`;

      const modifiedPayload = {
        ...payload,
        keskoStampCard,
        uiData: {
          ...payload.uiData,
          description: {
            ...payload.uiData.description,
            fi: combinedDescription,
          },
        },
      };

      await this.client.createCollectionStampCard(null, modifiedPayload);
      runInAction(() => {
        this.current = null;
        if (keskoStampCard) {
          this.navigate(routes.keskoStampCards.readyLink('k-ruoka'));
        } else {
          this.navigate(getLink('stampCards'));
        }
        this.stores.alertStore.success({
          message: 'Kiitos, keräilypassi on luotu. Muista markkinoida passia esim. push-viestillä.',
        });
      });
    } catch (err) {
      console.error(err);

      runInAction(() => {
        const showOnUI = _.get(err, ['response', 'data', 'data', 'ui'], false);
        const key = new Date().getTime();
        const message = showOnUI
          ? _.get(err, ['response', 'data', 'message'])
          : ReactCreateElement('span', { key: `wrapper-${key}` }, [
              'Virhe keräilypassin luomisessa. Ota yhteyttä: ',
              ReactCreateElement(
                'a',
                { href: 'mailto:tuki.kmarkkinointi@kesko.fi', key: `link-${key}` },
                'tuki.kmarkkinointi@kesko.fi',
              ),
            ]);

        this.stores.alertStore.error({
          message,
          ttl: 10000,
        });
        // document.querySelector('.alerts').scrollIntoView();
      });
    } finally {
      runInAction(() => {
        this.isLoadingStampCard = false;
      });
    }
  }

  @action async replaceCollectionStampCard(
    cardId: string,
    payload: CollectionStampCardPostPayload,
    keskoStampCard?: boolean,
  ) {
    try {
      this.isLoadingStampCard = true;
      await this.client.replaceCollectionStampCard(
        { cardId },
        { ...payload, chains: payload.chains || undefined, keskoStampCard },
      );
      runInAction(() => {
        this.current = null;
        if (keskoStampCard) {
          this.navigate(routes.viewKeskoStampCard.readyLink('k-ruoka', cardId));
        } else {
          this.navigate(getLink('viewStampCard', cardId));
        }
        this.stores.alertStore.success({
          message: 'Keräilypassi päivitetty!',
        });
      });
    } catch (err) {
      console.error(err);

      runInAction(() => {
        const showOnUI = _.get(err, ['response', 'data', 'data', 'ui'], false);
        const key = new Date().getTime();
        const message = showOnUI
          ? _.get(err, ['response', 'data', 'message'])
          : ReactCreateElement('span', { key: `wrapper-${key}` }, [
              'Virhe keräilypassin päivittämisessä. Ota yhteyttä: ',
              ReactCreateElement(
                'a',
                { href: 'mailto:tuki.kmarkkinointi@kesko.fi', key: `link-${key}` },
                'tuki.kmarkkinointi@kesko.fi',
              ),
            ]);

        this.stores.alertStore.error({
          message,
          ttl: 10000,
        });
        document.querySelector('.alerts').scrollIntoView();
      });
    } finally {
      runInAction(() => {
        this.isLoadingStampCard = false;
      });
    }
  }

  @action async deleteCollectionStampCard(cardId: string) {
    try {
      this.isLoadingStampCard = true;
      await this.client.deleteCollectionStampCard({ cardId });
      runInAction(() => {
        this.current = null;
        this.navigate(getLink('stampCards'));
        this.stores.alertStore.success({
          message: 'Keräilypassi poistettu!',
        });
      });
    } catch (err) {
      console.error(err);

      runInAction(() => {
        const showOnUI = _.get(err, ['response', 'data', 'data', 'ui'], false);
        const key = new Date().getTime();
        const message = showOnUI
          ? _.get(err, ['response', 'data', 'message'])
          : ReactCreateElement('span', { key: `wrapper-${key}` }, [
              'Virhe keräilypassin poistamisessa. Ota yhteyttä: ',
              ReactCreateElement(
                'a',
                { href: 'mailto:tuki.kmarkkinointi@kesko.fi', key: `link-${key}` },
                'tuki.kmarkkinointi@kesko.fi',
              ),
            ]);

        this.stores.alertStore.error({
          message,
          ttl: 10000,
        });
        document.querySelector('.alerts').scrollIntoView();
      });
    } finally {
      runInAction(() => {
        this.isLoadingStampCard = false;
      });
    }
  }

  @action async getCollectionStampCardTemplate(templateId?: string) {
    try {
      this.isLoadingStampCardTemplate = true;
      this.currentTemplate = null;
      if (!templateId) {
        runInAction(() => {
          this.currentTemplate = getDefaultStampCardTemplate();
        });
      } else {
        const res = await this.client.getCollectionStampCardTemplate({ templateId });
        runInAction(() => {
          this.currentTemplate = res.data;
        });
      }
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      runInAction(() => {
        this.isLoadingStampCardTemplate = false;
      });
    }
  }

  @action validateEditorTypeTab() {
    this.isEditorTabValid.type = true;
    this.validationError = null;
    this.invalidField = null;

    const stampCardType = _.get(this.current, ['type']);
    if (!stampCardType) {
      this.invalidField = 'type';
      this.validationError = 'Pakollinen kenttä puuttuu';
      this.isEditorTabValid.type = false;
      return false;
    }

    const requiredFields = [['stampConfiguration']];

    if (stampCardType === CollectionStampCardType.Basket) {
      requiredFields.push(['oneTimePurchase']);
    }

    _.each(requiredFields, (requiredPath) => {
      const required = _.get(this.current, requiredPath, '');
      if (_.isNil(required) || required.length === 0) {
        this.invalidField = requiredPath.join('.');
        this.validationError = 'Pakollinen kenttä puuttuu';
        this.isEditorTabValid.type = false;
        return false;
      }
    });
    if (!this.isEditorTabValid.type) {
      return false;
    }

    if (stampCardType === CollectionStampCardType.Product) {
      if (_.get(this.current, ['stampConfiguration']) < 1) {
        this.validationError = 'Ostettavien tuotteiden lukumäärä on oltava vähintään 1';
        this.invalidField = 'stampConfiguration';
        this.isEditorTabValid.type = false;
        return false;
      }
      const products = _.get(this.current, ['products']);
      if (!products?.length) {
        this.validationError = 'Kerättävä tuote puuttuu';
        this.invalidField = 'products';
        this.isEditorTabValid.type = false;
        return false;
      }
      if (_.some(products, (product) => product.ean.length !== 13)) {
        this.validationError = 'EAN-koodien on oltava 13 merkkiä pitkiä';
        this.invalidField = 'products';
        this.isEditorTabValid.type = false;
        return false;
      }
      if (!this.isEditorTabValid.type) {
        return false;
      }
    }
    if (stampCardType === CollectionStampCardType.Basket) {
      if (_.get(this.current, ['stampConfiguration']) < 1) {
        this.validationError = 'Kertaostoksien lukumäärä on oltava vähintään 1';
        this.invalidField = 'stampConfiguration';
        this.isEditorTabValid.type = false;
        return false;
      }
      if (Number(_.get(this.current, ['oneTimePurchase'])) < 1) {
        this.validationError = 'Kertaostoksen suuruus on oltava vähintään 1€';
        this.invalidField = 'oneTimePurchase';
        this.isEditorTabValid.type = false;
        return false;
      }
    }
    return true;
  }

  @action validateEditorRewardTab() {
    this.isEditorTabValid.reward = true;
    this.validationError = null;
    this.invalidField = null;

    const requiredFields = [
      ['reward', 'type'],
      ['reward', 'discountType'],
      ['reward', 'discountMethod'],
      ['reward', 'discount'],
      ['reward', 'itemLimit'],
      ['reward', 'minimumPurchase'],
      ['reward', 'title', 'fi'],
      ['reward', 'image'],
    ];

    _.each(requiredFields, (requiredPath) => {
      const required = _.get(this.current, requiredPath, '');
      if (_.isNil(required) || required.length === 0) {
        this.invalidField = requiredPath.join('.');
        this.validationError = 'Pakollinen kenttä puuttuu';
        this.isEditorTabValid.reward = false;
        return false;
      }
    });
    if (!this.isEditorTabValid.reward) {
      return false;
    }

    const discountType = _.get(this.current, ['reward', 'discountType']);
    const discount = _.get(this.current, ['reward', 'discount']);
    const minimumPurchase = _.get(this.current, ['reward', 'minimumPurchase']);
    if (discountType === CollectionStampCardRewardDiscountType.Product) {
      if (this.editorRewardType === CollectionStampCardEditorRewardType.ProductEur && discount < 0.1) {
        this.validationError = 'Alennettu hinta ei voi olla alle 0.10 €';
        this.invalidField = 'reward.discount';
        this.isEditorTabValid.reward = false;
        return false;
      }
      const rewardProducts = _.get(this.current, ['rewardProducts']);
      if (!rewardProducts?.length) {
        this.validationError = 'Palkintotuotteet puuttuu';
        this.invalidField = 'rewardProducts';
        this.isEditorTabValid.reward = false;
        return false;
      }
      if (_.some(rewardProducts, (product) => product.ean.length !== 13)) {
        this.validationError = 'EAN-koodien on oltava 13 merkkiä pitkiä';
        this.invalidField = 'rewardProducts';
        this.isEditorTabValid.reward = false;
      }
      if (!this.isEditorTabValid.reward) {
        return false;
      }
    }

    if (discountType === CollectionStampCardRewardDiscountType.BasketEuro) {
      if (
        _.some(
          this.current.stores,
          (store) => _.startsWith(store.storeId.toLowerCase(), 'n') || _.startsWith(store.storeId.toLowerCase(), 'c'),
        )
      ) {
        this.validationError = 'Kuitin loppusumma-alennukset eivät ole käytössä K-Citymarketeilla';
        this.invalidField = 'reward.type';
        this.isEditorTabValid.reward = false;
        return false;
      }

      if (discount < 0 || discount > 999) {
        this.validationError = 'Kuitin loppusumma-alennuksen täytyy olla väliltä 0 - 999 €';
        this.invalidField = 'reward.discount';
        this.isEditorTabValid.reward = false;
        return false;
      }

      if (minimumPurchase < discount) {
        this.validationError = 'Kertaostoksen suuruus ei voi olla alle euromääräisen alennuksen';
        this.invalidField = 'reward.minimumPurchase';
        this.isEditorTabValid.reward = false;
        return false;
      }
    }

    if (discountType === CollectionStampCardRewardDiscountType.BasketPercent) {
      if (discount < 0 || discount > 100) {
        this.validationError = 'Alennusprosentin täytyy olla väliltä 0 - 100';
        this.invalidField = 'reward.discount';
        this.isEditorTabValid.reward = false;
        return false;
      }
      if (
        _.some(
          this.current.stores,
          (store) => _.startsWith(store.storeId.toLowerCase(), 'n') || _.startsWith(store.storeId.toLowerCase(), 'c'),
        )
      ) {
        this.validationError = 'Kuitin loppusumma-alennukset eivät ole käytössä K-Citymarketeilla';
        this.invalidField = 'reward.type';
        this.isEditorTabValid.reward = false;
        return false;
      }

      if (!_.includes([12, 13, 14, 15, 16], discount)) {
        this.validationError = 'Tuntematon alennusprosentti';
        this.invalidField = 'reward.discount';
        this.isEditorTabValid.reward = false;
        return false;
      }

      if (minimumPurchase < 3) {
        this.validationError = 'Kertaostoksen suuruus on oltava vähintään 3 €';
        this.invalidField = 'reward.minimumPurchase';
        this.isEditorTabValid.reward = false;
        return false;
      }
    }
    return true;
  }

  @action validateEditorValidityTab() {
    this.isEditorTabValid.validity = true;
    this.validationError = null;
    this.invalidField = null;

    const requiredFields = [['visibleFrom'], ['visibleTo'], ['activeFrom'], ['activeTo']];

    _.each(requiredFields, (requiredPath) => {
      const required = _.get(this.current, requiredPath, '');
      if (_.isNil(required) || required.length === 0) {
        this.invalidField = requiredPath.join('.');
        this.validationError = 'Pakollinen kenttä puuttuu';
        this.isEditorTabValid.validity = false;
        return false;
      }
    });

    if (!this.isEditorTabValid.validity) {
      return false;
    }
    const activeFrom = _.get(this.current, ['activeFrom']);
    const activeTo = _.get(this.current, ['activeTo']);

    if (activeFrom > activeTo) {
      this.invalidField = 'activeFrom';
      this.validationError = 'Kampanjan alkamispäivä ei voi olla kampanjan päättymispäivän jälkeen';
      this.isEditorTabValid.validity = false;
      return false;
    }

    // If no stores AND no chains => invalid
    if (!this.current.stores.length && !this.current.chains?.length) {
      this.invalidField = 'stores';
      this.validationError = 'Valitse vähintään yksi kauppa';
      this.isEditorTabValid.validity = false;
      return false;
    }
    return true;
  }

  @action validateEditorAppearanceTab() {
    this.isEditorTabValid.appearance = true;
    this.validationError = null;
    this.invalidField = null;

    const requiredFields = [
      ['uiData', 'imageUrl', 'fi'],
      ['uiData', 'uiName', 'fi'],
      ['uiData', 'description', 'fi'],
    ];

    _.each(requiredFields, (requiredPath) => {
      const required = _.get(this.current, requiredPath, '');
      if (_.isNil(required) || required.length === 0) {
        this.invalidField = requiredPath.join('.');
        this.validationError = 'Pakollinen kenttä puuttuu';
        this.isEditorTabValid.appearance = false;
        return false;
      }
    });
    return true;
  }

  @action validateCurrent() {
    const typeValid = this.validateEditorTypeTab();
    if (typeValid) {
      const rewardValid = this.validateEditorRewardTab();
      if (rewardValid) {
        const validityValid = this.validateEditorValidityTab();
        if (validityValid) {
          const appearanceValid = this.validateEditorAppearanceTab();
          if (appearanceValid) {
            // validate rest
          }
        }
      }
    }
  }

  @action async initializeCardWithTemplate({
    cardTemplateId,
    me,
    chains,
    keskoStampCard,
  }: {
    cardTemplateId?: string;
    me: User;
    chains?: string[];
    keskoStampCard?: boolean;
  }) {
    // cardTemplateId will be empty for Chain Stamp Cards, they do not have templates
    const defaultStartDate = startOfDay(new Date());
    const defaultEndDate = endOfDay(addMonths(new Date(), 12));

    const defaultStore = {
      storeId: _.get(me, ['store']),
      chainId: _.get(me, ['chainId']),
      name: _.get(me, ['storeData', 'name']),
    };

    try {
      await this.getCollectionStampCardTemplate(cardTemplateId);
      runInAction(() => {
        this.current = initializeCollectionStampCard({
          template: cardTemplateId,
          visibleFrom: defaultStartDate.toISOString(),
          visibleTo: defaultEndDate.toISOString(),
          activeFrom: defaultStartDate.toISOString(),
          activeTo: defaultEndDate.toISOString(),
          redeemableTo: endOfDay(addWeeks(defaultEndDate, 2)).toISOString(),
          uiData: _.get(this.currentTemplate, ['uiData']),
          type: _.get(this.currentTemplate, ['type']),
          strategy: _.get(this.currentTemplate, ['strategy']),
          oneTimePurchase: _.get(this.currentTemplate, ['oneTimePurchase']),
          stores: defaultStore.storeId ? [defaultStore] : [],
          chains,
          stampConfiguration: _.get(this.currentTemplate, ['stampConfiguration']),
          cardLimit: _.get(this.currentTemplate, ['cardLimit']),
          products: _.get(this.currentTemplate, ['products']),
          rewardProducts: _.get(this.currentTemplate, ['rewardProducts']),
          reward: _.get(this.currentTemplate, ['reward']),
          imageData: _.omit(_.get(this.currentTemplate, ['imageData']), ['backgroundUrls']),
          keskoStampCard,
        });
        // Determine EditorRewardType
        const type = _.get(this.current, ['reward', 'type'], CollectionStampCardRewardType.ProductEur);
        const discount = _.get(this.current, ['reward', 'discount'], 0);
        if (type === CollectionStampCardRewardType.ProductEur && discount === 0) {
          this.editorRewardType = CollectionStampCardEditorRewardType.ProductFree;
        } else {
          this.editorRewardType = type as CollectionStampCardEditorRewardType;
        }
        this.validateCurrent();
      });
    } catch (err) {
      if (keskoStampCard) {
        this.navigate(routes.keskoStampCards.readyLink('k-ruoka'));
      } else {
        this.navigate(getLink('stampCards'));
      }
    }
  }

  @action updateValue(path: string[], value: any) {
    // Do not update anything while card is either loading or saving.
    if (this.isLoadingStampCard) {
      return false;
    }
    _.set(this.current, path, value);

    this.validateCurrent();
  }

  @action updateRewardType(value: CollectionStampCardEditorRewardType) {
    this.editorRewardType = value;
  }

  @action setEditorTabVisited(tabName: string) {
    this.isEditorTabVisited[tabName] = true;
  }

  @action async createCollectionStampCardImage(
    html: string,
    {
      text,
      textType,
      backgroundUrl,
      textBackgroundColor,
      textForegroundColor,
    }: {
      text: string;
      textType: CollectionStampCardImageTextType;
      backgroundUrl: string;
      textBackgroundColor: string;
      textForegroundColor: string;
    },
  ) {
    try {
      this.isLoadingStampCardImage = true;
      let url = backgroundUrl;

      // We only need to generate card image if there is text to display. Otherwise we can use the background.
      if (textType !== CollectionStampCardImageTextType.None) {
        const { data } = await this.client.createCollectionStampCardImage(null, { html });
        url = data.url;
      }

      runInAction(() => {
        this.current.uiData.imageUrl.fi = url;
        this.current.imageData = {
          text: { fi: text },
          textType,
          backgroundUrl,
          textBackgroundColor,
          textForegroundColor,
        };
      });
    } catch (err) {
      console.error(err);
    } finally {
      runInAction(() => {
        this.isLoadingStampCardImage = false;
      });
    }
  }
}
