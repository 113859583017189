import { ProductPricing } from 'enums/product';
import { CollectionStampCardImageTextType } from 'enums/stampCard';
import { CollectionStampCard } from 'types/next';

// Initialize retailer side collection stamp card data
export const initializeCollectionStampCard = (item: CollectionStampCard) => {
  // Initialize product based reward data
  if (item.reward.type === 'productEur') {
    if (item.rewardProducts?.length) {
      const hasPerPiecePricedProducts = item.rewardProducts?.some(
        (product) => product.pricingUnit === ProductPricing.PerPiece,
      );
      const hasPerWeightPricedProducts = item.rewardProducts?.some(
        (product) => product.pricingUnit === ProductPricing.Weight,
      );
      // Has only weight priced products
      if (hasPerWeightPricedProducts && !hasPerPiecePricedProducts) {
        item.reward.pricingUnit = ProductPricing.Weight;
        if (!item.reward.itemLimit) {
          item.reward.itemLimit = 100; // reset to 100g
        }
      }
      // Has only per piece priced products
      if (hasPerPiecePricedProducts && !hasPerWeightPricedProducts) {
        item.reward.pricingUnit = ProductPricing.PerPiece;
        item.reward.itemLimit = 1; // item limit is always 1
      }
    }
  }

  return item;
};

export const getCollectionStampCardImageHTML = ({
  preview,
  textType,
  backgroundUrl,
  text,
  textBackgroundColor,
  textForegroundColor,
  replaced,
  file,
}: {
  preview: boolean;
  textType: CollectionStampCardImageTextType;
  backgroundUrl: string;
  text: string;
  textBackgroundColor: string;
  textForegroundColor: string;
  replaced?: boolean;
  file?: string | ArrayBuffer;
}) => {
  let cardWidth = 1000;
  let cardHeight = 540;
  let textWrapperPositionTop = textType === CollectionStampCardImageTextType.Circle ? 75 : 150;
  let textWrapperPositionRight = textType === CollectionStampCardImageTextType.Circle ? 75 : 0;
  let textWrapperBorderRadius = textType === CollectionStampCardImageTextType.Circle ? 999 : 0;
  let textWrapperWidth = textType === CollectionStampCardImageTextType.Circle ? 390 : cardWidth;
  let textWrapperHeight = textType === CollectionStampCardImageTextType.Circle ? 390 : 240;
  let textMaxWidth = textType === CollectionStampCardImageTextType.Circle ? 325 : cardWidth;
  let textSize = 52;

  if (preview) {
    cardWidth = cardWidth / 3;
    cardHeight = cardHeight / 3;
    textWrapperPositionTop = textWrapperPositionTop / 3;
    textWrapperPositionRight = textWrapperPositionRight / 3;
    textWrapperWidth = textWrapperWidth / 3;
    textWrapperHeight = textWrapperHeight / 3;
    textMaxWidth = textType === CollectionStampCardImageTextType.Circle ? textMaxWidth / 3 : cardWidth;
    textSize = textSize / 3;
  }

  const textDisplay = textType === CollectionStampCardImageTextType.None ? 'none' : 'flex';

  const html = `
  <!doctype html>
  <html lang="en">
  <head>
    <meta charset="utf-8">
    <style>
        body { margin: 0; }
        .stampcard-image-editor-preview--card {
          width: ${cardWidth}px;
          height: ${cardHeight}px;
          background-image: url('${file ? file : replaced ? backgroundUrl + '?' + Date.now() : backgroundUrl}');
          position: relative;
          background-size: contain;
        }
        .stampcard-image-editor-preview--text {
          top: ${textWrapperPositionTop}px;
          right:${textWrapperPositionRight}px;
          width: ${textWrapperWidth}px;
          height: ${textWrapperHeight}px;
          display: ${textDisplay};
          position: absolute;
          justify-content: center;
          align-items: center;
        }

        .stampcard-image-editor-preview--text .background {
          background-color: ${textBackgroundColor};
          border-radius: ${textWrapperBorderRadius}px;
          opacity: 0.75;
          width: 100%;
          height: 100%;
          position: absolute;
        }

        .stampcard-image-editor-preview--text .text {
          font-size: ${textSize}px;
          max-width: ${textMaxWidth}px;
          display: flex;
          justify-content: center;
          align-content: center;
          text-align: center;
          color: ${textForegroundColor};
          z-index: 1;
        }
    </style>
  </head>
  <body>
    <div class="stampcard-image-editor-preview--card">
        <div class="stampcard-image-editor-preview--text">
            <div class="background"></div>
            <div class="text">${text}</div>
        </div>
    </div>
  </body>
  </html>
  `;

  return html;
};
