import React from 'react';
import { CustomField } from 'components/next/components/form/input';
import './weekdaySelect.scss';

interface Props {
  label?: string;
  detail?: string;
  selected: number[];
  toggleDay(weekday: number): void;
}

const WeekdaySelect = (props: Props) => {
  return (
    <div className="weekday-select">
      <CustomField label={props.label} detail={props.detail}>
        <div className="picker">
          {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((name, day) => (
            <span
              className={`day-pill ${props.selected.includes(day) ? 'selected' : ''}`}
              key={day}
              onClick={() => props.toggleDay(day)}
            >
              {name}
            </span>
          ))}
        </div>
      </CustomField>
    </div>
  );
};

export default WeekdaySelect;
