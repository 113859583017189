import React from 'react';
import ReactDOM from 'react-dom';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Provider } from 'mobx-react';
import App from './components/app';
import RootStore from 'stores/rootStore';

import { unregister } from 'utils/registerServiceWorker';

const rootStore = new RootStore();

// For easier debugging
declare global {
  interface Window {
    state;
    ga;
    gapi;
    print(): void;
  }
}
window.state = rootStore;

unregister();

if (process.env.REACT_APP_DEV_TOKEN) {
  console.log('WARN! Inserting token to cookies!');
  // document.cookie = `token=${process.env.REACT_APP_DEV_TOKEN};path=/`;
  document.cookie = `token=${process.env.REACT_APP_DEV_TOKEN};SameSite=Strict;path=/`;
}

const router = createBrowserRouter([{ path: '*', Component: App }]);

ReactDOM.render(
  <Provider {...rootStore}>
    <RouterProvider router={router} />
  </Provider>,
  document.getElementById('root'),
);
