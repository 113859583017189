import React from 'react';
import _ from 'utils/lodash';
import { getLink } from '../../routes';
import { ReactComponent as IconCalendar } from '@kesko/icons/time/icon-calendar.svg';
import { ReactComponent as IconChevronRight } from '@kesko/icons/nav/icon-chevron-right.svg';
import { Link } from 'react-router-dom';
import { Concept } from 'stores/next-retailer/conceptStore';

interface Props {
  concept: Concept;
  store: string;
}

export default class ProgramBox extends React.Component<Props> {
  render() {
    const { concept } = this.props;

    // take first 32 words
    const words = _.get(concept, ['shortDescription', 'fi'], '').split(' ');
    let truncatedDescription = _.take(words, 30).join(' ');

    if (words.length > 30) {
      truncatedDescription += '...';
    }

    const editorUrl = getLink('concept', concept.id);

    return (
      <Link to={editorUrl}>
        <div className="box concept-box" key={concept.id}>
          <div className="icon">
            <IconCalendar />
          </div>
          <div className="basic-info">
            <h3>{concept.title.fi}</h3>
            <p>{truncatedDescription}</p>
            {concept.allowAutomaticParticipation && (
              <div className="extra-info">
                {_.get(concept, 'conceptStoreAutomaticParticipation', [])
                  .map((csap) => csap.store)
                  .includes(this.props.store) ? (
                  <p className="text-small auto-participation enabled">Automaattinen osallistuminen päällä</p>
                ) : (
                  <p className="text-small auto-participation available">Automaattinen osallistuminen saatavilla</p>
                )}
              </div>
            )}
          </div>
          <div className="caret-end">
            <IconChevronRight />
          </div>
          <pre className="debug">{JSON.stringify(_.pick(concept, ['id', 'type', 'title']), null, 2)}</pre>
        </div>
      </Link>
    );
  }
}
