import { observable, action } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

export enum AlertLevel {
  Info = 'info',
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
}

export interface Alert {
  id?: string;
  level?: AlertLevel;
  ttl?: number;
  link?: string;
  message?: string | JSX.Element | JSX.Element[];
}

export default class AlertStore {
  @observable alerts: Alert[] = [];

  @action addAlert(alert: Alert) {
    if (alert.id === undefined) {
      alert.id = uuidv4();
    }
    if (alert.ttl === undefined) {
      alert.ttl = 10000; // 10 seconds by default
    }
    if (alert.ttl > 0) {
      // dismiss alert automatically after TTL
      window.setTimeout(() => this.dismissAlert(alert.id), alert.ttl);
    }
    this.alerts = [...this.alerts, alert];
  }

  @action info(alert: Alert) {
    alert.level = AlertLevel.Info;
    this.addAlert(alert);
  }

  @action warning(alert: Alert) {
    alert.level = AlertLevel.Warning;
    this.addAlert(alert);
  }

  @action error(alert: Alert) {
    alert.level = AlertLevel.Error;
    this.addAlert(alert);
  }

  @action success(alert: Alert) {
    alert.level = AlertLevel.Success;
    this.addAlert(alert);
  }

  @action dismissAlert(id: string) {
    this.alerts = this.alerts.filter((a) => a.id !== id);
  }

  @action clear() {
    this.alerts = [];
  }
}
