import { ProductPricing } from 'enums/product';
import type { Product } from 'types/campaign';

export const WEBSTORE_PRODUCT_FREE_DELIVERY_1 = {
  title: {
    fi: 'Kuljetus verkkokaupasta',
    sv: 'Hemtransport från nätbutiken',
  },
  image: 'https://public.keskofiles.com/f/k-markkinointi/icon-delivery',
  ean: '2000985900003',
  price: 12.9,
  pricingUnit: ProductPricing.PerPiece,
  isOnSale: false,
  deposit: null,
};
export const WEBSTORE_PRODUCT_FREE_DELIVERY_2 = {
  title: {
    fi: 'Kuljetus verkkokaupasta',
    sv: 'Hemtransport från nätbutiken',
  },
  image: 'https://public.keskofiles.com/f/k-markkinointi/icon-delivery',
  ean: '2000981600006',
  price: 12.9,
  pricingUnit: ProductPricing.PerPiece,
  isOnSale: false,
  deposit: null,
};

export const WEBSTORE_PRODUCT_FREE_PICKUP = {
  title: {
    fi: 'Keräily verkkokaupasta',
    sv: 'Plockning i nätbutiken',
  },
  image: 'https://public.keskofiles.com/f/k-markkinointi/icon-pickup-trunk',
  ean: '2000987100005',
  price: 6.9,
  pricingUnit: ProductPricing.PerPiece,
  isOnSale: false,
  deposit: null,
};

// Specific webstore products.
export const WEBSTORE_PRODUCTS: Product[] = [
  // Free delivery has 2 different EANs.
  {
    ...WEBSTORE_PRODUCT_FREE_DELIVERY_1,
    ean: `${WEBSTORE_PRODUCT_FREE_DELIVERY_1.ean},${WEBSTORE_PRODUCT_FREE_DELIVERY_2.ean}`,
  },
  // Since we can't validate webstore details from product API anymore, use both delivery EANs for delivery offer.
  // {
  //   title: {
  //     fi: 'Kuljetus verkkokaupasta',
  //     sv: 'Hemtransport från nätbutiken',
  //   },
  //   image: 'https://public.keskofiles.com/f/k-markkinointi/icon-delivery',
  //   ean: '2000981600006',
  // },
  WEBSTORE_PRODUCT_FREE_PICKUP,
];

// Defines difference (in days) between target group lock date and delivery send date
export const GT_LEAD_TIME_DAYS = 17;
export const BTT_LEAD_TIME_DAYS = 0;

// Delivery template defaults
export const DT_DEFAULT_DEADLINE_AFTER = 7;
export const DT_DEFAULT_DELIVERY_AFTER = 10;
export const DT_DEFAULT_LAST_EDIT_AFTER = 9;
