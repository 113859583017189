import _ from 'utils/lodash';
import { observable, action, computed } from 'mobx';
import type Api from 'types/next-api';
import DeliveryStore, { getDefaultDeliveryFromTemplate, DeliveryWithMeta } from './deliveryStore';
import type { User } from 'types/user';
import ConceptStore from './conceptStore';
import { ConceptType } from 'enums/common';

export interface DeliveryTemplateMeta {
  defaultDelivery: DeliveryWithMeta;
}

export type DeliveryTemplate = Api.Components.Schemas.DeliveryTemplate;
export type DeliveryTemplateSearchPayload = Api.Paths.SearchDeliveryTemplates.RequestBody;
export type DeliveryTemplateRelations = Api.Components.Schemas.DeliveryTemplateRelations;
export type DeliveryTemplateWithMeta = DeliveryTemplate & Partial<DeliveryTemplateRelations & DeliveryTemplateMeta>;

// which method to use when updating the entity
export interface TemplateEntityRef {
  current?: boolean;
  templateId?: string;
}

export default class TemplateStore {
  public client: Api.Client;
  public stores: {
    deliveryStore: DeliveryStore;
    conceptStore: ConceptStore;
  };

  @observable current: DeliveryTemplateWithMeta;
  @observable collection: DeliveryTemplateWithMeta[] = [];
  @computed
  get template(): DeliveryTemplateWithMeta {
    return this.current;
  }
  @computed
  get templates(): DeliveryTemplateWithMeta[] {
    return this.collection;
  }

  /**
   * Current entity methods
   */
  @action setCurrent(template: DeliveryTemplateWithMeta) {
    this.current = template;
  }
  @action unsetCurrent() {
    this.current = undefined;
  }

  /**
   * IDs of cached delivery templates
   */
  private templateIds: string[] = [];

  /**
   * Entity collection methods
   */
  public getCollectionItem(id: string) {
    return _.find(this.collection, { id });
  }

  @action
  public replaceCollectionItems(items: DeliveryTemplateWithMeta[]) {
    for (const item of items) {
      const index = _.findIndex(this.collection, { id: item.id });
      if (index !== -1) {
        // update
        if (!_.isEqual(this.collection[index], item)) {
          this.collection[index] = item;
        } else {
          // no need to update
        }
      } else {
        // add to collection
        this.collection = [...this.collection, item];
      }
    }
    return this.collection;
  }

  @action
  public updateCollection = (items: DeliveryTemplateWithMeta[]) => {
    const newItems = items.map((item) => {
      const existing = this.getCollectionItem(item.id);
      if (existing) {
        return { ...existing, ...item };
      } else {
        return item;
      }
    });
    return this.replaceCollectionItems(newItems);
  };

  @action
  public resetCollection() {
    this.collection = [];
    this.templateIds = [];
    return this.collection;
  }

  /**
   * Updating entities in store with reference
   */
  @action
  public createRefForEntity(entity: DeliveryTemplateWithMeta): TemplateEntityRef {
    if (entity === this.current) {
      return { current: true };
    }
    if (entity.id) {
      return { templateId: entity.id };
    }
  }

  public getEntityForRef(ref: TemplateEntityRef) {
    if (ref.current) {
      return this.current;
    }
    if (ref.templateId) {
      // update in collection
      return this.getCollectionItem(ref.templateId);
    }
  }

  @action
  public updateEntityWithRef = (ref: TemplateEntityRef, update: Partial<DeliveryTemplateWithMeta>) => {
    if (!ref) {
      // do nothing
      return null;
    }
    if (ref.current) {
      // update current
      return this.setCurrent({ ...this.current, ...update });
    }
    if (ref.templateId) {
      // update in collection
      const item = this.getCollectionItem(ref.templateId);
      return this.replaceCollectionItems([{ ...item, ...update }]);
    }
  };

  /**
   * API methods
   */
  @action async search(payload: DeliveryTemplateSearchPayload = {}) {
    try {
      const result = await this.client.searchDeliveryTemplates(null, payload);
      // Update collection with cached templates if they exist. Otherwise use search results.
      const templates = _.map(result.data.result, (template) =>
        this.templateIds.includes(template.id) ? _.find(this.collection, { id: template.id }) : template,
      );
      this.updateCollection(templates);
      return result.data.result;
    } catch (err) {
      this.handleError(err);
    }
  }

  @action async getDeliveryTemplate(id: string) {
    if (this.templateIds.includes(id)) {
      return _.find(this.collection, { id });
    }

    try {
      const result = await this.client.getDeliveryTemplate(id);
      this.updateCollection([result.data]);

      // fetch relations
      // eslint-disable-next-line
      (async () => {
        const template = this.getCollectionItem(id);
        this.stores.conceptStore.getConcept(template.concept);
      })();

      this.templateIds.push(id);
      return result.data;
    } catch (err) {
      this.handleError(err);
    }
  }

  @action async resetDeliveryTemplateInCollection(id: string) {
    try {
      const result = await this.client.getDeliveryTemplate(id);
      this.updateCollection([result.data]);

      return result.data;
    } catch (err) {
      this.handleError(err);
    }
  }

  /*
   * Business logic
   */
  @action async getDefaultDeliveryForTemplate(template: DeliveryTemplateWithMeta, me: User): Promise<DeliveryWithMeta> {
    const ref = this.createRefForEntity(template);
    if (!template.contentTemplates) {
      // fetch relations for delivery first
      template = await this.getDeliveryTemplate(template.id);
    }
    const concept = await this.stores.conceptStore.getConcept(template.concept, false);
    const defaultDelivery = await getDefaultDeliveryFromTemplate(
      template as DeliveryTemplate & DeliveryTemplateRelations,
      concept.type as ConceptType,
      me,
    );
    this.updateEntityWithRef(ref, { defaultDelivery });

    // pre-fetch max selection for delivery
    await this.stores.deliveryStore.getMaxSelectionCountsForDelivery(defaultDelivery, {
      defaultDeliveryTemplateId: template.id,
    });
    return defaultDelivery;
  }

  handleError(error: Error) {
    console.error(error);
  }
}
