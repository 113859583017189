import React, { useEffect } from 'react';
import { format, isYesterday, isToday } from 'date-fns';
import * as _ from 'lodash';
import { inject, observer } from 'mobx-react';
import ContentStore from 'stores/next-retailer/contentStore';
import Spinner from 'components/common/next/spinner';
import { getLink } from 'components/retailer/next/routes';
import { marked } from 'marked';
import './newsItemPage.scss';
import { ContentType } from 'enums/common';
import { useNavigate, useParams } from 'react-router-dom';

interface Props {
  contentStore?: ContentStore;
}

const NewsItemPage = ({ contentStore }: Props) => {
  const { newsItemId: newsItemIdParam } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const init = async () => {
      try {
        if (!contentStore.getContentItemByProps({ id: newsItemIdParam, type: ContentType.News })) {
          await contentStore.getContent(newsItemIdParam, ContentType.News);
        }
      } catch (err) {
        navigate(getLink('news'));
      }
    };
    init();
  }, []);

  const formatDate = (createdAt: string) => {
    const date = new Date(createdAt);
    if (isToday(date)) {
      return 'Tänään';
    }

    if (isYesterday(date)) {
      return 'Eilen';
    }

    return format(date, 'd.M.yyyy');
  };

  const render = () => {
    const newsItem = contentStore.getContentItemByProps({ id: newsItemIdParam, type: ContentType.News });

    if (!newsItem) {
      return <Spinner />;
    }

    const imageSrc = _.get(newsItem, ['meta', 'image']);
    const hasImage = !_.isNil(imageSrc);

    return (
      <div className="news-item-page">
        <div className="main-content">
          <div className="content">
            <header>
              <h2>{newsItem.title.fi}</h2>
              <span>{formatDate(newsItem.createdAt)}</span>
            </header>
            {hasImage && (
              <section className="news-item--image">
                <img src={imageSrc} alt="news" />
              </section>
            )}
            {hasImage && <h3 className="subtitle">{newsItem.title.fi}</h3>}
            <section className="news-item--content" dangerouslySetInnerHTML={{ __html: marked(newsItem.content.fi) }} />
          </div>
        </div>
      </div>
    );
  };

  return render();
};

export default inject('contentStore')(observer(NewsItemPage));
