export enum CollectionStampCardImageTextType {
  Circle = 'circle',
  Banner = 'banner',
  None = 'none',
}

export enum CollectionStampCardStrategy {
  SimpleProductCollecting = 'SIMPLE_PRODUCT_COLLECTING',
  OneTimePurchase = 'ONE_TIME_PURCHASE',
}

export enum CollectionStampCardRewardDiscountType {
  Product = '241',
  BasketEuro = '248',
  BasketPercent = '249',
}

export enum CollectionStampCardRewardDiscountMethod {
  ProductPercent = '2',
  ProductEuro = '3',
  BasketEuro = '1',
  BasketPercent = '2',
}

export enum CollectionStampCardRewardType {
  ProductEur = 'productEur',
  ProductPercentage = 'productPercentage',
  BasketEur = 'basketEur',
  BasketPercentage = 'basketPercentage',
}

export enum CollectionStampCardEditorRewardType {
  ProductFree = 'productFree',
  ProductEur = 'productEur',
  ProductPercentage = 'productPercentage',
  BasketEur = 'basketEur',
  BasketPercentage = 'basketPercentage',
}

export enum CollectionStampCardType {
  Product = 'product',
  Basket = 'basket',
}

export enum CollectionStampCardStatus {
  Passed = 'passed',
  ActiveOrUpcoming = 'active_or_upcoming',
}
