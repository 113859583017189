export const retailerBase = '/kauppias/next';
export const adminBase = '/next/admin';

export const routes = {
  logout: '/logout',
  unathorized: '/401',
  info: {
    introduction: '/esittely',
    help: '/ohjeet',
    problem: '/ongelmatilanteessa',
  },
  next: '/next', // redirects to either admin or retailer side based on role
  retailer: `${retailerBase}/*`,
  admin: `${adminBase}/*`,
};
