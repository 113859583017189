import React from 'react';
import './radioButton.scss';

interface RadioButtonProps {
  id: string;
  label: string;
  group: string;
  checked?: boolean;
  handleClick(e: any): void;
}

export const RadioButton = (props: RadioButtonProps) => (
  <div className="radio-button">
    <input
      type="radio"
      id={props.id}
      defaultChecked={props.checked || false}
      name={props.group}
      value={props.label}
      onChange={props.handleClick}
    />
    <label htmlFor={props.id}>{props.label}</label>
  </div>
);
