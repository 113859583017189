import _ from 'utils/lodash';

type getPrintRecipientCountParams = {
  restrictedCount?: number;
  maxCount: number;
  isHalvePrintRecipientCount: boolean;
};

export function isMobile() {
  return window.innerWidth < 1024;
}

export function trailingSlash(path: string) {
  return _.trimEnd(path, '/') + '/';
}

export function getPrintRecipientCount({
  restrictedCount = -1,
  maxCount,
  isHalvePrintRecipientCount,
}: getPrintRecipientCountParams): number {
  if (restrictedCount === -1) {
    return isHalvePrintRecipientCount ? Math.ceil(maxCount / 2) : maxCount;
  }
  return restrictedCount;
}
