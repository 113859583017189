import React from 'react';
import { isNil } from 'lodash';
import { DatePicker } from '@material-ui/pickers';
import { dateFormat } from 'components/next/utils';

export interface InputProps {
  id?: string;
  label?: string;
  type?: string;
  placeholder?: string | number;
  detail?: string;
  rows?: number;
  children?: JSX.Element | JSX.Element[];
  additionalClasses?: string | string[];
  value?: string | number;
  required?: boolean;
  min?: number;
  max?: number;
  step?: number;
  readonly?: boolean;
  handleChange?(event: any): void;
  isInvalid?: boolean;
  validationErrorText?: string;
}

/**
 * Note: styles defined in `form-components.scss`
 */

const catenateClassName = (c: string | string[], isInvalid?: boolean) => {
  const additionalClassNames = c ? (Array.isArray(c) ? c : c.split(' ')) : [];
  const classNames = ['input-field', ...additionalClassNames];
  if (isInvalid) {
    classNames.push('invalid');
  }
  return classNames.join(' ');
};

const getInputProps = (props: InputProps) => {
  const placeholder = props.placeholder ? props.placeholder.toString() : '';
  const missingRequiredValue = props.required && !props.value;
  const isInvalid = props.isInvalid;
  const validationErrorText =
    props.validationErrorText || (missingRequiredValue ? 'Please add missing value' : 'Please check value format');
  return {
    placeholder,
    isInvalid: isInvalid || missingRequiredValue,
    validationErrorText,
  };
};

export const FormInput = (props: InputProps) => {
  const { placeholder, isInvalid, validationErrorText } = getInputProps(props);
  let value;
  let extraProps;

  if (props.type === 'number') {
    value = props.value;
    extraProps = {
      ...(!isNil(props.min) && { min: props.min }),
      ...(!isNil(props.max) && { max: props.max }),
      ...(!isNil(props.step) && { step: props.step }),
    };
  } else {
    value = props.value ? props.value : '';
  }

  return (
    <div className={catenateClassName(props.additionalClasses, isInvalid)}>
      {props.label && <label>{`${props.label}${props.required ? ' *' : ''}`}</label>}
      <input
        type={props.type || 'text'}
        placeholder={placeholder}
        onChange={props.handleChange}
        value={value}
        id={props.id || null}
        readOnly={props.readonly}
        required={props.required || false}
        {...extraProps}
      />
      {isInvalid && <span className="error-text">{validationErrorText}</span>}
      {props.detail && <span className="detail-text">{props.detail}</span>}
      {props.children}
    </div>
  );
};

export const FormTextArea = (props: InputProps) => {
  const { placeholder, isInvalid, validationErrorText } = getInputProps(props);
  return (
    <div className={catenateClassName(props.additionalClasses, isInvalid)}>
      <label>{`${props.label}${props.required ? ' *' : ''}`}</label>
      <textarea
        placeholder={placeholder}
        rows={props.rows || 3}
        onChange={props.handleChange}
        value={props.value}
        id={props.id || null}
        readOnly={props.readonly}
        required={props.required || false}
        disabled={props.readonly}
      />
      {isInvalid && <span className="error-text">{validationErrorText}</span>}
      {props.detail && <span className="detail-text">{props.detail}</span>}
      {props.children}
    </div>
  );
};

export const CustomField = (props: InputProps) => {
  return (
    <div className={catenateClassName(props.additionalClasses)}>
      <label>{`${props.label || ''}${props.required ? ' *' : ''}`}</label>
      {props.children}
      {props.detail && <span className="detail-text">{props.detail}</span>}
    </div>
  );
};

export interface DateFieldProps {
  label: string;
  detail?: string;
  required?: boolean;
  disabled?: boolean;
  value: Date;
  minDate?: Date;
  maxDate?: Date;
  clearable?: boolean;
  additionalClasses?: string | string[];
  onChange?(value: Date): void;
  disableWeekends?: boolean;
  placeholder?: string;
}

const disableWeekends = (date: Date) => {
  return date.getDay() === 0 || date.getDay() === 6;
};

export const DateField = (props: DateFieldProps) => {
  return (
    <div className={catenateClassName(props.additionalClasses)}>
      <label>{`${props.label}${props.required ? ' *' : ''}`}</label>
      <DatePicker
        disableToolbar
        shouldDisableDate={props.disableWeekends ? disableWeekends : null}
        disabled={props.disabled}
        clearable={props.clearable}
        minDate={props.minDate}
        maxDate={props.maxDate}
        value={props.value}
        format={dateFormat}
        className="datepicker"
        onChange={props.onChange}
        placeholder={props.placeholder}
      />
      {props.detail && <span className="detail-text">{props.detail}</span>}
    </div>
  );
};
