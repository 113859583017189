import React from 'react';
import 'components/next/components/checkbox/checkbox.scss';

export interface CheckboxProps {
  id?: string;
  label?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?(e): void;
  className?: string;
}

export const Checkbox = ({ id, label, className, ...props }: CheckboxProps) => {
  const classes = ['checkbox'];
  if (className) {
    classes.push(className);
  }
  return (
    <div className={classes.join(' ')}>
      <input type="checkbox" id={id} {...props} />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};
