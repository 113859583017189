import { adminBase } from 'components/routes';
import { kRautaChainIds, kRuokaChainIds, onninenChainIds } from 'constants/common';
import { Chain } from 'enums/common';
import type { ChainRoute, Route } from './types';

/* Page routes */
export const home: Route = {
  title: 'Home',
  route: ':chain',
  link: `${adminBase}/:chain`,
  exact: true,
};

export const concepts: Route = {
  title: 'Concepts',
  route: ':chain/concepts',
  link: `${adminBase}/:chain/concepts`,
};

export const stores: Route = {
  title: 'Stores',
  route: ':chain/stores',
  link: `${adminBase}/:chain/stores`,
};

export const settings: Route = {
  title: 'Settings',
  route: ':chain/settings',
  link: `${adminBase}/:chain/settings`,
};

export const dimension: Route = {
  title: 'Dimension',
  route: ':chain/settings/dimension/:id',
  link: `${adminBase}/:chain/settings/dimension/:id`,
};

export const pricingItems: Route = {
  title: 'Pricing',
  route: ':chain/pricing',
  link: `${adminBase}/:chain/pricing`,
};

export const pricingItem: Route = {
  title: 'Pricing',
  route: ':chain/pricing/:id',
  link: `${adminBase}/:chain/pricing/:id`,
};

export const deliveries: Route = {
  title: 'Deliveries',
  route: ':chain/deliveries',
  link: `${adminBase}/:chain/deliveries`,
};

export const delivery: Route = {
  title: 'Delivery',
  route: ':chain/deliveries/:id',
  link: `${adminBase}/:chain/deliveries/:id`,
};

export const concept: Route = {
  title: 'Concept',
  route: ':chain/concepts/:id',
  link: `${adminBase}/:chain/concepts/:id`,
};

export const deliveryTemplates: Route = {
  title: 'Delivery Templates',
  route: ':chain/delivery-templates',
  link: `${adminBase}/:chain/delivery-templates`,
};

export const deliveryTemplate: Route = {
  title: 'Delivery template',
  route: ':chain/delivery-templates/:id',
  link: `${adminBase}/:chain/delivery-templates/:id`,
};

export const createDeliveryTemplate: Route = {
  title: 'Delivery Templates',
  route: ':chain/delivery-templates/new',
  link: `${adminBase}/:chain/delivery-templates/new`,
};

export const offers: Route = {
  title: 'Offers',
  route: ':chain/offers',
  link: `${adminBase}/:chain/offers`,
};

export const customers: Route = {
  title: 'Customers',
  route: ':chain/customers',
  link: `${adminBase}/:chain/customers`,
};

export const contentBlocks: Route = {
  title: 'Block Templates',
  route: ':chain/content-blocks',
  link: `${adminBase}/:chain/content-blocks`,
};

export const contentBlock: Route = {
  title: 'Content Block',
  route: ':chain/content-blocks/:id',
  link: `${adminBase}/:chain/content-blocks/:id`,
};

export const content: Route = {
  title: 'Content',
  route: ':chain/content',
  link: `${adminBase}/:chain/content`,
};

export const pushNotifications: Route = {
  title: 'Push Notifications',
  route: ':chain/push-notifications',
  link: `${adminBase}/:chain/push-notifications`,
};

export const collectionStampCards: Route = {
  title: 'Collection Stamp Cards',
  route: ':chain/collection-stamp-cards',
  link: `${adminBase}/:chain/collection-stamp-cards`,
  readyLink: (chain: string) => `${adminBase}/${chain}/collection-stamp-cards`,
};

export const collectionStampCardTemplates: Route = {
  title: 'Collection Stamp Card Templates',
  route: ':chain/collection-stamp-card-templates',
  link: `${adminBase}/:chain/collection-stamp-card-templates`,
};

export const keskoStampCards: Route = {
  title: 'Chain Stamp Cards',
  route: ':chain/chain-stamp-cards',
  link: `${adminBase}/:chain/chain-stamp-cards`,
  readyLink: (chain: string) => `${adminBase}/${chain}/chain-stamp-cards`,
};

export const viewKeskoStampCard: Route = {
  title: 'View Chain Stamp Card',
  route: ':chain/chain-stamp-cards/view/:cardId',
  link: `${adminBase}/:chain/chain-stamp-cards/view/:cardId`,
  readyLink: (chain: string, id: string) => `${adminBase}/${chain}/chain-stamp-cards/view/${id}`,
};

// Chain Stamp Card routes
export const createKeskoStampCard: Route = {
  title: 'Create Chain Stamp Card',
  route: ':chain/chain-cards/create/:tab',
  link: `${adminBase}/:chain/chain-cards/create/:tab`,
  readyLink: (chain: string, tab: string) => `${adminBase}/${chain}/chain-cards/create/${tab}`,
};

export const editKeskoStampCard: Route = {
  title: 'Edit Chain Stamp Card',
  route: ':chain/chain-cards/edit/:id/:tab',
  link: `${adminBase}/:chain/chain-cards/edit/:id/:tab`,
  readyLink: (chain: string, id: string, tab: string) => `${adminBase}/${chain}/chain-cards/edit/${id}/${tab}`,
};

export const createCollectionStampCardTemplate: Route = {
  title: 'Create Stamp Card Template',
  route: ':chain/cards/create',
  link: `${adminBase}/:chain/cards/create`,
};

export const editCollectionStampCardTemplate: Route = {
  title: 'Create Stamp Card Template',
  route: ':chain/cards/edit/:id',
  link: `${adminBase}/:chain/cards/edit/:id`,
};

export const reports: Route = {
  title: 'Reports',
  route: ':chain/reports',
  link: `${adminBase}/:chain/reports`,
};

export const statistics: Route = {
  title: 'Statistics',
  route: ':chain/statistics',
  link: `${adminBase}/:chain/statistics`,
};

// Catchall/error route --> don't include in pages
export const error: Route = {
  title: 'Error',
  route: 'error',
  link: `${adminBase}/error`,
  hideInNavBar: true,
};

/* Chain routes */
export const kRauta: ChainRoute = {
  title: 'K-Rauta',
  route: 'k-rauta',
  link: `${adminBase}/k-rauta`,
  chain: Chain.kRauta,
  chainIds: kRautaChainIds,
  pages: [home, concepts, deliveries, contentBlocks, content, stores, settings, reports],
};

export const kRuoka: ChainRoute = {
  title: 'K-Ruoka',
  route: 'k-ruoka',
  link: `${adminBase}/k-ruoka`,
  chain: Chain.kRuoka,
  chainIds: kRuokaChainIds,
  pages: [
    home,
    concepts,
    deliveries,
    contentBlocks,
    content,
    pricingItems,
    stores,
    settings,
    pushNotifications,
    collectionStampCards,
    collectionStampCardTemplates,
    keskoStampCards,
    reports,
    statistics,
  ],
};

export const kAuto: ChainRoute = {
  title: 'K-Auto',
  route: 'k-auto',
  link: `${adminBase}/k-auto`,
  chain: Chain.kAuto,
  hideInNavBar: true,
  chainIds: [],
  pages: [home],
};

export const onninen: ChainRoute = {
  title: 'Onninen',
  route: 'onninen',
  link: `${adminBase}/onninen`,
  chain: Chain.onninen,
  chainIds: onninenChainIds,
  pages: [home, concepts, deliveries, contentBlocks, content, stores, settings, reports],
};

export const chains: ChainRoute[] = [kRuoka, kRauta, kAuto, onninen];
