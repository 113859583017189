import React, { useEffect } from 'react';
import CloseButton from '../primitives/closeButton';

import './modal.scss';
import { useNavigate } from 'react-router-dom';

interface Props {
  children: JSX.Element;
  closeTo: string;
}

const Modal = ({ children, closeTo }: Props) => {
  const navigate = useNavigate();

  const handleClose = () => {
    navigate(closeTo, { replace: true });
  };

  const escapeClose = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      handleClose();
    }
  };

  const outsideClose = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (target.className === 'modal') {
      handleClose();
    }
  };

  useEffect(() => {
    window.addEventListener('click', outsideClose);
    document.addEventListener('keyup', escapeClose);
    return () => {
      window.removeEventListener('click', outsideClose);
      document.removeEventListener('keyup', escapeClose);
    };
  }, []);

  return (
    <div className="modal">
      <div className="modal-content">
        {children}
        <div className="modal-close">
          <CloseButton fallback={closeTo} />
        </div>
      </div>
    </div>
  );
};

export default Modal;
