import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { NavLink } from 'react-router-dom';
import page from 'components/next/pages/page/page';
import { inject, observer } from 'mobx-react';
import ConceptsStore from 'stores/next/programs';
import DeliveryTemplateStore from 'stores/next/deliveryTemplates';
import type { Concept, DeliveryTemplate, Route } from 'components/next/types';
import { concept as conceptRoute } from 'components/next/routes';
import './concepts.scss';
import { duplicateDeliveryTemplate, getConceptDefaults } from 'components/next/utils';
import SidebarWrapper from 'components/next/components/sidebar/sidebar';
import SearchBox from 'components/next/components/sidebar/searchBox';
import ChainSelector from 'components/next/components/chainSelector';
import { Checkbox } from '../../components/checkbox';
import Spinner from 'components/common/next/spinner';
import type Api from 'types/next-api';
import DeliveriesStore from 'stores/next/deliveries';

import { ReactComponent as IconDelete } from '@kesko/icons/action/icon-delete.svg';
import { ReactComponent as IconArrowRight } from '@kesko/icons/nav/icon-arrow-right.svg';
import { ReactComponent as CopyIcon } from '@kesko/icons/action/icon-copy.svg';
import { ReactComponent as IconUp } from '@kesko/icons/action/icon-push_up.svg';
import { ReactComponent as IconDown } from '@kesko/icons/action/icon-push_down.svg';
import StatisticsStore from 'stores/statisticsStore';
import {
  getAllowedConceptTypeForChainIds,
  getExcludedChains,
  isKRautaOnlyChain,
  isOnninenOnlyChain,
  useDebouncedEffect,
} from 'utils/helpers';
import { ChainAbbreviations } from 'constants/common';
import { ConceptType, ChangeOrderDirection } from 'enums/common';

type ConceptList = Api.Components.Responses.ConceptList;

interface ConceptsProps {
  conceptsStore?: ConceptsStore;
  deliveryTemplateStore?: DeliveryTemplateStore;
  deliveriesStore?: DeliveriesStore;
  statisticsStore?: StatisticsStore;
  chainIds: string[];
  getPageLink(route: Route, id: string): string;
}

const Concepts = ({
  conceptsStore,
  statisticsStore,
  deliveriesStore,
  deliveryTemplateStore,
  chainIds: chainIdsProp,
  getPageLink,
}: ConceptsProps) => {
  const allTypes = _.reduce(
    Object.keys(ConceptType),
    (types, key) => {
      const type = ConceptType[key as string];
      types[type] = true;
      return types;
    },
    {},
  );
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState<string>('');
  const [types, setTypes] = useState<{ [type: string]: boolean }>(allTypes);
  const [sort, setSort] = useState('order');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [chainIds, setChainIds] = useState(chainIdsProp);
  const [selectedConcepts, setSelectedConcepts] = useState<string[]>([]);
  const [categorizedResults, setCategorizedResults] = useState<Api.Components.Schemas.ConceptStatisticsResponseItem>({
    conceptId: '',
    recipients: 0,
    opens: 0,
    optOuts: 0,
    clicks: 0,
    totalRedeemers: 0,
    pullCount: 0,
    pullAmount: 0,
    pushNotificationStores: 0,
  });
  const [archived, setArchived] = useState(false);
  const [searchResult, setSearchResult] = useState<ConceptList | undefined>();
  // parallel/set simultaneously with searchResult, set of Ids found
  const [searchResultConceptIds, setSearchResultConceptIds] = useState<string[] | undefined>();

  const isKRautaChain = isKRautaOnlyChain(chainIdsProp);
  const isOnninenChain = isOnninenOnlyChain(chainIdsProp);
  const excludedChains = getExcludedChains(chainIdsProp);

  const getConcepts = () => {
    return searchResult ? searchResult.result : [];
  };

  /**
   * Returns computed stores list length if concepts are chosen, otherwise returns total stores.
   */
  const getParticipatingStores = () => {
    return _.uniq(categorizedResults.storesParticipating).length;
  };

  const searchConcepts = async () => {
    setIsLoading(true);
    const type = _.keys(_.pickBy(types, Boolean)) as ConceptType[];
    const searchResult = (await conceptsStore.search(
      {
        query,
        chainId: chainIds.length ? [chainIds[0], ...chainIds.slice(1)] : [chainIdsProp[0], ...chainIdsProp.slice(1)],
        type,
        archived,
      },
      { sort, order },
    )) as ConceptList;
    const conceptIds = searchResult.result.map(({ id }) => id);
    const updatedSelection = _.filter(selectedConcepts, (id) => _.includes(conceptIds, id)) as string[];
    setIsLoading(false);
    setSearchResult(searchResult);
    setSelectedConcepts(updatedSelection);
    setSearchResultConceptIds(conceptIds);
    (isKRautaChain || isOnninenChain) && updateStatistics(conceptIds, updatedSelection);
  };

  useEffect(() => {
    const init = async () => {
      (isKRautaChain || isOnninenChain) && (await statisticsStore.getStatisticsConcept());
    };
    init();
  }, []);

  useDebouncedEffect(searchConcepts, [query, chainIds, types, archived, sort, order], 500);

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    if (name === 'query') {
      setQuery(value);
      setIsLoading(true);
    }
  };

  const handleArchivedStateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setArchived(!archived);
    setIsLoading(true);
  };

  const handleChainChange = (chain: string) => {
    const newChainIds = [...chainIds];
    if (newChainIds.includes(chain)) {
      _.pullAt(newChainIds, newChainIds.indexOf(chain));
    } else {
      newChainIds.push(chain);
    }
    setChainIds(newChainIds);
    setIsLoading(true);
  };

  const handleTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTypes = { ...types };
    const type = e.currentTarget.dataset.conceptType;
    newTypes[type] = !types[type];
    setTypes(newTypes);
    setIsLoading(true);
  };

  const handleConceptSelect = (conceptId: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const selected = e.currentTarget.checked;
    const updatedSelection = selected
      ? [...selectedConcepts, conceptId]
      : _.filter(selectedConcepts, (id) => id !== conceptId);
    setSelectedConcepts(updatedSelection);
    updateStatistics(searchResultConceptIds, updatedSelection);
  };

  const addProgram = async () => {
    const newChainIds = chainIds.length ? chainIds : undefined;
    console.log('creating new concept with chainIds', newChainIds);
    await conceptsStore.createConcept(getConceptDefaults(newChainIds, null));
    searchConcepts();
  };

  const deleteProgram = async (id: string) => {
    const confirmed = window.confirm('Are you sure you want to delete this concept? This action cannot be undone.');
    if (confirmed) {
      setIsLoading(true);
      await conceptsStore.deleteConcept(id);
      setIsLoading(false);
      searchConcepts();
    }
  };

  const renderSidebar = () => {
    return (
      <div className="concepts-sidebar">
        <div className="concepts-filters">
          <button className="add-button program sidebar-button" onClick={() => addProgram()}>
            <span>Add concept</span>
            <img src={require('images/add-nega.svg').default} alt="add" />
          </button>
          <div className="sidebar__title-wrapper">
            <h3 className="sidebar__title">Filters</h3>
            {isLoading && <Spinner addClassName="spinner--unset" />}
          </div>
          <SearchBox
            value={query}
            name="query"
            onChange={handleQueryChange}
            placeholder="Search for concepts"
            detail="Search for concept by title or delivery template title"
          />
          <label>Chain</label>
          <ChainSelector
            chainSelection={chainIds}
            excludeChains={excludedChains}
            handleChainChange={handleChainChange}
          />
          <div className="filter-group">
            <Checkbox
              data-delivery-archived-state="archived"
              key={`concept-archived-state`}
              id={`concept-archived-state`}
              label="Show archived concepts"
              checked={archived}
              handleClick={handleArchivedStateChange}
            />
          </div>
          <div className="filter-group">
            <label>Type</label>
            {_.map(getAllowedConceptTypeForChainIds(chainIds), (type) => {
              return (
                <Checkbox
                  data-concept-type={type}
                  key={`concept-type-${type}`}
                  id={`concept-type-${type}`}
                  label={_.capitalize(type)}
                  checked={types[type]}
                  handleClick={handleTypeChange}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const duplicateConcept = async (id: string) => {
    setIsLoading(true);
    await conceptsStore.findById(parseInt(id, 10)).then(async (result: Concept) => {
      const payload = _.chain(result)
        .set('title.fi', `${result.title.fi} copy`)
        .omit('id')
        .omit('deliveryTemplates')
        .omit('conceptStoreAutomaticParticipation')
        .value();
      const deliveryTemplates = await Promise.all(
        result.deliveryTemplates.map((d) => deliveryTemplateStore.getTemplateById(d.id)),
      );
      const newConcept = await conceptsStore.createConcept(payload);

      // Duplicates all deliverytemplates
      await Promise.all(
        deliveryTemplates.map((d: DeliveryTemplate) => {
          const payload = duplicateDeliveryTemplate(d, conceptsStore.concept.type as ConceptType) as DeliveryTemplate;
          return deliveryTemplateStore.createDeliveryTemplate(_.set(payload, 'concept', newConcept.id));
        }),
      );
    });
    setIsLoading(false);
    searchConcepts();
  };

  const changeOrder = async (id: string, direction: ChangeOrderDirection) => {
    setIsLoading(true);
    try {
      const result = await conceptsStore.changeConceptOrder(id, { direction });
      searchConcepts();
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const getHeaderClass = (field: string) => {
    if (field !== sort) {
      return '';
    }
    return ` sorted ${order}`;
  };

  const renderConceptList = () => {
    return (
      <div className="table-container">
        <table className="styled concept-grid">
          <thead>
            <tr>
              {(isKRautaChain || isOnninenChain) && <th className="th-checkbox" />}
              <th className="th-title">Title</th>
              <th className="th-type">Type</th>
              <th className="th-chains">Chains</th>
              <th className="th-templates">Templates</th>
              <th className="th-deliveries">Deliveries</th>
              <th className="th-first-delivery">First delivery</th>
              <th className="th-last-delivery">Last delivery</th>
              <th className="th-status">Status</th>
              <th className="th-actions">Actions</th>
            </tr>
          </thead>
          <tbody>
            {getConcepts().map((concept, i) => {
              const templates = deliveryTemplateStore.templates.filter((t) => t.concept === concept.id);
              const templateIds = templates.map((t) => t.id);
              const deliveries = deliveriesStore.deliveries.filter((d) => _.includes(templateIds, d.deliveryTemplate));
              const startDates = templates.map((t) => t.firstStartDate);
              return (
                <tr key={i} className={concept.archived ? 'concept-row archived' : 'concept-row'}>
                  {(isKRautaChain || isOnninenChain) && (
                    <td className="checkboxes">
                      <Checkbox
                        id={`concept-id-${concept.id}`}
                        checked={_.includes(selectedConcepts, concept.id)}
                        handleClick={handleConceptSelect(concept.id)}
                      />
                    </td>
                  )}
                  <td className="td-title">
                    <NavLink to={getPageLink(conceptRoute, concept.id)} title={concept.title.fi}>
                      {concept.title.fi}
                    </NavLink>
                  </td>
                  <td className="td-type nowrap">{concept.type}</td>
                  <td className="td-chains nowrap">
                    {concept.chainIds &&
                      concept.chainIds.sort().map((c, i) =>
                        ChainAbbreviations[c] ? (
                          <span className={`chain-name ${ChainAbbreviations[c]}`} key={`chain-${i}`}>
                            {ChainAbbreviations[c]}
                          </span>
                        ) : null,
                      )}
                  </td>
                  <td className="td-templates nowrap">{templates.length}</td>
                  <td className="td-deliveries nowrap">{deliveries.length}</td>
                  <td className="td-first-delivery nowrap">{_.min(startDates)}</td>
                  <td className="td-last-delivery nowrap">{_.max(startDates)}</td>
                  <td className="td-status nowrap">{concept.published ? 'published' : 'draft'}</td>
                  <td className="td-actions nowrap">
                    <div className="buttons">
                      <button
                        className="copy-button"
                        onClick={() => changeOrder(concept.id, ChangeOrderDirection.Up)}
                        title="Move up"
                        disabled={i === 0}
                      >
                        <IconUp fill={i === 0 ? '#e2e3e3' : ''} stroke={i === 0 ? '#e2e3e3' : ''} />
                      </button>
                      <button
                        className="copy-button"
                        onClick={() => changeOrder(concept.id, ChangeOrderDirection.Down)}
                        title="Move down"
                        disabled={i === getConcepts().length - 1}
                      >
                        <IconDown
                          fill={i === getConcepts().length - 1 ? '#e2e3e3' : ''}
                          stroke={i === getConcepts().length - 1 ? '#e2e3e3' : ''}
                        />
                      </button>
                      <button
                        className="copy-button"
                        title="Duplicate concept"
                        onClick={() => duplicateConcept(concept.id)}
                      >
                        <CopyIcon />
                      </button>
                      <button className="program" title="Delete concept" onClick={() => deleteProgram(concept.id)}>
                        <IconDelete />
                      </button>

                      <NavLink to={concept.id} title="View concept">
                        <IconArrowRight />
                      </NavLink>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  /*
   * Function to dynamically recompute statistics. It does cycle over list of displayed Concepts every time, but since
   * the number of concepts seems to be about 100, I assume it is OK to leave the cycle here and do not try to improve
   * it by caching etc.
   * */
  const updateStatistics = (conceptIds: string[], selectedConceptIds: string[]): void => {
    const reply: Api.Components.Schemas.ConceptStatisticsResponseItem = {
      conceptId: '',
      recipients: 0,
      opens: 0,
      optOuts: 0,
      clicks: 0,
      totalRedeemers: 0,
      pullCount: 0,
      pullAmount: 0,
      pushNotificationStores: 0,
      storesParticipating: [],
    };

    // local helper function
    function addToStatistics(item: Api.Components.Schemas.ConceptStatisticsResponseItem) {
      reply.recipients += item.recipients;
      reply.opens += item.opens;
      reply.optOuts += item.optOuts;
      reply.clicks += item.clicks;
      reply.totalRedeemers += item.totalRedeemers;
      reply.pullAmount += item.pullAmount;
      reply.pullCount += item.pullCount;
      reply.pushNotificationStores = item.pushNotificationStores; // not dependent on concept TODO move to top level of proto
      if (item.storesParticipating) {
        reply.storesParticipating.push(...item.storesParticipating);
      }
    }

    const mapByConcept = statisticsStore.conceptStatisicsMapByConcept;

    // If we have no selected concepts, we add all shown concepts data
    if (_.isEmpty(selectedConceptIds)) {
      conceptIds.forEach((conceptId) => {
        if (mapByConcept.has(conceptId)) {
          addToStatistics(mapByConcept.get(conceptId));
        }
      });
    } else {
      // some selection made, adding numbers just for chosen selections.
      selectedConceptIds.forEach((conceptId) => {
        if (mapByConcept.has(conceptId)) {
          addToStatistics(mapByConcept.get(conceptId));
        }
      });
    }
    setCategorizedResults(reply);
  };

  const { concepts } = conceptsStore;
  return (
    <SidebarWrapper renderSidebar={renderSidebar}>
      <div className="concepts">
        {(isKRautaChain || isOnninenChain) && concepts && (
          // These should be removed once BTT migrates from legacy statistics
          <header>
            <div className="results-container">
              <div className="result">
                <span className="result__value">{getParticipatingStores()}</span>
                <span className="result_detail">kauppaa osallistuu</span>
              </div>
              <div className="result">
                <span className="result__value">{categorizedResults.recipients || 0}</span>
                <span className="result_detail">viestiä lähetetty</span>
              </div>
              <div className="result">
                <span className="result__value">{categorizedResults.opens || 0}</span>
                <span className="result_detail">viestiä avattu</span>
              </div>
              <div className="result">
                <span className="result__value">{categorizedResults.clicks || 0}</span>
                <span className="result_detail">viestin linkkiä klikattu</span>
              </div>
              <div className="result">
                <span className="result__value">{categorizedResults.optOuts || 0}</span>
                <span className="result_detail">vastaanottajaa perunut</span>
              </div>
              <div className="result">
                <span className="result__value">{categorizedResults.totalRedeemers || 0}</span>
                <span className="result_detail">edun lunastaneita</span>
              </div>
              <div className="result">
                <span className="result__value">
                  {categorizedResults.pullCount > 0
                    ? (categorizedResults.pullAmount / categorizedResults.pullCount).toFixed(2)
                    : 0.0 || 0.0}
                </span>
                <span className="result_detail">keskim. pull</span>
              </div>
              <div className="result">
                <span className="result__value">{categorizedResults.pushNotificationStores || 0}</span>
                <span className="result_detail">Push-viestin lähettänyttä</span>
              </div>
            </div>
          </header>
        )}
        {renderConceptList()}
        {isLoading && <Spinner />}
      </div>
    </SidebarWrapper>
  );
};

export default page(
  inject('conceptsStore', 'statisticsStore', 'deliveriesStore', 'deliveryTemplateStore')(observer(Concepts)),
);
