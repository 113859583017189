import React from 'react';
import * as dateFns from 'date-fns';
import _ from 'utils/lodash';
import { inject, observer } from 'mobx-react';
import page from 'components/next/pages/page/page';
import StoreStore from 'stores/next/stores';
import PushNotificationsStore from 'stores/next-retailer/pushNotificationsStore';
import Spinner from 'components/common/next/spinner';
import SidebarWrapper from 'components/next/components/sidebar/sidebar';
import { DateField } from 'components/next/components/form/input';
import type { PushNotification, Store } from 'types/next';
import { castDate } from 'utils/helpers';
import ChainSelector from 'components/next/components/chainSelector';
import { dateFormat, payloadDateFormat } from 'components/next/utils';
import { ChainAbbreviations, kRautaChainIds } from 'constants/common';
import { ChainCode } from 'enums/common';

interface Props {
  pushNotificationsStore: PushNotificationsStore;
  storeStore: StoreStore;
}

interface State {
  isLoading: boolean;
  storeId: string[];
  chainIds: string[];
  deliveryDateFrom: string;
  deliveryDateTo: string;
  statistics: any[];
  kcmPushNotifications: number;
  ksmPushNotifications: number;
  kmPushNotifications: number;
}

@inject('pushNotificationsStore', 'storeStore')
@observer
class PushNotifications extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
      storeId: [],
      chainIds: ['3', '4', '5'],
      kcmPushNotifications: 0,
      ksmPushNotifications: 0,
      kmPushNotifications: 0,
      deliveryDateFrom: null,
      deliveryDateTo: null,
      statistics: [],
    };
  }

  get pushNotifications() {
    return this.props.pushNotificationsStore.pushNotifications;
  }

  get statistics() {
    return this.state.statistics;
  }

  get stores() {
    return this.props.storeStore.stores;
  }

  getStoreForNotification = (storeId: string) => {
    const result = _.find(this.stores, (store) => store.storeId === storeId);
    if (result) {
      return result.name;
    }
    return 'Unknown store';
  };

  getChainForNotification = (storeId: string) => {
    const result = _.find(this.stores, (store) => store.storeId === storeId);
    if (result) {
      return result.chainId;
    }
    return undefined;
  };

  componentDidMount = async () => {
    await this.search();
    await this.props.storeStore.searchStores({});
  };

  search = async () => {
    const { pushNotificationsStore } = this.props;
    const { storeId, deliveryDateFrom, deliveryDateTo, chainIds } = this.state;
    this.setState({ isLoading: true });

    const search = _.omitBy(
      {
        storeId,
        deliveryDateFrom,
        deliveryDateTo,
        chainId: chainIds,
      },
      _.isNil,
    );

    await pushNotificationsStore.search(search);
    await this.handleStatisticsMapping(this.pushNotifications);
    await this.getPushCountsForStatistics(this.statistics);
    this.setState({ isLoading: false });
  };

  debouncedSearch = _.debounce(this.search, 500);

  // eslint-disable-next-line
  handleStoresChange = async (stores: Store[]) => {
    // eslint-disable-next-line
    await this.setState({ storeId: stores.map((store) => store.storeId) });
    this.debouncedSearch();
  };

  handleDateChange = (date: Date, field: string) => {
    const value = dateFns.isDate(date) ? dateFns.format(date, payloadDateFormat) : undefined;
    this.setState<never>({ [field]: value || null, isLoading: true });
    this.debouncedSearch();
  };
  x;

  // eslint-disable-next-line
  getPushCountsForStatistics = async (statistics) => {
    let kcmPushNotifications = 0;
    let ksmPushNotifications = 0;
    let kmPushNotifications = 0;

    statistics.forEach((stat) => {
      switch (stat.chainId) {
        case ChainCode.KCM:
          kcmPushNotifications += stat.pushCount;
          break;
        case ChainCode.KSM:
          ksmPushNotifications += stat.pushCount;
          break;
        case ChainCode.KM:
          kmPushNotifications += stat.pushCount;
          break;
        default:
          break;
      }
    });
    this.setState({ kcmPushNotifications, ksmPushNotifications, kmPushNotifications });
  };

  // eslint-disable-next-line
  handleStatisticsMapping = async (pushNotifications: PushNotification[]) => {
    const result = [];

    pushNotifications.forEach((notification) => {
      const store = notification.storeId;
      const chainId = this.getChainForNotification(store);
      const pushNotificationDate = castDate(notification.createdAt);
      const date = `${pushNotificationDate.getFullYear()}-${pushNotificationDate.getMonth() + 1}`;

      if (chainId !== undefined) {
        const existingObject = _.find(result, { date: date, chainId: chainId });
        if (existingObject) {
          existingObject.pushCount++;
          if (!existingObject.stores.includes(store)) {
            existingObject.stores.push(store);
          }
        } else {
          result.push({
            date: date,
            chainId: chainId,
            pushCount: 1,
            stores: [store],
          });
        }
      }
    });

    this.setState({ statistics: result, isLoading: false });
  };

  handleChainChange = (chain: string) => {
    const chainIds = [...this.state.chainIds];
    if (chainIds.includes(chain)) {
      _.pullAt(chainIds, chainIds.indexOf(chain));
    } else {
      chainIds.push(chain);
    }
    this.setState({ chainIds, isLoading: true });
    this.debouncedSearch();
  };

  renderPushNotificationTable = () => {
    const { isLoading } = this.state;
    const dateFormat = 'MMMM yyyy';

    return (
      <div className="push-notifications">
        <div className="table-container">
          <table className="styled">
            <thead>
              <tr>
                <th>Chain</th>
                <th>Month / Year</th>
                <th>Sent push notifications</th>
                <th>Stores that sent push notifications</th>
              </tr>
            </thead>
            <tbody>
              {_.orderBy(this.statistics, 'date', 'desc').map((statistic, index) => (
                <tr key={`push-${index}`}>
                  <td>
                    {
                      <span className={`chain-name ${ChainAbbreviations[statistic.chainId]}`}>
                        {ChainAbbreviations[statistic.chainId]}
                      </span>
                    }
                  </td>
                  <td>{dateFns.format(dateFns.parse(statistic.date, 'yyyy-M', new Date()), dateFormat)}</td>
                  <td>{statistic.pushCount}</td>
                  <td>{statistic.stores.length}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {isLoading && <Spinner />}
        </div>
      </div>
    );
  };

  renderSidebar = () => {
    const { chainIds, isLoading } = this.state;
    return (
      <div className="push-notifications-sidebar">
        <div className="sidebar__title-wrapper">
          <h3 className="sidebar__title">Filters</h3>
          {isLoading && <Spinner addClassName="spinner--unset" />}
        </div>
        <div className="filter-group">
          <label>Chain</label>
          <ChainSelector
            chainSelection={chainIds}
            handleChainChange={this.handleChainChange}
            excludeChains={kRautaChainIds}
          />
        </div>
        <div className="filter-group">
          <label>Delivery time</label>
          <DateField
            value={this.state.deliveryDateFrom ? new Date(this.state.deliveryDateFrom) : null}
            label="From"
            onChange={(e) => this.handleDateChange(e, 'deliveryDateFrom')}
            clearable
            placeholder={dateFormat}
          />
          <DateField
            value={this.state.deliveryDateTo ? new Date(this.state.deliveryDateTo) : null}
            label="To"
            onChange={(e) => this.handleDateChange(e, 'deliveryDateTo')}
            clearable
            placeholder={dateFormat}
          />
        </div>
      </div>
    );
  };

  render() {
    return (
      <SidebarWrapper renderSidebar={this.renderSidebar}>
        <div className="results-view">
          {this.props.children}
          <div className="results-container">
            <div className="result">
              <span className="result__value">{this.state.kcmPushNotifications}</span>
              <span className="result_detail">KCM push notifications</span>
            </div>
            <div className="result">
              <span className="result__value">{this.state.ksmPushNotifications}</span>
              <span className="result_detail">KSM push notifications</span>
            </div>
            <div className="result">
              <span className="result__value">{this.state.kmPushNotifications}</span>
              <span className="result_detail">KM push notifications</span>
            </div>
          </div>
          {this.renderPushNotificationTable()}
        </div>
      </SidebarWrapper>
    );
  }
}

export default page(PushNotifications);
