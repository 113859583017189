import { retailerBase } from '../../routes';

const routes = {
  front: () => '',

  news: () => `news`,
  newsItem: (newsItemId: string) => `news/${newsItemId}`,
  profile: () => `profile`,
  help: (section?: string) => `help/${section ? `${section}` : ''}`,

  // osallistumiseni
  // /deliveries
  deliveries: () => `deliveries`,
  delivery: (deliveryId: string) => `delivery/${deliveryId}`,

  // /browse/:conceptType
  concepts: (type: string) => `browse/${type}`,

  // /concept/:conceptId
  concept: (conceptId: string) => `concept/${conceptId}`,

  // lähetyksen luonti
  // /delivery/edit/:deliveryId/:templateId
  // /delivery/edit/new/:templateId
  // /delivery/edit/automatic/:templateId
  // /delivery/edit/automatic-disabled/:templateId
  editDelivery: (deliveryId: string, templateId: string) => `delivery/edit/${deliveryId}/${templateId}`,

  // push-viestit
  pushNotifications: () => `push-notifications`,
  // push-viestin luonti
  // /push-notifications/new
  newPushNotification: () => `push-notifications/new`,
  editPushNotification: (notificationId: string) => `push-notifications/${notificationId}`,
  // Collection stamp cards
  stampCards: () => `cards`,
  viewStampCard: (cardId: string) => `cards/view/${cardId}`,
  // Old collection stamp card editor
  createStampCard: (id?: string) => (id ? `cards/create/${id}/` : `cards/create/`),
  editStampCard: (id: string) => `cards/edit/${id}`,
  stampCardImageEditor: (mode: string, id?: string) => (id ? `cards/${mode}/${id}/image/` : `cards/${mode}/image`),
  stampCardProductPicker: (mode: string, id?: string) =>
    id ? `cards/${mode}/${id}/product/` : `cards/${mode}/product`,
  stampCardRewardPicker: (mode: string, id?: string) => (id ? `cards/${mode}/${id}/reward/` : `cards/${mode}/reward`),
  // New collection stamp card editor
  stampCardEditor: (mode: string, id: string, tab: string) => `card-editor/${mode}/${id}/${tab}`,
  logout: () => `logout`,
  mobileOffers: () => 'mobile-offers',
  mobileOffer: (mobileOfferId: string) => `mobile-offer/${mobileOfferId}`,
  newMobileOffers: () => 'mobile-offers/edit/new',
  editMobileOffers: (mobileOffersId: string) => `mobile-offers/edit/${mobileOffersId}`,
};

// Get relative route for React Router
export const getRoute = (name: string, ...args) => {
  return routes[name](...args);
};

// Get absolute link for navigation
export const getLink = (name: string, ...args) => {
  return `${retailerBase}/${routes[name](...args)}`;
};
