import React from 'react';
import { debounce, isEmpty, includes, filter, get } from 'lodash';
import { BTTProduct, SearchType } from 'types/campaign';
import { DateString } from 'types/string';
import { bttProductSearch } from 'utils/api/campaign';
import { noSubmit } from 'utils/helpers';
import Spinner from 'components/common/next/spinner';

import './bttProductSelect.scss';

interface Props {
  filterEans?: string[];
  extraProducts?: BTTProduct[];
  campaignStart?: DateString;
  campaignEnd?: DateString;
  searchType?: SearchType;
  noResultsText?: string;
  onSelect(product: BTTProduct): void;
  closeSearchOnSelect?: boolean;
  placeholder?: string;
  allowByPricingUnit?: string;
}

interface State {
  search: string;
  searchResults: BTTProduct[];
  resultsOpen: boolean;
  hasMore: boolean;
  loading: boolean;
  loadingMore: boolean;
}

class BttProductSelect extends React.Component<Props, State> {
  public containerNode;
  public searchField;
  public resultsIncrement = 15;

  constructor(props: Props) {
    super(props);
    this.state = {
      search: null,
      searchResults: [],
      resultsOpen: false,
      hasMore: false,
      loading: false,
      loadingMore: false,
    };
    this.search = this.search.bind(this);
    this.changeSearch = this.changeSearch.bind(this);
    this.openSearch = this.openSearch.bind(this);
    this.closeSearch = this.closeSearch.bind(this);
    this.searchProducts = debounce(this.searchProducts.bind(this), 300);
  }

  public searchProducts(query: string, amount?: number, more?: boolean) {
    const { searchResults } = this.state;
    const { extraProducts } = this.props;
    const increment = amount || this.resultsIncrement;
    const offset = more ? searchResults.length : undefined;

    if (isEmpty(query)) {
      this.setState({
        searchResults: [],
        loading: false,
        loadingMore: false,
      });
      return;
    }

    bttProductSearch(query, increment, offset)
      .then((res) => {
        const hasMore = res.length >= increment;
        let newResults = more ? [...searchResults, ...res] : res || [];

        if (extraProducts) {
          const extraResults = filter(extraProducts, (extraProduct: BTTProduct) => {
            const title: string = get(extraProduct, ['title', 'fi'], '');
            const ean: string = get(extraProduct, ['ean']);

            return includes(title.toLowerCase(), query.toLowerCase()) || includes(ean, query);
          });

          newResults = [...extraResults, ...newResults];
        }

        const { search, loading, loadingMore } = this.state;
        // If a new search has not been initiated or this cancelled
        if (search === query) {
          if (more && loadingMore) {
            this.setState({
              searchResults: newResults,
              loadingMore: false,
              hasMore,
            });
          } else if (loading) {
            this.setState({
              searchResults: newResults,
              loading: false,
              hasMore,
            });
          }
        }
      })
      .catch(() =>
        this.setState({
          searchResults: searchResults || [],
          loading: false,
          loadingMore: false,
        }),
      );
  }

  public search(value?: string, amount?: number, more?: boolean) {
    const { search } = this.state;
    const searchStr = value !== null ? value : search;
    if (searchStr && searchStr.length > 2) {
      if (more) {
        this.setState({ loadingMore: true });
      } else {
        this.setState({ loading: true });
      }
      this.searchProducts(searchStr, amount, more);
    } else if (this.searchField) {
      this.setState({ searchResults: [], loading: false });
      this.searchField.focus();
    }
  }

  public changeSearch(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.currentTarget;
    this.setState({ search: value, resultsOpen: true });
    this.search(value, null, null);
  }

  public openSearch() {
    this.setState({ resultsOpen: true });
  }

  public closeSearch() {
    this.setState({ resultsOpen: false });
  }

  public select(product: BTTProduct, closeSearch?: boolean) {
    const { onSelect, closeSearchOnSelect } = this.props;
    onSelect(product);

    if (closeSearchOnSelect || closeSearch) {
      this.closeSearch();
    }
  }
  public render() {
    const { filterEans, placeholder, noResultsText, allowByPricingUnit } = this.props;
    const { search, searchResults, resultsOpen, loading, loadingMore, hasMore } = this.state;

    const showResults = !loading;

    const resultItem = (product: BTTProduct) => {
      const { isOnSale, ean, image, title, price, pricingUnit } = product;
      const filterOut = includes(filterEans, ean);
      const filteredByPricingUnit = allowByPricingUnit && pricingUnit !== allowByPricingUnit;
      const disabled = filterOut || filteredByPricingUnit;
      return (
        <div
          className={`search-result product-item ${disabled ? ' disabled' : ''}`}
          key={ean}
          onClick={!disabled ? this.select.bind(this, product, false) : null}
          onKeyPress={!disabled ? this.select.bind(this, product, false) : null}
          tabIndex={disabled ? null : 0}
        >
          <div className="image-container">
            {filterOut ? (
              <img className="icon" src={require('images/checkmark.svg').default} alt="check" />
            ) : (
              <img
                src={image ? `${image}?h=50&fm=png` : 'https://www.k-ruoka.fi/assets/6335/img/ei-tuotekuvaa.svg'}
                alt="tuotekuva"
              />
            )}
          </div>
          <div className="item-details">
            <div className="title">{title}</div>
            <div className="label">
              <span className="emph">EAN</span> {ean}
            </div>
            <div className="label">
              <span className="emph">ID</span> {product.productId}
            </div>
          </div>
          <div className="price-container">
            <div className="title">{price}</div>
            <div className="label">€ / {pricingUnit}</div>
            {isOnSale && <div className="on-sale">tarjouksessa</div>}
          </div>
        </div>
      );
    };

    return (
      <div className="product-select" ref={(e) => (this.containerNode = e)}>
        <div className="product-search-field form-control">
          <input
            type="text"
            placeholder={placeholder ? placeholder : 'Etsi tuotetta nimellä, EAN-koodilla tai ID:llä'}
            autoComplete="off"
            value={search || ''}
            onChange={this.changeSearch}
            onFocus={this.openSearch}
            // onBlur={this.closeSearch}
            onKeyDown={noSubmit}
            ref={(e) => (this.searchField = e)}
          />
        </div>
        {searchResults && resultsOpen && (
          <div className="product-search-results">
            {showResults && searchResults.map(resultItem)}
            {loading || loadingMore ? (
              <div className="loading-results">
                <Spinner />
              </div>
            ) : (
              search &&
              isEmpty(searchResults) && (
                <div className="no-results">{noResultsText ? noResultsText : 'Ei hakutuloksia.'}</div>
              )
            )}
            {!loading && !loadingMore && searchResults.length > 0 && hasMore && search && (
              <div
                className="show-more"
                onClick={this.search.bind(this, null, null, true)}
                onKeyPress={this.search.bind(this, null, null, true)}
                tabIndex={0}
              >
                <div>Hae lisää</div>
                <img src={require('images/arrow-down.svg').default} alt="arrow-down" />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default BttProductSelect;
