import { observable, action, runInAction, computed } from 'mobx';
import { trim } from 'lodash';
import { Role } from 'types/user';
import { Option } from 'types/option';
import type { User, NotificationContact, NotificationType } from 'types/user';
import RootStore from './rootStore';
import * as api from 'utils/api/user';
import { ChainFilters } from 'constants/common';

export default class UserStore {
  public rootStore: RootStore;

  // getMe or logout request in progress
  @observable public requestInProgress = false;

  @observable
  public me: User = {
    name: null,
    aduser: null,
    role: null,
    store: null,
    chainId: null,
    storeData: null,
    email: null,
    adminAduser: null,
  };

  @observable public retailers: Option[] = [];
  @observable public stores: Option[] = [];
  @observable public storesByStoreId: {
    [storeId: string]: {
      storeId: string;
      chainId: string;
      name: string;
      shortName: string;
    };
  };
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable public notificationTypes: NotificationType[] = [];
  @observable public notificationContacts: NotificationContact[] = [];
  @observable public notificationDetailsLoaded = false;

  @computed
  public get isAdmin() {
    return !!this.me.adminAduser;
  }

  public getStoreShortName(name: string, chainId: string) {
    if (!ChainFilters[chainId]) {
      return name;
    }

    const regEx = new RegExp(ChainFilters[chainId], 'i');
    const shortName = name.replace(regEx, '');

    return trim(shortName);
  }

  @action
  public reset() {
    this.me = {
      name: null,
      aduser: null,
      role: null,
      store: null,
      chainId: null,
      storeData: null,
      email: null,
      adminAduser: null,
    };
    this.notificationContacts = [];
    this.notificationTypes = [];
    this.notificationDetailsLoaded = false;
    this.retailers = [];
    this.stores = [];
    this.storesByStoreId = {};
  }

  @action
  public async getMe() {
    if (this.requestInProgress) {
      return;
    }
    this.requestInProgress = true;
    this.rootStore.reset();
    try {
      const me = await api.getMe();
      runInAction(() => {
        this.requestInProgress = false;
        this.me = me;
      });
      if (me && me.role === Role.Admin) {
        // prefetch retailers and stores for admin
        this.getStores();
      }
      if (me && me.role === Role.Retailer) {
        // prefetch stores for retailer
        this.getStores();

        // Fetch notification details.
        this.getNotificationDetails(this.me.store);
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        this.rootStore.reset();
        runInAction(() => {
          this.requestInProgress = false;
        });
        return;
      }
      throw err;
    }
  }

  @action
  public async becomeRetailer(aduser: string) {
    // get token
    await api.getTokenForRetailer(aduser);
    // update currentUser
    await this.getMe();
  }

  @action
  public async selectStore(store: string) {
    try {
      // get token
      await api.getTokenForStore(store);
      // update currentUser
      await this.getMe();
    } catch (err) {
      if (err.response && err.response.status === 401) {
        this.rootStore.reset();
        runInAction(() => {
          this.requestInProgress = false;
        });
        return;
      }
      throw err;
    }
  }

  @action
  public async getStores() {
    const stores = await api.getStores();
    runInAction(() => {
      this.stores = stores
        .filter((store) => !store.removed)
        .map((store) => {
          this.storesByStoreId[store.businessUnitId] = {
            storeId: store.businessUnitId,
            chainId: store.chainId,
            name: store.storeName,
            shortName: this.getStoreShortName(store.storeName, store.chainId),
          };

          return {
            label: store.storeName,
            value: store.businessUnitId,
          };
        });
    });
  }

  // eslint-disable-next-line
  @action public async login(from?: string) {
    api.login(from);
  }

  @action
  public async logout() {
    this.requestInProgress = true;
    await api.logout();
    runInAction(() => {
      this.requestInProgress = false;
    });
    await this.getMe();
  }

  @action
  public async getNotificationDetails(storeId: string) {
    const response = await api.getNotificationDetails(storeId);
    runInAction(() => {
      this.notificationTypes = response.notificationTypes;
      this.notificationContacts = response.notificationContacts;
      this.notificationDetailsLoaded = true;
    });
  }

  @action
  public async patchNotificationTypes(storeId: string, notificationTypes: NotificationType[]) {
    await api.patchNotificationTypes(storeId, notificationTypes);
    await this.getNotificationDetails(storeId);
  }

  @action
  public async patchNotificationContacts(storeId: string, notificationContacts: NotificationContact[]) {
    await api.patchNotificationContacts(storeId, notificationContacts);
    await this.getNotificationDetails(storeId);
  }
}
