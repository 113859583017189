import React, { useEffect, useState } from 'react';
import _ from 'utils/lodash';
import { inject, observer } from 'mobx-react';
import ConceptBox from './conceptBox';
import Spinner from 'components/common/next/spinner';
import type { Concept, DeliveryTemplate } from 'types/next';
import ConceptStore from 'stores/next-retailer/conceptStore';
import UserStore from 'stores/userStore';
import { ConceptType, DeliveryTemplateStatus } from 'enums/common';
import './conceptList.scss';

interface Props {
  filters: {
    type?: ConceptType;
  };
}

interface InjectedProps extends Props {
  conceptStore?: ConceptStore;
  userStore?: UserStore;
}

const ConceptList = ({ filters, conceptStore, userStore }: InjectedProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const concepts = () => {
    return _.chain(conceptStore.concepts as Concept[])
      .filter(filters)
      .orderBy((c) => c.order, 'asc')
      .value();
  };

  const filteredConcept = () => {
    return concepts().filter((concept: Concept) => {
      if (concept.deliveryTemplates) {
        const templates = concept.deliveryTemplates.filter((template: DeliveryTemplate) => {
          if (template.status === DeliveryTemplateStatus.Open && template.published) {
            if (!template.stores || template.stores.includes(userStore.me.store)) {
              return template;
            }
          }
          return null;
        });
        if (templates.length > 0) {
          return concept;
        }
      }
      return null;
    });
  };

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      const concepts = await conceptStore.search({ archived: false });
      // Need to fetch also delivery templates, so that we can filter out concepts
      // without any delivery templates or concepts with no active/open delivery templates
      await Promise.all(concepts.map(async (c) => await conceptStore.getConcept(c.id, false)));
      setIsLoading(false);
    };
    init();
  }, []);

  return (
    <div className="concept-list">
      {isLoading ? (
        <Spinner addClassName="spinner--margin-top" />
      ) : (
        <div className="concepts">
          {filteredConcept().map((concept) => (
            <ConceptBox key={concept.id} concept={concept} store={userStore.me.store} />
          ))}
          {!filteredConcept().length && <p className="no-results">Lähetyksiä ei tällä hetkellä saatavilla.</p>}
        </div>
      )}
      <pre className="debug">{JSON.stringify(filteredConcept())}</pre>
    </div>
  );
};

export default inject('conceptStore', 'userStore')(observer(ConceptList));
