import React, { useEffect, useState } from 'react';
import _ from 'utils/lodash';
import type { DeliveryLanguage } from 'types/next';
import { inject, observer } from 'mobx-react';
import { ReactComponent as IconUnhappy } from '@kesko/icons/mood/icon-mood_unhappy.svg';
import { ReactComponent as IconRefresh } from '@kesko/icons/action/icon-refresh.svg';
import DeliveryStore, { Delivery, DeliveryRelations } from 'stores/next-retailer/deliveryStore';
import './contentPreview.scss';
import LanguageSelector from './languageSelector';
import Button from './form/button';
import { PreviewContent, DeliveryChannelName, PreviewFormat } from 'enums/common';
import { useParams } from 'react-router-dom';
import Spinner from 'components/common/next/spinner';

interface InjectedProps {
  deliveryStore?: DeliveryStore;
}

type Params = {
  id: string;
  channel: DeliveryChannelName;
  content: PreviewContent;
};

const ContentPreview = ({ deliveryStore }: InjectedProps) => {
  const { id: idParam, channel: channelParam, content: contentParam } = useParams<Params>();
  const [error, setError] = useState('');

  const previewEntityRef = idParam == 'new' ? { current: true } : { deliveryId: idParam };
  const previewEntity = deliveryStore.getEntityForRef(previewEntityRef);

  const isLoading =
    channelParam === DeliveryChannelName.Email
      ? previewEntity.emailPreviewLargeLoading
      : channelParam === DeliveryChannelName.Print
        ? previewEntity.printPreviewPdfLoading
        : false;

  const fetchEntity = async () => {
    const entity = previewEntity;
    if (!entity) {
      return null;
    }
    if (contentParam === PreviewContent.Delivery && idParam === 'new') {
      if (channelParam === DeliveryChannelName.Print) {
        deliveryStore.previewDelivery(
          entity as Delivery & DeliveryRelations,
          {
            channel: DeliveryChannelName.Print,
            format: PreviewFormat.Pdf,
          },
          previewEntityRef,
        );
      }
      if (channelParam === DeliveryChannelName.Email) {
        deliveryStore.previewDelivery(
          entity as Delivery & DeliveryRelations,
          {
            channel: DeliveryChannelName.Email,
            format: PreviewFormat.Html,
          },
          previewEntityRef,
        );
        deliveryStore.previewDelivery(
          entity as Delivery & DeliveryRelations,
          {
            channel: DeliveryChannelName.Email,
            format: PreviewFormat.Image,
          },
          previewEntityRef,
        );
      }
    }
    if (contentParam === PreviewContent.Delivery && idParam !== 'new') {
      await deliveryStore.getDelivery(idParam, false);
      if (channelParam === DeliveryChannelName.Print) {
        deliveryStore.previewDeliveryById(idParam, {
          channel: channelParam as DeliveryChannelName,
          format: PreviewFormat.Pdf,
        });
      }
      if (channelParam === DeliveryChannelName.Email) {
        deliveryStore.previewDeliveryById(idParam, {
          channel: DeliveryChannelName.Email,
          format: PreviewFormat.Html,
        });
        deliveryStore.previewDeliveryById(idParam, {
          channel: DeliveryChannelName.Email,
          format: PreviewFormat.Image,
        });
      }
    }
  };

  useEffect(() => {
    const init = async () => {
      try {
        await fetchEntity();
      } catch (err) {
        console.error(err);
        setError('An error occured while rendering preview.');
      }
    };
    init();
  }, [deliveryStore.previewLanguage]);

  const toggleLanguage = (previewLanguage: DeliveryLanguage) => {
    deliveryStore.setPreviewLanguage(previewLanguage);
  };

  const getEmailPreviewShareableLink = async () => {
    try {
      await deliveryStore.previewDelivery(
        deliveryStore.current as Delivery & DeliveryRelations,
        {
          channel: DeliveryChannelName.Email,
          format: PreviewFormat.Image,
          returnUrl: true,
        },
        previewEntityRef,
      );
    } catch (err) {
      console.error(err);
      setError('An error occured while rendering preview.');
    }
  };

  return (
    <div className="content-preview">
      {error && (
        <div className="error text-center">
          <IconUnhappy />
          <br />
          {error}
        </div>
      )}
      <header>
        Esikatsele kielellä
        <LanguageSelector
          language={deliveryStore.previewLanguage}
          languages={['fi', 'sv']}
          toggleLanguage={toggleLanguage}
        />
        {isLoading && (
          <div>
            <Spinner />
          </div>
        )}
        {channelParam === DeliveryChannelName.Email &&
          !previewEntity.emailPreviewUrl?.[deliveryStore.previewLanguage] && (
            <Button className="generate-link" color="bordered" onClick={getEmailPreviewShareableLink}>
              Luo jaettava linkki
            </Button>
          )}
        {channelParam === DeliveryChannelName.Email &&
          previewEntity.emailPreviewUrl?.[deliveryStore.previewLanguage] && (
            <div className="preview-url-container">
              <a
                className="preview-url"
                href={previewEntity.emailPreviewUrl[deliveryStore.previewLanguage]}
                target="_blank"
                rel="noopener noreferrer"
              >
                {previewEntity.emailPreviewUrl[deliveryStore.previewLanguage]}
              </a>
              <Button
                className="generate-link"
                color="bordered"
                title="Luo linkki uudelleen"
                onClick={getEmailPreviewShareableLink}
              >
                <IconRefresh />
              </Button>
            </div>
          )}
      </header>
      {channelParam === DeliveryChannelName.Email &&
        previewEntity.emailPreviewHtml?.[deliveryStore.previewLanguage] &&
        previewEntity.emailPreviewLarge?.[deliveryStore.previewLanguage] && (
          <a
            className="image-wrapper"
            href={previewEntity.emailPreviewHtml[deliveryStore.previewLanguage]}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={previewEntity.emailPreviewLarge[deliveryStore.previewLanguage]} alt="preview" />
          </a>
        )}
      {channelParam === DeliveryChannelName.Print && previewEntity.printPreviewPdf?.[deliveryStore.previewLanguage] && (
        <embed
          className="pdf-wrapper"
          src={previewEntity.printPreviewPdf[deliveryStore.previewLanguage]}
          type="application/pdf"
        />
      )}
    </div>
  );
};

export default inject('deliveryStore')(observer(ContentPreview));
