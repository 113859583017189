import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import AlertStore from 'stores/next/alerts';
import { ReactComponent as IconUpload } from '@kesko/icons/action/icon-upload.svg';
import './inputCsv.scss';
import { inject, observer } from 'mobx-react';

interface InputCsvProps {
  handleFileContent: (textContent: string) => void;
  dragDropText?: string;
  dragActiveText?: string;
  dragDropHelperText?: string;
  iconTitle?: string;
  alertStore?: AlertStore;
}

const InputCsv = (props: InputCsvProps) => {
  const {
    handleFileContent,
    alertStore,
    dragDropText = 'Raaha ja pudota tiedosto tähän, tai klikkaa valitaksesi',
    dragActiveText = 'Pudota tiedosto tähän ...',
    iconTitle = 'Lisää tiedosto',
    dragDropHelperText = '(Voit lisätä *.csv tai *.txt tiedostoja)',
  } = props;
  const handleAcceptedFile = (file: File) => {
    const reader = new FileReader();
    reader.onabort = () =>
      alertStore.error({
        message: `Tiedoston lukeminen keskeytyi.`,
      });
    reader.onerror = () =>
      alertStore.error({
        message: `Tiedoston lukeminen epäonnistui.`,
      });
    reader.onload = () => {
      if (typeof reader.result !== 'string') {
        alertStore.warning({
          message: `Lisättyä tiedostoa ${file.name} ei voitu lukea. Tarkistathan tiedostomuodon.`,
        });
        return;
      }
      handleFileContent(reader.result);
    };
    reader.readAsText(file);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: useCallback((acceptedFiles: File[]) => {
      acceptedFiles.forEach(handleAcceptedFile);
    }, []),
    onDropRejected: (fileRejections) => {
      fileRejections.forEach((fileRejection) => {
        alertStore.warning({
          message: `Lisättyä tiedostoa ${fileRejection.file.name} ei voitu lukea. Tarkistathan tiedostomuodon.`,
        });
      });
    },
    accept: {
      'text/csv': ['.csv', '.txt'],
      'text/plain': ['.csv', '.txt'],
    },
  });

  return (
    <div {...getRootProps({ className: 'input-csv-container' })}>
      <input {...getInputProps()} />
      <IconUpload title={iconTitle} />
      {isDragActive ? <p>{dragActiveText}</p> : <p>{dragDropText}</p>}
      <em>{dragDropHelperText}</em>
    </div>
  );
};

export default inject('alertStore')(observer(InputCsv));
