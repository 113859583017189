import React from 'react';
import { getLink } from '../../routes';
import _ from 'utils/lodash';
import * as date from 'date-fns';
import './deliveryCard.scss';
import { DeliveryWithMeta } from 'stores/next-retailer/deliveryStore';
import { ReactComponent as IconSend } from '@kesko/icons/action/icon-msg-send.svg';
import { ReactComponent as IconHeart } from '@kesko/icons/misc/icon-heart.svg';
import { ReactComponent as IconB2B } from '@kesko/icons/people/icon_userB2B.svg';
import { ReactComponent as IconChevronRight } from '@kesko/icons/nav/icon-chevron-right.svg';
import { castDate } from 'utils/helpers';
import { NumericFormat } from 'react-number-format';
import { ConceptType } from 'enums/common';
import { useNavigate } from 'react-router-dom';

interface Props {
  delivery: DeliveryWithMeta;
  disabled?: boolean;
  pullDelivery?: number;
  pullAverage?: number;
}

const DeliveryCard = ({ delivery, disabled, pullDelivery, pullAverage }: Props) => {
  const navigate = useNavigate();

  const viewDelivery = () => {
    if (!disabled) {
      navigate(getLink('delivery', delivery.id));
    }
  };

  const render = () => {
    const title = delivery.meta.titles.deliveryTemplate.fi;
    const classes = ['delivery-card', 'box'];
    if (disabled) {
      classes.push('disabled');
    }
    const description = delivery.description ? `: ${delivery.description}` : '';
    return (
      <div className={classes.join(' ')} onClick={viewDelivery}>
        <div className="icon">
          {delivery.meta.type === ConceptType.Program ? (
            <IconHeart />
          ) : delivery.meta.type === ConceptType.Season ? (
            <IconSend />
          ) : (
            <IconB2B />
          )}
        </div>
        <div className="basic-info">
          <h3 onClick={viewDelivery} className={'clickable'}>
            {title}
            {description} {disabled && '(tarkastelu väliaikaisesti suljettu)'}
          </h3>
          <p className="text-small">
            Asiakkailla: <strong>{date.format(castDate(castDate(delivery.startDate)), 'd.M.yyyy')}</strong>
          </p>
        </div>
        <div className="pull">
          <div>
            <h3>
              {_.isFinite(pullDelivery) && pullDelivery > 0 ? (
                <NumericFormat
                  value={pullDelivery}
                  displayType="text"
                  decimalScale={1}
                  fixedDecimalScale={true}
                  decimalSeparator=","
                  suffix="%"
                />
              ) : null}
            </h3>
          </div>
          <div>
            {_.isFinite(pullAverage) && pullAverage > 0 ? (
              <p>
                (
                <NumericFormat
                  value={pullAverage}
                  displayType="text"
                  decimalScale={1}
                  fixedDecimalScale={true}
                  decimalSeparator=","
                  suffix="%"
                />
                )
              </p>
            ) : null}
          </div>
        </div>
        <div className="caret-end">
          <IconChevronRight />
        </div>
      </div>
    );
  };

  return render();
};

export default DeliveryCard;
