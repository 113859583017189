import React from 'react';

interface Props {
  value: string;
  name: string;
  label?: string;
  className?: string;
  defaultChecked?: boolean;
  checked?: boolean;
  description?: string;
  fontWeight?: string;
  onChange?: (e: React.SyntheticEvent) => any;
  children?: JSX.Element | JSX.Element[];
}

const InputRadio = ({ value, label, className, checked, description, fontWeight, children, ...inputProps }: Props) => (
  <div>
    <div className={`radio-button ${className || ''}`}>
      <input type="radio" id={`radio-${value}`} {...inputProps} value={value} checked={checked} />
      {label && <label style={{ fontWeight }} htmlFor={`radio-${value}`} dangerouslySetInnerHTML={{ __html: label }} />}
      {children ? (
        <label style={{ fontWeight }} htmlFor={`radio-${value}`}>
          {children}
        </label>
      ) : null}
    </div>
    {description && (
      <div className={`radio-button-hidden ${className || ''}`}>
        <input type="radio" id={`radio-${value}`} {...inputProps} value={value} onChange={null} />
        <label htmlFor={`radio-${value}`} dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    )}
  </div>
);

export default InputRadio;
