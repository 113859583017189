import React from 'react';
import { NavLink } from 'react-router-dom';
import * as _ from 'lodash';
import type { Route } from 'components/next/types';
import './navigation.scss';
import { inject, observer } from 'mobx-react';
import AppStore from 'stores/next/app';
import { chains } from 'components/next/routes';

interface Props {
  appStore?: AppStore;
  type: 'header' | 'pages';
}

const Navigation = ({ appStore, type }: Props) => {
  const className = type === 'pages' ? 'navigation-bar page-selector' : 'navigation-bar';
  const routes = type === 'pages' ? appStore.chainRoute?.pages || [] : chains;
  return (
    <nav className={className}>
      <ul>
        {_.map(routes, (r: Route) =>
          r.hideInNavBar ? null : (
            <li key={r.title}>
              <NavLink
                end={r.exact}
                hidden={r.hideInNavBar}
                className={({ isActive }) => (isActive ? 'navigation-link is-active' : 'navigation-link')}
                to={r.link.replace(/:chain/, appStore.chain)}
              >
                {r.title}
              </NavLink>
            </li>
          ),
        )}
      </ul>
    </nav>
  );
};

export default inject('appStore')(observer(Navigation));
