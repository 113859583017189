import React, { useEffect, useState } from 'react';
import page from 'components/next/pages/page/page';
import { NavLink, Link, Route, Routes, useParams, useNavigate } from 'react-router-dom';
import LanguageSelector from 'components/common/next/languageSelector';
import Dropdown, { DropdownItem } from 'components/next/components/dropdown';
import { FormInput, CustomField, FormTextArea } from 'components/next/components/form/input';
import { Checkbox } from 'components/next/components/checkbox';
import ConceptsStore from 'stores/next/programs';
import { concept as conceptRoute } from 'components/next/routes';
import DeliveryTemplateStore from 'stores/next/deliveryTemplates';
import DeliveriesStore from 'stores/next/deliveries';
import { inject, observer } from 'mobx-react';
import * as date from 'date-fns';
import {
  timestampFormat,
  dateFormat,
  getDeliveryTemplateDefaults,
  duplicateDeliveryTemplate,
  getArchiveDate,
} from 'components/next/utils';
import './concept.scss';
import ChainSelector from 'components/next/components/chainSelector';
import { getConceptDefaults } from 'components/next/utils';
import type {
  Route as NextRoute,
  DeliveryTemplate as DeliveryTemplateEntity,
  DeliveryLanguage,
  Concept,
} from 'components/next/types';
import * as _ from 'lodash';
import DeliveryTemplate from 'components/next/pages/deliveryTemplate';
import { ReactComponent as SurprisedIcon } from '@kesko/icons/mood/icon-mood_surprise.svg';
import { ReactComponent as HappyIcon } from '@kesko/icons/mood/icon-mood_happy.svg';
import { ReactComponent as UnhappyIcon } from '@kesko/icons/mood/icon-mood_unhappy.svg';
import { ReactComponent as CopyIcon } from '@kesko/icons/action/icon-copy.svg';
import { ReactComponent as IconDelete } from '@kesko/icons/action/icon-delete.svg';
import { ReactComponent as IconArrowRight } from '@kesko/icons/nav/icon-arrow-right.svg';
import { ReactComponent as IconUp } from '@kesko/icons/action/icon-push_up.svg';
import { ReactComponent as IconDown } from '@kesko/icons/action/icon-push_down.svg';
import { castDate, getAllowedChains, getAllowedConceptTypes, getExcludedChains } from 'utils/helpers';
import type Api from 'types/next-api';
import PricingGroupStore from '../../../../stores/next/pricingGroup';
import AppStore from '../../../../stores/next/app';
import StoreStore from '../../../../stores/next/stores';
import { ChangeOrderDirection, ConceptType, StoreState } from 'enums/common';
import Spinner from 'components/common/next/spinner';
import Drawer from 'components/retailer/next/components/wrappers/drawer';
import { createEmitAndSemanticDiagnosticsBuilderProgram } from 'typescript';

type ConceptList = Api.Components.Responses.ConceptList;

const promptMessage =
  'Some changes you made to this concept have not been saved. If you move away from this page, these changes will be lost. Are you sure you want to continue?';

interface ConceptProps {
  conceptsStore: ConceptsStore;
  deliveryTemplateStore: DeliveryTemplateStore;
  deliveriesStore: DeliveriesStore;
  pricingGroupStore: PricingGroupStore;
  appStore: AppStore;
  storeStore: StoreStore;
  chainIds: string[];
  getPageLink(route: NextRoute, id: string): string;
}

function onUnload(e) {
  // NOTE: many modern browsers DO NOT support custom refresh messages...
  e.preventDefault();
  e.returnValue = promptMessage;
}

const Concept = ({
  conceptsStore,
  deliveryTemplateStore,
  deliveriesStore,
  pricingGroupStore,
  appStore,
  storeStore,
  chainIds: chainIdsProp,
  getPageLink,
}: ConceptProps) => {
  const { id: idParam, chain: chainParam } = useParams();
  const [archived, setArchived] = useState(false);
  const [changeSet, setChangeSet] = useState(getConceptDefaults(undefined));
  const [isLoading, setIsLoading] = useState(false);
  const [chainIds, setChainIds] = useState(chainIdsProp);
  const [dataReceived, setDataReceived] = useState(false);
  const [editedTemplates, setEditedTemplates] = useState([]);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [guard, setGuard] = useState(null);
  const [simultaneousEditError, setSimultaneousEditError] = useState(false);
  const [language, setLanguage] = useState('fi');
  const [storesExpanded, setStoresExpanded] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [searchResult, setSearchResult] = useState<ConceptList | undefined>();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [sort, setSort] = useState('order');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [selectedConcept, setSelectedConcept] = useState(null);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const navigate = useNavigate();

  // Block routing when there's unsaved changes
  React.useEffect(() => {
    // unload function is for page refresh / back button
    // blocker is for routing inside react router
    if (unsavedChanges) {
      window.addEventListener('beforeunload', onUnload);
    } else {
      window.removeEventListener('beforeunload', onUnload);
    }
  }, [
    // blocker,
    unsavedChanges,
  ]);

  // Get all deliveries made with current delivery templates
  const getDeliveriesMadeWithCurrentTemplates = () => {
    const { deliveryTemplates } = conceptsStore.concept;
    const templateIds = deliveryTemplates.map((t) => t.id);
    const deliveryNumber = deliveriesStore.deliveries.filter((d) => _.includes(templateIds, d.deliveryTemplate));
    return deliveryNumber;
  };

  useEffect(() => {
    const init = async () => {
      await storeStore.searchStores({});

      if (conceptsStore.concept && conceptsStore.concept.id === idParam) {
        setChangeSet(getConceptDefaults(undefined, conceptsStore.concept));
        setDataReceived(true);
        return;
      }

      conceptsStore.findById(parseInt(idParam, 10)).then(() => {
        setChangeSet(getConceptDefaults(undefined, conceptsStore.concept));
        setDataReceived(true);
      });
    };
    init();
    // clear event listener on unmount
    return () => {
      window.removeEventListener('beforeunload', onUnload);
    };
  }, []);

  // If guard is set, clear it on unmount
  useEffect(() => {
    if (guard) {
      return () => {
        window.clearInterval(guard);
      };
    }
  }, [guard]);

  useEffect(() => {
    if (process.env.REACT_APP_COPY_CONCEPTS_ON === 'true' && showDrawer) {
      const conceptsSearch = async () => {
        const concept = getConceptDefaults(undefined, conceptsStore.concept);
        const searchResult = (await conceptsStore.search(
          {
            chainId: chainIds.length
              ? [chainIds[0], ...chainIds.slice(1)]
              : [chainIdsProp[0], ...chainIdsProp.slice(1)],
            type: [concept.type],
            archived,
          },
          { sort, order },
        )) as ConceptList;

        setSearchResult(searchResult);
      };

      conceptsSearch();
    }
  }, [showDrawer]);

  const initGuard = () => {
    const guard = window.setInterval(async () => {
      const result = await conceptsStore.findLastUpdateById(parseInt(changeSet.id, 10));
      if (result) {
        const simultaneousEditError = _.get(result, ['updatedAt']) !== _.get(changeSet, ['updatedAt']);
        setSimultaneousEditError(simultaneousEditError);
      } else {
        console.error(`No corresponding Concept for id ${changeSet.id} found on server`);
      }
    }, 10000);
    setGuard(guard);
  };

  const updateChangeSet = (value: any, fieldName: string) => {
    setChangeSet((prevChangeSet) => {
      const newChangeSet = { ...prevChangeSet };
      _.set(newChangeSet, fieldName, value);
      return newChangeSet;
    });
    setUnsavedChanges(true);
    if (!guard) {
      initGuard();
    }
  };

  const save = async () => {
    const deliveryTemplates = changeSet.deliveryTemplates;
    const newChangeSet = _.omit(changeSet, 'deliveryTemplates', 'conceptStoreAutomaticParticipation');
    let confirmed = true;

    if (simultaneousEditError) {
      confirmed = window.confirm(
        'Someone else has made changes to this Concept while you were working. If you save now, these changes may be overwritten. Are you sure you want to continue?',
      );
    }

    if (!confirmed) {
      return;
    }

    // Check if there are any edited delivery templates
    if (editedTemplates.length > 0) {
      // Check if there have been any deliveries made with the current templates
      if (getDeliveriesMadeWithCurrentTemplates().length > 0) {
        // Check which delivery templates are being drafted
        const draftedTemplates = deliveryTemplates.filter((t) => t.published === false).map((t) => t.id);
        // Check if previous deliveries have been made with the edited templates
        const previousDeliveriesWithEditedTemplates = deliveriesStore.deliveries.filter((d) =>
          _.includes(draftedTemplates, d.deliveryTemplate),
        );

        if (previousDeliveriesWithEditedTemplates.length > 0) {
          confirmed = window.confirm(
            'Deliveries have already been made with the edited delivery template. Are you sure you want to change the status to draft?',
          );
        }
      }
    }

    if (
      conceptsStore.concept.conceptStoreAutomaticParticipation.length > 0 &&
      newChangeSet.type !== ConceptType.Program
    ) {
      confirmed = window.confirm(
        'Retailers have already opted in for automatic deliveries for this concept. Are you sure you want to change the type from Program?',
      );
    }

    if (!confirmed) {
      return;
    }

    // Check if there are any content templates with empty content slots
    const mappedEditedTemplates = deliveryTemplates.filter((t) => editedTemplates.includes(t.id) || !t.id);
    const editedContentTemplates = mappedEditedTemplates.filter((t) => t.contentTemplates.length > 0);
    const contentTemplatesWithEmptyContentSlots = editedContentTemplates.filter((t) =>
      t.contentTemplates.some((c) => c.contentSlots.some((s) => s.contentBlocks.length === 0)),
    );
    if (contentTemplatesWithEmptyContentSlots.length > 0) {
      confirmed = window.confirm(
        'Some of the content templates for this delivery template contain empty content slots. Are you sure you want to save?',
      );
      if (!confirmed) {
        return;
      }
    }

    try {
      clearInterval(guard);

      if (newChangeSet.type !== ConceptType.Program) {
        _.set(newChangeSet, 'maxTemplateParticipations', -1);
        _.set(newChangeSet, 'allowAutomaticParticipation', false);
      }
      // If concept is already archived, but new template is added, return concept to unarchived
      if (newChangeSet.archived) {
        // If there are any delivery template under 14 months from last_start_date, return concept to active
        if (deliveryTemplates.some((t) => t.lastStartDate > getArchiveDate())) {
          _.set(newChangeSet, 'archived', false);
        }
      }

      await conceptsStore.replaceConcept(newChangeSet);
      const savedDeliveryTemplates = await deliveryTemplateStore.batchSaveDeliveryTemplates(
        deliveryTemplates.filter((t) => editedTemplates.includes(t.id) || !t.id),
      );

      const updatedDeliveryTemplates = [...conceptsStore.concept.deliveryTemplates];
      savedDeliveryTemplates.forEach((savedTemplate) => {
        const index = _.findIndex(updatedDeliveryTemplates, (template) => template.id == savedTemplate.id);
        if (index < 0) {
          updatedDeliveryTemplates.push(savedTemplate);
        } else {
          updatedDeliveryTemplates[index] = savedTemplate;
        }
      });

      const updatedConcept = { ...conceptsStore.concept, deliveryTemplates: updatedDeliveryTemplates };
      conceptsStore.setConcept(updatedConcept);

      setEditedTemplates([]);
      setChangeSet(getConceptDefaults(undefined, conceptsStore.concept));
      setUnsavedChanges(false);
      setGuard(null);
      setSimultaneousEditError(false);
      pricingGroupStore.searchPricingGroups({});
    } catch (err) {
      console.error(err);
      conceptsStore.status = StoreState.error;
    }
  };

  const handleChainChange = (key: string) => {
    const chainIds = [...changeSet.chainIds];
    if (chainIds.includes(key) && chainIds.length > 1) {
      _.pullAt(chainIds, chainIds.indexOf(key));
    } else {
      chainIds.push(key);
    }
    updateChangeSet(chainIds, 'chainIds');
  };

  const markDeliveryTemplateEdited = (template: DeliveryTemplateEntity) => {
    const newEditedTemplates = [...editedTemplates];
    if (!newEditedTemplates.includes(template.id)) {
      newEditedTemplates.push(template.id);
      setEditedTemplates(newEditedTemplates);
    }
    if (!changeSet.deliveryTemplates) {
      return;
    }
    const deliveryTemplates = [...changeSet.deliveryTemplates];
    const index = _.findIndex(deliveryTemplates, (t) => t.id === template.id);
    deliveryTemplates[index] = template;
    updateChangeSet(deliveryTemplates, 'deliveryTemplates');
  };

  const addDeliveryTemplate = () => {
    const newChangeSet = { ...changeSet };
    newChangeSet.deliveryTemplates.push(
      getDeliveryTemplateDefaults(null, newChangeSet.id, newChangeSet.type as ConceptType),
    );
    setChangeSet(newChangeSet);
    save();
    updateChangeSet(conceptsStore.concept.archived, 'archived');
  };

  const deleteDeliveryTemplate = (id: string) => {
    const confirmed = window.confirm(
      'Are you sure you want to delete this delivery template? This action cannot be undone.',
    );
    if (confirmed) {
      deliveryTemplateStore.deleteDeliveryTemplate(id).then(() => {
        conceptsStore.findById(parseInt(changeSet.id, 10));
        const deliveryTemplates = changeSet.deliveryTemplates;
        updateChangeSet([...deliveryTemplates.filter((t) => t.id !== id)], 'deliveryTemplates');
      });
    }
  };

  const parseNumber = (value: any) => {
    const parsed = Number(value);
    if (!isNaN(parsed)) {
      return parsed;
    }
    return '';
  };

  const changeOrder = async (id: string, direction: ChangeOrderDirection) => {
    try {
      const result = await deliveryTemplateStore.changeDeliveryTemplateOrder(id, { direction });
      await conceptsStore.findById(parseInt(changeSet.id, 10));
    } catch (error) {
      console.error(error);
    }
  };

  const getConcepts = () => {
    return searchResult ? searchResult.result : [];
  };

  const dropdownConcepts = getConcepts().map((c, i) => ({
    name: c.title.fi,
    value: i,
    id: c.id,
  }));

  const handleConceptSelect = (selectedValue) => {
    const selectedItem = dropdownConcepts[selectedValue];
    setSelectedConcept(selectedItem);
    setSelectedIndex(selectedValue);
  };

  const handleDuplicateDeliveryTemplate = (id: string) => {
    if (process.env.REACT_APP_COPY_CONCEPTS_ON === 'true') {
      setSelectedTemplateId(id);
      setShowDrawer(true);
    } else {
      handleCopyTemplate(id);
    }
  };

  const handleCopyTemplate = async (id: string, concept?: Concept) => {
    setIsLoading(true);
    const original: DeliveryTemplateEntity = await deliveryTemplateStore.getTemplateById(id);
    let payload = null;

    if (process.env.REACT_APP_COPY_CONCEPTS_ON === 'true' && concept) {
      payload = duplicateDeliveryTemplate(
        original,
        conceptsStore.concept.type as ConceptType,
        concept,
      ) as DeliveryTemplateEntity;
    } else {
      payload = duplicateDeliveryTemplate(
        original,
        conceptsStore.concept.type as ConceptType,
      ) as DeliveryTemplateEntity;
    }

    try {
      const result = await deliveryTemplateStore.createDeliveryTemplate(payload);
      const conceptId = concept ? concept.id : changeSet.id;

      if (result.status === 201) {
        await conceptsStore.findById(parseInt(conceptId, 10));
      }

      const deliveryTemplates = changeSet.deliveryTemplates;
      setChangeSet(getConceptDefaults(undefined, conceptsStore.concept));
      updateChangeSet([...deliveryTemplates, result.data], 'deliveryTemplates');
      updateChangeSet(conceptsStore.concept.archived, 'archived');
      if (concept) {
        navigate(getPageLink(conceptRoute, concept.id), { replace: true });
      }
    } catch (error) {
      console.error(error);
    }
    if (process.env.REACT_APP_COPY_CONCEPTS_ON === 'true') {
      setShowDrawer(false);
      setSelectedIndex(-1);
      setSelectedConcept(null);
    }
    setUnsavedChanges(false);
    setIsLoading(false);
  };

  const handleCloseDrawer = () => {
    setShowDrawer(false);
  };

  const renderEditorStatus = () => {
    let message = 'All changes saved!';
    let icon = <HappyIcon />;
    let statusClass = 'ok';

    if (unsavedChanges) {
      message = 'There are unsaved changes!';
      statusClass = 'warning';
      icon = <SurprisedIcon />;
    }

    if (simultaneousEditError) {
      message = 'Someone has made changes that may conflict with yours';
      statusClass = 'error';
      icon = <UnhappyIcon />;
    }

    if (conceptsStore.status === StoreState.error || deliveryTemplateStore.status === StoreState.error) {
      message = 'Error saving changes';
      statusClass = 'error';
      icon = <UnhappyIcon />;
    }

    return (
      <div className={`editor-status ${statusClass}`}>
        <span>
          {message} {icon}
        </span>
      </div>
    );
  };

  const renderHeader = () => {
    const concept = getConceptDefaults(undefined, conceptsStore.concept);
    if (!dataReceived) {
      return '';
    }
    return (
      <header className="editor-header">
        <div className="editor-header__controls">
          <NavLink className="go-back" to=".." relative="path">
            <img src={require('images/arrow-back.svg').default} alt="back" />
          </NavLink>
          <div className="editor-header__details">
            <h3>{concept.title.fi}</h3>
            <h4>
              <span>ID: {concept.id}</span>
              <span>Title: {concept.title.fi}</span>
              <span>Last saved: {date.format(castDate(concept.updatedAt), timestampFormat)}</span>
              <span>Status: {changeSet.published ? 'published' : 'draft'}</span>
            </h4>
          </div>
          <div className="editor-header__actions">
            {renderEditorStatus()}
            <Dropdown
              data={[
                { name: 'Draft', value: false },
                { name: 'Published', value: true },
              ]}
              notSelectedText="Concept status"
              selectedItem={changeSet.published ? { name: 'Published', value: true } : { name: 'Draft', value: false }}
              select={(value) => updateChangeSet(value, 'published')}
            />
            <button className="save" onClick={(e) => save()}>
              Save
            </button>
          </div>
        </div>
        <div className="editor-header__details" />
      </header>
    );
  };

  const renderDeliveryTemplateTable = () => {
    const { deliveryTemplates } = conceptsStore.concept;
    if (!deliveryTemplates || deliveryTemplates.length === 0) {
      return '';
    }

    return (
      <table className="styled editor-grid">
        <thead>
          <tr>
            <th>
              <input type="checkbox" />
            </th>
            <th>Title</th>

            <th>
              Signup
              <br />
              starts
            </th>
            <th>
              Signup
              <br />
              ends
            </th>

            <th>
              First
              <br />
              delivery
            </th>

            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {_.orderBy(deliveryTemplates, 'order').map((t: DeliveryTemplateEntity, i: number) => (
            <tr key={i}>
              <td>
                <input type="checkbox" />
              </td>
              <td>
                <NavLink to={`delivery-templates/${t.id}`} title={t.title.fi}>
                  {t.title.fi}
                </NavLink>
              </td>
              <td className="nowrap">{date.format(castDate(t.publishDate), dateFormat)}</td>
              <td className="nowrap">{date.format(castDate(t.deadline), dateFormat)}</td>
              <td className="nowrap">{date.format(castDate(t.firstStartDate), dateFormat)}</td>
              <td className="nowrap">{t.status}</td>
              <td className="nowrap">
                <div className="buttons">
                  <button
                    className="copy-button"
                    onClick={() => changeOrder(t.id, ChangeOrderDirection.Up)}
                    title="Move up"
                    disabled={i === 0}
                  >
                    <IconUp fill={i === 0 ? '#e2e3e3' : ''} stroke={i === 0 ? '#e2e3e3' : ''} />
                  </button>
                  <button
                    className="copy-button"
                    onClick={() => changeOrder(t.id, ChangeOrderDirection.Down)}
                    title="Move down"
                    disabled={i === deliveryTemplates.length - 1}
                  >
                    <IconDown
                      fill={i === deliveryTemplates.length - 1 ? '#e2e3e3' : ''}
                      stroke={i === deliveryTemplates.length - 1 ? '#e2e3e3' : ''}
                    />
                  </button>
                  <button
                    className="copy-button"
                    onClick={() => handleDuplicateDeliveryTemplate(t.id)}
                    title="Duplicate template"
                  >
                    <CopyIcon />
                  </button>
                  <button
                    className="delete-delivery-template"
                    onClick={() => deleteDeliveryTemplate(t.id)}
                    title="Delete template"
                  >
                    <IconDelete />
                  </button>
                  <Link to={`delivery-templates/${t.id}`} title="View template">
                    <IconArrowRight />
                  </Link>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const toggleSelections = () => {
    setStoresExpanded(!storesExpanded);
  };

  const Content = () => {
    const storeIdsParticipatingAutomatically = conceptsStore.concept.conceptStoreAutomaticParticipation
      ? conceptsStore.concept.conceptStoreAutomaticParticipation.map((csap) => csap.store)
      : [];
    const automaticallyParticipatingStores = storeStore.stores.filter((store) =>
      storeIdsParticipatingAutomatically.includes(store.storeId),
    );

    if (!dataReceived || isLoading) {
      return <Spinner />;
    }

    const types: DropdownItem[] = getAllowedConceptTypes(appStore.chain).map((k) => ({ name: k, value: k }));
    const selectedType = types.find((t) => t.value === changeSet.type);
    return (
      <div className="content">
        <section className="editor-section general">
          <header className="editor-section__header">
            <div className="title-row">
              <h3 className="section-title">General</h3>
              <LanguageSelector
                language={language}
                languages={['fi', 'sv']}
                toggleLanguage={(language: DeliveryLanguage) => setLanguage(language)}
              />
            </div>
          </header>
          <div className="input-field">
            <label>Concept type</label>
            <Dropdown
              data={types}
              notSelectedText="Concept Type"
              selectedItem={selectedType}
              select={(value) => updateChangeSet(value, 'type')}
            />
          </div>
          <FormInput
            type="text"
            label={`Title - ${language}`}
            value={changeSet.title[language] || ''}
            handleChange={(e) => updateChangeSet(e.target.value, `title.${language}`)}
            required
          />
          <FormTextArea
            label={`Short description - ${language}`}
            value={changeSet.shortDescription[language] || ''}
            handleChange={(e) => updateChangeSet(e.target.value, `shortDescription.${language}`)}
          />
          <FormTextArea
            label={`Long description - ${language}`}
            value={changeSet.description[language] || ''}
            handleChange={(e) => updateChangeSet(e.target.value, `description.${language}`)}
          />
          <CustomField>
            <ChainSelector
              chainSelection={getAllowedChains(appStore.chain).filter((chain) => changeSet.chainIds.includes(chain))}
              excludeChains={getExcludedChains(getAllowedChains(appStore.chain))}
              handleChainChange={handleChainChange}
              label="Chain(s)"
              detail="Pick chains for deliveries"
            />
          </CustomField>
          {changeSet.type === ConceptType.Program && (
            <div>
              <CustomField>
                <Checkbox
                  id="automatic-participation"
                  label="Allow automatic participation for this concept"
                  checked={changeSet.allowAutomaticParticipation || false}
                  handleClick={(e) => {
                    updateChangeSet(e.target.checked, 'allowAutomaticParticipation');
                  }}
                  disabled={conceptsStore.concept.conceptStoreAutomaticParticipation.length > 0 ? true : false}
                />
              </CustomField>
              <CustomField>
                <Checkbox
                  id="print-recipient-halving"
                  label="Halve the number of print recipients"
                  checked={changeSet.halvePrintRecipientCount || false}
                  handleClick={(e) => {
                    updateChangeSet(e.target.checked, 'halvePrintRecipientCount');
                  }}
                />
              </CustomField>
              <CustomField>
                <label>Participating stores</label>
                <ul>
                  {!storesExpanded
                    ? _.take(_.orderBy(automaticallyParticipatingStores, 'name'), 3).map(({ storeId, name }) => (
                        <li key={storeId}>{name}</li>
                      ))
                    : _.orderBy(automaticallyParticipatingStores, 'name').map(({ storeId, name }) => (
                        <li key={storeId}>{name}</li>
                      ))}
                  {automaticallyParticipatingStores.length > 3 && !storesExpanded ? (
                    <li>
                      <strong>{automaticallyParticipatingStores.length - 3}</strong> other stores.
                    </li>
                  ) : null}
                  {automaticallyParticipatingStores.length > 3 && (
                    <strong className="button" onClick={toggleSelections}>
                      {storesExpanded ? 'Show less' : `Show all ${automaticallyParticipatingStores.length} stores`}
                    </strong>
                  )}
                </ul>
              </CustomField>
            </div>
          )}
          {changeSet.type === ConceptType.Program && (
            <CustomField>
              <FormInput
                label="Max different delivery participations"
                type="number"
                value={_.get(changeSet, 'maxTemplateParticipations', -1)}
                min={-1}
                max={1000}
                isInvalid={
                  changeSet.maxTemplateParticipations < -1 || isNaN(changeSet.maxTemplateParticipations) ? true : false
                }
                handleChange={(e) => updateChangeSet(parseNumber(e.target.value), 'maxTemplateParticipations')}
                detail="Maximum number of different deliveries retailers are allowed to participate, set to -1 for no maximum"
              />
            </CustomField>
          )}
        </section>
        <section className="editor-section delivery-templates">
          <header>
            <h3>Delivery templates</h3>
            <button className="add-delivery-template" onClick={() => addDeliveryTemplate()}>
              Add delivery template
              <img src={require('images/add.svg').default} alt="add" />
            </button>
          </header>
          {renderDeliveryTemplateTable()}
          {process.env.REACT_APP_COPY_CONCEPTS_ON === 'true' && (
            <Drawer open={showDrawer} className="drawer-pane" onClose={handleCloseDrawer}>
              <div className="drawer-options">
                <button className="copy-here" onClick={() => handleCopyTemplate(selectedTemplateId)}>
                  Copy here
                </button>
              </div>
              <div className="drawer-options">
                <p>Copy the template to another concept: </p>
                <Dropdown
                  data={dropdownConcepts}
                  notSelectedText="Choose Concept"
                  selectedItem={dropdownConcepts[selectedIndex]}
                  select={(value) => handleConceptSelect(value)}
                />
                <button className="copy-here" onClick={() => handleCopyTemplate(selectedTemplateId, selectedConcept)}>
                  Copy to this location
                </button>
              </div>
            </Drawer>
          )}
        </section>
      </div>
    );
  };

  if (!dataReceived) {
    return <Spinner />;
  }

  if (!conceptsStore.concept || !changeSet.chainIds) {
    return null;
  }

  // const renderConfirmation = () => {
  //   if (blocker) {
  //     if (blocker.state === 'blocked') {
  //       const confirmed = window.confirm(promptMessage);
  //       if (confirmed) {
  //         blocker.proceed?.();
  //       } else {
  //         blocker.reset?.();
  //       }
  //     }
  //   }
  // };

  return (
    <React.Fragment>
      {/* {renderConfirmation()} */}
      <div className="program">
        {renderHeader()}
        <Routes>
          <Route
            path="delivery-templates/:id"
            element={
              <div className="delivery-template-editor-overlay">
                <DeliveryTemplate
                  informListPage={markDeliveryTemplateEdited}
                  editedTemplates={editedTemplates}
                  unsavedChanges={unsavedChanges}
                  chainIds={changeSet.chainIds}
                />
              </div>
            }
          />
          <Route
            path="delivery-templates/:id/:tab"
            element={
              <div className="delivery-template-editor-overlay">
                <DeliveryTemplate
                  informListPage={markDeliveryTemplateEdited}
                  editedTemplates={editedTemplates}
                  unsavedChanges={unsavedChanges}
                  chainIds={changeSet.chainIds}
                />
              </div>
            }
          />
          <Route path="*" element={Content()} />
        </Routes>
      </div>
    </React.Fragment>
  );
};

export default page(
  inject(
    'conceptsStore',
    'deliveryTemplateStore',
    'deliveriesStore',
    'pricingGroupStore',
    'appStore',
    'storeStore',
  )(observer(Concept)),
);
