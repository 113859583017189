import React, { useEffect, useState } from 'react';
import _ from 'utils/lodash';
import { inject, observer } from 'mobx-react';
import { getLink } from '../../../../routes';
import DrawerContent from '../../../wrappers/drawerContent';
import OfferEditor, { Props as OfferEditorProps } from 'components/common/next/offerEditor';
import Button from '../../../../../../common/next/form/button';
import AlertStore from 'stores/next/alerts';
import './offerForm.scss';
import TemplateStore from 'stores/next-retailer/templateStore';
import DeliveryStore, { checkDeliveryOffer } from 'stores/next-retailer/deliveryStore';
import { toJS } from 'mobx';
import { isLockedOffer, isRecommendedOffer } from '../sections/offersBox';
import { OfferEditorMode } from 'components/next/utils';
import type { DeliveryOffer, Offer } from 'types/next';
import UserStore from '../../../../../../../stores/userStore';
import ConceptStore from 'stores/next-retailer/conceptStore';
import { ConceptType } from 'enums/common';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from 'components/common/next/spinner';

interface Props {
  templateStore?: TemplateStore;
  deliveryStore?: DeliveryStore;
  alertStore?: AlertStore;
  userStore?: UserStore;
  conceptStore?: ConceptStore;
}

const OfferForm = ({ templateStore, deliveryStore, alertStore, userStore, conceptStore }: Props) => {
  const navigate = useNavigate();
  const { templateId: templateIdParam, deliveryId: deliveryIdParam, offerId: offerIdParam } = useParams();
  const [offer, setOffer] = useState<Offer>();
  const [isOfferValid, setIsOfferValid] = useState(false);

  const isNew = () => {
    return deliveryIdParam === 'new';
  };

  const getDelivery = () => {
    // use current delivery
    return deliveryStore.current;
  };

  const getTemplate = () => {
    return _.find(templateStore.templates, { id: templateIdParam });
  };

  const getConcept = () => {
    const { concept } = getTemplate();
    return _.find(conceptStore.concepts, { id: concept });
  };

  const getConceptType = () => {
    return getConcept().type as ConceptType;
  };

  const isReady = () => {
    return getTemplate() && getConcept() && getDelivery();
  };

  const getInitialOffer = () => {
    return _.find(getDelivery().deliveryOffers, { id: offerIdParam });
  };

  const getOfferOptions = () => {
    return getTemplate().offerOptions;
  };

  const hasEmailChannel = () => {
    const channelNames = getTemplate().channels.map((c) => c.name);
    return _.includes(channelNames, 'email') && _.find(getTemplate().contentTemplates, { channel: 'email' });
  };

  const hasPrintChannel = () => {
    const channelNames = getTemplate().channels.map((c) => c.name);
    return _.includes(channelNames, 'print') && _.find(getTemplate().contentTemplates, { channel: 'print' });
  };

  const back = () => {
    navigate(getLink('editDelivery', deliveryIdParam, templateIdParam), { state: { disableNotification: true } });
  };

  useEffect(() => {
    if (!getDelivery() || !getConcept() || !getTemplate()) {
      back();
    }
    if (!isNew() && getDelivery().id !== deliveryIdParam) {
      back();
    }
  }, []);

  const save = async () => {
    const channels = getDelivery().targetGroup.channels;
    const channel = channels.email === -1 ? 'email' : 'print';
    const { deliveryOffers } = getDelivery();
    const { valid } = await checkDeliveryOffer(offer);

    if (!valid) {
      return;
    }

    if (offerIdParam === 'new') {
      deliveryOffers.push(offer);
    } else {
      const replace = _.findIndex(deliveryOffers, { id: offerIdParam });
      deliveryOffers[replace] = offer;
    }

    if (hasEmailChannel() || hasPrintChannel()) {
      alertStore.success({
        message: 'Muutokset hyväksytty',
        link: `preview/delivery/${channel}/new`,
      });
    } else {
      alertStore.success({
        message: 'Muutokset hyväksytty',
      });
    }
    back();
  };

  const setOfferValidity = (isOfferValid: boolean) => {
    setIsOfferValid(isOfferValid);
  };

  const chooseOfferEditMode = (offer: DeliveryOffer): OfferEditorMode => {
    if (isLockedOffer(offer, getTemplate().offerOptions)) {
      return OfferEditorMode.VIEW;
    }
    return isRecommendedOffer(offer, getTemplate().offerOptions) ? OfferEditorMode.CHANGE : OfferEditorMode.EDIT;
  };

  const renderHeader = () => {
    return <h2>Muokkaa tuotenostoa</h2>;
  };

  const renderFooter = () => {
    return (
      <div className="offer-form-footer">
        <div>&nbsp;</div>
        <div>
          <Button color="bordered" onClick={back}>
            Peruuta
          </Button>
          <Button onClick={save} disabled={!isOfferValid}>
            Tallenna
          </Button>
        </div>
      </div>
    );
  };

  if (!isReady()) {
    return <Spinner />;
  }

  const render = () => {
    const template = getTemplate();
    const { delivery } = deliveryStore;
    const initialOffer = getInitialOffer();

    const offerEditorProps: OfferEditorProps = {
      onOfferChange: setOffer,
      onValidityChange: setOfferValidity,
      offerOptions: getOfferOptions(),
      disableProductOffers: template.offerOpts.disableProductOffers,
      disableWebstoreOffers: template.offerOpts.disableWebstoreOffers,
      disableBasketOffers: template.offerOpts.disableBasketOffers,
      requiredRecommendations: template.offerOpts.requiredRecommendations,
      allowCustomOffers: template.offerOpts.allowOwn,
      editorMode: offerIdParam === 'new' ? OfferEditorMode.EDIT : chooseOfferEditMode(initialOffer),
      chain: userStore.me.chainId,
      conceptType: getConceptType(),
    };

    if (offerIdParam !== 'new' && initialOffer) {
      offerEditorProps.initialOffer = toJS(initialOffer) as Offer;
    }
    // don't allow activation links if there is no email selected
    if (delivery.targetGroup.channels.email === 0) {
      offerEditorProps.activationLinkOverride = false;
    }

    return (
      <DrawerContent className="offer-form" renderHeader={renderHeader} renderFooter={renderFooter}>
        <OfferEditor {...offerEditorProps} />
      </DrawerContent>
    );
  };

  return render();
};

export default inject('templateStore', 'deliveryStore', 'alertStore', 'userStore', 'conceptStore')(observer(OfferForm));
