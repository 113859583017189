import React from 'react';
import './sidebar.scss';

interface Props {
  children: JSX.Element | JSX.Element[];
  renderSidebar: () => any;
}

const SidebarWrapper = ({ children, renderSidebar, ...props }: Props) => (
  <div className="sidebar-container">
    <aside className="sidebar" {...props}>
      {renderSidebar()}
    </aside>
    {children}
  </div>
);

export default SidebarWrapper;
