import React from 'react';
import type { Offer, OfferOption, OfferOptionUsageCount } from 'components/next/types';
import * as dateFns from 'date-fns';
import * as _ from 'lodash';
import * as YAML from 'js-yaml';
import OfferEditor from 'components/common/next/offerEditor';
import Button from 'components/retailer/next/components/../../../common/next/form/button';
import ToggleButton from 'components/common/next/form/toggleButton';
import { CustomField, DateField, FormInput } from 'components/next/components/form/input';
import { ReactComponent as IconUp } from '@kesko/icons/action/icon-push_up.svg';
import { ReactComponent as IconDown } from '@kesko/icons/action/icon-push_down.svg';
import { ReactComponent as IconDelete } from '@kesko/icons/action/icon-delete.svg';
import './offerOptions.scss';
import { imageSrc } from 'utils/helpers';
import { OfferEditorMode, payloadDateFormat } from 'components/next/utils';
import { inject } from 'mobx-react';
import DeliveryStore from 'stores/next-retailer/deliveryStore';
import { ConceptType } from 'enums/common';

interface OfferOptionsProps {
  offerOptions: OfferOption[];
  offerOpts: any;
  conceptType: ConceptType;
  firstStartDate: string;
  handleSimpleValueChange(value, fieldName: string): void;
  update(c: OfferOption[]);
}

interface InjectedProps extends OfferOptionsProps {
  deliveryStore: DeliveryStore;
}

interface OfferOptionsState {
  offer?: OfferOption;
  editingOffer?: OfferOption;
  editorOpen: boolean;
  offerOptionsUsageCount: OfferOptionUsageCount | undefined;
}

const enhancedHandleSimpleValueChange = (
  value: string | number | boolean,
  previousValue: string | number | boolean,
  fieldName: string,
  props: OfferOptionsProps,
  handleSimpleValueChange: (value: string | number | boolean, fieldName: string) => void,
): void => {
  const { offerOpts, firstStartDate, conceptType } = props;

  if (conceptType === ConceptType.Program) {
    if (fieldName === 'offerOpts.endDate') {
      const newEndDate = new Date(value as string);
      const dateDiff = dateFns.differenceInDays(newEndDate, new Date(previousValue as string));
      handleSimpleValueChange(offerOpts.defaultLength + dateDiff, 'offerOpts.defaultLength');
    }
    if (fieldName === 'offerOpts.defaultLength') {
      if (isNaN(value as number) || Number(value) < 1) {
        return;
      }
      const startDate = new Date(firstStartDate);
      const newEndDate = dateFns.addDays(startDate, (value as number) - 1);
      handleSimpleValueChange(dateFns.format(newEndDate, payloadDateFormat), 'offerOpts.endDate');
    }
  } else if (conceptType === ConceptType.Season) {
    // this is useful only for templates that have been created with older K Markkinointi versions allowing admin to
    // set the offer start and end dates. Changes to default length removes offer dates
    if (fieldName === 'offerOpts.defaultLength') {
      handleSimpleValueChange(undefined, 'offerOpts.startDate');
      handleSimpleValueChange(undefined, 'offerOpts.endDate');
    }
  }

  handleSimpleValueChange(value, fieldName);
};

@inject('deliveryStore')
export default class OfferOptions extends React.Component<OfferOptionsProps, OfferOptionsState> {
  constructor(props: OfferOptionsProps) {
    super(props);
    this.state = {
      offer: undefined,
      editorOpen: false,
      offerOptionsUsageCount: undefined,
    };
  }

  get injected() {
    return this.props as InjectedProps;
  }

  get deliveryStore() {
    return this.injected.deliveryStore;
  }

  async componentDidMount() {
    if (this.props.offerOptions.length > 0) {
      const ids = this.props.offerOptions.map((opt) => opt.id);
      const offerOptionsUsageCount = await this.injected.deliveryStore.getOfferOptionUseCount(ids);
      this.setState({ offerOptionsUsageCount });
    }
  }

  openEditor = () => {
    this.setState({ editorOpen: false }, () => this.setState({ editorOpen: true }));
  };

  closeEditor = () => {
    this.setState({ editorOpen: false, editingOffer: null });
  };

  new = () => {
    this.setState({ editingOffer: null });
    this.openEditor();
  };

  delete = (id: string) => {
    const { update, offerOptions } = this.props;
    const confirmed = window.confirm('Are you sure you want to delete this offer?');
    if (confirmed) {
      update(_.reject(offerOptions, { id }));
    }
  };

  edit = (id: string) => {
    const { offerOptions } = this.props;
    const offer = _.find(offerOptions, { id });
    this.setState({ editingOffer: offer });
    this.openEditor();
  };

  save = () => {
    const { offerOptions } = this.props;
    const offerOption = this.state.offer;
    const update = _.findIndex(offerOptions, { id: offerOption.id });
    if (update !== -1) {
      offerOptions[update] = offerOption;
    } else {
      offerOption.default = true;
      offerOptions.push(offerOption);
    }
    this.props.update(offerOptions);
    this.closeEditor();
  };

  changeOrder = (offer: OfferOption, shiftUp: boolean) => {
    const { offerOptions, update } = this.props;
    const currentOrder = offer.order;
    const adjacentOrder = shiftUp ? currentOrder - 1 : currentOrder + 1;
    const adjacentOffer = offerOptions.find((o) => o.order === adjacentOrder);
    if (!adjacentOffer) {
      return;
    }

    update(
      offerOptions.map((o) => {
        if (o.id === offer.id) {
          o.order = adjacentOrder;
        }
        if (o.id === adjacentOffer.id) {
          o.order = currentOrder;
        }
        return o;
      }),
    );
  };

  toggleOfferOpt = (id: string, path: string) => {
    return (value) => {
      const { offerOptions } = this.props;
      const offerOption = _.find(offerOptions, { id });
      _.set(offerOption, path, !value);
      this.props.update(offerOptions);
    };
  };

  renderOffer = (o: OfferOption) => {
    const { editingOffer } = this.state;

    if (editingOffer && o.id === editingOffer.id) {
      return null;
    }

    const allowedToDelete = (offerOptionId: string) => {
      if (this.state.offerOptionsUsageCount === undefined) {
        // return false as long as we don't know the counts
        return false;
      }
      const offerOptionCount = this.state.offerOptionsUsageCount.find(
        (optUsage) => optUsage.offerOptionId === offerOptionId,
      );
      // not allowing to delete offer options in use. If count = 0 or not found, ok to delete
      return offerOptionCount ? offerOptionCount.count === 0 : true;
    };

    return (
      <section key={o.id} className="editor-section offer-option">
        <header className="editor-section__header">
          <div className="title-row">
            <h3>{o.title.fi}</h3>
            <div className="title-row__buttons">
              <button onClick={() => this.changeOrder(o, false)}>
                <IconDown />
              </button>
              <button onClick={() => this.changeOrder(o, true)}>
                <IconUp />
              </button>
              <button
                className="delete-offer delete-button"
                disabled={!allowedToDelete(o.id)}
                onClick={() => this.delete(o.id)}
                title={
                  allowedToDelete(o.id)
                    ? 'Remove offer from the template'
                    : `Offer is already in use and can't be deleted`
                }
              >
                <IconDelete />
              </button>
            </div>
          </div>
        </header>
        <div className="editor-section__content">
          <div className="offer-image">
            {o.image && <img src={imageSrc(o.image, { h: 200, fm: 'png' })} alt="offer" />}
          </div>
          <div className="offer-details">
            <pre>
              {YAML.dump(
                _.omitBy(
                  {
                    ..._.omit(o, [
                      'id',
                      'type',
                      'title',
                      'createTosOffer',
                      'description',
                      'image',
                      'products',
                      'order',
                      'default',
                      'required',
                      'reimbursement',
                    ]),
                    eans: o.products && _.map(o.products, 'ean'),
                  },
                  _.isNil,
                ),
              )}
            </pre>
          </div>
          <div className="offer-meta">
            <CustomField additionalClasses="half-wide" label="Pre-selected?">
              <ToggleButton
                isSelected={o.default}
                selectedText="yes"
                notSelectedText="no"
                value={o.default}
                toggle={this.toggleOfferOpt(o.id, 'default')}
              />
            </CustomField>
            <CustomField additionalClasses="half-wide" label="Locked?">
              <ToggleButton
                isSelected={o.required}
                selectedText="yes"
                notSelectedText="no"
                value={o.required}
                toggle={this.toggleOfferOpt(o.id, 'required')}
              />
            </CustomField>
          </div>
          <div className="actions">
            <Button color="bordered" onClick={() => this.edit(o.id)}>
              Change
            </Button>
          </div>
        </div>
      </section>
    );
  };

  render() {
    const { offerOptions, offerOpts, firstStartDate, handleSimpleValueChange, conceptType } = this.props;
    const { editorOpen, editingOffer } = this.state;

    const startDate = firstStartDate && conceptType === ConceptType.Program ? new Date(firstStartDate) : null;
    const endDate =
      startDate && conceptType === ConceptType.Program ? dateFns.addDays(startDate, offerOpts.defaultLength - 1) : null;

    return (
      <div className="editor-tab content-fields">
        <button className="add-content-field" onClick={this.new}>
          Add offer
          <img src={require('images/add.svg').default} alt="add" />
        </button>

        <section className="editor-section">
          <h3 className="section-title">Offer options</h3>
          <FormInput
            type="number"
            label="Min"
            handleChange={(e) => handleSimpleValueChange(parseInt(e.target.value, 10), 'offerOpts.min')}
            additionalClasses="half-wide"
            value={`${offerOpts.min}`}
            detail="Minimum allowed amount of offers"
          />
          <FormInput
            type="number"
            label="Max"
            handleChange={(e) => handleSimpleValueChange(parseInt(e.target.value, 10), 'offerOpts.max')}
            additionalClasses="half-wide"
            value={`${offerOpts.max}`}
            detail="Maximum allowed amount of offers"
          />
          <FormInput
            type="number"
            label="Offer length"
            handleChange={(e) =>
              enhancedHandleSimpleValueChange(
                parseInt(e.target.value, 10),
                offerOpts.defaultLength,
                'offerOpts.defaultLength',
                this.props,
                handleSimpleValueChange,
              )
            }
            value={`${offerOpts.defaultLength}`}
            additionalClasses="half-wide"
          />
          <FormInput
            type="number"
            label="Number of required recommendations"
            handleChange={(e) =>
              handleSimpleValueChange(parseInt(e.target.value, 10), 'offerOpts.requiredRecommendations')
            }
            value={`${offerOpts.requiredRecommendations}`}
            additionalClasses="half-wide"
          />
          <CustomField additionalClasses="half-wide" label="Allow own offers">
            <ToggleButton
              isSelected={offerOpts.allowOwn}
              selectedText="yes"
              notSelectedText="no"
              value={offerOpts.allowOwn}
              toggle={(v) => handleSimpleValueChange(!offerOpts.allowOwn, 'offerOpts.allowOwn')}
            />
          </CustomField>
          <CustomField label="Disable product offers for all retailers">
            <ToggleButton
              isSelected={offerOpts.disableProductOffers}
              selectedText="yes"
              notSelectedText="no"
              value={offerOpts.disableProductOffers}
              toggle={(v) => handleSimpleValueChange(!offerOpts.disableProductOffers, 'offerOpts.disableProductOffers')}
            />
          </CustomField>
          <CustomField label="Disable web store offers for all retailers">
            <ToggleButton
              isSelected={offerOpts.disableWebstoreOffers}
              selectedText="yes"
              notSelectedText="no"
              value={offerOpts.disableWebstoreOffers}
              toggle={(v) =>
                handleSimpleValueChange(!offerOpts.disableWebstoreOffers, 'offerOpts.disableWebstoreOffers')
              }
            />
          </CustomField>
          <CustomField label="Disable basket offers for all retailers">
            <ToggleButton
              isSelected={offerOpts.disableBasketOffers}
              selectedText="yes"
              notSelectedText="no"
              value={offerOpts.disableBasketOffers}
              toggle={(v) => handleSimpleValueChange(!offerOpts.disableBasketOffers, 'offerOpts.disableBasketOffers')}
            />
          </CustomField>
          {conceptType === ConceptType.Program && (
            <CustomField additionalClasses="half-wide">
              <DateField
                value={startDate}
                label="Offer start date"
                disabled={true}
                onChange={(date: Date) =>
                  handleSimpleValueChange(dateFns.format(date, payloadDateFormat), 'offerOpts.startDate')
                }
              />
            </CustomField>
          )}
          {conceptType === ConceptType.Program && (
            <CustomField additionalClasses="half-wide">
              <DateField
                value={endDate}
                minDate={startDate}
                clearable={false}
                label="Offer end date"
                onChange={(date: Date) =>
                  enhancedHandleSimpleValueChange(
                    dateFns.format(date, payloadDateFormat),
                    dateFns.format(endDate, payloadDateFormat),
                    'offerOpts.endDate',
                    this.props,
                    handleSimpleValueChange,
                  )
                }
              />
            </CustomField>
          )}
        </section>
        {editorOpen && (
          <section className="editor-section offer-option-editor">
            <header className="editor-section__header">
              <div className="title-row">
                <h3>{editingOffer ? 'Change offer' : 'New offer'}</h3>
              </div>
            </header>
            <OfferEditor
              initialOffer={editingOffer as Offer}
              onOfferChange={(offer) => this.setState({ offer })}
              editorMode={OfferEditorMode.EDIT}
              allowCustomOffers={true}
              conceptType={conceptType}
            />
            <Button color="bordered" onClick={() => this.save()}>
              {editingOffer ? 'Accept changes' : 'Accept'}
            </Button>
          </section>
        )}
        {_.orderBy(offerOptions, 'order').map(this.renderOffer)}
      </div>
    );
  }
}
