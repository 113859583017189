import React from 'react';

interface Props {
  label?: string;
  value?: string;
  placeholder?: string;
  rows?: number;
  textarea?: boolean;
  name: string;
  onChange?: (e: React.SyntheticEvent) => any;
  readOnly?: boolean;
  additionalClasses?: string;
  maxLength?: number;
  required?: boolean;
}

const InputText = ({ label, textarea, additionalClasses, ...restProps }: Props) => {
  return (
    <div className={`input-text form-control${additionalClasses ? ' ' + additionalClasses : ''}`}>
      <label htmlFor={restProps.name}>{label}</label>
      {textarea ? <textarea {...restProps} /> : <input type="text" {...restProps} />}
    </div>
  );
};

export default InputText;
