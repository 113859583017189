import React from 'react';
import { NavLink } from 'react-router-dom';
import { getLink } from '../../routes';
import { imageUrl, logout } from 'utils/api/user';

interface Props {
  storeId: string;
  retailerName: string;
  nega?: boolean;
  next?: boolean;
}

interface State {
  open: boolean;
}

class UserNavigation extends React.Component<Props, State> {
  public containerNode;
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.outsideClickListener = this.outsideClickListener.bind(this);
  }

  public closeMenu() {
    this.setState({ open: false });
    document.removeEventListener('click', this.outsideClickListener);
  }

  public outsideClickListener(event) {
    const { target } = event;
    if (!this.containerNode || !this.containerNode.contains(target)) {
      this.closeMenu();
    }
  }

  public toggleMenu() {
    const { open } = this.state;
    if (!open) {
      this.setState({ open: true });
      document.addEventListener('click', this.outsideClickListener);
    } else {
      this.closeMenu();
    }
  }

  public componentWillUnmount() {
    document.removeEventListener('click', this.outsideClickListener);
  }

  public logout = async () => {
    await logout();
    window.location.href = '/next/'; // reset
  };

  public render() {
    const { storeId, retailerName, nega } = this.props;
    const { open } = this.state;
    return (
      <div
        className={`user-navigation retailer right${open ? ' open' : ''}`}
        onClick={this.toggleMenu}
        ref={(e) => (this.containerNode = e)}
      >
        <div className="right-text">
          <div className="retailer-name">
            <div className="label-title">Kauppias</div>
            <span>{retailerName}</span>
          </div>
          <img src={require('images/arrow-down-' + (nega ? 'nega' : 'dark') + '.svg')} alt="arrow-down" />
        </div>
        <div className="retailer-menu">
          <div className="info-navigation-links">
            <NavLink to={getLink('profile')} className="navigation-link">
              Profiili
            </NavLink>
            <div className="navigation-link" onClick={this.logout}>
              <span className="link-text">Kirjaudu ulos</span>
            </div>
          </div>
        </div>
        <div className="right-image">
          <img src={`${imageUrl}?store=${storeId}`} alt="store" />
        </div>
      </div>
    );
  }
}

export default UserNavigation;
