import React from 'react';
import { Checkbox } from 'components/next/components/checkbox';
import type { TargetGroup, DeliveryTemplate, Dimension, DeliveryLanguage } from 'components/next/types';
import * as _ from 'lodash';
import { CustomField } from 'components/next/components/form/input';
import ToggleButton from 'components/common/next/form/toggleButton';
import './targetGroups.scss';
import { BusinessType } from 'enums/common';

interface TargetAudienceProps {
  template: DeliveryTemplate;
  dimensions: Dimension[];
  targetGroupType: BusinessType;
  handleSimpleValueChange(value, fieldName: string): void;
  handleTargetGroupOptionChange(o: TargetGroup[] | boolean): void;
}
export default class TargetAudience extends React.Component<TargetAudienceProps> {
  constructor(props: TargetAudienceProps) {
    super(props);
    this.handleDimensionSelection = this.handleDimensionSelection.bind(this);
    this.renderDimensionGrid = this.renderDimensionGrid.bind(this);
    this.selectAll = this.selectAll.bind(this);
  }

  handleDimensionSelection(dimensionName: string, name: string, property: string, isSelected: boolean) {
    const { handleTargetGroupOptionChange } = this.props;
    const { dimensions } = this.props.template.targetGroupOpts;
    const dimension = dimensions.find((d) => d.name === dimensionName) || {
      name: dimensionName,
      locked: [],
      default: [],
      options: [],
    };

    if (isSelected) {
      dimension[property].push(name);
    } else {
      _.pull(dimension[property], name);
    }

    if (dimensions.indexOf(dimension) > -1) {
      dimensions[dimensions.indexOf(dimension)] = dimension;
    } else {
      dimensions.push(dimension);
    }
    // Don't return dimensions with zero items selected
    handleTargetGroupOptionChange(
      dimensions.filter((d) => !(_.isEmpty(d.locked) && _.isEmpty(d.default) && _.isEmpty(d.options))),
    );
  }

  handleAreaSelection(allowSelection: boolean) {
    this.props.handleTargetGroupOptionChange(allowSelection);
  }

  selectAll(dimensionName: string, property: string, isSelected: boolean) {
    const { handleTargetGroupOptionChange, dimensions } = this.props;
    const existingUserSelection = this.props.template.targetGroupOpts.dimensions;
    const payloadDimension = existingUserSelection.find((dimension) => dimension.name === dimensionName) || {
      name: dimensionName,
      locked: [],
      default: [],
      options: [],
    };

    const dimension = dimensions.find((d) => d.name === dimensionName);

    _.set(payloadDimension, property, isSelected ? dimension.options.map((o) => o.name) : []);

    const index = _.findIndex(existingUserSelection, (s) => s.name === payloadDimension.name);
    if (index > -1) {
      existingUserSelection[index] = payloadDimension;
    } else {
      existingUserSelection.push(payloadDimension);
    }
    handleTargetGroupOptionChange(
      existingUserSelection.filter((d) => !(_.isEmpty(d.locked) && _.isEmpty(d.default) && _.isEmpty(d.options))),
    );
  }

  renderDimensionGrid(dimensionName: string) {
    const dimension = this.props.dimensions.find((d) => d.name === dimensionName);
    const dimensionSelection = this.props.template.targetGroupOpts.dimensions.find((d) => d.name === dimensionName);
    const optionCount = dimension.options.length;
    const allOptionsSelected = dimensionSelection ? optionCount === dimensionSelection['options'].length : false;
    const allDefaultsSelected = dimensionSelection ? optionCount === dimensionSelection['default'].length : false;
    const allLockedSelected = dimensionSelection ? optionCount === dimensionSelection['locked'].length : false;
    // dimension options marked as exclusive
    const exclusiveOptions = dimension.options.filter((o) => o.exclusive).map((o) => o.name);
    // name of the exclusive dimension option having any of the values selected
    const exclusiveOptionSelected =
      dimensionSelection &&
      (dimensionSelection.options.find((o) => exclusiveOptions.includes(o)) ||
        dimensionSelection.default.find((o) => exclusiveOptions.includes(o)) ||
        dimensionSelection.locked.find((o) => exclusiveOptions.includes(o)));
    // names of the dimension options having any of the values selected
    const nonExclusiveOptions = dimensionSelection
      ? [...dimensionSelection.options, ...dimensionSelection.locked, ...dimensionSelection.default].filter(
          (o) => !exclusiveOptions.includes(o),
        )
      : [];
    const isNonExclusiveOptionSelected = nonExclusiveOptions.length > 0;

    const isOptionDisabled = (currentOption: string) => {
      if (exclusiveOptionSelected === currentOption) {
        // this option is exclusive and it's currently selected
        return false;
      } else if (exclusiveOptionSelected) {
        // some exclusive option is selected, which means that none of other options are allowed to be selected
        return true;
      } else if (isNonExclusiveOptionSelected) {
        // some non-exclusive option is selected. This option is disabled, if it's exclusive option
        return exclusiveOptions.includes(currentOption) ? true : false;
      }
    };

    return (
      <table className="styled editor-grid">
        <thead>
          <tr>
            <th>Name</th>
            <th>
              <Checkbox
                id={`${dimensionName}-options-all`}
                handleClick={(e) => this.selectAll(dimensionName, 'options', e.target.checked)}
                checked={allOptionsSelected}
              />
              Options
            </th>
            <th>
              <Checkbox
                id={`${dimensionName}-default-all`}
                handleClick={(e) => this.selectAll(dimensionName, 'default', e.target.checked)}
                checked={allDefaultsSelected}
              />
              Default
            </th>
            <th>
              <Checkbox
                id={`${dimensionName}-locked-all`}
                handleClick={(e) => this.selectAll(dimensionName, 'locked', e.target.checked)}
                checked={allLockedSelected}
              />
              Locked
            </th>
            <th>Exclusive</th>
          </tr>
        </thead>
        <tbody>
          {_.orderBy(dimension.options, 'order').map((o, i) => (
            <tr key={i}>
              <td>{o.title}</td>
              <td className="checkbox-container">
                <Checkbox
                  id={`${o.name}-option`}
                  checked={dimensionSelection ? dimensionSelection.options.includes(o.name) : false}
                  disabled={isOptionDisabled(o.name)}
                  handleClick={(e) => this.handleDimensionSelection(dimensionName, o.name, 'options', e.target.checked)}
                />
              </td>
              <td className="checkbox-container">
                <Checkbox
                  id={`${o.name}-default`}
                  checked={dimensionSelection ? dimensionSelection.default.includes(o.name) : false}
                  disabled={isOptionDisabled(o.name)}
                  handleClick={(e) => this.handleDimensionSelection(dimensionName, o.name, 'default', e.target.checked)}
                />
              </td>
              <td className="checkbox-container">
                <Checkbox
                  id={`${o.name}-locked`}
                  checked={dimensionSelection ? dimensionSelection.locked.includes(o.name) : false}
                  disabled={isOptionDisabled(o.name)}
                  handleClick={(e) => this.handleDimensionSelection(dimensionName, o.name, 'locked', e.target.checked)}
                />
              </td>
              <td className="checkbox-container">
                <Checkbox
                  id={`${o.name}-exclusive`}
                  checked={o.exclusive}
                  disabled={true}
                  handleClick={() => {
                    /* no nothing */
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  toggleLanguage = (language: DeliveryLanguage) => {
    let { languages } = this.props.template;
    if (!languages.includes(language)) {
      languages.push(language);
    } else {
      languages = languages.filter((l) => l !== language);
    }
    this.props.handleSimpleValueChange(languages, 'languages');
  };

  toggleDefaultLanguage = (defaultLanguage: DeliveryLanguage) => {
    let { defaultLanguages } = this.props.template;
    if (!defaultLanguages) {
      defaultLanguages = [];
    }
    if (!defaultLanguages.includes(defaultLanguage)) {
      defaultLanguages.push(defaultLanguage);
    } else {
      defaultLanguages = defaultLanguages.filter((l) => l !== defaultLanguage);
    }
    this.props.handleSimpleValueChange(defaultLanguages, 'defaultLanguages');
  };

  render() {
    const { dimensions, template } = this.props;
    const toggleButtonDisabled = (lang: DeliveryLanguage) =>
      template.languages.includes(lang) && template.languages.length === 1;
    const isB2B = this.props.targetGroupType === BusinessType.B2b;
    return (
      <div className="editor-tab target-groups">
        <section className="editor-section languages">
          <h3 className="section-title">Languages</h3>
          <CustomField label="Finnish" additionalClasses="third-wide">
            <ToggleButton
              isSelected={template.languages.includes('fi')}
              value="fi"
              toggle={this.toggleLanguage}
              disabled={toggleButtonDisabled('fi')}
            />
            <span className="detail-text">{toggleButtonDisabled('fi') ? 'Cannot disable the only language' : ''}</span>
          </CustomField>
          <CustomField label="Swedish" additionalClasses="third-wide">
            <ToggleButton
              isSelected={template.languages.includes('sv')}
              value="sv"
              toggle={this.toggleLanguage}
              disabled={toggleButtonDisabled('sv')}
            />
            <span className="detail-text">{toggleButtonDisabled('sv') ? 'Cannot disable the only language' : ''}</span>
          </CustomField>
          <CustomField label="Swedish by default" additionalClasses="third-wide">
            <ToggleButton
              isSelected={template.defaultLanguages && template.defaultLanguages.includes('sv')}
              value="sv"
              toggle={this.toggleDefaultLanguage}
            />
            <span className="detail-text">
              {template.defaultLanguages && template.defaultLanguages.includes('sv')
                ? 'Remember to also select Swedish'
                : ''}
            </span>
          </CustomField>
        </section>
        {!isB2B && (
          <section className="editor-section home-area">
            <h3 className="section-title">Allow area selection</h3>
            <CustomField>
              <Checkbox
                id="allow-area-selection"
                label="Allow the retailer to select postal codes?"
                handleClick={(e) => this.handleAreaSelection(e.target.checked)}
                checked={this.props.template.targetGroupOpts.useAreas}
              />
            </CustomField>
          </section>
        )}
        {dimensions.map((d, i) => (
          <section className="editor-section no-padding" key={i}>
            <h3 className="section-title">Dimension: {d.title}</h3>
            {this.renderDimensionGrid(d.name)}
          </section>
        ))}
      </div>
    );
  }
}
