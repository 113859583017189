import { action, observable } from 'mobx';
import * as _ from 'lodash';
import type { Dimension, TargetGroupSelection, DimensionOption, SelectionEmails } from 'types/next';
import type { Client } from 'types/next-api';
import { StoreState, BusinessType } from 'enums/common';

export interface GroupedSelectionEmails extends SelectionEmails {
  group?: SelectionEmails[];
}

export default class DimensionsStore {
  client: Client;

  @observable dimensions: Dimension[] = [];
  @observable b2bDimensions: Dimension[] = [];
  @observable b2bEmailRecipients: SelectionEmails[];
  @observable groupedB2bEmailRecipients: GroupedSelectionEmails[] = [];
  // eslint-disable-next-line
  @observable dimensionOptionInUse: Array<{ inUse?: boolean; dimensionOption?: DimensionOption }> = [];
  @observable status: StoreState = StoreState.ready;
  private currentlyUsedChainIds = [];

  async getDimensionOptionInUse(dimension: Dimension) {
    try {
      const result = await this.client.isDimensionOptionInUse(null, dimension.options);
      this.dimensionOptionInUse = result.data;
      this.status = StoreState.ready;
    } catch {
      this.status = StoreState.error;
    }
  }

  groupB2bEmailRecipients(emailRecipients: SelectionEmails[]) {
    // Find duplicate emails in currentEmails and group them together
    const groupedEmails = _.groupBy(emailRecipients, (email) => email.email);

    // Take the first email in the group and add the rest under it as group parameter
    const groupedEmailsWithGroups = _.map(groupedEmails, (emails) => {
      const firstEmail = emails[0];
      const group = emails.slice(1);
      return { ...firstEmail, group };
    });

    this.groupedB2bEmailRecipients = groupedEmailsWithGroups;
  }

  @action
  async getDimensionsByChain(chainIds: string[], type?: string) {
    try {
      const result = await this.client.searchDimensions(null, {
        chainId: [chainIds[0], ...chainIds.slice(1)],
        type: type,
      });
      this.dimensions = result.data;
      this.currentlyUsedChainIds = [...chainIds];
      this.status = StoreState.ready;
    } catch {
      this.status = StoreState.error;
    }
  }

  @action
  async getSelectionEmails(selection: TargetGroupSelection) {
    try {
      const result = await this.client.selectionEmails(null, selection);
      const emailRecipients = _.get(result.data, ['emailRecipients'], []);
      this.status = StoreState.ready;
      this.b2bEmailRecipients = emailRecipients;
      this.groupB2bEmailRecipients(emailRecipients);
    } catch {
      this.status = StoreState.error;
    }
  }

  @action async getB2BDimensionsByChain(chainIds: string[]) {
    try {
      const result = await this.client.searchDimensions(null, {
        chainId: [chainIds[0], ...chainIds.slice(1)],
        type: BusinessType.B2b,
      });
      this.b2bDimensions = result.data;
      this.status = StoreState.ready;
    } catch {
      this.status = StoreState.error;
    }
  }

  @action
  async createDimension(dimension: Dimension) {
    try {
      const result = await this.client.createDimension(null, dimension);
      await this.getDimensionsByChain(this.currentlyUsedChainIds);
      await this.getB2BDimensionsByChain(this.currentlyUsedChainIds);
      this.status = StoreState.ready;
      return result.data;
    } catch {
      this.status = StoreState.error;
    }
  }

  @action
  async updateDimension(dimension: Dimension) {
    try {
      const result = await this.client.updateDimension({ dimensionId: dimension.id.toString() }, dimension);
      await this.getDimensionsByChain(this.currentlyUsedChainIds);
      await this.getB2BDimensionsByChain(this.currentlyUsedChainIds);
      this.status = StoreState.ready;
      return result.data;
    } catch {
      this.status = StoreState.error;
    }
  }

  @action
  async deleteDimension(dimensionId: string) {
    try {
      await this.client.deleteDimension({ dimensionId });
      await this.getDimensionsByChain(this.currentlyUsedChainIds);
      await this.getB2BDimensionsByChain(this.currentlyUsedChainIds);
      this.status = StoreState.ready;
    } catch {
      this.status = StoreState.error;
    }
  }
}
