export const timestampFormat = 'dd.MM.yyyy HH:mm:ss';
export const shortTimestampFormat = 'dd.MM.yyyy HH:mm';
export const dateFormat = 'd.M.yyyy';
export const shortDateFormat = 'd.M.';
export const payloadDateFormat = 'yyyy-MM-dd';
export const payloadDateTimeFormat = 'yyyy-MM-dd HH:mm:ss';

export const dimensionNames = {
  segment: 'segmentti',
  segmentDetail: 'segmentti_tarkentava',
  customerRelation: 'k_asiakas',
  lansey: 'lansey',
  misc: 'misc',
};

export const monthNames = {
  en: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  fi: [
    'Tammikuu',
    'Helmikuu',
    'Maaliskuu',
    'Huhtikuu',
    'Toukokuu',
    'Kesäkuu',
    'Heinäkuu',
    'Elokuu',
    'Syyskuu',
    'Lokakuu',
    'Marraskuu',
    'Joulukuu',
  ],
};

export const storeData = `Store data:
{{kauppa_id}}
{{kauppa_ketju_id}}
{{kauppa_ketju}}
{{kauppa_kauppanimi}}
{{kauppa_markkinointinimi}}
{{kauppa_kauppiasnimi}}
{{kauppa_kauppiastitteli}}
{{kauppa_osoite}}
{{kauppa_postinumero}}
{{kauppa_postitoimipaikka}}
{{kauppa_web_url}}
{{kauppa_webstore_url}}
{{kauppa_facebook_url}}
{{kauppa_kauppiaskuva}}
{{kauppa_geo_latitude}}
{{kauppa_geo_longitude}}

Email specific:
{{subject}}
{{preheader}}

Delivery slots:
{{slot.<slotName>}}

Delivery fields:
{{field.<fieldName>}}

Image fields:
{{field.<fieldName>.src}}
{{field.<fieldName>.crop}}
{{field.<fieldName>.thumb}}
{{field.<fieldName>.text}}

Delivery offers:
{{offers_valid_to}}
{{offers_valid_from}}
{{offers_includes_free_product}}

{{#each offers as |offer|}}
{{offer.title}}
{{offer.description}}
{{offer.image}}
{{offer.discountPrice.eur}}
{{offer.discountPrice.cnt}}
{{offer.discountPercentage}}
{{offer.pricingUnit}}
{{offer.regularPriceMin}}
{{offer.regularPriceMax}}
{{offer.regularPriceText}}
{{offer.restrictionText}}
{{offer.valid.to}}
{{offer.valid.from}}
{{offer.eans}}
{{offer.type}}
{{offer.isProduct}}
{{offer.isBasket}}
{{offer.isWebstore}}
{{offer.productOfferType}}
{{offer.basketOfferType}}
{{offer.webstoreOfferType}}
{{offer.webstoreDiscount}}
{{offer.createTosOffer}}
{{/each}}

Offers can also be referenced directly with index, for example 1st offer title:
{{offers.[0].title}}
`;

export const targetData = `<%= recipient.firstName %>
<%= recipient.lastName %>
<%= recipient.language %>
<%@ include view='PlussaMirrorPageUrl' %>
<%= escapeUrl(recipient.plussaOptOutUID) %>`;

export const mdInstructions = `
Lists:
  - Put list items on separate lines
  - Put a '-' and a space at the beginning of every list item
  - Use numbers instead of dashes at the beginning of list items
    to create an ordered list
Titles:
  - Titles start with a '#' character
  - You can use more than one # to denote title levels (Use one
    for the main title, two for subtitles and so forth)
Bold and italic text:
  - Use single '*' characters at the beginning and end of a word or
    piece of text to denote italic text
  - Use double '**' characters in the same way to denote bold text
`;

export const availableFonts = [
  { family: 'Skatta Sans Black Italic', url: 'http://campaign.kesko.fi/res/kesko/SkattaSans-Black-Italic.ttf' },
  { family: 'Skatta Sans Black', url: 'http://campaign.kesko.fi/res/kesko/SkattaSans-Black.ttf' },
  { family: 'Skatta Sans Bold Italic', url: 'http://campaign.kesko.fi/res/kesko/SkattaSans-Bold-Italic.ttf' },
  { family: 'Skatta Sans Bold', url: 'http://campaign.kesko.fi/res/kesko/SkattaSans-Bold.ttf' },
  { family: 'Skatta Sans Condensed Black', url: 'http://campaign.kesko.fi/res/kesko/SkattaSans-Condensed-Black.ttf' },
  { family: 'Skatta Sans Condensed Bold', url: 'http://campaign.kesko.fi/res/kesko/SkattaSans-Condensed-Bold.ttf' },
  { family: 'Skatta Sans Condensed Medium', url: 'http://campaign.kesko.fi/res/kesko/SkattaSans-Condensed-Medium.ttf' },
  {
    family: 'Skatta Sans Condensed Regular',
    url: 'http://campaign.kesko.fi/res/kesko/SkattaSans-Condensed-Regular.ttf',
  },
  { family: 'Skatta Sans Light Italic', url: 'http://campaign.kesko.fi/res/kesko/SkattaSans-Light-Italic.ttf' },
  { family: 'Skatta Sans Light', url: 'http://campaign.kesko.fi/res/kesko/SkattaSans-Light.ttf' },
  { family: 'Skatta Sans Medium Italic', url: 'http://campaign.kesko.fi/res/kesko/SkattaSans-Medium-Italic.ttf' },
  { family: 'Skatta Sans Medium', url: 'http://campaign.kesko.fi/res/kesko/SkattaSans-Medium.ttf' },
  { family: 'Skatta Sans Regular Italic', url: 'http://campaign.kesko.fi/res/kesko/SkattaSans-Regular-Italic.ttf' },
  { family: 'Skatta Sans Regular', url: 'http://campaign.kesko.fi/res/kesko/SkattaSans-Regular.ttf' },
  { family: 'Skatta Script Bold', url: 'http://campaign.kesko.fi/res/kesko/SkattaScript-Bold.ttf' },
  { family: 'Skatta Sans Headline', url: 'http://campaign.kesko.fi/res/kesko/SkattaSans-Headline.ttf' },
  { family: 'Skatta Sans Headline Black', url: 'http://campaign.kesko.fi/res/kesko/SkattaSans-Headline-Black.ttf' },
  { family: 'Chalet-LondonEighty', url: 'http://campaign.kesko.fi/res/kesko/Chalet-NewYorkEighty.ttf' },
  { family: 'Chalet-NewYorkEighty', url: 'http://campaign.kesko.fi/res/kesko/Chalet-LondonEighty.ttf' },
];

export enum PushNotificationView {
  SHOW_FRONT_PAGE = 'SHOW_FRONT_PAGE',
  SHOW_OWN_STORE_OFFERS = 'SHOW_OWN_STORE_OFFERS',
  SHOW_OWN_STORE = 'SHOW_OWN_STORE',
  SHOW_TARGETED_OFFERS = 'SHOW_TARGETED_OFFERS',
  SHOW_STAMP_CARDS = 'SHOW_STAMP_CARDS',
}
export enum PushNotificationTarget {
  PUSH_NOTIFICATION = 'PUSH_NOTIFICATION',
  NOTIFICATION_CENTER = 'NOTIFICATION_CENTER',
}

export const pushNotificationViewMap = {
  [PushNotificationView.SHOW_FRONT_PAGE]: 'K-Ruoka -sovelluksen etusivu',
  [PushNotificationView.SHOW_OWN_STORE_OFFERS]: 'Oman kauppani tarjoukset -näkymä',
  [PushNotificationView.SHOW_OWN_STORE]: 'Oman kauppani tiedot -näkymä',
  [PushNotificationView.SHOW_TARGETED_OFFERS]: 'Asiakkaan Omat Plussa-edut -näkymä',
  [PushNotificationView.SHOW_STAMP_CARDS]: 'Keräilypassit -näkymä',
};
export const pushNotificationTargetMap = {
  [PushNotificationTarget.NOTIFICATION_CENTER]: 'K-Ruoka-sovelluksen ilmoituskeskus',
  [PushNotificationTarget.PUSH_NOTIFICATION]: 'Push notifikaatio',
};

export enum OfferEditorMode {
  EDIT,
  CHANGE,
  VIEW,
}
