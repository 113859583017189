export enum Role {
  Admin = 'admin',
  Retailer = 'retailer',
  Guest = 'guest',
  Any = 'any',
}

export interface User {
  name: string;
  aduser: string;
  role: Role;
  store?: string;
  chainId?: string;
  storeData?: any;
  email?: string;
  adminAduser?: string;
}

export enum NotificationType {
  deliveryConfirmation = 'deliveryConfirmation',
  newDeliveryTemplate = 'newDeliveryTemplate',
  deliveryTemplateReminder = 'deliveryTemplateReminder',
  draftDeliveryReminder = 'draftDeliveryReminder',
  stampcardDeliveryConfirmation = 'stampcardDeliveryConfirmation',
  stampcardReminder = 'stampcardReminder',
  automaticParticipationReminder = 'automaticParticipationReminder',
}

export enum NotificationContactType {
  mobile = 'mobile',
  email = 'email',
}

export interface NotificationContact {
  type: NotificationContactType | '';
  value: string;
}
