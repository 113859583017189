import { ContentType } from 'enums/common';

export const ChainFilters = {
  '3': 'K-Market',
  '4': 'K-Supermarket',
  '5': 'K-Citymarket',
  '51': 'K-Rauta',
  '182': 'Onninen',
};

export const onninenChainIds = ['182'];
export const kRautaChainIds = ['51'];
export const kRuokaChainIds = ['3', '4', '5'];

export const ChainAbbreviations = {
  3: 'KM',
  4: 'KSM',
  5: 'KCM',
  51: 'KR',
  182: 'OE',
};

export const pushNotificationLimit = 'pushNotificationLimit';
export const siteDisabled = 'siteDisabled';
export const siteDisabledText = 'siteDisabledText';

export const ContentTypeNames = {
  [ContentType.News]: 'News Article',
  [ContentType.Help]: 'Help Text',
  [ContentType.UI]: 'UI Text',
};

// discountMethod 12(5%),13(7%),14(10%), 15(15%) and 16(20%)
export const ACBasketOfferPercentages = {
  '5%': {
    discountPercentage: 5,
    discountMethod: '12',
  },
  '7%': {
    discountPercentage: 7,
    discountMethod: '13',
  },
  '10%': {
    discountPercentage: 10,
    discountMethod: '14',
  },
  '15%': {
    discountPercentage: 15,
    discountMethod: '15',
  },
  '20%': {
    discountPercentage: 20,
    discountMethod: '16',
  },
};
