import { chains } from 'components/next/routes';
import { observable, action } from 'mobx';
import { Chain } from 'enums/common';
import type { ChainRoute } from 'types/next';

export default class AppStore {
  @observable chain: Chain = undefined;
  @observable chainRoute: ChainRoute = undefined;

  @action setChain(chain: Chain) {
    this.chain = chain;
    this.chainRoute = chains.find((c) => c.chain === chain);
  }

  @action
  public reset() {
    this.chain = undefined;
    this.chainRoute = undefined;
  }
}
