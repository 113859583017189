import React from 'react';
import _ from 'utils/lodash';
import { v4 as uuidv4 } from 'uuid';
import Button from '../form/button';
import type { OfferOption, DeliveryOffer } from 'types/next';
import { OfferEditorMode } from 'components/next/utils';
import './optionsForm.scss';

interface Props {
  offer?: DeliveryOffer;
  setOffer: ({ offer, preserveOptionId }: { offer: DeliveryOffer; preserveOptionId?: boolean }) => void;
  offerOptions: OfferOption[];
  maybeEditOfferOption?: () => void;
  selectedOfferIds: string[];
  requiredRecommendations?: number;
  editorMode: OfferEditorMode;
}

class OfferOptionsForm extends React.Component<Props> {
  get offer() {
    return this.props.offer;
  }

  get setOffer() {
    return this.props.setOffer;
  }

  get offerOptions() {
    return this.props.offerOptions || [];
  }

  get maybeEditOfferOption() {
    return this.props.maybeEditOfferOption;
  }

  get selectedDeliveryOfferIds() {
    return this.props.selectedOfferIds || [];
  }

  get requiredRecommendations() {
    return this.props.requiredRecommendations || 0;
  }

  setOfferOption = (offerOptionId: string) => {
    const offerOption = this.offerOptions.find(({ id }) => id === offerOptionId);

    const prices = _.filter(_.map(_.get(offerOption, ['products']), 'price'), _.isNumber);

    // Use general offer details instead if no product prices are available
    const minPrice = _.min(prices) || offerOption.regularPriceMin;
    const maxPrice = _.max(prices) || offerOption.regularPriceMax;

    const discountPrice = _.isNil(minPrice) ? null : offerOption.discountPrice || Number((0.75 * minPrice).toFixed(1));

    this.setOffer({
      offer: {
        ...offerOption,
        id: this.offer.id || uuidv4(),
        offerOptionId: offerOption.id,
        // Set default discount price for products
        ...(offerOption.type === 'product' &&
          offerOption.productOfferType === 'price' &&
          discountPrice && { discountPrice }),
        regularPriceMin: minPrice || null,
        regularPriceMax: maxPrice || null,
      },
      preserveOptionId: true,
    });
    this.maybeEditOfferOption();
  };

  public chosenRecommendationOfferCount = (offeroptions: OfferOption[]): number =>
    offeroptions.reduce((count, opt) => {
      return this.selectedDeliveryOfferIds.includes(opt.id) ? ++count : count;
    }, 0);

  public isAlreadySelected = (offerOptionId: string): boolean =>
    Boolean(this.selectedDeliveryOfferIds.find((id) => id === offerOptionId));

  public isLimitHit = (): boolean =>
    this.requiredRecommendations > 0 &&
    this.chosenRecommendationOfferCount(this.offerOptions) === this.requiredRecommendations;

  render() {
    const offer = this.offer;
    if (!offer) {
      return null;
    }
    return (
      <div className="offer-form-options">
        <h3>Suositukset</h3>
        {this.requiredRecommendations > 0 && (
          <span className="offer-recommended-info">
            Suosituseduista valittu
            {` ${this.chosenRecommendationOfferCount(this.offerOptions)}/${this.requiredRecommendations}`}
          </span>
        )}
        <ul className="offer-options-list">
          {this.offerOptions.map((offerOption) => {
            return (
              <li className="offer-option" key={offerOption.id}>
                <div className="offer-image">
                  <img src={offerOption.image} alt={offerOption.title.fi} />
                </div>
                <div className="offer-info">
                  <h4 className="offer-title">{offerOption.title.fi}</h4>
                </div>
                {this.isAlreadySelected(offerOption.id) && <span className="offer-chosen">Valittu</span>}
                <div className="actions">
                  {offerOption.id === this.offer.offerOptionId ? (
                    <Button
                      color="default"
                      onClick={() => this.setOfferOption(offerOption.id)}
                      disabled={this.isAlreadySelected(offerOption.id) || this.isLimitHit()}
                    >
                      Valittu
                    </Button>
                  ) : (
                    <Button
                      color="bordered"
                      onClick={() => this.setOfferOption(offerOption.id)}
                      disabled={
                        this.isAlreadySelected(offerOption.id) ||
                        (this.props.editorMode === OfferEditorMode.EDIT && this.isLimitHit())
                      }
                    >
                      Valitse
                    </Button>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default OfferOptionsForm;
