import React from 'react';
import _ from 'lodash';
import * as date from 'date-fns';
import { inject, observer } from 'mobx-react';
import DeliveriesStore from 'stores/next/deliveries';
import StoreStore from 'stores/next/stores';
import StatisticsStore from 'stores/statisticsStore';
import page from 'components/next/pages/page/page';
import Spinner from 'components/common/next/spinner';
import { DateField } from 'components/next/components/form/input';
import DeliveryTemplateStore from 'stores/next/deliveryTemplates';
import ConceptsStore from 'stores/next/programs';
import type { Delivery, DeliveryTemplate, Store, DeliveryTemplateTabStatistics } from 'components/next/types';
import { Checkbox } from '../../../components/checkbox';
import SearchBox from 'components/next/components/sidebar/searchBox';
import SidebarWrapper from 'components/next/components/sidebar/sidebar';
import ChainSelector from 'components/next/components/chainSelector';
import { getExcludedChains, castDate, getAllowedConceptTypeForChainIds } from 'utils/helpers';
import { dateFormat, payloadDateFormat } from 'components/next/utils';
import { ChainAbbreviations } from 'constants/common';
import { ConceptType, DeliveryChannelName, DeliveryStatus, DeliveryState } from 'enums/common';

interface DeliveriesState {
  chainIds: string[];
  excludedChains: string[];
  channels: { [type: string]: boolean };
  endDateFrom: string;
  endDateTo: string;
  isLoading: boolean;
  order: 'asc' | 'desc';
  query: string;
  searchResult: Delivery[];
  selectedDeliveries: string[];
  selectedDeliveryTemplates: DeliveryTemplate[];
  sort?: string;
  startDateFrom: string;
  startDateTo: string;
  states: { [type: string]: boolean };
  statuses: { [type: string]: boolean };
  storeIds: string[];
  stores: { [id: string]: Store };
  types: { [type: string]: boolean };
  archived: boolean;
  statistics?: DeliveryTemplateTabStatistics;
  deliveryTemplateSearchResult: DeliveryTemplate[];
  estimatedRecipients: {
    email: number;
    print: number;
  };
}

interface DeliveriesProps {
  deliveriesStore: DeliveriesStore;
  deliveryTemplateStore: DeliveryTemplateStore;
  conceptsStore: ConceptsStore;
  storeStore: StoreStore;
  statisticsStore: StatisticsStore;
  chainIds: string[];
}

type StateDateField = keyof DeliveriesState;

@inject('deliveriesStore', 'storeStore', 'statisticsStore', 'conceptsStore', 'deliveryTemplateStore')
@observer
class Deliveries extends React.Component<DeliveriesProps, DeliveriesState> {
  debouncedSearchDeliveries: (updateStores?: boolean) => void;
  debouncedSearchDeliveryTemplates: (updateStores?: boolean) => void;

  constructor(props) {
    super(props);
    const { chainIds } = this.props;
    this.state = {
      query: '',
      chainIds,
      excludedChains: getExcludedChains(chainIds),
      storeIds: [],
      selectedDeliveries: [],
      selectedDeliveryTemplates: [],
      stores: {},
      sort: 'id',
      order: 'desc',
      isLoading: true,
      types: _.reduce(
        Object.keys(ConceptType),
        (types, key) => {
          const type = ConceptType[key as string];
          types[type] = true;
          return types;
        },
        {},
      ),
      channels: _.reduce(
        Object.keys(DeliveryChannelName),
        (channels, key) => {
          const channel = DeliveryChannelName[key as string];
          channels[channel] = true;
          return channels;
        },
        {},
      ),
      statuses: _.reduce(
        Object.keys(DeliveryStatus),
        (statuses, key) => {
          const status = DeliveryStatus[key as string];
          statuses[status] = true;
          return statuses;
        },
        {},
      ),
      states: _.reduce(
        Object.keys(DeliveryState),
        (states, key) => {
          const state = DeliveryState[key as string];
          states[state] = true;
          return states;
        },
        {},
      ),
      startDateFrom: null,
      startDateTo: null,
      endDateFrom: null,
      endDateTo: null,
      searchResult: [],
      archived: false,
      deliveryTemplateSearchResult: [],
      estimatedRecipients: {
        email: 0,
        print: 0,
      },
    };
    this.debouncedSearchDeliveries = _.debounce((updateStores = false) => this.searchDeliveries(updateStores), 500);
    this.debouncedSearchDeliveryTemplates = _.debounce(
      (updateStores = false) => this.searchDeliveryTemplates(updateStores),
      500,
    );
  }

  get deliveries() {
    return this.state.searchResult;
  }

  get deliveryTemplates() {
    return this.state.deliveryTemplateSearchResult;
  }

  get selectedDeliveryTemplates() {
    return this.state.selectedDeliveryTemplates;
  }

  componentDidMount = async () => {
    const { statisticsStore } = this.props;
    await statisticsStore.getStatisticsDeliveryTemplate();
    await this.searchDeliveryTemplates(true);
    await this.props.storeStore.searchStores({});
  };

  searchDeliveryTemplates = async (updateStores = false) => {
    this.setState({ isLoading: true });
    const { deliveryTemplateStore, conceptsStore, storeStore } = this.props;
    const {
      chainIds,
      channels,
      order,
      query,
      sort,
      startDateFrom,
      startDateTo,
      endDateFrom,
      endDateTo,
      types,
      archived,
    } = this.state;

    const type = _.keys(_.pickBy(types, Boolean));
    const channel = _.keys(_.pickBy(channels, Boolean));

    // Get all available concepts
    const conceptResult = await conceptsStore.search({
      chainId: chainIds.length
        ? [chainIds[0], ...chainIds.slice(1)]
        : [this.props.chainIds[0], ...this.props.chainIds.slice(1)],
    });
    const concept = _.map(conceptResult.result, 'id');

    const search = _.omitBy(
      {
        query,
        concept,
        chainId: chainIds,
        type,
        channel,
        order,
        sort,
        archived,
      },
      _.isNil,
    );
    try {
      const deliveryTemplates = await deliveryTemplateStore.search(search, { sort, order });
      const deliveryTemplateSearchResult = deliveryTemplates.result as DeliveryTemplate[];

      const mapByDeliveryTemplate = this.props.statisticsStore.deliveryTemplateStatisticsMapByTemplate;
      const searchStartDateFrom = startDateFrom ? castDate(startDateFrom) : null;
      const searchStartDateTo = startDateTo ? castDate(startDateTo) : null;
      const searchEndDateFrom = endDateFrom ? castDate(endDateFrom) : null;
      const searchEndDateTo = endDateTo ? castDate(endDateTo) : null;

      // Get all delivery templates that have deliveries in mapByDeliveryTemplate
      const deliveryTemplatesWithDeliveries = _.filter(deliveryTemplateSearchResult, (deliveryTemplate) => {
        const current = mapByDeliveryTemplate.get(deliveryTemplate.id);
        // If date filters are set, we need to check if the delivery template has deliveries that are within the date range
        if (searchStartDateFrom || searchStartDateTo || searchEndDateFrom || searchEndDateTo) {
          return (
            current &&
            current.deliveries.some((delivery) => {
              const startDate = date.startOfDay(castDate(delivery.startDate));
              const endDate = date.endOfDay(castDate(delivery.endDate));
              return (
                (!startDateFrom ||
                  date.isAfter(startDate, searchStartDateFrom) ||
                  date.isSameDay(startDate, searchStartDateFrom)) &&
                (!startDateTo ||
                  date.isBefore(startDate, searchStartDateTo) ||
                  date.isSameDay(startDate, searchStartDateTo)) &&
                (!endDateFrom ||
                  date.isAfter(endDate, searchEndDateFrom) ||
                  date.isSameDay(endDate, searchEndDateFrom)) &&
                (!endDateTo || date.isBefore(endDate, searchEndDateTo) || date.isSameDay(endDate, searchEndDateTo))
              );
            })
          );
        }
        return true;
      });

      if (updateStores) {
        const stores = await storeStore.searchStores({ chainIds });

        this.setState({
          deliveryTemplateSearchResult,
          selectedDeliveryTemplates: deliveryTemplatesWithDeliveries,
          stores: _.keyBy(stores, 'storeId'),
          isLoading: false,
        });
      } else {
        this.setState({
          deliveryTemplateSearchResult,
          selectedDeliveryTemplates: deliveryTemplatesWithDeliveries,
          isLoading: false,
        });
      }
      this.updateStatistics(this.state.selectedDeliveryTemplates.map((d) => d.id));
    } catch (err) {
      this.setState({ isLoading: false });
    }
  };

  searchDeliveries = async (updateStores = false, limit = 0) => {
    const { deliveriesStore, storeStore } = this.props;
    const {
      chainIds,
      channels,
      endDateFrom,
      endDateTo,
      order,
      query,
      selectedDeliveries,
      sort,
      startDateFrom,
      startDateTo,
      states,
      statuses,
      storeIds,
      types,
      archived,
    } = this.state;

    const type = _.keys(_.pickBy(types, Boolean));
    const channel = _.keys(_.pickBy(channels, Boolean));
    const state = _.keys(_.pickBy(states, Boolean));
    const status = _.keys(_.pickBy(statuses, Boolean));

    const search = _.omitBy(
      {
        query,
        chainId: chainIds,
        ...(storeIds.length > 0 && { storeId: storeIds }),
        type,
        channel,
        startDateFrom,
        startDateTo,
        endDateFrom,
        endDateTo,
        state,
        status,
        archived,
      },
      _.isNil,
    );

    try {
      const deliveries = await deliveriesStore.search(search, { limit, sort, order });
      const searchResult = deliveries.data.result as Delivery[];
      const deliveryIds = _.map(searchResult, 'id');
      const updatedSelection = _.filter(selectedDeliveries, (id) => _.includes(deliveryIds, id)) as string[];

      if (updateStores) {
        const stores = await storeStore.searchStores({ chainIds });

        this.setState({
          searchResult,
          selectedDeliveries: updatedSelection,
          stores: _.keyBy(stores, 'storeId'),
          isLoading: false,
        });
      } else {
        this.setState({
          searchResult,
          selectedDeliveries: updatedSelection,
          isLoading: false,
        });
      }
    } catch (err) {
      this.setState({ isLoading: false });
    }
  };

  handleChainChange = (chain: string) => {
    const chainIds = [...this.state.chainIds];
    if (chainIds.includes(chain)) {
      _.pullAt(chainIds, chainIds.indexOf(chain));
    } else {
      chainIds.push(chain);
    }
    this.setState({ chainIds, isLoading: true });
    this.debouncedSearchDeliveryTemplates();
  };

  handleTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { types } = this.state;
    const type = e.currentTarget.dataset.conceptType;
    types[type] = !types[type];
    this.setState({ types, isLoading: true });
    this.debouncedSearchDeliveryTemplates();
  };

  handleChannelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { channels } = this.state;
    const channel = e.currentTarget.dataset.deliveryChannel;
    channels[channel] = !channels[channel];
    this.setState({ channels, isLoading: true });
    this.debouncedSearchDeliveryTemplates();
  };

  handleDateChange = (raw_date: Date, field: StateDateField) => {
    const value = date.isDate(raw_date) ? date.format(raw_date, payloadDateFormat) : undefined;
    this.setState<never>({ [field]: value || null, isLoading: true });
    this.debouncedSearchDeliveryTemplates();
  };

  // eslint-disable-next-line
  handleQueryChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    if (name === 'query') {
      this.setState({ query: value, isLoading: true });
      this.debouncedSearchDeliveryTemplates();
    }
  };

  formatChannels = (delivery: Delivery) => {
    return _.keys(_.pickBy(_.get(delivery, ['targetGroup', 'channels']), (value) => value !== 0)).join(', ');
  };

  renderSidebar = () => {
    const {
      query,
      chainIds,
      excludedChains,
      types,
      channels,
      startDateFrom,
      startDateTo,
      endDateFrom,
      endDateTo,
      isLoading,
    } = this.state;
    return (
      <div className="deliveries-sidebar">
        <div className="deliveries-filters">
          <div className="sidebar__title-wrapper">
            <h3 className="sidebar__title">Filters</h3>
            {isLoading && <Spinner addClassName="spinner--unset" />}
          </div>
          <SearchBox
            value={query}
            name="query"
            onChange={this.handleQueryChange}
            placeholder="Search for deliveries"
            detail="Search for concept or delivery template title"
          />
          <label>Chain</label>
          <ChainSelector
            chainSelection={chainIds}
            excludeChains={excludedChains}
            handleChainChange={this.handleChainChange}
          />
          <div className="filter-group">
            <label>Type</label>
            {_.map(getAllowedConceptTypeForChainIds(chainIds), (type) => {
              return (
                <Checkbox
                  data-concept-type={type}
                  key={`concept-type-${type}`}
                  id={`concept-type-${type}`}
                  label={_.capitalize(type)}
                  checked={types[type]}
                  handleClick={this.handleTypeChange}
                />
              );
            })}
          </div>
          <div className="filter-group">
            <label>Channels</label>
            {_.map(Object.keys(DeliveryChannelName), (key) => {
              const channel: string = DeliveryChannelName[key as string];

              return (
                <Checkbox
                  data-delivery-channel={channel}
                  key={`delivery-channel-${channel}`}
                  id={`delivery-channel-${channel}`}
                  label={_.capitalize(channel)}
                  checked={channels[channel]}
                  handleClick={this.handleChannelChange}
                />
              );
            })}
          </div>
          <div className="filter-group">
            <label>Start date</label>
            <DateField
              value={startDateFrom ? new Date(startDateFrom) : null}
              label="From"
              onChange={(e) => this.handleDateChange(e, 'startDateFrom')}
              clearable
              placeholder={dateFormat}
            />
            <DateField
              value={startDateTo ? new Date(startDateTo) : null}
              label="To"
              onChange={(e) => this.handleDateChange(e, 'startDateTo')}
              clearable
              placeholder={dateFormat}
            />
          </div>
          <div className="filter-group">
            <label>End date</label>
            <DateField
              value={endDateFrom ? new Date(endDateFrom) : null}
              label="From"
              onChange={(e) => this.handleDateChange(e, 'endDateFrom')}
              clearable
              placeholder={dateFormat}
            />
            <DateField
              value={endDateTo ? new Date(endDateTo) : null}
              label="To"
              onChange={(e) => this.handleDateChange(e, 'endDateTo')}
              clearable
              placeholder={dateFormat}
            />
          </div>
        </div>
      </div>
    );
  };

  getDeliveriesWithinTimeFrame = (templateId: string) => {
    const mapByDeliveryTemplate = this.props.statisticsStore.deliveryTemplateStatisticsMapByTemplate;
    const { startDateFrom, startDateTo, endDateFrom, endDateTo } = this.state;
    const template = mapByDeliveryTemplate.get(templateId);

    if (template && template.deliveries.length && (startDateFrom || startDateTo)) {
      const filteredDeliveries = template.deliveries.filter((delivery) => {
        const deliveryStartDate = date.startOfDay(new Date(delivery.startDate));
        const deliveryEndDate = date.endOfDay(new Date(delivery.endDate));
        const searchStartDateFrom = new Date(startDateFrom);
        const searchStartDateTo = new Date(startDateTo);
        const searchEndDateFrom = new Date(endDateFrom);
        const searchEndDateTo = new Date(endDateTo);

        return (
          (!startDateFrom ||
            date.isAfter(deliveryStartDate, searchStartDateFrom) ||
            date.isSameDay(deliveryStartDate, searchStartDateFrom)) &&
          (!startDateTo ||
            date.isBefore(deliveryStartDate, searchStartDateTo) ||
            date.isSameDay(deliveryStartDate, searchStartDateTo)) &&
          (!endDateFrom ||
            date.isAfter(deliveryEndDate, searchEndDateFrom) ||
            date.isSameDay(deliveryEndDate, searchEndDateFrom)) &&
          (!endDateTo ||
            date.isBefore(deliveryEndDate, searchEndDateTo) ||
            date.isSameDay(deliveryEndDate, searchEndDateTo))
        );
      });
      return filteredDeliveries;
    } else {
      if (template && template.deliveries.length) {
        return template.deliveries;
      }
    }
  };

  updateStatistics = (templateIds: string[]) => {
    const { startDateFrom, startDateTo, endDateFrom, endDateTo } = this.state;
    const mapByDeliveryTemplate = this.props.statisticsStore.deliveryTemplateStatisticsMapByTemplate;

    this.setState({ estimatedRecipients: { email: 0, print: 0 } });

    let { email, print } = this.state.estimatedRecipients;
    // local helper function
    function addToStatistics(item: DeliveryTemplateTabStatistics) {
      email += item.deliveries.reduce((acc, curr) => acc + curr.estimatedRecipients.email, 0);
      print += item.deliveries.reduce((acc, curr) => acc + curr.estimatedRecipients.print, 0);
    }

    templateIds.forEach((templateId) => {
      if (mapByDeliveryTemplate.has(templateId)) {
        const template = mapByDeliveryTemplate.get(templateId);
        // If the deliveries have a start date that is after startDateFrom or before startDateTo, we add them to the statistics
        if (template && template.deliveries.length && (startDateFrom || startDateTo)) {
          const filteredDeliveries = template.deliveries.filter((delivery) => {
            const deliveryStartDate = date.startOfDay(new Date(delivery.startDate));
            const deliveryEndDate = date.endOfDay(new Date(delivery.endDate));
            const searchStartDateFrom = new Date(startDateFrom);
            const searchStartDateTo = new Date(startDateTo);
            const searchEndDateFrom = new Date(endDateFrom);
            const searchEndDateTo = new Date(endDateTo);

            return (
              (!startDateFrom ||
                date.isAfter(deliveryStartDate, searchStartDateFrom) ||
                date.isSameDay(deliveryStartDate, searchStartDateFrom)) &&
              (!startDateTo ||
                date.isBefore(deliveryStartDate, searchStartDateTo) ||
                date.isSameDay(deliveryStartDate, searchStartDateTo)) &&
              (!endDateFrom ||
                date.isAfter(deliveryEndDate, searchEndDateFrom) ||
                date.isSameDay(deliveryEndDate, searchEndDateFrom)) &&
              (!endDateTo ||
                date.isBefore(deliveryEndDate, searchEndDateTo) ||
                date.isSameDay(deliveryEndDate, searchEndDateTo))
            );
          });
          addToStatistics({
            ...template,
            deliveries: filteredDeliveries,
          });
        } else {
          addToStatistics(mapByDeliveryTemplate.get(templateId));
        }
      }
    });

    this.setState({ estimatedRecipients: { email, print } });
  };
  renderDeliveryRows = () => {
    const mapByDeliveryTemplate = this.props.statisticsStore.deliveryTemplateStatisticsMapByTemplate;
    if (!this.selectedDeliveryTemplates) {
      return null;
    }

    const deliveryTemplatesAndDeliveryResults = this.selectedDeliveryTemplates.map((deliveryTemplate) => {
      const deliveries = this.getDeliveriesWithinTimeFrame(deliveryTemplate.id);
      return {
        ...deliveryTemplate,
        deliveries: deliveries,
      };
    });

    return (
      <tbody id="delivery-table-body">
        {deliveryTemplatesAndDeliveryResults.map((d) => (
          <tr key={d.id} className="delivery-row">
            <td>{d.id}</td>
            <td>{_.get(d, ['title', 'fi'])}</td>
            <td>
              {mapByDeliveryTemplate.get(d.id) &&
                mapByDeliveryTemplate
                  .get(d.id)
                  .chainIds.sort()
                  .map((c, i) =>
                    ChainAbbreviations[c] ? (
                      <span className={`chain-name ${ChainAbbreviations[c]}`} key={`chain-${i}`}>
                        {ChainAbbreviations[c]}
                      </span>
                    ) : null,
                  )}
            </td>
            <td>{_.join(_.map(d.channels, 'name'), ', ')}</td>
            <td>{d.deliveries ? d.deliveries.length : 0}</td>
          </tr>
        ))}
      </tbody>
    );
  };

  render() {
    const { isLoading } = this.state;

    return (
      <SidebarWrapper renderSidebar={this.renderSidebar}>
        <div className="results-view">
          {this.props.children}
          <div className="results-container">
            <div className="result">
              <span className="result__value">{this.state.estimatedRecipients.email}</span>
              <span className="result_detail">Estimated e-mail recipients</span>
            </div>
            <div className="result">
              <span className="result__value">{this.state.estimatedRecipients.print}</span>
              <span className="result_detail">Estimated print recipients</span>
            </div>
          </div>

          <div className="deliveries">
            <div className="table-container">
              <table className="styled">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Delivery template</th>
                    <th>Chains</th>
                    <th>Channels</th>
                    <th>Total deliveries</th>
                  </tr>
                </thead>
                {this.renderDeliveryRows()}
              </table>
              {isLoading && <Spinner />}
            </div>
          </div>
        </div>
      </SidebarWrapper>
    );
  }
}
export default page(Deliveries);
