export enum EditState {
  Pristine = 'pristine',
  Saved = 'saved',
  Changed = 'changed',
  Error = 'error',
}

export enum StoreState {
  loading = 'loading',
  ready = 'ready',
  error = 'error',
}

export enum ChangeOrderDirection {
  Up = 'up',
  Down = 'down',
}

export enum ChainCode {
  KM = '3',
  KSM = '4',
  KCM = '5',
  KR = '51',
  ON = '182',
}

export enum Chain {
  kRauta = 'k-rauta',
  kAuto = 'k-auto',
  kRuoka = 'k-ruoka',
  onninen = 'onninen',
}

export enum ContentType {
  News = 'news',
  Help = 'help',
  UI = 'ui',
}

export enum DeliveryTemplateStatus {
  Draft = 'draft',
  Published = 'published',
  Open = 'open',
  Upcoming = 'upcoming',
  Closed = 'closed',
}

export enum BusinessType {
  B2c = 'b2c',
  B2b = 'b2b',
}

export enum ConceptType {
  Season = 'season',
  Program = 'program',
  B2b = 'b2b',
}

export enum DiscountMethod {
  Price = 'price',
  Percent = 'percent',
  Free = 'free',
}

export enum EligibilityType {
  Person = 'person',
  Household = 'household',
}

export enum DeliveryState {
  Draft = 'draft',
  Confirmed = 'confirmed',
  Cancelled = 'cancelled',
}

export enum DeliveryStatus {
  Editable = 'editable',
  Processed = 'processed',
  Ongoing = 'ongoing',
  Finished = 'finished',
}

export enum PreviewContent {
  Template = 'template',
  Delivery = 'delivery',
}

export enum AutomaticParticipationStatisticsType {
  Concept = 'concept',
  Template = 'template',
}

export enum PreviewFormat {
  Image = 'image',
  Pdf = 'pdf',
  Html = 'html',
}

export enum DeliveryChannelName {
  Print = 'print',
  Email = 'email',
  Mobile = 'mobile',
}

export enum PricingMethod {
  Normal = 'normal',
  KCMHalfPrice = 'kcm_half_price',
  KCMTwoThirdsPrice = 'kcm_two_thirds_price',
}

export enum ContentFieldType {
  text = 'text',
  image = 'image',
  select = 'select',
  date = 'date',
  richtext = 'richtext',
  link = 'link',
  bttPromotedProduct = 'bttPromotedProduct',
}
