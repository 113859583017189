import React from 'react';
import Button from '../../../../../../common/next/form/button';
import { DeliveryWithMeta } from 'stores/next-retailer/deliveryStore';
import DeliveryTarget from '../../deliveryTarget';
import { DeliveryTemplateWithMeta } from 'stores/next-retailer/templateStore';
import { toJS } from 'mobx';
import { ConceptType } from 'enums/common';

interface Props {
  template: DeliveryTemplateWithMeta;
  delivery: DeliveryWithMeta;
  disabled?: boolean;
  conceptType?: ConceptType;
}

const TargetSelectionBox = ({ template, delivery, disabled, conceptType }: Props) => {
  const isEditable = () => {
    const { targetGroupOpts } = template;
    if (targetGroupOpts.useAreas) {
      return true;
    }
    const options = toJS(targetGroupOpts.dimensions).flatMap(({ options }) => options);
    const locked = toJS(targetGroupOpts.dimensions).flatMap(({ locked }) => locked);
    const editableOptions = options.filter((option) => !locked.includes(option));
    if (editableOptions.length > 0) {
      return true;
    }
    return false;
  };

  return (
    <DeliveryTarget
      delivery={delivery}
      conceptType={conceptType}
      renderActions={() => {
        if (disabled) {
          return <></>;
        }
        return (
          <Button color="bordered" link="target">
            {isEditable ? 'Muokkaa' : 'Tarkastele'}
          </Button>
        );
      }}
    />
  );
};

export default TargetSelectionBox;
