import React, { Fragment, useEffect, useState } from 'react';
import _ from 'utils/lodash';
import { inject, observer } from 'mobx-react';
import * as date from 'date-fns';
import * as dateFns from 'date-fns';
import ContentStore from 'stores/next-retailer/contentStore';
import DeliveryStore from 'stores/next-retailer/deliveryStore';
import TemplateStore from 'stores/next-retailer/templateStore';
import UserStore from 'stores/userStore';
import DeliveryTarget from './deliveryTarget';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as IconPreview } from '@kesko/icons/action/icon-preview.svg';
import Button from '../../../../common/next/form/button';
import InfoPopover from '../common/infoPopover';
import { DeliveryChannelName, ConceptType, DeliveryStatus, PreviewFormat } from 'enums/common';
import { getLink } from '../../routes';
import './deliveryView.scss';
import type { DeliveryOffer } from 'types/next';
import Alert from 'components/common/next/alert/alert';
import AlertStore, { AlertLevel } from 'stores/next/alerts';
import {
  castDate,
  imageSrc,
  isKRautaChain,
  isKRuokaChain,
  isOnninenChain,
  calculateDivisionSafe,
  getChain,
} from 'utils/helpers';
import ResultsStore, { CombinedOfferResult } from 'stores/next-retailer/resultsStore';
import { NumericFormat } from 'react-number-format';
import { DeliveryState } from 'types/delivery';
import InvoicesStore from 'stores/next/invoices';
import { getPrintRecipientCount } from '../../utils/helpers';
import ConceptStore from 'stores/next-retailer/conceptStore';
import Spinner from 'components/common/next/spinner';

interface InjectedProps {
  deliveryStore?: DeliveryStore;
  templateStore?: TemplateStore;
  resultsStore?: ResultsStore;
  alertStore?: AlertStore;
  userStore?: UserStore;
  contentStore?: ContentStore;
  invoicesStore?: InvoicesStore;
  conceptStore?: ConceptStore;
  isKCM?: boolean;
}

const DeliveryView = ({
  deliveryStore,
  templateStore,
  resultsStore,
  alertStore,
  userStore,
  contentStore,
  invoicesStore,
  conceptStore,
  isKCM,
}: InjectedProps) => {
  const { deliveryId: deliveryIdParam } = useParams();
  const [isWaiting, setIsWaiting] = useState(false);
  const navigate = useNavigate();

  const delivery = deliveryStore.deliveries.find((delivery) => delivery.id === deliveryIdParam);

  const template = templateStore.templates.find((template) => template.id === delivery.deliveryTemplate);

  const concept = conceptStore.concepts.find((concept) => concept.id === template.concept);

  const isReady = Boolean(delivery && template && concept);

  const results = delivery ? resultsStore.getDeliveryResults(delivery.id) : null;

  const stats = delivery ? resultsStore.getTemplateStats(delivery.deliveryTemplate) : null;

  const isLoading = delivery?.selectionCountsLoading || !delivery?.selectionCounts || !concept;

  const isKRauta = isKRautaChain(userStore.me.chainId);
  const isKRuoka = isKRuokaChain(userStore.me.chainId);
  const isOnninen = isOnninenChain(userStore.me.chainId);

  const counts = (() => {
    const counts = {
      email: 0,
      print: 0,
    };
    if (!isLoading) {
      if (results && results.emailDeliveryResultsAvailable) {
        counts.email = results.emailResults.totalRecipients;
      } else {
        counts.email = delivery.selectionCounts.channels.email;
      }
      if (results && results.printDeliveryResultsAvailable) {
        counts.print = results.printResults.totalRecipients;
      } else {
        counts.print = getPrintRecipientCount({
          restrictedCount: delivery.targetGroup.channels.print,
          maxCount: delivery.selectionCounts.channels.print,
          isHalvePrintRecipientCount: concept.halvePrintRecipientCount,
        });
      }
    }

    return counts;
  })();

  const updatePreviews = (delivery) => {
    if (!delivery) {
      return;
    }
    if (delivery.targetGroup.channels.email) {
      deliveryStore.previewDeliveryById(deliveryIdParam, { channel: DeliveryChannelName.Email });
      deliveryStore.previewDeliveryById(deliveryIdParam, {
        channel: DeliveryChannelName.Email,
        format: PreviewFormat.Html,
      });
    }
    if (delivery.targetGroup.channels.print) {
      deliveryStore.previewDeliveryById(deliveryIdParam, { channel: DeliveryChannelName.Print });
      deliveryStore.previewDeliveryById(deliveryIdParam, {
        channel: DeliveryChannelName.Print,
        format: PreviewFormat.Pdf,
      });
    }
  };

  useEffect(() => {
    const init = async () => {
      const delivery = await deliveryStore.getDelivery(deliveryIdParam, true);
      deliveryStore.setCurrent(delivery);
      invoicesStore.getDeliveryInvoiceSum(deliveryIdParam);
      updatePreviews(delivery);
    };
    init();
  }, []);

  useEffect(() => {
    updatePreviews(deliveryStore.current);
  }, [deliveryStore.previewLanguage]);

  const handleDelete = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const confirmed = window.confirm('Oletko varma, että haluat poistaa luonnoksen? Tätä ei voida perua.');
    if (confirmed) {
      setIsWaiting(true);
      try {
        const title = template.title.fi;
        await deliveryStore.deleteDelivery(delivery.id);
        navigate(-1);
        alertStore.success({
          message: <span>Luonnos "{title}" poistettu onnistuneesti.</span>,
        });
      } catch (error) {
        alertStore.error({
          message: `Virhe poistaessa luonnosta.`,
          ttl: 0,
        });
      }
      window.scrollTo({ top: 0 });
      setIsWaiting(false);
    }
  };

  const infoButton = (opts: { slug: string; link?: string }) => {
    return (
      <InfoPopover>
        <div
          className="help-content"
          dangerouslySetInnerHTML={{
            __html: contentStore.getContentBySlug(opts.slug, { html: true }),
          }}
        />
        {opts.link && (
          <footer>
            <a href={opts.link} target="_blank" rel="noopener noreferrer">
              Avaa ohjeet uudessa ikkunassa
            </a>
          </footer>
        )}
      </InfoPopover>
    );
  };

  const renderDeleteDraftButton = () => {
    return delivery.state === DeliveryState.draft ? (
      <div className="delete-draft-button">
        <Button color="bordered" onClick={handleDelete} disabled={isWaiting}>
          Poista luonnos
        </Button>
      </div>
    ) : null;
  };

  const renderDeliveryHeader = () => {
    return (
      <div className={`delivery-header box-nega ${getChain([userStore.me.chainId])}`}>
        <div className="delivery-info">
          <h2>{template?.title?.fi}</h2>
          <ul>
            <li>
              Asiakkailla: <strong>{date.format(castDate(castDate(delivery.startDate)), 'd.M.yyyy')}</strong>
            </li>
            <li>
              Kampanja-aika:{' '}
              <strong>
                {date.format(castDate(delivery.offerStartDate), 'd.M.')}–
                {date.format(castDate(castDate(delivery.offerEndDate)), 'd.M.yyyy')}
              </strong>
            </li>
            <li>Lähetyksen tulokset päivittyvät K Markkinointiin muutaman päivän viiveellä.</li>
          </ul>
        </div>
        {renderDeleteDraftButton()}
      </div>
    );
  };

  const renderDeliveryEmailChannel = () => {
    return (
      <div className="box channel-box channel-email">
        <h3 className="channel-title">
          Sähköposti:{' '}
          {delivery.selectionCounts && (
            <Fragment>
              toteutuneita vastaanottajia <span className="channel-count">{counts.email}</span> henkilöä
              {dateFns.isFuture(new Date(delivery.startDate)) && ' (Ei vielä lähetetty)'}
            </Fragment>
          )}
        </h3>
        <h4 className="channel-subtitle">
          {delivery.deliveryIdentifiers.filter((identifier) => identifier.includes('_email_')).join(', ')}
        </h4>
        <div className="box-sections">
          <div className="box-section">
            {delivery.emailPreviewThumb?.[deliveryStore.previewLanguage] ? (
              <div className="channel-preview">
                <Link to={`preview/delivery/email/${delivery.id}`}>
                  <div className="preview-wrapper">
                    <img src={delivery.emailPreviewThumb[deliveryStore.previewLanguage]} alt="preview" />
                  </div>
                  <div className="preview-icon">
                    <IconPreview />
                  </div>
                </Link>
              </div>
            ) : (
              <Spinner />
            )}
          </div>
          {results && results.emailDeliveryResultsAvailable ? (
            <Fragment>
              <div className="box-section">
                <div className="result email-results">
                  <h4>Sähköposti</h4>
                  <div className="number-display">
                    <NumericFormat
                      value={
                        100 * calculateDivisionSafe(results.emailResults.opens, results.emailResults.totalRecipients)
                      }
                      displayType="text"
                      suffix="%"
                      decimalScale={1}
                    />
                  </div>
                  <p>sähköpostin saaneista henkilöistä ovat avanneet viestin</p>

                  <div className="number-display">
                    <NumericFormat
                      value={100 * calculateDivisionSafe(results.emailResults.clicks, results.emailResults.opens)}
                      displayType="text"
                      suffix="%"
                      decimalScale={1}
                    />
                  </div>
                  <p>sähköpostin avanneista henkilöistä ovat klikanneet jotain viestissä olevaa linkkiä</p>
                </div>
              </div>

              {isKRuoka && results && results.offerResultsAvailable && (
                <Fragment>
                  <div className="box-section">
                    <div className="result email-results">
                      <h4>Etujen lunastukset</h4>
                      <div className="number-display">
                        <NumericFormat
                          value={results.emailResults.householdPurchasedChannel}
                          displayType="text"
                          decimalScale={0}
                        />
                      </div>
                      <p>sähköpostin saaneista talouksista lunasti vähintään yhden edun</p>
                    </div>
                  </div>

                  <div className="box-section">
                    <div className="result email-results">
                      <h4>Pull</h4>
                      <div className="number-display">
                        <NumericFormat
                          value={
                            100 *
                            calculateDivisionSafe(
                              results.emailResults.householdPurchasedChannel,
                              results.emailResults.targetedHouseholds,
                            )
                          }
                          displayType="text"
                          decimalScale={1}
                          decimalSeparator=","
                          suffix="%"
                        />
                      </div>
                      <p>sähköpostin saaneista talouksista lunasti vähintään yhden edun</p>
                      {!_.isUndefined(stats) && !_.isUndefined(stats.pullAverage) ? (
                        <div>
                          <div className="number-display">
                            (
                            <NumericFormat
                              value={Number(stats.pullAverage)}
                              displayType="text"
                              decimalScale={1}
                              decimalSeparator=","
                              suffix="%"
                            />
                            )
                          </div>
                          <p>Muut saman lähetyksen tehneet</p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </Fragment>
              )}
              {(isKRauta || isOnninen) && concept.type !== ConceptType.B2b && results && (
                <Fragment>
                  <div className="box-section">
                    <div className="result email-results">
                      <h4>MYYNTI</h4>
                      <div className="number-display">
                        <NumericFormat
                          value={Math.abs(
                            calculateDivisionSafe(
                              results.emailResults.householdSales,
                              results.emailResults.householdReceipts,
                            ),
                          )}
                          displayType="text"
                          suffix="€"
                          decimalSeparator=","
                          decimalScale={2}
                        />
                      </div>
                      <p>Sähköpostin avanneista talouksien keskiostos (verollinen)</p>
                      <div className="number-display">
                        <NumericFormat
                          value={Math.abs(results.emailResults.householdSales)}
                          displayType="text"
                          suffix="€"
                          decimalSeparator=","
                          decimalScale={2}
                        />
                      </div>
                      <p>Sähköpostin avanneista talouksien myynti (verollinen)</p>
                    </div>
                  </div>

                  <div className="box-section">
                    <div className="result email-results">
                      <h4>ASIOINTI</h4>
                      <div className="number-display">
                        <NumericFormat
                          value={
                            100 *
                            calculateDivisionSafe(results.emailResults.householdReceipts, results.emailResults.opens)
                          }
                          displayType="text"
                          suffix="%"
                          decimalScale={1}
                        />
                      </div>
                      <p>Sähköpostin avanneista talouksista päätyi ostoon</p>
                      {!_.isUndefined(stats) && !_.isUndefined(stats.pullAverage) ? (
                        <div>
                          <div className="number-display">
                            (
                            <NumericFormat
                              value={Number(stats.pullAverage)}
                              displayType="text"
                              decimalScale={1}
                              decimalSeparator=","
                              suffix="%"
                            />
                            )
                          </div>
                          <p>Muut saman lähetyksen tehneet</p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </Fragment>
              )}
            </Fragment>
          ) : (
            <div className="box-section">
              <div className="result no-results">Ei tuloksia saatavilla.</div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderDeliveryPrintChannel = () => {
    return (
      <div className="box channel-box channel-print ">
        <h3 className="channel-title">
          Printti:{' '}
          {delivery.selectionCounts && (
            <Fragment>
              toteutuneita vastaanottajia <span className="channel-count">{counts.print}</span> taloutta
              {dateFns.isFuture(new Date(delivery.startDate)) && ' (Ei vielä lähetetty)'}
            </Fragment>
          )}
        </h3>
        <h4 className="channel-subtitle">
          {delivery.deliveryIdentifiers.filter((identifier) => identifier.includes('_print_')).join(', ')}
        </h4>
        <div className="box-sections">
          <div className="box-section">
            {delivery.printPreviewThumb?.[deliveryStore.previewLanguage] ? (
              <div className="channel-preview">
                <Link to={`preview/delivery/print/${delivery.id}`}>
                  <div className="preview-wrapper">
                    <img src={delivery.printPreviewThumb[deliveryStore.previewLanguage]} alt="preview" />
                  </div>
                  <div className="preview-icon">
                    <IconPreview />
                  </div>
                </Link>
              </div>
            ) : (
              <Spinner />
            )}
          </div>
          {results && results.printDeliveryResultsAvailable ? (
            <Fragment>
              <div className="box-section">
                <div className="result print-results">
                  <h4>Printti</h4>
                  <div className="number-display">
                    <NumericFormat
                      value={results.printResults.targetedHouseholds}
                      displayType="text"
                      decimalScale={0}
                      fixedDecimalScale
                    />
                  </div>
                  <p>Printtilähetyksen vastaanottanutta taloutta</p>
                  {invoicesStore.deliveryIdToInvoiceSum.get(delivery.id) && (
                    <Fragment>
                      <div className="number-display">
                        <NumericFormat
                          value={invoicesStore.deliveryIdToInvoiceSum.get(delivery.id)}
                          displayType="text"
                          decimalScale={2}
                          fixedDecimalScale
                          decimalSeparator=","
                          suffix="€"
                        />
                      </div>
                      <p>Printtilähetyksen kustannus (paino + posti)</p>
                    </Fragment>
                  )}
                </div>
              </div>
              {results.offerResultsAvailable && (
                <Fragment>
                  <div className="box-section">
                    <div className="result print-results">
                      <h4>Etujen lunastukset</h4>
                      <div className="number-display">
                        <NumericFormat
                          value={results.printResults.householdPurchasedChannel}
                          displayType="text"
                          decimalScale={0}
                        />
                      </div>
                      <p>printin saaneista talouksista lunasti vähintään yhden edun</p>
                    </div>
                  </div>

                  <div className="box-section">
                    <div className="result print-results">
                      <h4>Pull</h4>
                      <div className="number-display">
                        <NumericFormat
                          value={
                            100 *
                            calculateDivisionSafe(
                              results.printResults.householdPurchasedChannel,
                              results.printResults.targetedHouseholds,
                            )
                          }
                          displayType="text"
                          decimalScale={1}
                          decimalSeparator=","
                          suffix="%"
                        />
                      </div>
                      <p>Kohderyhmän talouksista lunasti yhden tai useamman edun</p>
                    </div>
                  </div>
                </Fragment>
              )}
            </Fragment>
          ) : (
            <div className="box-section">
              <div className="result no-results">Ei tuloksia saatavilla.</div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderDeliveryOffer = (offer: DeliveryOffer) => {
    const offerResults = results && (_.get(results, ['combinedOfferResults', offer.offerId]) as CombinedOfferResult);
    const pull = offerResults
      ? calculateDivisionSafe(offerResults.householdPurchased, offerResults.targetedHouseholds)
      : null;
    return (
      <div key={offer.id} className="box offer-box">
        <h3 className="offer-title">{offer.title.fi}</h3>
        {offer.products &&
          offer.products.map((product) =>
            offer.products !== null && product.ean !== undefined ? (
              <h4 className="offer-eancode" key={product.ean}>
                EAN-koodi: {product.ean}
              </h4>
            ) : null,
          )}
        <h4 className="offer-subtitle">{offer.offerId}</h4>
        <div className="box-sections">
          <div className="box-section">
            <div className="offer-image">
              <img src={imageSrc(offer.image, { fm: 'png', w: 400 })} alt="offer" />
            </div>
          </div>
          {offerResults ? (
            <Fragment>
              <div className="box-section">
                <div className="result offer-results">
                  <h4>Etujen lunastukset</h4>
                  <div className="number-display">
                    <NumericFormat value={offerResults.totalRedeemers} displayType="text" decimalScale={0} />
                  </div>
                  <p>lunastettua etua kampanja-aikana</p>
                  <div className={isKCM ? 'number-display hidden' : 'number-display'}>
                    <NumericFormat
                      value={Math.abs(offerResults.totalOfferValue)}
                      displayType="text"
                      suffix="€"
                      decimalSeparator=","
                      decimalScale={2}
                    />
                  </div>
                  <p className={isKCM ? 'hidden' : ''}>asiakkaille annettujen alennusten yhteenlaskettu arvo</p>
                </div>
              </div>
              <div className="box-section">
                <div className="result offer-results">
                  <h4>Pull</h4>
                  <div className="number-display">
                    {_.isFinite(pull) ? (
                      <NumericFormat
                        value={100 * pull}
                        displayType="text"
                        decimalScale={1}
                        decimalSeparator=","
                        suffix="%"
                      />
                    ) : (
                      '-'
                    )}
                  </div>
                  <p>talouksista lunasti edun</p>
                </div>
              </div>
            </Fragment>
          ) : (
            <div className="box-section">
              <div className="result no-results">Ei lunastettuja tarjouksia.</div>
            </div>
          )}
        </div>
      </div>
    );
  };

  if (!isReady) {
    return <Spinner />;
  }

  const { emailResults } = results;
  const bttHeader1Label =
    delivery.meta?.type === ConceptType.B2b ? 'Sähköpostia lähestytty' : 'Plussa-asiakasta lähestytty';

  return (
    <>
      <div className="delivery-view">
        <div className="main-content">
          <div className="content">
            {renderDeliveryHeader()}

            {delivery.status === DeliveryStatus.Editable && (
              <Alert level={AlertLevel.Info} className="editable-notice">
                <div className="notice-text">
                  Lähetys on vielä muokattavissa{' '}
                  <strong>{date.format(castDate(castDate(delivery.deadline)), 'd.M.yyyy')}</strong> kello{' '}
                  <strong>23:59</strong> asti
                </div>
                <Button color="bordered" link={getLink('editDelivery', delivery.id, template.id)}>
                  Muokkaa lähetystä
                </Button>
              </Alert>
            )}

            {results && (
              <div className="results box">
                <div className="box-section">
                  <div className="big-number-display">
                    <div className="big-number">
                      <NumericFormat
                        value={counts ? counts.email + counts.print : 0}
                        displayType="text"
                        thousandSeparator="&nbsp;"
                      />
                    </div>
                    <label>Arvioitu vastaanottajamäärä</label>
                  </div>
                </div>
                {/* GT header box1 */}
                {isKRuoka && !_.isUndefined(results.targetedHouseholdsTotal) && (
                  <div className="box-section">
                    <div className="big-number-display">
                      <div className="big-number">
                        <NumericFormat
                          value={results.targetedHouseholdsTotal}
                          displayType="text"
                          thousandSeparator="&nbsp;"
                        />
                      </div>
                      <label>Plussa-taloutta lähestytty</label>
                    </div>
                  </div>
                )}
                {/* GT header box2 */}
                {isKRuoka && !_.isUndefined(results.householdPurchasedTotal) && (
                  <div className="box-section">
                    <div className="big-number-display">
                      <div className="big-number">
                        <NumericFormat
                          value={results.householdPurchasedTotal}
                          displayType="text"
                          thousandSeparator="&nbsp;"
                        />
                      </div>
                      <label>edun lunastanutta taloutta</label>
                    </div>
                  </div>
                )}
                {/* GT header box3 */}
                {isKRuoka && (
                  <div className="box-section">
                    <div className="big-number-display">
                      <div className="big-number">
                        {_.isFinite(results.pullPercentage) ? (
                          <NumericFormat
                            value={results.pullPercentage}
                            displayType="text"
                            suffix="%"
                            decimalScale={1}
                            decimalSeparator=","
                          />
                        ) : (
                          '-'
                        )}
                      </div>
                      <label>talouksista lunasti vähintään yhden edun.</label>
                      {!_.isUndefined(stats) && !_.isUndefined(stats.pullAverage) ? (
                        <p>
                          Muut saman lähetyksen tehneet (
                          <b>
                            pull% ka.&nbsp;
                            <NumericFormat
                              value={Number(stats.pullAverage)}
                              displayType="text"
                              decimalScale={1}
                              decimalSeparator=","
                              suffix="%"
                            />
                          </b>
                          )
                        </p>
                      ) : null}
                    </div>
                  </div>
                )}
                {/* K-Rauta & Onninen header box1 */}
                {(isKRauta || isOnninen) && results.totalRecipients !== undefined && (
                  <div className="box-section">
                    <div className="big-number-display">
                      <div className="big-number">
                        <NumericFormat value={results.totalRecipients} displayType="text" thousandSeparator="&nbsp;" />
                      </div>
                      <label>{bttHeader1Label}</label>
                    </div>
                  </div>
                )}
                {/* K-Rauta and Onninen header box2 */}
                {(isKRauta || isOnninen) &&
                  !_.isUndefined(emailResults.totalRecipients) &&
                  !_.isUndefined(emailResults.opens) && (
                    <div className="box-section">
                      <div className="big-number-display">
                        <div className="big-number">
                          <NumericFormat
                            value={100 * calculateDivisionSafe(emailResults.opens, emailResults.totalRecipients)}
                            displayType="text"
                            suffix="%"
                            decimalScale={1}
                          />
                        </div>
                        <label>Sähköpostin saaneista ovat avanneet viestin</label>
                      </div>
                    </div>
                  )}
                {/* K-Rauta header box3 */}
                {isKRauta && concept.type !== ConceptType.B2b && (
                  <div className="box-section">
                    <div className="big-number-display">
                      <div className="big-number">
                        <NumericFormat
                          value={100 * calculateDivisionSafe(emailResults.householdReceipts, emailResults.opens)}
                          displayType="text"
                          suffix="%"
                          decimalScale={1}
                        />
                      </div>
                      <label>Sähköpostin avanneista talouksista päätyi ostoon</label>
                      {!_.isUndefined(stats) && !_.isUndefined(stats.pullAverage) ? (
                        <p>
                          Muut saman lähetyksen tehneet (
                          <b>
                            pull% ka.&nbsp;
                            <NumericFormat
                              value={Number(stats.pullAverage)}
                              displayType="text"
                              decimalScale={1}
                              decimalSeparator=","
                              suffix="%"
                            />
                          </b>
                          )
                        </p>
                      ) : null}
                    </div>
                  </div>
                )}
              </div>
            )}

            <h2>Lähetyksen kohdennus</h2>
            <DeliveryTarget delivery={delivery} conceptType={concept.type as ConceptType} />
            <div className="section-header">
              <h2>Kanavat</h2>
              {concept.type === ConceptType.Program && infoButton({ slug: 'info-lunastusprosentti' })}
              {Boolean(delivery.targetGroup.channels.email) && renderDeliveryEmailChannel()}
              {Boolean(delivery.targetGroup.channels.print) && renderDeliveryPrintChannel()}
            </div>

            {delivery.deliveryOffers && delivery.deliveryOffers.length > 0 && (
              <Fragment>
                <h2>Edut ja tuotenostot</h2>
                {delivery.deliveryOffers.map(renderDeliveryOffer)}
              </Fragment>
            )}

            <pre className="debug">{JSON.stringify(delivery, null, 2)}</pre>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default inject(
  'deliveryStore',
  'templateStore',
  'resultsStore',
  'alertStore',
  'userStore',
  'contentStore',
  'invoicesStore',
  'conceptStore',
)(observer(DeliveryView));
