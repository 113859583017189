import React from 'react';
import type { DeliveryLanguage } from 'types/next';
import './languageSelector.scss';

interface Props {
  language: DeliveryLanguage;
  languages: DeliveryLanguage[];
  toggleLanguage(l: DeliveryLanguage);
}

export const LanguageSelector = (props) => {
  const { language, languages, toggleLanguage } = props;
  const toggleButtonClass = (lang: DeliveryLanguage) => `toggle-language ${lang === language ? ' is-selected' : ''}`;
  const renderButtons = (): JSX.Element[] =>
    languages.map((lang: DeliveryLanguage) => (
      <button key={lang} className={toggleButtonClass(lang)} onClick={() => toggleLanguage(lang)}>
        {lang === 'fi' ? 'Suomi' : 'Ruotsi'}
      </button>
    ));
  return <div className="language-selector">{renderButtons()}</div>;
};

export default LanguageSelector;
