import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Role } from 'types/user';
import UserStore from 'stores/userStore';
import { adminBase, retailerBase } from '../routes';

interface Props {
  userStore?: UserStore;
}

const NextRedirect = ({ userStore }: Props) => {
  const location = useLocation();
  const { me } = userStore;

  if (me && me.role === Role.Admin) {
    return <Navigate to={adminBase} replace />;
  }
  if (me && me.role === Role.Retailer) {
    return <Navigate to={retailerBase} replace />;
  }
  // do auth
  return <Navigate to="/" state={{ from: location.pathname }} replace />;
};

export default inject('userStore')(observer(NextRedirect));
