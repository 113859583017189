import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import page from 'components/next/pages/page/page';
import { NavLink, useNavigate } from 'react-router-dom';
import PricingStore from 'stores/next/pricing';
import AlertStore from 'stores/next/alerts';
import { pricingItem as pricingItemRoute } from 'components/next/routes';
import type { Route } from 'components/next/types';
import Alerts from 'components/common/next/alert/alerts';
import { getPricingItemDefaults } from 'components/next/utils';
import { ReactComponent as IconDelete } from '@kesko/icons/action/icon-delete.svg';
import { ReactComponent as IconArrowRight } from '@kesko/icons/nav/icon-arrow-right.svg';

import './pricingItems.scss';

interface PricingProps {
  pricingStore?: PricingStore;
  alertStore?: AlertStore;
  getPageLink(route: Route, id: string): string;
}

const PricingItems = ({ pricingStore, alertStore, getPageLink }: PricingProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    const init = async () => {
      await pricingStore.searchPricingItems({});
    };
    init();
  }, []);

  const handleCreate = async () => {
    try {
      const pricingItem = await pricingStore.createPricingItem(getPricingItemDefaults());
      navigate(getPageLink(pricingItemRoute, pricingItem.id));
    } catch (err) {
      alertStore.error({ message: err.message, ttl: 0 });
    }
  };

  const handleDelete = (id: string) => async () => {
    if (!window.confirm('Are you sure you want to delete this pricing item? This action cannot be undone.')) {
      return;
    }

    try {
      await pricingStore.deletePricingItem(id);
    } catch (err) {
      alertStore.error({ message: err.message, ttl: 0 });
    }
  };

  const { pricingItems, pricingItemsInUse } = pricingStore;

  return (
    <div className="content pricing-items">
      <Alerts />
      <header>
        <h2>Pricing items</h2>
        <button className="add-content-template" onClick={handleCreate}>
          <span>Add pricing item</span>
          <img src={require('images/add.svg').default} alt="add" />
        </button>
      </header>

      <table className="styled entity-grid">
        <thead>
          <tr>
            <th style={{ width: '80%' }}>Title</th>
            <th>Order</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {pricingItems.map((p, i) => (
            <tr key={p.id}>
              <td>{p.title}</td>
              <td>{p.order}</td>
              <td>
                {pricingItemsInUse[p.id] ? null : (
                  <button onClick={handleDelete(p.id)}>
                    <IconDelete />
                  </button>
                )}
                <NavLink to={p.id}>
                  <IconArrowRight />
                </NavLink>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default page(inject('pricingStore', 'alertStore')(observer(PricingItems)));
