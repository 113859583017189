import React from 'react';
import _ from 'utils/lodash';
import InputText from 'components/common/next/form/inputText';

import './inputLink.scss';

interface Props {
  value: { text: string; href: string };
  onChange: ({ text, href }: { text: string; href: string }) => void;
  label?: string;
  readOnly?: boolean;
  name: string;
}

interface State {}

class InputLink extends React.Component<Props, State> {
  get text() {
    return _.get(this.props, ['value', 'text']);
  }

  get href() {
    return _.get(this.props, ['value', 'href']);
  }

  public onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange } = this.props;
    const text = e.target.value;
    onChange({ text, href: this.href });
  };

  public onHrefChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange } = this.props;
    const href = e.target.value;
    onChange({ text: this.text, href });
  };

  public render() {
    const { label, name, readOnly } = this.props;
    return (
      <div className="form-control">
        {label && <label>{label}</label>}
        <InputText
          name={`${name}-text`}
          placeholder="Linkin teksti"
          value={this.text}
          onChange={this.onTextChange}
          readOnly={readOnly}
        />

        <InputText
          name={`${name}-href`}
          placeholder="http://www.plussa.com"
          value={this.href}
          onChange={this.onHrefChange}
          readOnly={readOnly}
        />
      </div>
    );
  }
}

export default InputLink;
