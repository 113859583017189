import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Outlet } from 'react-router-dom';
import { find } from 'lodash';
import { getChain, scrollToTop } from 'utils/helpers';
import { getLink } from './routes';
import UserStore from 'stores/userStore';
import ConceptStore from 'stores/next-retailer/conceptStore';
import DeliveryStore from 'stores/next-retailer/deliveryStore';
import TemplateStore from 'stores/next-retailer/templateStore';
import MobileNavigation from 'components/common/mobileNavigation';
import Select from 'components/common/select';
import UserNavigation from './components/nav/userNavigation';
import MobileHeader from 'components/common/mobileHeader';
import NameLogo from 'components/common/nameLogo';
import NavigationLinks from './components/nav/navLinks';
import Breadcrumb from './components/common/breadcrumb';
import GitHash from '../../common/next/gitHash';

import './layout.scss';
import Alerts from 'components/common/next/alert/alerts';
import AppStore from 'stores/next/app';
import AnalyticsStore from 'stores/next-retailer/analyticsStore';
import ContentStore from 'stores/next-retailer/contentStore';
import PushNotificationsStore from 'stores/next-retailer/pushNotificationsStore';

interface Props {
  isMobile: boolean;
}

interface InjectedProps extends Props {
  userStore?: UserStore;
  appStore?: AppStore;
  conceptStore?: ConceptStore;
  deliveryStore?: DeliveryStore;
  templateStore?: TemplateStore;
  analyticsStore?: AnalyticsStore;
  contentStore?: ContentStore;
  pushNotificationsStore?: PushNotificationsStore;
}

const Layout = ({
  userStore,
  appStore,
  conceptStore,
  deliveryStore,
  templateStore,
  analyticsStore,
  contentStore,
  pushNotificationsStore,
  isMobile,
}: InjectedProps) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const chainName = getChain([userStore.me.chainId]);
    if (appStore.chain !== chainName) {
      appStore.setChain(chainName);
    }
  }, [userStore.me]);

  const storeLogin = async (storeId: string) => {
    conceptStore.unsetCurrent();
    deliveryStore.unsetCurrent();
    templateStore.unsetCurrent();
    contentStore.unsetCurrent();
    conceptStore.resetCollection();
    deliveryStore.resetCollection();
    templateStore.resetCollection();
    contentStore.resetCollection();
    pushNotificationsStore.resetCollection();
    analyticsStore.unsetAnalytics();
    await userStore.selectStore(storeId);
    scrollToTop();
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prevIsMobileMenuOpen) => !prevIsMobileMenuOpen);
  };

  const { stores, me } = userStore;
  const { storeData } = me;
  const retailerNameProp =
    storeData &&
    find(storeData.additionalProperty, {
      name: 'RetailerName',
    });
  const retailerName = retailerNameProp?.value ? retailerNameProp.value : me.name;
  const storeId = me.store;
  const storeName = storeData ? storeData.name : 'Kaupan tietoja ei löytynyt.';

  return (
    <div className="main-container">
      {isMobile ? (
        isMobileMenuOpen && (
          <MobileNavigation className={appStore.chain} closeMenu={toggleMobileMenu}>
            <div className="top-navigation">
              <UserNavigation storeId={storeId} retailerName={retailerName} nega={true} />
              <Select
                placeholder="Valitse..."
                selectClass="store-select"
                selected={{ label: storeName, value: storeId }}
                options={stores}
                compress={true}
                noInput={true}
                onClick={storeLogin}
              />
            </div>
            <NavigationLinks storeId={storeId} />
          </MobileNavigation>
        )
      ) : (
        <nav className={`navigation ${appStore.chain}`}>
          <NameLogo toPath={getLink('front')} />
          <hr style={{ marginTop: 0 }} />
          <NavigationLinks storeId={storeId} />
          <GitHash />
        </nav>
      )}
      {isMobile && isMobileMenuOpen && <div className="content-overlay" onClick={toggleMobileMenu} />}
      <div className="content-with-header">
        {isMobile ? (
          <MobileHeader logoLink={getLink('front')} menuOpen={isMobileMenuOpen} onClick={toggleMobileMenu} />
        ) : (
          <div className="header store-navigation">
            <div className="flex-content">
              <Select
                text="Valittu kauppa"
                placeholder="Valitse..."
                selectClass="store-select"
                selected={{ label: storeName, value: storeId }}
                options={stores}
                compress={true}
                onClick={storeLogin}
              />
              <UserNavigation storeId={storeId} retailerName={retailerName} />
            </div>
          </div>
        )}
        <div className="retailer content-with-header">
          <Breadcrumb />
          <Outlet />
          <Alerts />
        </div>
      </div>
    </div>
  );
};

export default inject(
  'userStore',
  'appStore',
  'conceptStore',
  'deliveryStore',
  'templateStore',
  'analyticsStore',
  'contentStore',
  'pushNotificationsStore',
)(observer(Layout));
