import React from 'react';
import { inject, observer } from 'mobx-react';
import { ConceptType } from 'enums/common';
import ConceptListView from '../concept/conceptList';
import UserStore from 'stores/userStore';
import { isKRautaChain } from 'utils/helpers';

interface Props {
  userStore?: UserStore;
}

const B2BListView = ({ userStore }: Props) => {
  const isKRauta = isKRautaChain(userStore.me.chainId);

  const b2b_header = isKRauta ? 'B2B-lähetykset' : 'Myymälän Lähetykset';
  const b2b_content = isKRauta
    ? `B2B-lähetykset ovat  yritysasiakkaille suunnattuja lähetyksiä.`
    : `Myymälän Lähetykset ovat yritysasiakkaille suunnattuja lähetyksiä.`;

  return (
    <div className="front-page ">
      <div className="main-content">
        <div className="content">
          <h2>{b2b_header}</h2>
          <p>{b2b_content}</p>
          <ConceptListView filters={{ type: ConceptType.B2b }} />
        </div>
      </div>
    </div>
  );
};

export default inject('userStore')(observer(B2BListView));
