import React from 'react';
import './page.scss';
import { useParams } from 'react-router-dom';
import type { DeliveryTemplate, Route } from 'components/next/types';
import { ConceptType } from 'enums/common';

type pageParams = {
  chain: string;
};

interface PageProps {
  children?: React.ReactNode;
  mode?: any;
  chainIds?: string[];
  editedTemplates?: string[];
  informListPage?(data: DeliveryTemplate): void;
  informParentPage?(data: any): void; // inform about changes in the content such as user editing Dimension
  unsavedChanges?: boolean;
  conceptType?: ConceptType;
  keskoStampCard?: boolean;
}

// eslint-disable-next-line react/display-name
const Page = (ContentComponent) => (props: PageProps) => {
  const { chain } = useParams<pageParams>();
  const getPageLink = (route: Route, id: string, tab?: string): string => {
    return route.link
      .replace(/:chain/, chain)
      .replace(/:id/, id)
      .replace(/:tab/, tab || '');
  };
  return (
    <div className="page">
      <ContentComponent {...props} getPageLink={getPageLink} />
    </div>
  );
};

export default Page;
