import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import UserStore from 'stores/userStore';
import { useNavigate } from 'react-router-dom';

interface InjectedProps {
  userStore?: UserStore;
}

const Logout = ({ userStore }: InjectedProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    const init = async () => {
      await userStore.logout();
      navigate('/', { replace: true });
    };
    init();
  }, []);

  return null;
};

export default inject('userStore')(observer(Logout));
