import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import type { Store } from 'components/next/types';
import { CustomField } from 'components/next/components/form/input';
import './storeSelector.scss';
import { useCombobox } from 'downshift';

interface Props {
  detail?: string;
  stores: Store[];
  limit?: number;
  defaultStoreIds: string[];
  label?: string;
  placeholder?: string;
  onChange(stores: Store[]): void;
}

const StoreSelector = ({ detail, stores, limit, defaultStoreIds, label, placeholder, onChange }: Props) => {
  const [filteredStores, setFilteredStores] = useState<Store[]>(stores);

  useEffect(() => {
    setFilteredStores(filterStoresWith(inputValue));
  }, [stores, defaultStoreIds]);

  const selected = _.filter(stores, (store: Store) => _.includes(defaultStoreIds, store.storeId));

  const filterStoresWith = (inputValue: string) => {
    const inputFiltered = _.filter(stores, (store: Store) => {
      return (
        (store.name.toLowerCase().includes(inputValue.toLowerCase()) ||
          store.storeId.toLowerCase().startsWith(inputValue.toLowerCase())) &&
        !store.removed
      );
    });

    const selectedFiltered = _.take(
      _.filter(inputFiltered, (store: Store) => !_.includes(_.map(selected, 'storeId'), store.storeId)),
      limit || 10,
    );
    return selectedFiltered;
  };

  const { isOpen, getLabelProps, getMenuProps, getInputProps, highlightedIndex, getItemProps, inputValue, selectItem } =
    useCombobox({
      items: filteredStores,
      stateReducer: (state, actionAndChanges) => {
        const { type, changes } = actionAndChanges;
        switch (type) {
          // If an item was selected, empty the input value
          case useCombobox.stateChangeTypes.ItemClick:
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
            return {
              ...changes,
              inputValue: '',
            };
          default:
            return changes;
        }
      },
      onInputValueChange: ({ inputValue }) => {
        setFilteredStores(filterStoresWith(inputValue));
      },
      onSelectedItemChange: ({ selectedItem }) => {
        if (selectedItem) {
          onChange([...selected, selectedItem]);
        }
      },
    });

  const handleRemove = (storeId: string) => {
    onChange(_.filter(selected, (store: Store) => store.storeId !== storeId));
    selectItem(null);
  };

  const renderSelected = () => {
    if (selected.length === 0) {
      return null;
    }

    return (
      <div className="selected">
        {_.map(selected, (store: Store) => {
          return (
            <div key={store.storeId} className="selected__store">
              {store.storeId} {store.name}
              <button className="selected__remove" onClick={() => handleRemove(store.storeId)}>
                remove
              </button>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="store-selector">
      <CustomField detail={selected.length === 0 ? detail : null}>
        {label && <label {...getLabelProps}>{label}</label>}
        <div className="store-selector__wrapper">
          <div>
            <input {...getInputProps({ placeholder: placeholder || 'Search for stores' })} />
          </div>
          <ul
            {...getMenuProps({
              className: `store-selector__list${
                !(isOpen && filteredStores.length) ? ' store-selector__list--hidden' : ''
              }`,
            })}
          >
            {isOpen &&
              filteredStores.map((item, index) => (
                <li
                  key={`${item.storeId}${index}`}
                  {...getItemProps({
                    item,
                    index,
                    className: `store-selector__store${
                      highlightedIndex === index ? ' store-selector__store--highlight' : ''
                    }`,
                  })}
                >
                  {item.name}
                </li>
              ))}
          </ul>
        </div>
        {renderSelected()}
      </CustomField>
    </div>
  );
};

export default StoreSelector;
