import React from 'react';
import './radioPill.scss';

interface Props {
  value: string;
  name: string;
  label: string;
  className?: string;
  checked?: boolean;
  defaultChecked?: boolean;
  onChange?: (e: React.SyntheticEvent) => any;
}

const RadioPill = ({ value, name, label, className, onChange, defaultChecked, checked }: Props) => (
  <div className={`radio-pill ${className || ''}`}>
    <input type="radio" id={`radio-${value}`} {...{ value, name, onChange, defaultChecked, checked }} />
    {label && <label htmlFor={`radio-${value}`}>{label}</label>}
  </div>
);

export default RadioPill;
