import { observable, action } from 'mobx';
import type { Dimension } from 'types/next';
import type { Client } from '../../types/next-api';

export default class MetadataStore {
  client: Client;

  @observable dimensions: Dimension[];

  @action async getDimensionsByChain(chainIds: string[], type?: string) {
    const result = await this.client.searchDimensions(null, {
      chainId: [chainIds[0], ...chainIds.slice(1)],
      type: type,
    });
    if (result.status === 200) {
      this.dimensions = result.data;
    }
  }
}
