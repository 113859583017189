import React from 'react';
interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  detail?: string;
  multiple?: boolean;
}

const InputSelect = ({ children, label, ...props }: Props) => {
  return (
    <div className="form-control">
      {label && <label>{label}</label>}
      <select {...props}>{children}</select>
      {props.detail && <span className="detail-text">{props.detail}</span>}
    </div>
  );
};

export default InputSelect;
