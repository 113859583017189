import _ from 'lodash';
import { observable, action, runInAction } from 'mobx';
import type { Client } from 'types/next-api';
import type { Store } from 'types/next';

interface SearchProps {
  query?: string;
  chainIds?: string[];
  limit?: number;
  offset?: number;
  includeTestStores?: boolean;
}

export default class StoreStore {
  client: Client;
  @observable stores: Store[] = [];

  @action async searchStores({ query, limit, offset, chainIds, includeTestStores }: SearchProps) {
    try {
      const {
        data: { result },
      } = await this.client.searchStores({ limit, offset }, { query, chainId: chainIds, includeTestStores });
      runInAction(() => {
        this.stores = result;
      });
      return result;
    } catch (e: any) {
      console.log(`Error searching stores: ${e.message}`);
      return [];
    }
  }
}
