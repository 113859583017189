import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'utils/lodash';
import { format, isBefore, isAfter } from 'date-fns';
import AlertStore from 'stores/next/alerts';
import UserStore from 'stores/userStore';
import StampCardStoreNew from 'stores/next-retailer/stampCardStore';
import Spinner from 'components/common/next/spinner';
import Alerts from 'components/common/next/alert/alerts';
import CloseButton from 'components/retailer/next/components/primitives/closeButton';
import { isMobile } from 'components/retailer/next/utils/helpers';
import { getLink } from 'components/retailer/next/routes';
import Button from 'components/common/next/form/button';
import GitHash from 'components/common/next/gitHash';
import {
  StampCardTypeTab,
  StampCardRewardTab,
  StampCardValidityTab,
  StampCardAppearanceTab,
  StampCardSummaryTab,
} from './tabs';
import './stampCardEditor.scss';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { createKeskoStampCard, editKeskoStampCard, keskoStampCards } from 'components/next/routes';
import { kRuokaChainIds } from 'constants/common';

export const parseNumber = (value) => (value === '' ? '' : Number(value.replace(',', '.')));

export interface StampCardEditorTabProps {
  userStore: UserStore;
  stampCardStoreNew: StampCardStoreNew;
  isStampCardEditable: boolean;
  mode: StampCardEditorMode;
  id: string;
  currentTab: string;
  keskoStampCard?: boolean;
}

export enum StampCardEditorMode {
  Create = 'create',
  Edit = 'edit',
}

interface InjectedProps {
  userStore?: UserStore;
  stampCardStoreNew?: StampCardStoreNew;
  alertStore?: AlertStore;
}

interface Props extends InjectedProps {
  keskoStampCard?: boolean;
  mode?: StampCardEditorMode;
}

type Params = {
  mode: StampCardEditorMode;
  id: string;
  tab: string;
  chain?: string;
};

const TABS = [
  {
    path: 'type',
    text: 'Keräilypassin tyyppi',
    component: StampCardTypeTab,
  },
  {
    path: 'reward',
    text: 'Palkintoetu',
    component: StampCardRewardTab,
  },
  {
    path: 'validity',
    text: 'Voimassaolo',
    component: StampCardValidityTab,
  },
  {
    path: 'appearance',
    text: 'Ulkoasu',
    component: StampCardAppearanceTab,
  },
  {
    path: 'summary',
    text: 'Yhteenveto',
    component: StampCardSummaryTab,
  },
];

const StampCardEditor = ({ userStore, stampCardStoreNew, alertStore, keskoStampCard, mode }: Props) => {
  const navigate = useNavigate();
  const {
    mode: modeParam = mode || StampCardEditorMode.Create,
    id: idParam,
    tab: tabParam = 'type',
    chain: chainParam,
  } = useParams<Params>();

  const getTabProps = () => {
    const i = _.findIndex(TABS, (t) => t.path === tabParam);
    if (i === -1) {
      return null;
    }
    const isFirst = i === 0;
    const isLast = i === TABS.length - 1;
    return {
      nextPath: isLast ? null : TABS[i + 1].path,
      nextLink: isLast
        ? null
        : keskoStampCard
          ? modeParam === StampCardEditorMode.Create
            ? createKeskoStampCard.readyLink(chainParam, TABS[i + 1].path)
            : editKeskoStampCard.readyLink(chainParam, idParam, TABS[i + 1].path)
          : getLink('stampCardEditor', modeParam, idParam, TABS[i + 1].path),
      previousLink: isFirst
        ? null
        : keskoStampCard
          ? modeParam === StampCardEditorMode.Create
            ? createKeskoStampCard.readyLink(chainParam, TABS[i - 1].path)
            : editKeskoStampCard.readyLink(chainParam, idParam, TABS[i - 1].path)
          : getLink('stampCardEditor', modeParam, idParam, TABS[i - 1].path),
      isFirst,
      isLast,
      TabComponent: TABS[i].component,
      isTabValid: stampCardStoreNew.isEditorTabValid[tabParam],
    };
  };

  const getEnabledTabs = () => {
    const { isEditorTabValid, isEditorTabVisited } = stampCardStoreNew;
    const enabledTabs = [];
    for (const tab of TABS) {
      enabledTabs.push(isEditorTabValid[tab.path] && isEditorTabVisited[tab.path]);
    }
    return enabledTabs;
  };

  const isStampCardEditable = () =>
    (stampCardStoreNew.original && !isBefore(new Date(stampCardStoreNew.original.activeFrom), new Date())) ||
    modeParam !== StampCardEditorMode.Edit;

  useEffect(() => {
    const init = async () => {
      if (!getTabProps) {
        navigate(getLink('stampCards'));
      }
      if (!stampCardStoreNew.current) {
        alertStore.clear();
        if (modeParam === StampCardEditorMode.Create) {
          // Initialize with chains if keskoStampCard
          await stampCardStoreNew.initializeCardWithTemplate({
            cardTemplateId: idParam,
            me: userStore.me,
            chains: keskoStampCard ? ['3', '4', '5'] : undefined,
            keskoStampCard,
          });
        } else {
          await stampCardStoreNew.getCollectionStampCard(idParam);
        }
        if (keskoStampCard) {
          if (modeParam === StampCardEditorMode.Create) {
            navigate(createKeskoStampCard.readyLink(chainParam, TABS[0].path));
          } else {
            navigate(editKeskoStampCard.readyLink(chainParam, idParam, TABS[0].path));
          }
        } else {
          navigate(getLink('stampCardEditor', modeParam, idParam, TABS[0].path));
        }
        stampCardStoreNew.validateCurrent();
      }
    };
    init();
  }, []);

  const handleChangeTab = (tabIndex, canNavigate) => () => {
    if (canNavigate) {
      if (keskoStampCard) {
        if (modeParam === StampCardEditorMode.Create) {
          navigate(createKeskoStampCard.readyLink(chainParam, TABS[tabIndex].path));
        } else {
          navigate(editKeskoStampCard.readyLink(chainParam, idParam, TABS[tabIndex].path));
        }
      } else {
        navigate(getLink('stampCardEditor', modeParam, idParam, TABS[tabIndex].path));
      }
    }
  };

  const handlePreviousTab = () => {
    const { previousLink } = getTabProps();
    navigate(previousLink);
  };

  const handleNextTab = () => {
    const { nextPath, nextLink } = getTabProps();
    stampCardStoreNew.setEditorTabVisited(nextPath);
    navigate(nextLink);
  };

  const handleCancel = () => {
    const confirmed = window.confirm('Oletko varma, että haluat poistua tallentamatta muutoksia?');
    if (confirmed) {
      stampCardStoreNew.reset();
      if (keskoStampCard) {
        navigate(keskoStampCards.readyLink(chainParam));
      } else {
        navigate(getLink('stampCards'));
      }
    }
  };

  const handleSubmit = async () => {
    const { activeFrom } = stampCardStoreNew.current;
    if (modeParam === StampCardEditorMode.Create) {
      const confirmed = window.confirm(
        `Haluatko varmasti luoda keräilypassin? Keräilypassi tulee voimaan ${format(new Date(activeFrom), 'd.M.yyyy')}`,
      );

      if (confirmed) {
        await stampCardStoreNew.createCollectionStampCard(stampCardStoreNew.current, keskoStampCard);
      }
    } else {
      await stampCardStoreNew.replaceCollectionStampCard(idParam, stampCardStoreNew.current, keskoStampCard);
    }
  };

  const handleDelete = async () => {
    const confirmed = window.confirm(`Haluatko varmasti poistaa keräilypassin?`);

    if (confirmed) {
      await stampCardStoreNew.deleteCollectionStampCard(stampCardStoreNew.current.id);
    }
  };

  const renderTabs = () => {
    const enabledTabs = getEnabledTabs();
    return (
      <header className="editor-header">
        <nav className="editor-header__tabs">
          {TABS.map(({ path, text }, tabIndex) => {
            const isDisabled = path !== tabParam && !enabledTabs[tabIndex];
            const canNavigate = path !== tabParam && enabledTabs[tabIndex];
            const classNames = ['tab-selector'];
            if (path === tabParam) {
              classNames.push('is-selected');
            }
            return (
              <button
                key={`tab-${path}`}
                className={classNames.join(' ')}
                onClick={handleChangeTab(tabIndex, canNavigate)}
                disabled={isDisabled}
                style={{ cursor: canNavigate ? 'pointer' : 'default' }}
              >
                {text}
              </button>
            );
          })}
        </nav>
      </header>
    );
  };

  const renderFooter = () => {
    const { isEditorValid, isLoadingStampCard } = stampCardStoreNew;
    const { activeFrom } = stampCardStoreNew.current;
    const { isFirst, isLast, isTabValid } = getTabProps();

    // Enable deleting upcoming stamp cards only on edit mode.
    const shouldEnableDelete = modeParam === StampCardEditorMode.Edit && isAfter(new Date(activeFrom), new Date());

    return (
      <div className="footer-buttons">
        <GitHash />
        {isFirst ? (
          <Button color="inverse" onClick={handleCancel} disabled={isLoadingStampCard}>
            Peruuta
          </Button>
        ) : (
          <Button color="inverse" onClick={handlePreviousTab} disabled={isLoadingStampCard}>
            Takaisin
          </Button>
        )}
        {shouldEnableDelete && (
          <Button color="bordered" onClick={handleDelete} disabled={isLoadingStampCard}>
            Poista
          </Button>
        )}
        {isLast ? (
          <Button color="default" onClick={handleSubmit} disabled={!isEditorValid || isLoadingStampCard}>
            {modeParam === StampCardEditorMode.Create ? 'Luo passi' : 'Päivitä passi'}
          </Button>
        ) : (
          <Button color="default" onClick={handleNextTab} disabled={!isTabValid || isLoadingStampCard}>
            Jatka
          </Button>
        )}
        {isLoadingStampCard && (
          <div>
            <Spinner addClassName="spinner--margin-left" />
          </div>
        )}
      </div>
    );
  };

  const { TabComponent } = getTabProps();

  if (!stampCardStoreNew.current || !TabComponent) {
    return null;
  }

  return (
    <div className="stampcard-editor main-container full-page">
      <div className="header">
        <div className="full-page-content">
          <div className="text-center">
            <h1 className="form-title">
              {modeParam === StampCardEditorMode.Create ? 'Luo uusi keräilypassi' : 'Muokkaa keräilypassia'}
            </h1>
            <CloseButton
              fallback={keskoStampCard ? keskoStampCards.readyLink(chainParam) : getLink('stampCards')}
              onClick={handleCancel}
            />
          </div>
        </div>
        <div className="text-center">{renderTabs()}</div>
      </div>

      <div className="main-content">
        <div className="full-page-content">
          <Alerts />
          {modeParam === StampCardEditorMode.Edit && (
            <div className="box">Huomioithan, että keräilypassin muokkaus ei vaikuta jo luotuihin palkintoetuihin!</div>
          )}
          <TabComponent
            userStore={userStore}
            stampCardStoreNew={stampCardStoreNew}
            isStampCardEditable={isStampCardEditable()}
            mode={modeParam as StampCardEditorMode}
            id={idParam}
            currentTab={tabParam}
            keskoStampCard={keskoStampCard}
          />
          {isMobile() && <div className="mobile-footer">{renderFooter()}</div>}
        </div>
      </div>
      {!isMobile() && <div className="footer with-space">{renderFooter()}</div>}
      <Outlet />
    </div>
  );
};

export default inject('userStore', 'stampCardStoreNew', 'alertStore')(observer(StampCardEditor));
