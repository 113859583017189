import React, { useEffect } from 'react';
import type { ContentTemplate, EmailContentTemplate, PrintContentTemplate, DeliveryLanguage } from 'types/next';
import { FormInput, CustomField, FormTextArea } from '../form/input';
import { Checkbox } from 'components/next/components/checkbox';
import ChainSelector from '../chainSelector';
import { storeData, targetData, availableFonts } from 'components/next/utils';
import HelpButton from 'components/common/next/help/helpButton';
import Dropdown, { DropdownItem } from 'components/next/components/dropdown';
import { ReactComponent as RemoveIcon } from '@kesko/icons/action/icon-delete.svg';
import _, { random } from 'lodash';
import InputImage from 'components/common/next/form/inputImage';
import { inject, observer } from 'mobx-react';
import AppStore from 'stores/next/app';
import { getAllowedChains, getExcludedChains } from 'utils/helpers';

interface Props {
  contentTemplate: ContentTemplate;
  language: DeliveryLanguage;
  update: (field: string, value: any) => void;
  updateHTML: (html: string, shouldCallUpdate?: boolean) => void;
  appStore?: AppStore;
}

const printTypes: DropdownItem[] = [
  { name: 'HTML', value: 'html' },
  { name: 'Image', value: 'image' },
  { name: 'Text', value: 'text' },
];

const EditorBody = ({ contentTemplate, language, update, updateHTML, appStore }: Props) => {
  const emailTemplate = contentTemplate.channel === 'email' ? (contentTemplate as EmailContentTemplate) : null;
  const printTemplate =
    contentTemplate.channel === 'print'
      ? (contentTemplate as PrintContentTemplate & { recipeField: { fi?: string; sv?: string } })
      : null;
  const templateId = contentTemplate.id || `new-${random(999999, false)}`;

  const templateAvailableFonts: DropdownItem[] = !printTemplate
    ? []
    : printTemplate.recipe[language].fonts.map((font) => ({ name: font.family, value: font.family }));

  useEffect(() => {
    let html = '';
    if (printTemplate) {
      html = compileHTML();
    } else {
      html = emailTemplate.html[language];
    }
    updateHTML(html, false);
  }, [language]);

  const getSelectedTypeObject = (typeName: string) => {
    if (printTemplate) {
      return printTypes.find((type) => (type.value as string) === typeName);
    }
    return null;
  };

  const handleChainChange = (chainId: string) => {
    if (contentTemplate.chainIds.includes(chainId)) {
      update(
        'chainIds',
        contentTemplate.chainIds.filter((id) => id === chainId),
      );
    } else {
      update('chainIds', contentTemplate.chainIds.concat(chainId));
    }
  };

  const renderInstructions = () => {
    return (
      <div>
        <h3>Supported K Markkinointi tags:</h3>
        <p>The following tags are supported in content template HTML and populated by K Markkinointi:</p>
        <pre>{storeData}</pre>
        <h3>Supported Adobe Campaign tags:</h3>
        <p>The following tags are supported in content template HTML and populated by Adobe Campaign:</p>
        <pre>{targetData}</pre>
      </div>
    );
  };

  const compileHTML = () => {
    if (printTemplate) {
      return printTemplate.recipe[language].elements.map((elem) => (elem.type === 'html' ? elem.html : '')).join('');
    }
    return emailTemplate.html[language];
  };

  const editHTML = (value: string) => {
    updateHTML(value);
  };

  const editRecipeLayer = (index: number, url: string) => {
    const layers = [...printTemplate.recipe[language].layers];
    layers[index] = { url };
    update(`recipe.${language}.layers`, layers);
  };

  const editElement = (index: number, propertyName: string, value: string | number) => {
    const elements = [...printTemplate.recipe[language].elements];
    const val = _.isNaN(value) ? '' : value;
    const element = elements[index];

    // on HTML elements, set pageWidth and pageHeight to match width and height automatically to avoid user confusion
    // (mismatch causes weird scaling issues in end product PDF)
    if (element.type === 'html') {
      if (propertyName === 'width') {
        _.set(element, 'pageWidth', `${value}px`);
      }
      if (propertyName === 'height') {
        _.set(element, 'pageHeight', `${value}px`);
      }
    }
    _.set(element, propertyName, val);
    elements[index] = element;
    update(`recipe.${language}.elements`, elements);
  };

  const editElementHTML = (index: number, html: string) => {
    editElement(index, 'html', html);
    updateHTML(compileHTML(), false);
  };

  const addLayer = () => {
    if (!printTemplate) {
      return;
    }
    const recipe = printTemplate ? { ...printTemplate.recipe[language] } : {};
    const layers = recipe.layers || [];
    _.set(recipe, 'layers', layers.concat({ url: '' }));
    update(`recipe.${language}`, recipe);
  };

  const removeLayer = (index: number) => {
    const layers = [...printTemplate.recipe[language].layers];
    _.pullAt(layers, index);
    update(`recipe.${language}.layers`, layers);
  };

  const addElement = () => {
    if (!printTemplate) {
      return;
    }
    const recipe = printTemplate ? { ...printTemplate.recipe[language] } : {};
    const elements = recipe.elements || [];

    let mostElementsLang;
    let mostElements = elements.length;
    const recipeLangs = printTemplate.recipe ? Object.keys(printTemplate.recipe) : [];
    _.forEach(recipeLangs, (lang) => {
      if (lang !== language) {
        const candidateElements = _.get(printTemplate.recipe, [lang, 'elements'], []);
        if (candidateElements.length > mostElements) {
          mostElementsLang = lang;
          mostElements = candidateElements.length;
        }
      }
    });

    _.set(
      recipe,
      'elements',
      elements.concat(
        mostElementsLang ? _.clone(printTemplate.recipe[mostElementsLang].elements[elements.length]) : { type: 'text' },
      ),
    );
    update(`recipe.${language}`, recipe);
  };

  const removeElement = (index: number) => {
    const elements = [...printTemplate.recipe[language].elements];
    _.pullAt(elements, index);
    update(`recipe.${language}.elements`, elements);
  };

  const getContent = (contentTemplate: EmailContentTemplate | PrintContentTemplate, propertyName: string) => {
    return _.get(contentTemplate, `${propertyName}.${language}`) || '';
  };

  const renderCommonFields = (contentTemplate: EmailContentTemplate | PrintContentTemplate) => {
    const title = (
      <FormInput
        key="template-title"
        label={`Template title - ${language}`}
        value={getContent(contentTemplate, 'title')}
        handleChange={(e) => update(`title.${language}`, e.target.value)}
      />
    );

    const defaultSubject = (
      <FormInput
        key="template-subject"
        label={`Email default subject - ${language}`}
        value={getContent(contentTemplate, 'emailDefaultSubject')}
        handleChange={(e) => update(`emailDefaultSubject.${language}`, e.target.value)}
        required
      />
    );

    const preheader = (
      <FormInput
        key="template-preheader"
        label={`Email preheader - ${language}`}
        value={getContent(contentTemplate, 'emailPreheader')}
        handleChange={(e) => update(`emailPreheader.${language}`, e.target.value)}
        detail="Use the {{preheader}} tag in the HTML template"
      />
    );

    const chainSelector = (
      <CustomField key="template-chains">
        <ChainSelector
          chainSelection={contentTemplate.chainIds}
          excludeChains={getExcludedChains(getAllowedChains(appStore.chain))}
          handleChainChange={handleChainChange}
          label="Chain(s)"
          detail="Select chain(s) for this template"
        />
      </CustomField>
    );

    if (contentTemplate.channel === 'email') {
      return [title, defaultSubject, preheader, chainSelector];
    }
    return [title, chainSelector];
  };

  const renderEmailTemplateEditor = () => {
    return (
      <div className="form-control">
        <HelpButton renderContent={renderInstructions} />
        <FormTextArea
          label={`Body HTML - ${language}`}
          value={getContent(emailTemplate, 'html')}
          handleChange={(e) => editHTML(e.target.value)}
          detail="For newsletter, wrap retailer specific part of HTML that gets sent to Adobe campaing between these special comments"
        />
      </div>
    );
  };

  const pickFont = (font: any, setSelected: boolean) => {
    let fonts = printTemplate.recipe[language].fonts;
    if (!setSelected) {
      fonts = fonts.filter((existingFont) => existingFont.family !== font.family);
    } else {
      fonts.push(font);
    }
    update(`recipe.${language}.fonts`, fonts);
  };

  const renderPrintTemplateEditor = () => {
    const contentTemplate = printTemplate;
    const layers = _.get(contentTemplate, `recipe.${language}.layers`, null);
    const elements = _.get(contentTemplate, `recipe.${language}.elements`, null);

    return (
      <div className="print-recipe-editor">
        <div className="recipe-layers">
          <div className="title-row">
            <h3>Recipe layers</h3>
            <button className="add-button" onClick={() => addLayer()}>
              Add Layer
              <img src={require('images/add.svg').default} alt="add" />
            </button>
          </div>
          {layers &&
            layers.map((layer, i) => (
              <div className="recipe-layer" key={i}>
                <div className="title-row">
                  <h3>{`Layer ${i + 1}`}</h3>
                  <button className="delete-button" onClick={() => removeLayer(i)}>
                    <RemoveIcon />
                  </button>
                </div>
                <InputImage
                  value={{ src: layer.url || null }}
                  label="Layer PDF"
                  onChange={({ src }) => editRecipeLayer(i, src)}
                  acceptTypes=".pdf"
                />
              </div>
            ))}
        </div>
        <div className="recipe-fonts">
          <div className="title-row">
            <h3>Recipe fonts</h3>
          </div>
          <div className="font-selector">
            {availableFonts.map((font, i) => (
              <Checkbox
                key={`${i}`}
                id={`font-${templateId}-${i}`}
                handleClick={(e) => pickFont(font, e.target.checked)}
                checked={printTemplate.recipe[language].fonts.some(
                  (existingFont) => existingFont.family === font.family,
                )}
                label={font.family}
              />
            ))}
          </div>
        </div>
        <div className="recipe-elements">
          <div className="title-row">
            <h3>Recipe elements</h3>
            <button className="add-button" onClick={() => addElement()}>
              Add Element
              <img src={require('images/add.svg').default} alt="add" />
            </button>
          </div>
          {elements &&
            elements.map((element, i) => (
              <div className="recipe-element" key={`${i}-${language}`}>
                <div className="title-row">
                  <h3>{`Element ${i + 1}`}</h3>
                  <button className="delete-button" onClick={() => removeElement(i)}>
                    <RemoveIcon />
                  </button>
                </div>
                <CustomField label="Element type">
                  <Dropdown
                    key={`${i}-type`}
                    data={printTypes}
                    notSelectedText="Recipe type"
                    selectedItem={getSelectedTypeObject(element.type)}
                    select={(value) => editElement(i, 'type', value as string)}
                  />
                </CustomField>
                <FormInput
                  key={`${i}-x`}
                  label="X coordinate"
                  type="number"
                  additionalClasses="third-wide"
                  value={element.x}
                  handleChange={(e) => editElement(i, 'x', parseInt(e.target.value, 10))}
                />
                <FormInput
                  key={`${i}-y`}
                  label="Y coordinate"
                  type="number"
                  additionalClasses="third-wide"
                  value={element.y}
                  handleChange={(e) => editElement(i, 'y', parseInt(e.target.value, 10))}
                />
                <FormInput
                  key={`${i}-page`}
                  label="Page number"
                  type="number"
                  additionalClasses="third-wide"
                  value={element.page}
                  handleChange={(e) => editElement(i, 'page', parseInt(e.target.value, 10))}
                />
                <FormInput
                  key={`${i}-w`}
                  label="Width"
                  type="number"
                  additionalClasses="third-wide"
                  value={element.width}
                  handleChange={(e) => editElement(i, 'width', parseInt(e.target.value, 10))}
                />
                <FormInput
                  key={`${i}-h`}
                  label="Height"
                  type="number"
                  additionalClasses="third-wide"
                  value={element.height}
                  handleChange={(e) => editElement(i, 'height', parseInt(e.target.value, 10))}
                />
                <FormInput
                  key={`${i}-r`}
                  label="Rotation"
                  type="number"
                  additionalClasses="third-wide"
                  detail="Element rotation in degrees"
                  value={element.rotation}
                  handleChange={(e) => editElement(i, 'rotation', parseInt(e.target.value, 10))}
                />
                {element.type === 'html' && (
                  <React.Fragment>
                    <FormTextArea
                      key={`${i}-html`}
                      label={`HTML - ${language}`}
                      value={element.html}
                      handleChange={(e) => editElementHTML(i, e.target.value)}
                    />
                  </React.Fragment>
                )}
                {element.type === 'image' && (
                  <React.Fragment>
                    <FormInput
                      key={`${i}-url-input`}
                      label="Image URL"
                      value={element.url || ''}
                      handleChange={(e) => editElement(i, 'url', e.target.value)}
                    />
                    <InputImage
                      value={{ src: element.url || null }}
                      key={`${i}-url`}
                      label="Upload image"
                      onChange={({ src }) => editElement(i, 'url', src)}
                    />
                  </React.Fragment>
                )}
                {element.type === 'text' && (
                  <React.Fragment>
                    <FormInput
                      key={`${i}-font-size`}
                      label="Font size"
                      type="number"
                      additionalClasses="third-wide"
                      value={element.size}
                      handleChange={(e) => editElement(i, 'size', parseInt(e.target.value, 10))}
                    />
                    <FormInput
                      key={`${i}-line-height`}
                      label="Line height"
                      type="number"
                      additionalClasses="third-wide"
                      value={element.lineHeight}
                      handleChange={(e) => editElement(i, 'lineHeight', parseInt(e.target.value, 10))}
                    />
                    <FormInput
                      key={`${i}-align`}
                      label="Text align"
                      additionalClasses="third-wide"
                      value={element.align}
                      handleChange={(e) => editElement(i, 'align', e.target.value)}
                    />
                    <FormInput
                      key={`${i}-color`}
                      label="Color"
                      value={element.color}
                      additionalClasses="half-wide"
                      handleChange={(e) => editElement(i, 'color', e.target.value)}
                    />
                    <CustomField label="Font">
                      <React.Fragment>
                        {printTemplate.recipe[language].fonts.length > 0 && (
                          <Dropdown
                            key={`${i}-font`}
                            data={templateAvailableFonts}
                            notSelectedText="Pick a font"
                            select={(e) => editElement(i, 'font', e as string)}
                          />
                        )}
                        {printTemplate.recipe[language].fonts.length === 0 && (
                          <span className="fonts-missing">You have to pick choose fonts for this recipe first</span>
                        )}
                      </React.Fragment>
                    </CustomField>

                    <FormTextArea
                      key={`${i}-text`}
                      label="Text"
                      value={element.text}
                      handleChange={(e) => editElement(i, 'text', e.target.value)}
                    />
                  </React.Fragment>
                )}
              </div>
            ))}
        </div>
      </div>
    );
  };

  return (
    <div className="content-template-editor-body">
      <React.Fragment>
        {renderCommonFields(emailTemplate || printTemplate)}
        {emailTemplate ? renderEmailTemplateEditor() : renderPrintTemplateEditor()}
      </React.Fragment>
    </div>
  );
};

export default inject('appStore')(observer(EditorBody));
