import React, { useEffect } from 'react';
import * as _ from 'lodash';
import * as dateFns from 'date-fns';
import { getAllowedConceptTypeForChainIds, isKRautaChain, isOnninenChain } from 'utils/helpers';
import { inject, observer } from 'mobx-react';
import Spinner from 'components/common/next/spinner';
import DeliveryCard from '../delivery/deliveryCard';
import DeliveryStore from 'stores/next-retailer/deliveryStore';
import TemplateStore from 'stores/next-retailer/templateStore';
import UserStore from 'stores/userStore';
import ResultsStore from 'stores/next-retailer/resultsStore';
import ContentStore from 'stores/next-retailer/contentStore';
import InfoPopover from '../common/infoPopover';
import { DeliveryState, ConceptType } from 'enums/common';
import './deliveriesPage.scss';

interface InjectedProps {
  deliveryStore?: DeliveryStore;
  templateStore?: TemplateStore;
  userStore?: UserStore;
  resultsStore?: ResultsStore;
  contentStore?: ContentStore;
}

const DeliveriesPage = ({ deliveryStore, templateStore, userStore, resultsStore, contentStore }: InjectedProps) => {
  useEffect(() => {
    deliveryStore.search().then((results) => {
      const templateIds = _.chain(results)
        .map((d) => d.deliveryTemplate)
        // Filter out null values (Mobile offers)
        .compact()
        .uniq()
        .value();
      templateStore.search({ id: templateIds as [string] });
    });
  }, []);

  const infoButton = (opts: { slug: string; link?: string }) => {
    return (
      <InfoPopover>
        <div
          className="help-content"
          dangerouslySetInnerHTML={{
            __html: contentStore.getContentBySlug(opts.slug, { html: true }),
          }}
        />
        {opts.link && (
          <footer>
            <a href={opts.link} target="_blank" rel="noopener noreferrer">
              Avaa ohjeet uudessa ikkunassa
              {/* <ChevronRight /> */}
            </a>
          </footer>
        )}
      </InfoPopover>
    );
  };

  const renderDeliveryBoxes = (conceptType: ConceptType) => {
    // no drafts or archived
    const deliveries = deliveryStore.deliveries
      .filter((d) => d.meta && d.meta.type === conceptType && d.state === DeliveryState.Confirmed && !d.archived)
      .sort((a, b) => dateFns.compareDesc(new Date(a.startDate), new Date(b.startDate)));
    if (_.isEmpty(deliveries)) {
      return 'Ei lähetyksiä.';
    }

    return deliveries.map((delivery) => {
      const deliveryResults = resultsStore.getDeliveryResults(delivery.id);
      const template = templateStore.getCollectionItem(delivery.deliveryTemplate);
      const templateStats = resultsStore.getTemplateStats(delivery.deliveryTemplate);
      const pullAverage = templateStats ? Number(templateStats.pullAverage) : null;
      return (
        <DeliveryCard
          key={delivery.id}
          delivery={delivery}
          disabled={!template}
          pullDelivery={deliveryResults.pullPercentage}
          pullAverage={pullAverage}
        />
      );
    });
  };

  const renderDeliveryList = () => {
    if (!deliveryStore.deliveries) {
      return <Spinner />;
    }

    const allowedConceptTypes = getAllowedConceptTypeForChainIds([userStore.me.chainId]);
    const showSeasonConcepts = allowedConceptTypes.includes(ConceptType.Season);
    const showProgramConcepts = allowedConceptTypes.includes(ConceptType.Program);
    const showB2BConcepts = allowedConceptTypes.includes(ConceptType.B2b);

    const isKRauta = isKRautaChain(userStore.me.chainId);
    const chain_send_header_text = isKRauta ? 'Ketjun Lähetykset' : 'Omat asiakasohjelmani';
    const own_send_header_text = isKRauta ? 'Omat Lähetykset' : 'Omat lähetykseni';
    const b2b_send_header_text = isKRauta ? 'B2B-lähetykset' : 'Myymälän lähetykset';
    return (
      <div className="delivery-lists">
        {showProgramConcepts && (
          <div className="delivery-list">
            <div className="section-header">
              <h3>{chain_send_header_text}</h3>
              {infoButton({ slug: 'info-lunastusprosentti' })}
            </div>
            <div className="no-results">{renderDeliveryBoxes(ConceptType.Program)}</div>
          </div>
        )}
        {showSeasonConcepts && (
          <div className="delivery-list">
            <div className="section-header">
              <h3>{own_send_header_text}</h3>
              {infoButton({ slug: 'info-lunastusprosentti' })}
            </div>
            <div className="no-results">{renderDeliveryBoxes(ConceptType.Season)}</div>
          </div>
        )}
        {showB2BConcepts && (
          <div className="delivery-list">
            <div className="section-header">
              <h3>{b2b_send_header_text}</h3>
              {infoButton({ slug: 'info-lunastusprosentti' })}
            </div>
            <div className="no-results">{renderDeliveryBoxes(ConceptType.B2b)}</div>
          </div>
        )}
      </div>
    );
  };

  const render = () => {
    const mydeliveries_header = 'Osallistumiseni';
    const isKRautaOrOnninen = isKRautaChain(userStore.me.chainId) || isOnninenChain(userStore.me.chainId);
    const mydeliveries_content = isKRautaOrOnninen
      ? 'Tässä ovat listattuna kaikki lähetykset, joihin olet osallistunut.'
      : 'Tässä ovat listattuna kaikki ohjelmat ja lähetykset, joihin olet osallistunut.';
    return (
      <React.Fragment>
        <div className="deliveries-page">
          <div className="main-content">
            <div className="content">
              <h2>{mydeliveries_header}</h2>
              <p>{mydeliveries_content}</p>
              {renderDeliveryList()}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  return render();
};

export default inject(
  'deliveryStore',
  'templateStore',
  'userStore',
  'resultsStore',
  'contentStore',
)(observer(DeliveriesPage));
