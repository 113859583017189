import React from 'react';

interface Props {
  closeMenu(): void;
  className?: string;
}

class MobileNavigation extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.hideMenu = this.hideMenu.bind(this);
  }

  public componentDidMount() {
    window.addEventListener('click', this.hideMenu);
  }

  public componentWillUnmount() {
    window.removeEventListener('click', this.hideMenu);
  }

  public hideMenu(event: Event) {
    const { closeMenu } = this.props;
    const target = event.target as HTMLElement;
    const isLink = (el: HTMLElement) => {
      while ((el = el.parentElement) && el.tagName !== 'A') {
        /* do nothing */
      }
      return !!el;
    };
    if (target.className === 'option' || isLink(target)) {
      closeMenu();
    }
  }

  public render() {
    const { children, className } = this.props;

    return <nav className={`navigation mobile-navigation ${className}`}>{children}</nav>;
  }
}

export default MobileNavigation;
