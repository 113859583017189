import { action } from 'mobx';
import UserStore from './userStore';
import ContentStore from './contentStore';
import AppStore from './next/app';
import SettingsStore from './next/setting';

export default class RootStore {
  public userStore: UserStore;
  public contentStore: ContentStore;
  public appStore: AppStore;
  public settingsStore: SettingsStore;
  constructor() {
    this.userStore = new UserStore(this);
    this.contentStore = new ContentStore();
    this.appStore = new AppStore();
    this.settingsStore = new SettingsStore();
  }

  @action
  public reset() {
    this.userStore.reset();
    this.contentStore.reset();
    this.appStore.reset();
    this.settingsStore.reset();
  }
}
