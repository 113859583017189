import { observable, action } from 'mobx';
import type { JSXElement } from '@babel/types';

export default class HelpStore {
  @observable overlay: boolean;
  @observable content: JSXElement;

  // eslint-disable-next-line
  @action async openHelp(content?: JSXElement) {
    this.content = content;
    this.overlay = true;
  }

  // eslint-disable-next-line
  @action async closeHelp() {
    this.overlay = false;
  }
}
