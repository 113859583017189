import React from 'react';
import { includes, isEmpty } from 'lodash';
import type { Option } from 'types/option';
import { noSubmit } from 'utils/helpers';

interface Props {
  options: Option[];
  placeholder: string;
  selectClass: string;
  text?: string;
  selected?: Option;
  compress?: boolean;
  noInput?: boolean;
  onClick(value: any): void;
}

interface State {
  open: boolean;
  filtered: Option[];
  search: string;
  selected?: Option;
}

class Select extends React.Component<Props, State> {
  public containerNode;
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
      filtered: props.options,
      search: props.selected ? props.selected.label : '',
      selected: props.selected,
    };
    this.toggle = this.toggle.bind(this);
    this.select = this.select.bind(this);
    this.onInput = this.onInput.bind(this);
    this.close = this.close.bind(this);
    this.outsideClickListener = this.outsideClickListener.bind(this);
  }

  public UNSAFE_componentWillReceiveProps(newProps: Props) {
    const { options, selected } = newProps;
    this.setState({
      filtered: options,
      selected,
      search: (selected && selected.label) || '',
    });
  }

  public close() {
    this.setState({ open: false });
    document.removeEventListener('click', this.outsideClickListener);
  }

  public outsideClickListener(event) {
    const { target } = event;
    if (!this.containerNode || !this.containerNode.contains(target)) {
      this.close();
    }
  }

  public toggle(event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) {
    const { open } = this.state;
    const { tagName } = event.target as HTMLElement;
    if (!open) {
      this.setState({ open: true });
      document.addEventListener('click', this.outsideClickListener);
    } else if (tagName !== 'INPUT') {
      this.close();
    }
  }

  public select(option: Option) {
    this.setState({
      search: option.label,
      selected: option,
      open: false,
    });
    const { onClick } = this.props;
    onClick(option.value);
  }

  public onInput(event: React.ChangeEvent<HTMLInputElement>) {
    const { options } = this.props;
    const { value } = event.currentTarget;
    const filtered = options.filter((option) => {
      const label = option.label;
      return label && includes(label.toLowerCase(), value.toLowerCase());
    });
    this.setState({ filtered, search: value });
  }

  public componentWillUnmount() {
    document.removeEventListener('click', this.outsideClickListener);
  }

  public render() {
    const { open, filtered, search, selected } = this.state;
    const { options, compress, text, placeholder, selectClass, noInput } = this.props;

    let selectBox;
    if (compress && options.length === 1) {
      selectBox = (
        <div className="select-container">
          <div className={`select ${selectClass}`}>
            <div className="selected">
              <div>{options[0].label}</div>
            </div>
          </div>
        </div>
      );
    } else {
      const select = noInput ? (
        <div tabIndex={0} className={`select selectable ${selectClass}`} onClick={this.toggle} onKeyPress={this.toggle}>
          <div className="selected">
            <div>{selected ? selected.label : placeholder}</div>
          </div>
        </div>
      ) : (
        <div tabIndex={0} className={`select selectable ${selectClass}`} onClick={this.toggle} onKeyPress={this.toggle}>
          <div className="selected">
            <input type="text" value={search} placeholder={placeholder} onChange={this.onInput} onKeyDown={noSubmit} />
            <div className="hidden-text">{selected ? selected.label : search || placeholder}</div>
          </div>
        </div>
      );
      selectBox = (
        <div className={`select-container${open ? ' open' : ''}`} ref={(e) => (this.containerNode = e)}>
          {select}
          <div className={`options ${selectClass}`}>
            {isEmpty(filtered) ? (
              <div className="option disabled">No results</div>
            ) : (
              filtered.map((option, i) => (
                <div
                  key={i}
                  tabIndex={0}
                  className="option"
                  onClick={() => this.select(option)}
                  onKeyPress={() => this.select(option)}
                >
                  {option.label}
                </div>
              ))
            )}
          </div>
        </div>
      );
    }

    return text ? (
      <div className="select-with-label left">
        <div className="select-text">{text}</div>
        {selectBox}
      </div>
    ) : (
      selectBox
    );
  }
}

export default Select;
