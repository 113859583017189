import { observable, action } from 'mobx';
import type { Concept, ConceptSearchPayload, ConceptChangeOrderPayload } from 'components/next/types';
import * as _ from 'lodash';
import { StoreState } from 'enums/common';

export default class ConceptsStore {
  client;

  @observable concepts: Concept[] = [];
  @observable concept: Concept = undefined;
  @observable error: string = undefined;
  @observable count = 0;
  @observable status: StoreState = StoreState.ready;

  @action async search(payload: ConceptSearchPayload = {}, parameters = {}) {
    // _.set(parameters);
    this.status = StoreState.loading;
    const result = await this.client.searchConcepts(parameters, payload);
    this.count = result.data.count;
    this.setConcepts(result.data.result);
    return result.data;
  }

  @action setConcepts(concepts) {
    this.concepts = concepts;
    this.status = StoreState.ready;
  }

  @action setConcept(concept) {
    this.concept = concept;
  }

  @action async findById(id: number) {
    const result = await this.client.getConcept(id);
    if (result.status === 200) {
      this.setConcept(result.data);
      return result.data;
    }

    return null;
  }

  @action async findLastUpdateById(id: number) {
    const result = await this.client.getConceptLastUpdated(id);
    if (result.status === 200) {
      return result.data;
    }

    return null;
  }

  @action setError(message: string) {
    this.error = message;
  }

  @action setClient(client) {
    this.client = client;
  }

  @action async replaceConcept(program: Concept) {
    this.status = StoreState.loading;
    try {
      const result = await this.client.replaceConcept(program.id, program);
      if (result.status === 200) {
        this.setConcept(result.data);
        return result.data;
      } else {
        this.setError(result.message);
      }
    } catch (error) {
      this.status = StoreState.error;
    }
  }

  @action async createConcept(program: Concept) {
    this.status = StoreState.loading;
    try {
      const result = await this.client.createConcept(null, _.omit(program, 'conceptStoreAutomaticParticipation'));
      if (result.status === 201) {
        this.search();
        return result.data;
      }
    } catch (error) {
      this.status = StoreState.error;
    }
  }

  @action async deleteConcept(id: string) {
    const result = await this.client.deleteConcept(parseInt(id, 10));
    if (result.status === 200) {
      this.search();
    }
  }

  @action async changeConceptOrder(id: string, payload: ConceptChangeOrderPayload) {
    await this.client.changeConceptOrder(parseInt(id, 10), payload);
  }
}
