import React from 'react';
import { getLink } from '../../routes';
import NavLink from './navLink';
import { ReactComponent as IconHome } from '@kesko/icons/nav/icon-home.svg';
import { ReactComponent as IconHeart } from '@kesko/icons/misc/icon-heart.svg';
import { ReactComponent as IconNews } from '@kesko/icons/item/icon-news.svg';
import { ReactComponent as IconQuestion } from '@kesko/icons/attention/icon-question.svg';
import { ReactComponent as IconCalendar } from '@kesko/icons/time/icon-calendar.svg';
import { ReactComponent as IconSend } from '@kesko/icons/action/icon-msg-send.svg';
import { ReactComponent as IconComment } from '@kesko/icons/comm/icon-comment.svg';
import { ReactComponent as IconStampCard } from '@kesko/icons/shopping/icon-payment_stampCard.svg';
import { ReactComponent as IconB2B } from '@kesko/icons/people/icon_userB2B.svg';
import { getAllowedConceptTypes, isKRautaChain, isOnninenChain } from 'utils/helpers';
import AppStore from 'stores/next/app';
import { Chain, ConceptType } from 'enums/common';
import { inject, observer } from 'mobx-react';
import { ReactComponent as IconMobile } from '@kesko/icons/misc/icon-device_mobile.svg';

interface Props {
  appStore?: AppStore;
  storeId?: string;
}

const NavigationLinks = ({ appStore, storeId }: Props) => {
  const allowedConceptTypes = getAllowedConceptTypes(appStore.chain);
  const isKRauta = appStore.chain === Chain.kRauta;
  const showSeasonConcepts = allowedConceptTypes.includes(ConceptType.Season);
  const showProgramConcepts = allowedConceptTypes.includes(ConceptType.Program);
  const showB2BConcepts = allowedConceptTypes.includes(ConceptType.B2b);
  const showPushNotifications = appStore.chain === Chain.kRuoka;
  const showStampCards = appStore.chain === Chain.kRuoka;
  const showMobileOffers = appStore.chain === Chain.kRuoka;

  return (
    <div className="link-list">
      <NavLink key="front" to={getLink('front')} title="Etusivu" icon={IconHome} exact />
      <hr />
      <NavLink key="deliveries" to={getLink('deliveries')} title="Osallistumiseni" icon={IconCalendar} />
      {showProgramConcepts && (
        <NavLink
          key="concepts"
          to={getLink('concepts', 'program')}
          title={isKRauta ? 'Ketjun lähetykset' : 'Sesonkilähetykset'}
          icon={IconHeart}
        />
      )}
      {showSeasonConcepts && (
        <NavLink
          key="seasons"
          to={getLink('concepts', 'season')}
          title={isKRauta ? 'Omat lähetykset' : 'Kaupan Lähetykset'}
          icon={IconSend}
        />
      )}
      {showB2BConcepts && (
        <NavLink
          key="b2b"
          to={getLink('concepts', 'b2b')}
          title={isKRauta ? 'B2B-lähetykset' : 'Myymälän lähetykset'}
          icon={IconB2B}
        />
      )}
      {showPushNotifications && (
        <NavLink key="push-notifications" to={getLink('pushNotifications')} title="Push-viestit" icon={IconComment} />
      )}
      {showStampCards && <NavLink key="cards" to={getLink('stampCards')} title="Keräilypassit" icon={IconStampCard} />}
      {showMobileOffers && (
        <NavLink key="mobile-offers" to={getLink('mobileOffers')} title="Aktivoitavat mobiiliedut" icon={IconMobile} />
      )}
      <hr />
      <NavLink key="news" to={getLink('news')} title="Uutiset ja tiedotteet" icon={IconNews} />
      <NavLink key="help" to={getLink('help')} title="Ohjeet" icon={IconQuestion} />
    </div>
  );
};

export default inject('appStore')(observer(NavigationLinks));
