import React, { useEffect, useState } from 'react';
import { useBlocker } from 'react-router-dom';
import page from 'components/next/pages/page/page';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';

import './contentManager.scss';
import ContentStore from 'stores/next-retailer/contentStore';
import type { ContentItem } from 'components/next/types/entities';
import { FormInput, FormTextArea, CustomField } from 'components/next/components/form/input';
import ChainSelector from 'components/next/components/chainSelector';
import { Checkbox } from 'components/next/components/checkbox';
import Dropdown, { DropdownItem } from 'components/next/components/dropdown';

import AlertStore from 'stores/next/alerts';
import Alerts from 'components/common/next/alert/alerts';

import { ReactComponent as IconUp } from '@kesko/icons/action/icon-push_up.svg';
import { ReactComponent as IconDown } from '@kesko/icons/action/icon-push_down.svg';
import { ReactComponent as IconDelete } from '@kesko/icons/action/icon-delete.svg';
import { ReactComponent as IconMinus } from '@kesko/icons/action/icon-minus.svg';
import { ReactComponent as IconPlus } from '@kesko/icons/action/icon-plus.svg';
import { ReactComponent as SurprisedIcon } from '@kesko/icons/mood/icon-mood_surprise.svg';
import { ReactComponent as HappyIcon } from '@kesko/icons/mood/icon-mood_happy.svg';
import { ReactComponent as UnhappyIcon } from '@kesko/icons/mood/icon-mood_unhappy.svg';

import { getContentItemDefaults, changeOrder, mdInstructions } from 'components/next/utils';
import SidebarWrapper from 'components/next/components/sidebar/sidebar';
import SearchBox from 'components/next/components/sidebar/searchBox';
import { RadioButton } from 'components/next/components/radioButton/radioButton';
import HelpButton from 'components/common/next/help/helpButton';
import InputImage from 'components/common/next/form/inputImage';
import { ContentTypeNames } from 'constants/common';
import { ContentType } from 'enums/common';
import { useDebouncedEffect } from 'utils/helpers';
import Spinner from 'components/common/next/spinner';

interface Props {
  contentStore?: ContentStore;
  alertStore?: AlertStore;
}

const types: DropdownItem[] = [
  { name: ContentTypeNames[ContentType.UI], value: ContentType.UI },
  { name: ContentTypeNames[ContentType.Help], value: ContentType.Help },
  { name: ContentTypeNames[ContentType.News], value: ContentType.News },
];

const promptMessage =
  'There are content items with unsaved changes. If you navigate away from this page, these changes will be lost. Are you sure you want to continue?';

function onUnload(e) {
  // NOTE: many modern browsers DO NOT support custom refresh messages...
  e.preventDefault();
  e.returnValue = promptMessage;
}

const ContentManager = ({ contentStore, alertStore }) => {
  const [contentItems, setContentItems] = useState<ContentItem[]>([]);
  const [contentItemsWithUnsavedChanges, setContentItemsWithUnsavedChanges] = useState<string[]>([]);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const [query, setQuery] = useState('');
  const [type, setType] = useState<ContentType>(null);
  const [saveError, setSaveError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Block routing when there's unsaved changes
  const blocker = useBlocker(unsavedChanges);
  useEffect(() => {
    // unload function is for page refresh / back button
    // blocker is for routing inside react router
    if (unsavedChanges) {
      window.addEventListener('beforeunload', onUnload);
    } else {
      window.removeEventListener('beforeunload', onUnload);
      if (blocker.state === 'blocked') {
        blocker.reset();
      }
    }
  }, [blocker, unsavedChanges]);

  const search = async () => {
    setIsLoading(true);
    const contentItems = await contentStore.search({ query, type });
    setContentItems(contentItems);
    setIsLoading(false);
  };

  useEffect(() => {
    search();
    // clear event listener on unmount
    return () => {
      window.removeEventListener('beforeunload', onUnload);
    };
  }, []);

  useDebouncedEffect(search, [query, type], 500);

  const addContentItem = async () => {
    const contentItem = getContentItemDefaults({ type: type || ContentType.UI });
    await contentStore.saveContent(contentItem);
    search();
    alertStore.success({
      message: (
        <span>
          <a href={`#${contentItem.slug}`}>
            <strong key="content-item">{contentItem.slug}</strong>
          </a>{' '}
          was added successfully
        </span>
      ),
    });
  };

  const handleDelete = async (id: string) => {
    const confirmed = window.confirm(
      'Are you sure you want to delete this content item? This action cannot be undone.',
    );
    if (confirmed) {
      await contentStore.deleteContent(id);
      search();
    }
  };

  const handleSave = async (contentItem?: ContentItem) => {
    setSaveError(false);
    let newContentItemsWithUnsavedChanges = [...contentItemsWithUnsavedChanges];

    if (contentItem) {
      const result = await contentStore.saveContent(contentItem);
      if (result.status === 200) {
        newContentItemsWithUnsavedChanges = newContentItemsWithUnsavedChanges.filter((id) => id !== contentItem.id);
      } else {
        setSaveError(true);
      }
    } else {
      const result = await Promise.all(
        contentItems
          .filter((contentItem) => contentItemsWithUnsavedChanges.includes(contentItem.id))
          .map((contentItem) => contentStore.saveContent(contentItem)),
      );
      if (result.some((res) => res.status !== 200)) {
        setSaveError(true);
      } else {
        newContentItemsWithUnsavedChanges = [];
      }
    }
    setUnsavedChanges(newContentItemsWithUnsavedChanges.length > 0);
    setContentItemsWithUnsavedChanges(newContentItemsWithUnsavedChanges);
  };

  const updateQuery = (query: string) => {
    setQuery(query);
  };

  const updateContentItem = (id: string, fieldName: string, value: string | string[]): void => {
    setSaveError(false);
    const newContentItems = [...contentItems];
    const contentItem = contentItems.find((contentItem) => contentItem.id === id);
    const index = _.findIndex(contentItems, (contentItem) => contentItem.id === id);
    _.set(contentItem, fieldName, value);
    newContentItems[index] = contentItem;
    const newContentItemsWithUnsavedChanges = contentItemsWithUnsavedChanges.includes(id)
      ? contentItemsWithUnsavedChanges
      : contentItemsWithUnsavedChanges.concat(id);
    setContentItems(newContentItems);
    setUnsavedChanges(newContentItemsWithUnsavedChanges.length > 0);
    setContentItemsWithUnsavedChanges(newContentItemsWithUnsavedChanges);
  };

  const appendMarkdownImage = (id: string, url: string) => {
    const contentItem = contentItems.find((contentItem) => contentItem.id === id);
    updateContentItem(id, 'content.fi', `${contentItem.content.fi}\n![alt](${url})`);
  };

  const selectChains = (id: string, value: string): void => {
    const chainIds = contentItems.find((contentItem) => contentItem.id === id).chainIds;
    if (chainIds.includes(value)) {
      _.pullAt(chainIds, chainIds.indexOf(value));
    } else {
      chainIds.push(value);
    }
    updateContentItem(id, 'chainIds', chainIds);
  };

  const handleChangeOrder = async (index: number, shiftUp: boolean) => {
    const result = changeOrder(contentItems, index, shiftUp);
    setContentItems(result);
    await contentStore.updateContents(result);
  };

  const sanitizeSlug = (slug: string) => {
    return slug.replace(/ /g, '-').replace(/[äÄ]/g, 'a').replace(/[öÖ]/g, 'o');
  };

  const toggleEditor = (id: string) => {
    setExpandedItems(expandedItems.includes(id) ? expandedItems.filter((i) => i !== id) : expandedItems.concat(id));
  };

  const renderStatus = () => {
    if (saveError) {
      return (
        <div className="content-manager__status error">
          <span>There was a problem saving your changes!</span>
          <UnhappyIcon />
        </div>
      );
    }

    if (contentItemsWithUnsavedChanges.length) {
      return (
        <div className="content-manager__status unsaved">
          <span>There are content items with unsaved changes!</span>
          <SurprisedIcon />
        </div>
      );
    }

    return (
      <div className="content-manager__status ok">
        <span>All changes saved!</span>
        <HappyIcon />
      </div>
    );
  };

  const setTypeFilter = (type: ContentType | null) => {
    setType(type);
  };

  const renderInstructions = () => {
    return (
      <div>
        <h3>Formatting content item text:</h3>
        <p>You can use the following markdown properties to style conten item text:</p>
        <pre>{mdInstructions}</pre>
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="content-sidebar">
        <div className="sidebar__title-wrapper">
          <h3 className="sidebar__title">Filters</h3>
          {isLoading && <Spinner addClassName="spinner--unset" />}
        </div>
        <SearchBox
          value={query}
          name="query"
          onChange={(e) => updateQuery(e.target.value)}
          placeholder="Search for content items..."
        />
        <div className="content-type-filters">
          <h4>Filter content items by type</h4>
          <RadioButton
            group="content-type-filter"
            id="no-type-filter"
            label="No filter"
            checked={type === null}
            handleClick={() => setTypeFilter(null)}
          />
          <RadioButton
            group="content-type-filter"
            id="filter-ui-content"
            label={ContentTypeNames[ContentType.UI]}
            checked={type === ContentType.UI}
            handleClick={() => setTypeFilter(ContentType.UI)}
          />
          <RadioButton
            group="content-type-filter"
            id="filter-help-content"
            label={ContentTypeNames[ContentType.Help]}
            checked={type === ContentType.Help}
            handleClick={() => setTypeFilter(ContentType.Help)}
          />
          <RadioButton
            group="content-type-filter"
            id="filter-news-content"
            label={ContentTypeNames[ContentType.News]}
            checked={type === ContentType.News}
            handleClick={() => setTypeFilter(ContentType.News)}
          />
        </div>
      </div>
    );
  };

  const renderContentEditors = (contentItems: ContentItem[]) => {
    return _.orderBy(contentItems, 'order').map((contentItem, i) => {
      const editorClass = expandedItems.includes(contentItem.id)
        ? 'editor-section content-item-editor expanded'
        : 'editor-section content-item-editor';
      return (
        <section id={contentItem.slug} className={editorClass} key={i}>
          <header className="editor-section__header">
            <div className="title-row">
              <h3>{contentItem.slug}</h3>
              <div className="title-row__buttons">
                {contentItemsWithUnsavedChanges.includes(contentItem.id) && (
                  <span className="unsaved-warning">This content item has unsaved changes</span>
                )}
                <button onClick={() => handleChangeOrder(i, false)}>
                  <IconDown />
                </button>
                <button onClick={() => handleChangeOrder(i, true)}>
                  <IconUp />
                </button>
                <button onClick={() => handleDelete(contentItem.id)}>
                  <IconDelete />
                </button>
                {contentItemsWithUnsavedChanges.includes(contentItem.id) && (
                  <button className="save-button" onClick={() => handleSave(contentItem)}>
                    Save
                  </button>
                )}
                <button className="toggle-expander" onClick={() => toggleEditor(contentItem.id)}>
                  {expandedItems.includes(contentItem.id) ? <IconMinus /> : <IconPlus />}
                </button>
              </div>
            </div>
            <div className="entity-details">
              <span>Type: {contentItem.type}</span>
              <span>Slug: {contentItem.slug}</span>
            </div>
          </header>
          <div className="content-item-fields">
            <CustomField additionalClasses="half-wide" label="Type">
              <Dropdown
                data={types}
                selectedItem={types.find((type) => type.value === contentItem.type)}
                notSelectedText="Content item type"
                select={(value) => updateContentItem(contentItem.id, 'type', value as string)}
              />
            </CustomField>
            <FormInput
              label="Slug"
              required={true}
              additionalClasses="half-wide"
              value={contentItem.slug}
              handleChange={(e) => updateContentItem(contentItem.id, 'slug', sanitizeSlug(e.target.value))}
            />
            {contentItem.type !== 'ui' && (
              <FormInput
                label="Title"
                required={true}
                value={contentItem.title.fi}
                handleChange={(e) => updateContentItem(contentItem.id, 'title.fi', e.target.value)}
              />
            )}
            <CustomField label="Chains" detail="Which chains to show this content to">
              <ChainSelector
                chainSelection={contentItem.chainIds}
                handleChainChange={(value) => selectChains(contentItem.id, value)}
              />
              <CustomField>
                <Checkbox
                  id={`${contentItem.id}-show-on-front`}
                  checked={contentItem.showOnFrontPage || false}
                  handleClick={(e) => updateContentItem(contentItem.id, 'showOnFrontPage', e.currentTarget.checked)}
                  label="Show on front page"
                />
              </CustomField>
            </CustomField>
            <div className="form-control">
              <HelpButton renderContent={renderInstructions} />
              <FormTextArea
                label="Content"
                value={contentItem.content.fi}
                handleChange={(e) => updateContentItem(contentItem.id, 'content.fi', e.target.value)}
              />
            </div>
            <InputImage
              label="Add image"
              onChange={({ src }) => appendMarkdownImage(contentItem.id, src)}
              value={{ src: null }}
            />
            {_.includes(['news'], contentItem.type) && (
              <InputImage
                label="Main Image"
                onChange={({ src }) => updateContentItem(contentItem.id, 'meta.image', src)}
                value={{ src: _.get(contentItem, ['meta', 'image']) }}
              />
            )}
          </div>
        </section>
      );
    });
  };

  const renderConfirmation = () => {
    if (blocker) {
      if (blocker.state === 'blocked') {
        const confirmed = window.confirm(promptMessage);
        if (confirmed) {
          blocker.proceed?.();
        } else {
          blocker.reset?.();
        }
      }
    }
  };

  return (
    <React.Fragment>
      {renderConfirmation()}
      <SidebarWrapper renderSidebar={renderSidebar}>
        <div className="content-manager">
          <div className="content">
            <header className="content-manager__header">
              <h2>Content Manager</h2>
              {renderStatus()}
              <div className="header-buttons">
                <button className="add-button program" onClick={() => addContentItem()}>
                  Add content item
                  <img src={require('images/add.svg').default} alt="add" />
                </button>
                <button className="save-button" onClick={() => handleSave()}>
                  Save
                </button>
              </div>
            </header>
            {contentItems && renderContentEditors(contentItems)}
            {isLoading && <Spinner />}
          </div>
          <Alerts />
        </div>
      </SidebarWrapper>
    </React.Fragment>
  );
};

export default page(inject('contentStore', 'alertStore')(observer(ContentManager)));
