import React from 'react';
import * as _ from 'lodash';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  detail?: string;
}

const SearchBox = ({ ...restProps }: Props) => (
  <div className="search-box-wrapper">
    <div className="search-box">
      <img src={require('images/search.svg').default} className="search-box_icon" alt="search" />
      <input type="text" className="search-box__input" {..._.omit(restProps, ['detail'])} />
    </div>
    {restProps.detail && <span className="detail-text">{restProps.detail}</span>}
  </div>
);

export default SearchBox;
