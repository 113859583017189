import type { Client, Components } from '../types/next-api';
import { action, observable } from 'mobx';
import { DeliveryTemplateTabStatistics, HomeStatistics } from 'types/next';

// TODO what are the @observable and @action
export default class StatisticsStore {
  client: Client;

  @observable
  homePageStatisicsMap: Map<string, HomeStatistics> = new Map<string, HomeStatistics>();

  @observable
  conceptStatisicsMapByConcept: Map<string, Components.Schemas.ConceptStatisticsResponseItem> = new Map<
    string,
    Components.Schemas.ConceptStatisticsResponseItem
  >();

  @observable
  deliveryTemplateStatisticsMapByTemplate: Map<string, DeliveryTemplateTabStatistics> = new Map<
    string,
    DeliveryTemplateTabStatistics
  >();

  @observable
  offerStatisticsArray: Components.Schemas.OfferStatisticsResponseItem[] =
    new Array<Components.Schemas.OfferStatisticsResponseItem>();

  @observable
  deliveryStatisticsArray: Components.Schemas.DeliveryStatisticsResponseItem[] =
    new Array<Components.Schemas.DeliveryStatisticsResponseItem>();

  @observable
  automaticParticipationConceptStatistics: Components.Schemas.AutomaticParticipationStatisticsResponseItem[] =
    new Array<Components.Schemas.AutomaticParticipationStatisticsResponseItem>();

  @observable
  automaticParticipationDeliveryTemplateStatistics: Components.Schemas.AutomaticParticipationStatisticsResponseItem[] =
    new Array<Components.Schemas.AutomaticParticipationStatisticsResponseItem>();

  @observable
  automaticParticipationsThisYear: number;

  @action
  public async getStatisticsHome() {
    const homePageStatisticsReply = await this.client.getStatisticsHome();
    if (homePageStatisticsReply.data && homePageStatisticsReply.data.data) {
      this.homePageStatisicsMap.clear();
      homePageStatisticsReply.data.data.forEach((statistics) => {
        this.homePageStatisicsMap.set(statistics.chainId, statistics);
      });
    }
    return this.homePageStatisicsMap;
  }

  @action
  public async getStatisticsConcept() {
    const conceptStats = await this.client.getStatisticsConcepts();
    if (conceptStats.data && conceptStats.data.data) {
      this.conceptStatisicsMapByConcept.clear();
      conceptStats.data.data.forEach((conceptStatistics) => {
        this.conceptStatisicsMapByConcept.set(conceptStatistics.conceptId, conceptStatistics);
      });
    }
    return this.conceptStatisicsMapByConcept;
  }

  @action
  public async getStatisticsDeliveryTemplate() {
    const deliveryTemplateStats = await this.client.getStatisticsDeliveryTemplates();
    if (deliveryTemplateStats.data && deliveryTemplateStats.data.data) {
      this.deliveryTemplateStatisticsMapByTemplate.clear();
      deliveryTemplateStats.data.data.forEach((deliveryTemplateStat) => {
        this.deliveryTemplateStatisticsMapByTemplate.set(deliveryTemplateStat.deliveryTemplateId, deliveryTemplateStat);
      });
    }
    return this.deliveryTemplateStatisticsMapByTemplate;
  }

  @action
  public async getStatisticsBestPerforming() {
    const bestPerformingStatisticsResponse = await this.client.getStatisticsBestPerforming();
    if (bestPerformingStatisticsResponse.data.offerResults && bestPerformingStatisticsResponse.data.emailResults) {
      this.offerStatisticsArray = [];
      this.deliveryStatisticsArray = [];
      bestPerformingStatisticsResponse.data.offerResults.forEach((statistics) => {
        this.offerStatisticsArray.push(statistics);
      });
      bestPerformingStatisticsResponse.data.emailResults.forEach((statistics) => {
        this.deliveryStatisticsArray.push(statistics);
      });
    }
    return;
  }

  @action
  public async getStatisticsAutomaticParticipation() {
    const automaticParticipationStatisticsResponse = await this.client.getStatisticsAutomaticParticipation();
    if (automaticParticipationStatisticsResponse.data) {
      this.automaticParticipationConceptStatistics = automaticParticipationStatisticsResponse.data.concepts;
      this.automaticParticipationDeliveryTemplateStatistics =
        automaticParticipationStatisticsResponse.data.deliveryTemplates;
      this.automaticParticipationsThisYear =
        automaticParticipationStatisticsResponse.data.automaticParticipationsThisYear?.length;
    }
    return;
  }
}
