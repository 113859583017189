import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import _ from 'utils/lodash';
import { format, isBefore, isAfter } from 'date-fns';
import { inject, observer } from 'mobx-react';
import { ReactComponent as IconArrowLeft } from '@kesko/icons/nav/icon-arrow-left.svg';
import UserStore from 'stores/userStore';
import StampCardStore from 'stores/next/stampCard';
import './stampCardViewer.scss';
import { getLink } from '../../routes';
import { BASKET_DISCOUNT_PERCENTAGES, CHAIN_LOGOS } from 'constants/stampCard';
import {
  CollectionStampCardStrategy,
  CollectionStampCardRewardDiscountType,
  CollectionStampCardRewardDiscountMethod,
} from 'enums/stampCard';
import { editKeskoStampCard } from 'components/next/routes';

interface InjectedProps {
  stampCardStore?: StampCardStore;
  userStore?: UserStore;
}
interface Props {
  keskoStampCard?: boolean;
}

const StampCardViewer = ({ stampCardStore, userStore, keskoStampCard }: InjectedProps & Props) => {
  const { cardId: cardIdParam, chain: chainParam } = useParams();
  const [language, setLanguage] = useState<'fi' | 'sv'>('fi');

  useEffect(() => {
    stampCardStore.getCollectionStampCard(cardIdParam);
    stampCardStore.getCollectionStampCardStatistics({ id: [cardIdParam] });
  }, []);

  const renderStatistics = () => {
    const { isLoadingStampCardStatistics, stampCardStatistics } = stampCardStore;

    if (isLoadingStampCardStatistics) {
      return <div>Ladataan kortin statistiikkoja...</div>;
    }

    const stats = _.get(stampCardStatistics, [cardIdParam]);

    if (!stats) {
      return <div>Kortin statistiikat ei saatavilla.</div>;
    }

    const stampcardRegistrationsQty = _.get(stats, ['stampcardRegistrationsQty'], '-');
    const stampQty = _.get(stats, ['stampQty'], '-');
    const fullStampcardQty = _.get(stats, ['fullStampcardQty'], '-');

    return (
      <React.Fragment>
        <div>
          <p>{stampcardRegistrationsQty}</p>aktiivista passia
        </div>
        <div>
          <p>{stampQty}</p>leimaa yhteensä
        </div>
        <div>
          <p>{fullStampcardQty}</p>täyttä passia
        </div>
      </React.Fragment>
    );
  };

  const formatConfiguration = () => {
    const { strategy, stampConfiguration, oneTimePurchase } = stampCardStore.current;

    if (strategy === CollectionStampCardStrategy.SimpleProductCollecting) {
      return (
        <React.Fragment>
          Osta
          <p>
            <span className="emph">{stampConfiguration}</span>
          </p>
          normaalihintaista tuotetta
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          Osta
          <p>
            <span className="emph">{stampConfiguration}</span>
          </p>
          <div>
            kertaa yli <span className="emph">{oneTimePurchase}€</span> kertaostokset
          </div>
        </React.Fragment>
      );
    }
  };

  const formatReward = () => {
    const {
      reward: { discount, discountType, discountMethod, minimumPurchase },
    } = stampCardStore.current;

    if (discountType === CollectionStampCardRewardDiscountType.Product) {
      if (discountMethod === CollectionStampCardRewardDiscountMethod.ProductEuro) {
        return (
          <React.Fragment>
            Saat tuotteen hintaan
            <p>
              <span className="emph">{discount}€</span>
            </p>
            palkinnoksi
          </React.Fragment>
        );
      } else if (discountMethod === CollectionStampCardRewardDiscountMethod.ProductPercent) {
        if (discount === 100) {
          return (
            <React.Fragment>
              Saat tuotteen
              <p>
                <span className="emph">veloituksetta</span>
              </p>
              palkinnoksi
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment>
              Saat tuotteen
              <p>
                <span className="emph">{discount}%</span>
              </p>
              alennuksella palkinnoksi
            </React.Fragment>
          );
        }
      }
    } else if (discountType === CollectionStampCardRewardDiscountType.BasketEuro) {
      return (
        <React.Fragment>
          Saat ostosten loppusummasta
          <p>
            <span className="emph">{discount}€</span>
          </p>
          <div>
            alennuksen, kun loppusumma on yli <span className="emph">{minimumPurchase}€</span>
          </div>
        </React.Fragment>
      );
    } else if (
      (discountType as CollectionStampCardRewardDiscountType) === CollectionStampCardRewardDiscountType.BasketPercent
    ) {
      return (
        <React.Fragment>
          Saat ostosten loppusummasta
          <p>
            <span className="emph">{_.get(BASKET_DISCOUNT_PERCENTAGES, [discount])}%</span>
          </p>
          <div>
            alennuksen, kun loppusumma on yli <span className="emph">{minimumPurchase}€</span>
          </div>
        </React.Fragment>
      );
    }
  };

  const renderConfiguration = () => (
    <React.Fragment>
      <div className="left">
        <h2>Kerättävät leimat</h2>
        <div>{formatConfiguration()}</div>
      </div>
      <div className="right">
        <h2>Palkintoetu</h2>
        <div>{formatReward()}</div>
      </div>
    </React.Fragment>
  );

  const renderProducts = () => {
    const { products, strategy } = stampCardStore.current;

    if (products.length === 0 || strategy !== CollectionStampCardStrategy.SimpleProductCollecting) {
      return null;
    }

    return (
      <React.Fragment>
        <h2>Tuotteet</h2>
        <div className="products section">
          <table>
            <thead>
              <tr>
                <th>EAN</th>
                <th>Tuotenimi</th>
                <th>Normaalihinta</th>
              </tr>
            </thead>
            <tbody>
              {_.map(products, (product) => {
                return (
                  <tr key={`product-${_.get(product, ['ean'])}`}>
                    <td>{_.get(product, ['ean'])}</td>
                    <td>{_.get(product, ['title', 'fi'])}</td>
                    <td>{_.get(product, ['price']) || '-'}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  };

  const renderRewardProducts = () => {
    const { rewardProducts, strategy } = stampCardStore.current;

    if (rewardProducts.length === 0 || strategy !== CollectionStampCardStrategy.SimpleProductCollecting) {
      return null;
    }

    return (
      <React.Fragment>
        <h2>Palkintotuotteet</h2>
        <div className="products section">
          <table>
            <thead>
              <tr>
                <th>EAN</th>
                <th>Tuotenimi</th>
              </tr>
            </thead>
            <tbody>
              {_.map(rewardProducts, (product) => {
                return (
                  <tr key={`product-${_.get(product, ['ean'])}`}>
                    <td>{_.get(product, ['ean'])}</td>
                    <td>{_.get(product, ['title', 'fi'])}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  };

  const renderCardStores = () => {
    const { stores } = stampCardStore.current;
    const { storesByStoreId } = userStore;

    if (stores.length === 0) {
      return null;
    }

    return (
      <div className="stampcard-details-stores">
        {_.map(stores, ({ storeId }) => {
          const storeData = _.get(storesByStoreId, [storeId]);
          const { chainId, name, shortName } = storeData;

          const image = _.includes(['3', '4', '5'], chainId) ? <img src={CHAIN_LOGOS[chainId]} alt={name} /> : null;

          return (
            <div key={`card-store-${storeId}`} className="stampcard-details-store">
              {image}
              {shortName}
            </div>
          );
        })}
      </div>
    );
  };

  const renderCardStatusPill = () => {
    const currentStampCard = stampCardStore.current;

    const isActive =
      !isBefore(new Date(), new Date(currentStampCard.activeFrom)) &&
      !isAfter(new Date(), new Date(currentStampCard.activeTo));

    return (
      <div className={`stampcard-status ${isActive ? 'active' : 'inactive'}`}>
        {isActive ? 'Aktiivinen' : 'Ei aktiivinen'}
      </div>
    );
  };

  const render = () => {
    const currentStampCard = stampCardStore.current;
    const { isLoadingStampCard } = stampCardStore;

    if (isLoadingStampCard || !currentStampCard) {
      return null;
    }

    const shouldDisableEdit = isBefore(new Date(currentStampCard.activeTo), new Date());

    const stampCardEditorRoute = getLink('stampCardEditor', 'edit', cardIdParam, 'type');

    return (
      <div className="stampcard-viewer main-container">
        <div className="main-content">
          <div className="content">
            <div className="basic-info section">
              <div className="top">
                <div>
                  <Link to={getLink('stampCards')}>
                    <IconArrowLeft />
                  </Link>
                  <h2>{_.get(currentStampCard, ['uiData', 'uiName', 'fi'])}</h2>
                </div>
                <div>
                  {!shouldDisableEdit && (
                    <Link
                      className="btn btn-default"
                      to={
                        keskoStampCard
                          ? editKeskoStampCard.readyLink(chainParam, cardIdParam, 'type')
                          : stampCardEditorRoute
                      }
                    >
                      Muokkaa
                    </Link>
                  )}
                </div>
              </div>
              <div className="bottom">
                <div className="description">{_.get(currentStampCard, ['uiData', 'description', 'fi'])}</div>
                <div className="dates">
                  Passi voimassa <b>{format(new Date(_.get(currentStampCard, ['activeTo'])), 'd.M.yyyy')}</b> asti
                  <br />
                  Edut voimassa <b>{format(new Date(_.get(currentStampCard, ['redeemableTo'])), 'd.M.yyyy')}</b> asti
                </div>
              </div>
            </div>
            <div className="statistics section">{renderStatistics()}</div>
            <div className="configuration section">{renderConfiguration()}</div>
            {renderProducts()}
            {renderRewardProducts()}
            <h2>Passin kuvaus ja ulkoasu K-Ruoka -sovelluksessa</h2>
            <div className="preview section">
              <div className="left">
                <h4>Otsikko</h4>
                <div>{_.get(currentStampCard, ['uiData', 'uiName', 'fi'])}</div>
                <h4>Kuvaus ja säännöt</h4>
                <div className="pre-wrap">{_.get(currentStampCard, ['uiData', 'description', 'fi'])}</div>
                <h4>Leimojen keräysaika</h4>
                <div>
                  {format(new Date(_.get(currentStampCard, ['activeFrom'])), 'd.M.yyyy')} -{' '}
                  {format(new Date(_.get(currentStampCard, ['activeTo'])), 'd.M.yyyy')}
                </div>
                <h4>Lunastus viimeistään</h4>
                <div>{format(new Date(_.get(currentStampCard, ['redeemableTo'])), 'd.M.yyyy')}</div>
              </div>
              <div className="right">
                <div className="stampcard-preview-image-wrapper">
                  <img
                    className="stampcard-image"
                    src={_.get(currentStampCard, ['uiData', 'imageUrl', language])}
                    alt="stampcard"
                  />
                  <div className="stampcard-details">
                    <div className="stampcard-details-left">
                      {renderCardStores()}
                      <div className="stampcard-details-name">
                        {_.get(currentStampCard, ['uiData', 'uiName', 'fi'])}
                      </div>
                      Leimojen keräysaika: {format(new Date(currentStampCard.activeFrom), 'd.M.yyyy')} -{' '}
                      {format(new Date(currentStampCard.activeTo), 'd.M.yyyy')}
                    </div>
                    <div className="stampcard-details-right">{renderCardStatusPill()}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return render();
};

export default inject('stampCardStore', 'userStore')(observer(StampCardViewer));
