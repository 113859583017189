import React from 'react';
import { AlertLevel } from 'stores/next/alerts';
import { ReactComponent as IconClose } from '@kesko/icons/action/icon-close.svg';
import './alert.scss';

interface Props {
  level?: AlertLevel;
  className?: string;
  onDismiss?: (e?: React.MouseEvent) => any;
  children: string | JSX.Element | JSX.Element[];
}

class Alert extends React.Component<Props> {
  render() {
    const classes = ['alert'];
    const { children, className, onDismiss } = this.props;

    const level = this.props.level || AlertLevel.Info;
    classes.push(level);

    if (className) {
      classes.push(className);
    }

    return (
      <div className={classes.join(' ')}>
        <div className="alert-message">{children}</div>
        {onDismiss && (
          <button className="alert-dismiss" onClick={onDismiss}>
            <IconClose />
          </button>
        )}
      </div>
    );
  }
}

export default Alert;
