import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getBackLocation } from 'utils/helpers';
import { ReactComponent as IconClose } from '@kesko/icons/action/icon-close.svg';
import './closeButton.scss';

interface Props {
  fallback?: string;
  onClick?: () => void;
}

const CloseButton = ({ fallback, onClick }: Props) => {
  const location = useLocation();
  // If no fallback link given, this is just a button
  if (!fallback) {
    return (
      <div onClick={onClick} className="close-button" style={{ cursor: 'pointer' }}>
        <IconClose />
      </div>
    );
  }
  return (
    <Link onClick={onClick} to={fallback ? getBackLocation(location, fallback) : null} className="close-button">
      <IconClose />
    </Link>
  );
};

export default CloseButton;
