import React, { useEffect, useRef, useState } from 'react';
import { isEmpty, includes, filter, get } from 'lodash';
import { Product, SearchType } from 'types/campaign';
import { DateString } from 'types/string';
import { productSearch } from 'utils/api/campaign';
import { noSubmit, useDebouncedEffect, validateEan13 } from 'utils/helpers';
import Button from '../form/button';
import Spinner from 'components/common/next/spinner';

import './productSelect.scss';

interface Props {
  filterEans?: string[];
  extraProducts?: Product[];
  campaignStart?: DateString;
  campaignEnd?: DateString;
  searchType?: SearchType;
  noResultsText?: string;
  onSelect(product: Product, isNewProduct: boolean): void;
  closeSearchOnSelect?: boolean;
  placeholder?: string;
  allowByPricingUnit?: string;
}

const ProductSelect = ({
  filterEans,
  extraProducts,
  campaignStart,
  campaignEnd,
  searchType,
  noResultsText,
  onSelect,
  closeSearchOnSelect,
  placeholder,
  allowByPricingUnit,
}: Props) => {
  const [searchString, setSearchString] = useState<string>('');
  const [searchResults, setSearchResults] = useState<Product[]>([]);
  const [resultsOpen, setResultsOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const searchFieldRef = useRef<HTMLInputElement>();

  const searchProducts = async () => {
    if (!(isLoading || isLoadingMore)) {
      return;
    }

    const query = searchString;
    const increment = 10;
    const offset = isLoadingMore ? searchResults.length : undefined;

    await productSearch(query, increment, offset, campaignStart, campaignEnd)
      .then((res) => {
        const hasMore = res.length >= increment;
        let newResults = isLoadingMore ? [...searchResults, ...res] : res || [];

        if (extraProducts) {
          const extraResults = filter(extraProducts, (extraProduct: Product) => {
            const title: string = get(extraProduct, ['title', 'fi'], '');
            const ean: string = get(extraProduct, ['ean']);

            return includes(title.toLowerCase(), query.toLowerCase()) || includes(ean, query);
          });

          newResults = [...extraResults, ...newResults];
        }
        // If a new search has not been initiated or this cancelled
        if (searchString === query) {
          setSearchResults(newResults);
          setIsLoadingMore(false);
          setIsLoading(false);
          setHasMore(hasMore);
        }
      })
      .catch(() => {
        setSearchResults(searchResults || []);
        setIsLoadingMore(false);
        setIsLoading(false);
        setHasMore(false);
      });
  };

  useDebouncedEffect(searchProducts, [searchString, isLoading, isLoadingMore], 500);

  const changeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearchString(value);
    if (value.length > 2) {
      setIsLoading(true);
      setResultsOpen(true);
    } else {
      setIsLoading(false);
      setResultsOpen(false);
    }
  };

  const handleSearchMore = () => {
    setIsLoadingMore(true);
  };

  const openSearch = () => {
    setResultsOpen(true);
  };

  const select = (product: Product, isNewProduct: boolean, closeSearch?: boolean) => {
    onSelect(product, isNewProduct);

    if (closeSearchOnSelect || closeSearch) {
      setResultsOpen(false);
    }
    searchFieldRef.current.focus();
  };

  const addNewProduct = () => {
    const newProduct: Product = {
      title: {
        fi: '',
      },
      ean: searchString || '',
    };
    return select(newProduct, true, true);
  };

  const renderResultItem = (product: Product) => {
    const { isOnSale, ean, image, title, price, pricingUnit } = product;
    const filterOut = includes(filterEans, ean);
    const filteredByPricingUnit = allowByPricingUnit && pricingUnit !== allowByPricingUnit;
    const disabled = filterOut || filteredByPricingUnit;
    return (
      <div
        className={`search-result product-item ${disabled ? ' disabled' : ''}`}
        key={ean}
        onClick={!disabled ? () => select(product, false) : null}
        tabIndex={disabled ? null : 0}
      >
        <div className="image-container">
          {filterOut ? (
            <img className="icon" src={require('images/checkmark.svg').default} alt="check" />
          ) : (
            <img
              src={image ? `${image}?h=50&fm=png` : 'https://www.k-ruoka.fi/assets/6335/img/ei-tuotekuvaa.svg'}
              alt="tuotekuva"
            />
          )}
        </div>
        <div className="item-details">
          <div className="title">
            {title.fi}
            {filteredByPricingUnit && (
              <span className="filtered-by-unit"> (Et voi valita {pricingUnit}-hintaista tuotetta)</span>
            )}
          </div>
          <div className="label">
            <span className="emph">EAN</span> {ean}
          </div>
        </div>
        <div className="price-container">
          <div className="title">{price}</div>
          <div className="label">€ / {pricingUnit}</div>
          {isOnSale && <div className="on-sale">tarjouksessa</div>}
        </div>
      </div>
    );
  };

  const isAdd = searchType === SearchType.add;
  return (
    <div className="product-select">
      <div className="product-search-field form-control">
        <input
          type="text"
          placeholder={placeholder ? placeholder : 'Lisää tuote nimellä tai EAN-koodilla'}
          autoComplete="off"
          value={searchString || ''}
          onChange={changeSearch}
          onFocus={openSearch}
          onKeyDown={noSubmit}
          ref={searchFieldRef}
        />
      </div>
      {searchResults && resultsOpen && (
        <div className="product-search-results">
          {!isLoading && searchResults.map(renderResultItem)}
          {isLoading || isLoadingMore ? (
            <div className="loading-results">
              <Spinner />
            </div>
          ) : (
            searchString &&
            isEmpty(searchResults) && (
              <div className="no-results">{noResultsText ? noResultsText : 'Ei hakutuloksia.'}</div>
            )
          )}
          {!isLoading && !isLoadingMore && searchResults.length > 0 && hasMore && searchString && (
            <div className="show-more" onClick={handleSearchMore} tabIndex={0}>
              <div>Hae lisää</div>
              <img src={require('images/arrow-down.svg').default} alt="arrow-down" />
            </div>
          )}
          {isEmpty(searchResults) && isAdd && !isLoading && searchString && (
            <div className="search-result item add-own-result">
              <div className="result-details">
                <div className="image-container">
                  <img src={require('images/search-light.svg').default} alt="empty" />
                </div>
                <div className="label">Eikö mikään tuotteista vastaa etsimääsi?</div>
              </div>
              <Button
                color="bordered"
                className="title"
                onClick={addNewProduct}
                disabled={!validateEan13(searchString)}
              >
                Lisää tuote EAN-koodilla
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ProductSelect;
