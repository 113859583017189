import { action, observable } from 'mobx';
import type { Client, Components } from 'types/next-api';
import _ from 'utils/lodash';
import type { DeliverySearchPayload, DeliveryTemplateStatisticsSearchPayload } from 'types/next';
import { DeliveryChannelName } from 'enums/common';

export type DeliveryResult = Components.Schemas.DeliveryResult;
export type OfferResult = Components.Schemas.OfferResult;
export type DeliveryTemplateStats = Components.Responses.DeliveryTemplateStatisticsResponse;

export type CombinedOfferResult = {
  totalRedeemers?: number;
  totalOfferValue?: number;
  householdPurchased?: number;
  targetedHouseholds?: number;
};

export type CombinedOfferResultMap = {
  [offerId: string]: CombinedOfferResult;
};

export interface AggregatedDeliveryResults {
  offerResults: OfferResult[];
  combinedOfferResults: CombinedOfferResultMap;
  deliveryResults: DeliveryResult[];

  resultsAvailable: boolean;
  deliveryResultsAvailable: boolean;
  emailDeliveryResultsAvailable: boolean;
  printDeliveryResultsAvailable: boolean;
  offerResultsAvailable: boolean;

  totalRecipients?: number;

  emailResults?: {
    totalRecipients: number;

    opens: number;
    clicks: number;
    optouts: number;

    totalRedeemers?: number;
    totalOfferValue?: number;
    householdPurchased?: number;
    householdPurchasedDelivery?: number;
    targetedHouseholds?: number;
    householdReceipts: number;
    householdSales: number;
    householdPurchasedChannel?: number;
  };
  printResults?: {
    totalRecipients?: number;

    totalRedeemers?: number;
    totalOfferValue?: number;
    householdPurchased?: number;
    householdPurchasedDelivery?: number;
    targetedHouseholds?: number;
    householdPurchasedChannel?: number;
  };

  householdPurchased?: number;
  targetedHouseholds?: number;
  totalOfferValue?: number;
  totalRedeemers?: number;
  pullPercentage?: number;
  targetedHouseholdsTotal?: number;
  householdPurchasedTotal?: number;
}

export default class ResultsStore {
  client: Client;

  @observable deliveryResults: DeliveryResult[] = [];
  @observable offerResults: OfferResult[] = [];
  @observable templateStats: DeliveryTemplateStats[] = [];

  // Cache of indexes based on delivery ID. Set is needed because it will not have duplicates
  // Key: DeliveryResults.delivery. Value: Set of indexes inside "deliveryResults" that have that value of "delivery". One-to-many match.
  deliveryIdToIndexes: Map<string, Set<string | number>> = new Map<string, Set<string | number>>();
  // Key: DeliveryResults.deliveryIdentifier. Value: index in deliveryResults that matches the deliveryIdentifier.
  deliveryIdentifierToIndexes: Map<string | number, number> = new Map<string | number, number>();
  // Key: concatenated string of OfferResult.deliveryIdentifier and OfferResult.offerId. Value: index in offerResults that matches the key values.
  offerIdAndDeliveryToIndexes: Map<string, number> = new Map<string, number>();
  // Map to easily fetch statistics
  templateToIndexes: Map<string, any> = new Map<string, any>();

  getResultsByDeliveryId(deliveryId: string): DeliveryResult[] {
    const reply: DeliveryResult[] = [];
    const resultIndexes = this.deliveryIdToIndexes.get(deliveryId) || [];
    resultIndexes.forEach((index) => {
      reply.push(this.deliveryResults[index]);
    });
    return reply;
  }

  public saveResultToArrays(result: DeliveryResult) {
    if (!result) return;
    const newLength = this.deliveryResults.push(result);
    if (!this.deliveryIdToIndexes.has(result.delivery)) {
      this.deliveryIdToIndexes.set(result.delivery, new Set<string | number>());
    }
    // The "result" was pushed to the end of deliveryResults. Hence, its array position (index) is (newlength-1).
    // We cache this index in the maps below so we can get it based on "delivery" or "deliveryIdentifier" field
    // values (as opposed to full scan of 'deliveryResults' which must be avoided).
    this.deliveryIdToIndexes.get(result.delivery).add(newLength - 1);
    this.deliveryIdentifierToIndexes.set(result.deliveryIdentifier, newLength - 1);
  }

  getDeliveryResults(deliveryId: string) {
    const deliveryResults = this.getResultsByDeliveryId(deliveryId);
    const offerResults = this.offerResults.filter((result) => result.delivery === deliveryId);

    const deliveryResultsAvailable = deliveryResults.length > 0;
    const offerResultsAvailable = offerResults.length > 0;

    const res: AggregatedDeliveryResults = {
      deliveryResults,
      offerResults,
      combinedOfferResults: {},
      resultsAvailable: deliveryResultsAvailable || offerResultsAvailable,
      deliveryResultsAvailable,
      emailDeliveryResultsAvailable: false,
      printDeliveryResultsAvailable: false,
      offerResultsAvailable,
      totalRecipients: 0,
      emailResults: {
        totalRecipients: 0,
        opens: 0,
        clicks: 0,
        optouts: 0,
        totalRedeemers: 0,
        totalOfferValue: 0,
        householdPurchased: 0,
        householdPurchasedDelivery: 0,
        targetedHouseholds: 0,
        householdReceipts: 0,
        householdSales: 0,
        householdPurchasedChannel: 0,
      },
      printResults: {
        totalRecipients: 0,
        totalRedeemers: 0,
        totalOfferValue: 0,
        householdPurchased: 0,
        householdPurchasedDelivery: 0,
        targetedHouseholds: 0,
        householdPurchasedChannel: 0,
      },
      householdPurchased: 0,
      targetedHouseholds: 0,
      totalOfferValue: 0,
      totalRedeemers: 0,
      pullPercentage: 0,
      targetedHouseholdsTotal: 0,
      householdPurchasedTotal: 0,
    };

    _.each(deliveryResults, (r) => {
      // Total recipients, email + print, if B2B delivery, use value from b2bCustomers
      res.totalRecipients += r.persons ? r.persons : r.b2bCustomers ? r.b2bCustomers : 0;

      // Total recipients per channel, if B2B delivery, use value from b2bCustomers
      res.emailResults.totalRecipients +=
        r.channel === DeliveryChannelName.Email ? (r.persons ? r.persons : r.b2bCustomers ? r.b2bCustomers : 0) : 0;
      res.printResults.totalRecipients += r.channel === DeliveryChannelName.Print ? r.persons : 0;

      // Email statistics
      res.emailResults.opens += r.channel === DeliveryChannelName.Email ? r.opens : 0;
      res.emailResults.clicks += r.channel === DeliveryChannelName.Email ? r.clicks : 0;
      res.emailResults.optouts += r.channel === DeliveryChannelName.Email ? r.optouts : 0;

      // BTT has some extra rows in statistics
      res.emailResults.householdReceipts += r.channel === DeliveryChannelName.Email ? r.householdReceipts : 0;
      res.emailResults.householdSales += r.channel === DeliveryChannelName.Email ? r.householdSales : 0;

      // BTT has delivery pull percentage counted in each delivery result row
      if (r.pullPercentage) {
        res.pullPercentage = r.pullPercentage;
      }

      if (r.channel === DeliveryChannelName.Email) {
        res.emailDeliveryResultsAvailable = true;
      }
      if (r.channel === DeliveryChannelName.Print) {
        res.printDeliveryResultsAvailable = true;
      }
    });

    _.each(offerResults, (r) => {
      const isEmailOffer = _.includes(r.deliveryIdentifier, '_email_');
      const isPrintOffer = _.includes(r.deliveryIdentifier, '_print_');

      if (isEmailOffer) {
        // Count of redeemed offers
        res.emailResults.totalRedeemers += r.discountQty;

        // Total offer value = total amount of offers in euro.
        res.emailResults.totalOfferValue += r.discountAmtEur;

        res.emailResults.householdPurchased += r.householdPurchased;

        // Each row in result data contains total number of householdPurchasedDelivery and targetedHouseholds for channel.
        res.emailResults.householdPurchasedDelivery += r.householdPurchasedDelivery;
        res.emailResults.targetedHouseholds = r.householdTargetedChannel;
        // household_purchased_channel to channel result data
        res.emailResults.householdPurchasedChannel = r.householdPurchasedChannel;
      } else if (isPrintOffer) {
        // Count of redeemed offers
        res.printResults.totalRedeemers += r.discountQty;

        // Total offer value = total amount of offers in euro.
        res.printResults.totalOfferValue += r.discountAmtEur;

        res.printResults.householdPurchased += r.householdPurchased;

        res.printResults.householdPurchasedDelivery += r.householdPurchasedDelivery;
        res.printResults.targetedHouseholds = r.householdTargetedChannel;
        // household_purchased_channel to channel result data
        res.printResults.householdPurchasedChannel = r.householdPurchasedChannel;
      }

      // offer results have same total on each line so taking value from one.
      res.targetedHouseholdsTotal = r.householdTargetedTotal;
      res.householdPurchasedTotal = r.householdPurchasedTotal;

      res.householdPurchased += r.householdPurchased;
      res.totalOfferValue += r.discountAmtEur;
      res.totalRedeemers += r.discountQty;

      if (res.combinedOfferResults[r.offerId]) {
        res.combinedOfferResults[r.offerId].totalRedeemers += r.discountQty;
        res.combinedOfferResults[r.offerId].totalOfferValue += r.discountAmtEur;
        res.combinedOfferResults[r.offerId].householdPurchased += r.householdPurchased;
        // Sum targeted households over different channels.
        res.combinedOfferResults[r.offerId].targetedHouseholds += r.targetedHouseholds;
      } else {
        res.combinedOfferResults[r.offerId] = {
          totalRedeemers: r.discountQty,
          totalOfferValue: r.discountAmtEur,
          householdPurchased: r.householdPurchased,
          targetedHouseholds: r.targetedHouseholds,
        };
      }
      // GT has delivery pull percentage counted in each offer result row
      if (r.pullPercentage) {
        res.pullPercentage = r.pullPercentage;
      }
    });

    res.targetedHouseholds =
      res.emailResults.targetedHouseholds + res.printResults.targetedHouseholds > 0
        ? res.emailResults.targetedHouseholds + res.printResults.targetedHouseholds
        : res.emailResults.totalRecipients + res.printResults.totalRecipients;

    return res;
  }

  @action
  public updateDeliveryResults = (results: DeliveryResult[]) => {
    for (const result of results) {
      const index = this.deliveryIdentifierToIndexes.get(result.deliveryIdentifier);
      if (index !== undefined) {
        // update
        if (!_.isEqual(this.deliveryResults[index], result)) {
          this.deliveryResults[index] = result;
        } else {
          // no need to update
        }
      } else {
        // add to collection
        this.saveResultToArrays(result);
      }
    }
    return this.deliveryResults;
  };

  getKeyForOfferResult(o: OfferResult): string {
    return `${o.deliveryIdentifier}_${o.offerId}`;
  }

  @action
  public updateOfferResults = (results: OfferResult[]) => {
    for (const result of results) {
      const index = this.offerIdAndDeliveryToIndexes.get(this.getKeyForOfferResult(result));
      if (index !== undefined) {
        // update
        if (!_.isEqual(this.offerResults[index], result)) {
          this.offerResults[index] = result;
        } else {
          // no need to update
        }
      } else {
        // add to collection and cache
        const newLength = this.offerResults.push(result); // = [...this.offerResults, result];
        this.offerIdAndDeliveryToIndexes.set(this.getKeyForOfferResult(result), newLength - 1);
      }
    }
    return this.offerResults;
  };

  @action
  public async searchDeliveryResults(payload: DeliverySearchPayload = {}) {
    const { data } = await this.client.searchDeliveryResults(null, payload);
    this.updateDeliveryResults(data.deliveryResults);
    this.updateOfferResults(data.offerResults);
  }

  @action
  public async getDeliveryTemplateStats(payload: DeliveryTemplateStatisticsSearchPayload = { id: [] }) {
    const { data } = await this.client.getTemplateStatistics(null, payload);
    this.updateTemplateStatistics(data);
  }

  @action
  public updateTemplateStatistics = (stats: DeliveryTemplateStats) => {
    for (const stat of stats) {
      const index = this.templateToIndexes.get(stat.deliveryTemplate);
      if (index !== undefined) {
        // update
        if (!_.isEqual(this.templateToIndexes.get(stat.deliveryTemplate), stat)) {
          this.templateToIndexes.set(stat.deliveryTemplate, stat);
        } else {
          // no need to update
        }
      } else {
        this.templateToIndexes.set(stat.deliveryTemplate, stat);
      }
    }
    return this.templateToIndexes;
  };

  @action
  public getTemplateStats(templateId: string) {
    return this.templateToIndexes.get(templateId);
  }
}
