import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'utils/lodash';
import { ReactComponent as IconDelete } from '@kesko/icons/action/icon-delete.svg';
import { ReactComponent as IconEdit } from '@kesko/icons/action/icon-edit.svg';
import { ReactComponent as SurprisedIcon } from '@kesko/icons/mood/icon-mood_surprise.svg';
import { ReactComponent as HappyIcon } from '@kesko/icons/mood/icon-mood_happy.svg';
import { ReactComponent as UnhappyIcon } from '@kesko/icons/mood/icon-mood_unhappy.svg';
import StampCardStore from 'stores/next/stampCard';
import StoreStore from 'stores/next/stores';
import page from 'components/next/pages/page/page';
import StoreSelector from 'components/next/components/storeSelector';
import ChainSelector from 'components/next/components/chainSelector';
import ProductSelect from 'components/common/next/offerEditor/productSelect';
import InputSelect from 'components/common/next/form/inputSelect';
import InputText from 'components/common/next/form/inputText';
import InputImage from 'components/common/next/form/inputImage';
import InputRadio from 'components/common/next/form/inputRadio';
import Dropdown from 'components/next/components/dropdown';
import Button from 'components/common/next/form/button';
import Spinner from 'components/common/next/spinner';
import { FormInput } from 'components/next/components/form/input';
import { collectionStampCardTemplates, editCollectionStampCardTemplate } from 'components/next/routes';
import type { Product, CollectionStampCardProduct, Store, Route } from 'types/next';
import { SearchType } from 'types/campaign';
import { discountImageUrl } from 'utils/api/campaign';
import {
  WEBSTORE_PRODUCT_FREE_DELIVERY_1,
  WEBSTORE_PRODUCT_FREE_DELIVERY_2,
  WEBSTORE_PRODUCT_FREE_PICKUP,
} from 'utils/constants';
import './collectionStampCardTemplateEditor.scss';
import { validateEan13, getImageFileDimensions } from 'utils/helpers';
import { deleteFileFromS3, replaceFileInS3 } from 'utils/api/other';
import { kRautaChainIds } from 'constants/common';
import { PRODUCT_IMAGE_PLACEHOLDER, BASKET_DISCOUNT_PERCENTAGES } from 'constants/stampCard';
import { ProductPricing } from 'enums/product';
import {
  CollectionStampCardStatus,
  CollectionStampCardType,
  CollectionStampCardStrategy,
  CollectionStampCardRewardType,
  CollectionStampCardRewardDiscountType,
  CollectionStampCardRewardDiscountMethod,
  CollectionStampCardImageTextType,
} from 'enums/stampCard';
import { EditState } from 'enums/common';
import { getCollectionStampCardImageHTML } from 'utils/stampCard';
import { useNavigate, useParams } from 'react-router-dom';

const parseNumber = (value) => (value === '' ? '' : Number(value.replace(',', '.')));

export enum StampCardTemplateEditorMode {
  Create = 'create',
  Edit = 'edit',
}

interface Props {
  mode: StampCardTemplateEditorMode;
  getPageLink(route: Route, id: string): string;
}

interface InjectedProps extends Props {
  stampCardStore?: StampCardStore;
  storeStore?: StoreStore;
}

const CollectionStampCardTemplateEditor = ({ mode, getPageLink, stampCardStore, storeStore }: InjectedProps) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [tab, setTab] = useState(0);
  const [productTitle, setProductTitle] = useState('');
  const [productImage, setProductImage] = useState('');
  const [productEan, setProductEan] = useState('');
  const [rewardTitle, setRewardTitle] = useState('');
  const [rewardImage, setRewardImage] = useState('');
  const [rewardEan, setRewardEan] = useState('');
  const [templateImage, setTemplateImage] = useState('');
  const [replaced, setReplaced] = useState(false);

  const tabs = [
    {
      name: 'General',
      tab: 0,
    },
    {
      name: 'Image',
      tab: 1,
    },
  ];

  const states = [
    {
      name: 'Draft',
      value: false,
    },
    {
      name: 'Published',
      value: true,
    },
  ];

  const getCurrentCardTemplate = () => {
    return stampCardStore.currentTemplate;
  };

  const getModeAndId = () => {
    return {
      mode,
      id,
    };
  };

  const getProductBasedPricingMode = (): ProductPricing | null => {
    const resolveProduct = (prodString: ProductPricing) => {
      // reward product might be null, unexpected value or expected value (kpl/kg)
      if (prodString) {
        if (prodString === ProductPricing.Weight) {
          return ProductPricing.Weight;
        } else if (prodString === ProductPricing.PerPiece) {
          return ProductPricing.PerPiece;
        }
      }
      return null;
    };

    const rewardProductsTypes = stampCardStore?.currentTemplate?.rewardProducts?.map((product) => product.pricingUnit);
    const uniqueRewardProducts = [...new Set(rewardProductsTypes)];
    if (rewardProductsTypes) {
      if (uniqueRewardProducts.length === 1) {
        return resolveProduct(uniqueRewardProducts[0] as ProductPricing);
      }
    }
    // If there's no reward products, return template value. Otherwise result is not defined (this happens with mixed kpl/kg reward products)
    return uniqueRewardProducts.length === 0
      ? resolveProduct(stampCardStore?.currentTemplate?.reward?.pricingUnit as ProductPricing)
      : null;
  };

  useEffect(() => {
    const init = async () => {
      await storeStore.searchStores({});

      if (mode === StampCardTemplateEditorMode.Create) {
        stampCardStore.initializeCardTemplate();
      } else {
        await stampCardStore.initializeCardTemplate(id);
        // the pricing unit might not be automatically set in case of older stamp cards
        stampCardStore.currentTemplate.reward.pricingUnit = getProductBasedPricingMode();
      }
    };
    init();
    return () => {
      stampCardStore.currentTemplate = null;
    };
  }, []);

  const back = () => {
    if (_.includes([EditState.Error, EditState.Changed], stampCardStore.currentTemplateState)) {
      const confirmed = window.confirm(
        'If you move away from this page, all unsaved changes will be lost. Are you sure you want to continue?',
      );

      if (confirmed) {
        navigate(getPageLink(collectionStampCardTemplates, null));
      }
    } else {
      navigate(getPageLink(collectionStampCardTemplates, null));
    }
  };

  const save = async () => {
    if (mode === StampCardTemplateEditorMode.Create) {
      try {
        await stampCardStore.createCollectionStampCardTemplate(stampCardStore.currentTemplate);
        navigate(getPageLink(editCollectionStampCardTemplate, stampCardStore.currentTemplate.id));
      } catch (err) {
        console.error(err);
      }
    } else {
      try {
        await stampCardStore.replaceCollectionStampCardTemplate(id, stampCardStore.currentTemplate);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const handleAddProduct = (type: 'product' | 'reward') => {
    const product = {
      title: {
        fi: type === 'product' ? productTitle : rewardTitle,
      },
      image: type === 'product' ? productImage : rewardImage,
      ean: type === 'product' ? productEan : rewardEan,
      pricingUnit: ProductPricing.PerPiece,
    };

    if (type === 'reward' && stampCardStore.currentTemplate.reward.pricingUnit) {
      product.pricingUnit = stampCardStore.currentTemplate.reward.pricingUnit as ProductPricing;
    }

    if (product.image === '') {
      product.image = null;
    }

    handleSelectProduct(product, type);

    if (type === 'product') {
      setProductTitle('');
      setProductImage('');
      setProductEan('');
    } else {
      setRewardTitle('');
      setRewardImage('');
      setRewardEan('');
    }
  };

  const handleSelectProduct = (product: Product, type: 'product' | 'reward') => {
    const { ean, title, price, pricingUnit, image } = product;
    const stampCardProduct: CollectionStampCardProduct = { ean, title, price, pricingUnit, image };
    const { products, rewardProducts } = stampCardStore.currentTemplate;

    if (type === 'product') {
      stampCardStore.updateTemplateValue(['products'], [...products, stampCardProduct]);
    } else {
      stampCardStore.updateTemplateValue(['rewardProducts'], [...rewardProducts, stampCardProduct]);
      stampCardStore.updateTemplateValue(['reward', 'pricingUnit'], getProductBasedPricingMode());
    }
  };

  const handleRemoveProduct = (ean: string, type: 'product' | 'reward') => {
    const {
      products,
      rewardProducts,
      reward: { image },
    } = stampCardStore.currentTemplate;

    if (type === 'product') {
      stampCardStore.updateTemplateValue(
        ['products'],
        _.filter(products, (product) => product.ean !== ean),
      );
    } else {
      const product = _.find(rewardProducts, { ean });
      if (product && product.image === image) {
        stampCardStore.updateTemplateValue(['reward', 'image'], PRODUCT_IMAGE_PLACEHOLDER);
      }
      stampCardStore.updateTemplateValue(
        ['rewardProducts'],
        _.filter(rewardProducts, (product) => product.ean !== ean),
      );
      stampCardStore.updateTemplateValue(['reward', 'pricingUnit'], getProductBasedPricingMode());
    }
  };

  const handleCopyRewardsFromProducts = () => {
    const rewards = _.get(stampCardStore.currentTemplate, ['rewardProducts']);
    if (rewards.length > 0) {
      const confirmed = window.confirm(
        'Are you sure you want to copy products to rewards and replace current rewards?',
      );
      if (!confirmed) {
        return false;
      }
    }

    const products = _.get(stampCardStore.currentTemplate, ['products']);
    stampCardStore.updateTemplateValue(['rewardProducts'], products);
    stampCardStore.updateTemplateValue(['reward', 'pricingUnit'], getProductBasedPricingMode());
  };

  const handleAddTemplateImage = () => {
    const { backgroundUrls } = stampCardStore.currentTemplate.imageData;
    const newBackgroundUrls = [...backgroundUrls, templateImage];

    stampCardStore.updateTemplateValue(['imageData', 'backgroundUrls'], newBackgroundUrls);
    setTemplateImage('');
  };

  const getActiveStampCardsUsingImage = async (imageUrl: string) => {
    await stampCardStore.searchCollectionStampCards({
      payload: { status: CollectionStampCardStatus.ActiveOrUpcoming },
    });

    const stampCardsWithImage = _.filter(stampCardStore.stampCards, (stampCard) => {
      const { backgroundUrl } = stampCard.imageData;
      return backgroundUrl === imageUrl;
    });

    return stampCardsWithImage;
  };

  const handleDeleteTemplateImage = async (imageUrl: string) => {
    const activeStampCardsUsingSelectedImage = await getActiveStampCardsUsingImage(imageUrl);
    if (activeStampCardsUsingSelectedImage.length > 0) {
      alert(
        `Image is currently in use by ${activeStampCardsUsingSelectedImage.length} active stamp cards. Unable to delete.`,
      );
      return;
    }

    // Only get the file name from the imageUrl
    const imageName = imageUrl.split('/').pop();
    const { backgroundUrls } = stampCardStore.currentTemplate.imageData;
    const confirmed = window.confirm(
      'Are you sure you want to delete the image? This will completely remove it from storage for all templates. \n\nThis action can not be undone.',
    );
    if (!confirmed) {
      return false;
    }

    try {
      await deleteFileFromS3(imageName, 'stampcard');
      stampCardStore.updateTemplateValue(['imageData', 'backgroundUrls'], _.without(backgroundUrls, imageUrl));
    } catch (err) {
      console.error(err);
    }
  };

  const handleReplaceTemplateImage = async (imageUrl: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const imageName = imageUrl.split('/').pop();
    const { currentTarget } = event;
    const { files } = currentTarget;
    const constraints = { minWidth: 1000, minHeight: 540 };
    const confirmed = window.confirm(
      'Are you sure you want to replace the image? This will replace it for all templates. \n\nThis action can not be undone.',
    );
    if (!confirmed) {
      return false;
    }

    if (files.length === 1) {
      const file = files[0];

      const validImageDimensions = await getImageFileDimensions(file, constraints.minWidth, constraints.minHeight);
      if (!validImageDimensions) {
        alert('The image you uploaded is too small. The image must be at least 1000px wide and 540px high.');
        return false;
      }

      try {
        await replaceFileInS3(file, imageName, 'stampcard');

        // Add a timestamp to the corresponding image element to bust the browser image cache
        const labelElement = document.querySelector(`label[for="radio-${imageUrl}"]`);
        if (labelElement) {
          const imageElement = labelElement.querySelector('img');
          if (imageElement) {
            imageElement.src = `${imageUrl}?${Date.now()}`;
          }
        }
        setReplaced(true);
      } catch (err) {
        alert('The image could not be replaced. Please try again later.');
        console.error(err);
      }
    }
  };

  const handleTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const type = e.target.value as CollectionStampCardType;
    const strategy =
      type === CollectionStampCardType.Basket
        ? CollectionStampCardStrategy.OneTimePurchase
        : CollectionStampCardStrategy.SimpleProductCollecting;
    const oneTimePurchase = strategy === CollectionStampCardStrategy.OneTimePurchase ? 50 : '';

    stampCardStore.updateTemplateValue(['products'], []);
    stampCardStore.updateTemplateValue(['rewardProducts'], []);
    stampCardStore.updateTemplateValue(['type'], type);
    stampCardStore.updateTemplateValue(['strategy'], strategy);
    stampCardStore.updateTemplateValue(['oneTimePurchase'], oneTimePurchase);
  };

  const handleChainChange = (chain: string) => {
    const chainIds = [...stampCardStore.currentTemplate.chains];
    if (chainIds.includes(chain)) {
      _.pullAt(chainIds, chainIds.indexOf(chain));
    } else {
      chainIds.push(chain);
    }
    stampCardStore.updateTemplateValue(['chains'], chainIds);
  };

  const handleStoresChange = (stores: Store[]) => {
    const storeIds = stores.length > 0 ? _.map(stores, 'storeId') : null;
    stampCardStore.updateTemplateValue(['stores'], storeIds);
  };

  const handleRewardTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const type = e.target.value as CollectionStampCardRewardType;

    let discountType;
    let discountMethod;
    let discount = 0;
    let minimumPurchase = 0;
    let title;
    let image;

    switch (type) {
      default:
      case CollectionStampCardRewardType.ProductEur: {
        discountType = CollectionStampCardRewardDiscountType.Product;
        discountMethod = CollectionStampCardRewardDiscountMethod.ProductEuro;
        title = 'Tuote alennettuun hintaan';
        image = PRODUCT_IMAGE_PLACEHOLDER;
        break;
      }
      case CollectionStampCardRewardType.ProductPercentage: {
        discountType = CollectionStampCardRewardDiscountType.Product;
        discountMethod = CollectionStampCardRewardDiscountMethod.ProductPercent;
        title = 'Tuote alennettuun hintaan';
        image = PRODUCT_IMAGE_PLACEHOLDER;
        break;
      }
      case CollectionStampCardRewardType.BasketEur: {
        discountType = CollectionStampCardRewardDiscountType.BasketEuro;
        discountMethod = CollectionStampCardRewardDiscountMethod.BasketEuro;
        discount = 10;
        minimumPurchase = 50;
        title = `${discount}€ alennus vähintään ${minimumPurchase}€ kertaostoksesta`;
        image = discountImageUrl(0, discount);
        break;
      }
      case CollectionStampCardRewardType.BasketPercentage: {
        discountType = CollectionStampCardRewardDiscountType.BasketPercent;
        discountMethod = CollectionStampCardRewardDiscountMethod.BasketPercent;
        discount = 11; // This maps to AC discount group which equals to 3%
        minimumPurchase = 50; // Basket percent discount minimum purchase must be atleast 3 euros.
        const discountPercentage = _.get(BASKET_DISCOUNT_PERCENTAGES, discount);
        title = `${discountPercentage}% alennus vähintään ${minimumPurchase}€ kertaostoksesta`;
        image = discountImageUrl(0, null, discountPercentage);
        break;
      }
    }

    stampCardStore.updateTemplateValue(['rewardProducts'], []);
    stampCardStore.updateTemplateValue(['reward', 'type'], type);
    stampCardStore.updateTemplateValue(['reward', 'discountType'], discountType);
    stampCardStore.updateTemplateValue(['reward', 'discountMethod'], discountMethod);
    stampCardStore.updateTemplateValue(['reward', 'discount'], discount);
    stampCardStore.updateTemplateValue(['reward', 'minimumPurchase'], minimumPurchase);
    stampCardStore.updateTemplateValue(['reward', 'title', 'fi'], title);
    stampCardStore.updateTemplateValue(['reward', 'image'], image);
  };

  const renderProducts = (type: 'product' | 'reward') => {
    const {
      strategy,
      reward: { discountMethod, discount, type: rewardType },
    } = stampCardStore.currentTemplate;
    if (
      (type === 'product' && strategy !== CollectionStampCardStrategy.SimpleProductCollecting) ||
      (type === 'reward' &&
        !_.includes(
          [CollectionStampCardRewardType.ProductEur, CollectionStampCardRewardType.ProductPercentage],
          rewardType,
        ))
    ) {
      return null;
    }

    const { products, rewardProducts } = stampCardStore.currentTemplate;

    return (
      <div className={`stampcard-template-editor-${type}s form-control`}>
        <label>Products</label>
        {(type === 'product' ? products : rewardProducts).length === 0 && 'No products.'}
        {_.map(type === 'product' ? products : rewardProducts, (product) => {
          let price: string | number = _.get(product, ['price']);
          if (type === 'reward') {
            if (price && discountMethod === CollectionStampCardRewardDiscountMethod.ProductPercent) {
              price = `${(Number(price) - (Number(price) / 100) * discount).toFixed(2)}€`;
            } else if (discountMethod === CollectionStampCardRewardDiscountMethod.ProductEuro) {
              price = `${discount}€`;
            }
          }
          return (
            <div className="product-picker-product" key={`product-${_.get(product, ['ean'])}`}>
              <div className="product-image">
                <img
                  src={
                    _.get(product, ['image']) ? `${_.get(product, ['image'])}?h=50&fm=png` : PRODUCT_IMAGE_PLACEHOLDER
                  }
                  alt={_.get(product, ['title', 'fi'])}
                />
              </div>
              <div className="product-details">
                <div className="product-title">{_.get(product, ['title', 'fi'])}</div>
                <div className="product-ean">
                  <span className="emph">EAN </span>
                  {_.get(product, ['ean'])}
                </div>
              </div>
              <div className="product-pricing">
                <div className={`product-price${type === 'product' ? '' : ' discount'}`}>{price}</div>
                <div className="product-pricingUnit">{_.get(product, ['pricingUnit'])}</div>
              </div>
              <div className="remove-item" onClick={() => handleRemoveProduct(_.get(product, ['ean']), type)}>
                <IconDelete />
              </div>
            </div>
          );
        })}
        {type === 'reward' && products.length > 0 && (
          <div className="stampcard-template-editor-rewards-actions">
            <Button color="bordered" onClick={handleCopyRewardsFromProducts}>
              Copy from products
            </Button>
          </div>
        )}
      </div>
    );
  };

  const renderAddProductForm = (type: 'product' | 'reward') => {
    const validatedEan = type === 'product' ? productEan : rewardEan;
    const pricingUnit =
      type === 'reward' && stampCardStore.currentTemplate.reward.pricingUnit
        ? (stampCardStore.currentTemplate.reward.pricingUnit as ProductPricing)
        : ProductPricing.PerPiece;
    const addButtonDisabled = !validateEan13(validatedEan) || (type === 'product' ? !productTitle : !rewardTitle);

    return (
      <React.Fragment>
        <div className="stampcard-template-add-product-inputs">
          <div>
            <InputText
              key="title"
              name="title"
              label="Title"
              value={type === 'product' ? productTitle : rewardTitle}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                type === 'product' ? setProductTitle(e.target.value) : setRewardTitle(e.target.value)
              }
            />
            <InputText
              key="ean"
              name="ean"
              label="EAN"
              value={type === 'product' ? productEan : rewardEan}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                type === 'product' ? setProductEan(e.target.value) : setRewardEan(e.target.value)
              }
            />
            <InputText
              key="pricingUnit"
              name="pricingUnit"
              label="Pricing unit"
              value={pricingUnit === ProductPricing.Weight ? 'weight' : 'pcs'}
              readOnly
            />
          </div>
          <InputImage
            label="Product image"
            acceptTypes="image/png,image/jpg,image/jpeg"
            onChange={({ src }) => (type === 'product' ? setProductImage(src) : setRewardImage(src))}
            value={{ src: type === 'product' ? productImage : rewardImage }}
            styles={{ maxHeight: 100 }}
          />
        </div>
        <div className="stampcard-template-add-product-actions ">
          <Button color="default" onClick={() => handleAddProduct(type)} disabled={addButtonDisabled}>
            Add
          </Button>
        </div>
      </React.Fragment>
    );
  };

  const renderProductPicker = (type: 'product' | 'reward') => {
    const products = _.get(stampCardStore.currentTemplate, [type === 'product' ? 'products' : 'rewardProducts'], []);
    return (
      <React.Fragment>
        <div className="form-control">
          <label>Add product</label>
          {renderAddProductForm(type)}
        </div>
        <label>Search products</label>
        <ProductSelect
          filterEans={_.map(products, 'ean')}
          searchType={SearchType.add}
          onSelect={(product) => handleSelectProduct(product, type)}
          extraProducts={[
            WEBSTORE_PRODUCT_FREE_DELIVERY_1,
            WEBSTORE_PRODUCT_FREE_DELIVERY_2,
            WEBSTORE_PRODUCT_FREE_PICKUP,
          ]}
          noResultsText="No results"
          closeSearchOnSelect
          placeholder="Search product by name or EAN"
        />
      </React.Fragment>
    );
  };

  const renderSimpleProductCollectionConfiguration = () => {
    const type = _.get(stampCardStore.currentTemplate, ['type']);
    if (type === CollectionStampCardType.Basket) {
      return null;
    }

    return (
      <div className="product-configuration form-control">
        <label>Amount of products to purchase</label>
        <FormInput
          additionalClasses="form-control"
          type="number"
          min={1}
          step={1}
          handleChange={(e) => stampCardStore.updateTemplateValue(['stampConfiguration'], parseNumber(e.target.value))}
          value={_.get(stampCardStore.currentTemplate, ['stampConfiguration'])}
        />
        {renderProductPicker('product')}
      </div>
    );
  };

  const renderOneTimePurchaseConfiguration = () => {
    const type = _.get(stampCardStore.currentTemplate, ['type']);
    if (type !== CollectionStampCardType.Basket) {
      return null;
    }

    return (
      <div className="product-configuration form-control">
        <label>Size of one time purchase, €</label>
        <FormInput
          additionalClasses="form-control"
          type="number"
          min={1}
          handleChange={(e) => stampCardStore.updateTemplateValue(['oneTimePurchase'], parseNumber(e.target.value))}
          value={_.get(stampCardStore.currentTemplate, ['oneTimePurchase'])}
        />
      </div>
    );
  };

  const renderRewardConfiguration = () => {
    const {
      reward: { type, minimumPurchase },
    } = stampCardStore.currentTemplate;

    if (type === CollectionStampCardRewardType.ProductEur) {
      return (
        <div className="form-control">
          <label>Discount price, €</label>
          <div className="reward-type form-control">
            <FormInput
              additionalClasses="discount-amount"
              detail="Price for the customer"
              type="number"
              min={0}
              handleChange={(e) =>
                stampCardStore.updateTemplateValue(['reward', 'discount'], parseNumber(e.target.value))
              }
              value={_.get(stampCardStore.currentTemplate, ['reward', 'discount'])}
            />
          </div>
          <label>Discount default amount (g for weight)</label>
          <div className="reward-type form-control">
            <FormInput
              detail="The default discounted amount. Always 1 for price per piece products"
              type="number"
              min={0}
              handleChange={(e) =>
                stampCardStore.updateTemplateValue(['reward', 'itemLimit'], parseNumber(e.target.value))
              }
              value={_.get(stampCardStore.currentTemplate, ['reward', 'itemLimit'])}
            />
            <InputSelect
              name="pricingUnit"
              value={getProductBasedPricingMode() || ''}
              disabled={stampCardStore.currentTemplate?.rewardProducts?.length !== 0}
              onChange={(e) => {
                stampCardStore.updateTemplateValue(['reward', 'pricingUnit'], e.target.value);
              }}
            >
              <option value=""></option>
              <option value="kpl">pcs</option>
              <option value="kg">weight</option>
            </InputSelect>
          </div>
          {renderProductPicker('reward')}
        </div>
      );
    } else if (type === CollectionStampCardRewardType.ProductPercentage) {
      return (
        <div className="form-control">
          <label>Discount percentage</label>
          <div className="reward-type form-control">
            <FormInput
              additionalClasses="discount-amount"
              type="number"
              min={0}
              max={100}
              step={1}
              handleChange={(e) =>
                stampCardStore.updateTemplateValue(['reward', 'discount'], parseNumber(e.target.value))
              }
              value={_.get(stampCardStore.currentTemplate, ['reward', 'discount'])}
            />
          </div>
          {renderProductPicker('reward')}
        </div>
      );
    } else if (type === CollectionStampCardRewardType.BasketEur) {
      return (
        <div className="reward-type form-control">
          <div className="form-control">
            <label>Discount amount, €</label>
            <FormInput
              type="number"
              min={0}
              step={1}
              handleChange={(e) => {
                const value = parseNumber(e.target.value);
                stampCardStore.updateTemplateValue(['reward', 'discount'], value);

                // Minimum purchase must be atleast the same as discount.
                if (value && minimumPurchase < value) {
                  stampCardStore.updateTemplateValue(['reward', 'minimumPurchase'], value);
                }
              }}
              value={_.get(stampCardStore.currentTemplate, ['reward', 'discount'])}
            />
          </div>
          <div className="form-control">
            <label>Size of one time purchase, €</label>
            <FormInput
              type="number"
              detail="Must be atleast the same as discount amount"
              min={_.get(stampCardStore.currentTemplate, ['reward', 'discount'])}
              handleChange={(e) => {
                const value = parseNumber(e.target.value);
                stampCardStore.updateTemplateValue(['reward', 'minimumPurchase'], value);
              }}
              value={_.get(stampCardStore.currentTemplate, ['reward', 'minimumPurchase'])}
            />
          </div>
        </div>
      );
    } else if ((type as CollectionStampCardRewardType) === CollectionStampCardRewardType.BasketPercentage) {
      return (
        <div className="reward-type form-control">
          <InputSelect
            label="Discount percentage"
            value={_.get(stampCardStore.currentTemplate, ['reward', 'discount'])}
            onChange={(e) => stampCardStore.updateTemplateValue(['reward', 'discount'], parseInt(e.target.value, 10))}
          >
            <option key={11} value={11}>
              3%
            </option>
            <option key={12} value={12}>
              5%
            </option>
            <option key={13} value={13}>
              7%
            </option>
            <option key={14} value={14}>
              10%
            </option>
            <option key={15} value={15}>
              15%
            </option>
            <option key={16} value={16}>
              20%
            </option>
          </InputSelect>
          <div className="form-control">
            <label>Size of one time purchase, €</label>
            <FormInput
              type="number"
              detail="Must be atleast 3€ "
              min={3}
              handleChange={(e) => {
                const value = parseNumber(e.target.value);
                stampCardStore.updateTemplateValue(['reward', 'minimumPurchase'], value);
              }}
              value={_.get(stampCardStore.currentTemplate, ['reward', 'minimumPurchase'])}
            />
          </div>
        </div>
      );
    }
  };

  const renderPreview = () => {
    const {
      imageData: {
        text: { fi },
        textType,
        backgroundUrl,
        textBackgroundColor,
        textForegroundColor,
      },
    } = stampCardStore.currentTemplate;

    return (
      <div
        className="stampcard-template-image-preview-html"
        dangerouslySetInnerHTML={{
          __html: getCollectionStampCardImageHTML({
            preview: true,
            backgroundUrl,
            textType: textType as CollectionStampCardImageTextType,
            text: fi,
            textBackgroundColor,
            textForegroundColor,
            replaced,
          }),
        }}
      />
    );
  };

  const renderRewardTitleEditor = () => {
    const {
      rewardProducts,
      reward: {
        type,
        title: { fi: title },
        image,
      },
    } = stampCardStore.currentTemplate;

    if (type === CollectionStampCardRewardType.ProductEur) {
      return (
        <div className="reward-details">
          <InputText
            key="title"
            name="title"
            label="Reward title and image"
            value={title}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              stampCardStore.updateTemplateValue(['reward', 'title', 'fi'], e.target.value)
            }
          />
          <InputSelect
            label="Select a product for the image"
            value={image}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              stampCardStore.updateTemplateValue(['reward', 'image'], e.target.value)
            }
            placeholder="Select a product"
          >
            <option key="reward-no-image" value={PRODUCT_IMAGE_PLACEHOLDER}>
              No image
            </option>
            {_.map(
              _.filter(
                rewardProducts,
                (product) => !_.isNil(product.image) && product.image !== PRODUCT_IMAGE_PLACEHOLDER,
              ),
              (product, i) => {
                return (
                  <option key={`reward-${i}`} value={product.image}>
                    {product.title.fi}
                  </option>
                );
              },
            )}
          </InputSelect>
          <img src={image} alt="reward-stamp" />
        </div>
      );
    } else if (
      type === CollectionStampCardRewardType.BasketEur ||
      type === CollectionStampCardRewardType.BasketPercentage
    ) {
      return (
        <div className="reward-details">
          <InputText
            key="title"
            name="title"
            label="Reward title and image"
            value={title}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              stampCardStore.updateTemplateValue(['reward', 'title', 'fi'], e.target.value)
            }
          />
          <img src={image} alt="reward-basket" />
        </div>
      );
    }
  };

  const renderGeneral = () => {
    const {
      type,
      stores,
      chains,
      templateDescription: { fi: templateDescription },
      uiData: {
        uiName: { fi: title },
        description: { fi: description },
      },
    } = stampCardStore.currentTemplate;

    return (
      <React.Fragment>
        <div className="editor-section">
          <h3 className="section-title">General</h3>
          <div className="section-content split grid">
            <div>
              <InputText
                key="title"
                name="title"
                label="Title"
                value={title}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  stampCardStore.updateTemplateValue(['uiData', 'uiName', 'fi'], e.target.value)
                }
              />
              <InputText
                textarea
                rows={5}
                key="description"
                name="description"
                label="Description and rules (for store customers)"
                value={description}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  stampCardStore.updateTemplateValue(['uiData', 'description', 'fi'], e.target.value)
                }
              />
              <InputText
                textarea
                rows={5}
                key="template-description"
                name="template-description"
                label="Collection stamp card template description (for retailers)"
                value={templateDescription}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  stampCardStore.updateTemplateValue(['templateDescription', 'fi'], e.target.value)
                }
              />
            </div>
            <div className="form-control">
              <label>Stores</label>
              <StoreSelector
                stores={storeStore.stores}
                defaultStoreIds={stores}
                onChange={handleStoresChange}
                detail="Search for store name or business unit ID"
              />
              <label>Chains</label>
              <ChainSelector
                chainSelection={chains}
                handleChainChange={handleChainChange}
                excludeChains={kRautaChainIds}
              />
            </div>
          </div>
        </div>
        <div className="editor-section">
          <h3 className="section-title">Stamp collecting</h3>
          <div className="section-content split grid">
            <div>
              <InputSelect label="Collection type" value={type} onChange={handleTypeChange}>
                <option key={CollectionStampCardType.Product} value={CollectionStampCardType.Product}>
                  Products
                </option>
                <option key={CollectionStampCardType.Basket} value={CollectionStampCardType.Basket}>
                  Basket purchases
                </option>
              </InputSelect>
              {renderSimpleProductCollectionConfiguration()}
              {renderOneTimePurchaseConfiguration()}
            </div>
            <div>{renderProducts('product')}</div>
          </div>
        </div>
        <div className="editor-section">
          <h3 className="section-title">Reward</h3>
          <div className="section-content split grid">
            <div>
              <InputSelect
                label="Reward type"
                value={_.get(stampCardStore.currentTemplate, ['reward', 'type'])}
                onChange={handleRewardTypeChange}
              >
                <option key={CollectionStampCardRewardType.ProductEur} value={CollectionStampCardRewardType.ProductEur}>
                  Product with fixed price
                </option>
                {/* <option
        key={CollectionStampCardRewardType.ProductPercentage}
        value={CollectionStampCardRewardType.ProductPercentage}
      >
        Tuote prosenttialennuksella
      </option> */}
                <option key={CollectionStampCardRewardType.BasketEur} value={CollectionStampCardRewardType.BasketEur}>
                  Basket offer, eur
                </option>
                <option
                  key={CollectionStampCardRewardType.BasketPercentage}
                  value={CollectionStampCardRewardType.BasketPercentage}
                >
                  Basket offer, percent
                </option>
              </InputSelect>
              {renderRewardConfiguration()}
            </div>
            <div>
              {renderRewardTitleEditor()}
              {renderProducts('reward')}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const renderImageEditor = () => {
    const {
      imageData: {
        text: { fi: text },
        textType,
        backgroundUrl,
        backgroundUrls,
        textBackgroundColor,
        textForegroundColor,
      },
    } = stampCardStore.currentTemplate;

    return (
      <div className="editor-section">
        <h3 className="section-title">Stamp card image</h3>
        <div className="section-content split">
          <div className="stampcard-template-image-preview">
            {renderPreview()}
            <div className="form-control">
              <InputSelect
                label="Text type"
                value={textType}
                onChange={(e) => stampCardStore.updateTemplateValue(['imageData', 'textType'], e.target.value)}
              >
                <option key={CollectionStampCardImageTextType.Circle} value={CollectionStampCardImageTextType.Circle}>
                  Circle
                </option>
                <option key={CollectionStampCardImageTextType.Banner} value={CollectionStampCardImageTextType.Banner}>
                  Banner
                </option>
                <option key={CollectionStampCardImageTextType.None} value={CollectionStampCardImageTextType.None}>
                  No text
                </option>
              </InputSelect>
              <InputSelect
                label="Text color"
                value={textForegroundColor}
                onChange={(e) =>
                  stampCardStore.updateTemplateValue(['imageData', 'textForegroundColor'], e.target.value)
                }
              >
                <option key="fg-#ffffff" value="#ffffff">
                  Light
                </option>
                <option key="fg-#000000" value="#000000">
                  Dark
                </option>
              </InputSelect>
              <InputSelect
                label="Text backgroud color"
                value={textBackgroundColor}
                onChange={(e) =>
                  stampCardStore.updateTemplateValue(['imageData', 'textBackgroundColor'], e.target.value)
                }
              >
                <option key="bg-#00205b" value="#00205b">
                  Blue
                </option>
                <option key="bg-#ff6900" value="#ff6900">
                  Orange
                </option>
              </InputSelect>
              <InputText
                textarea
                key="text"
                name="text"
                label="Text"
                value={text}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  stampCardStore.updateTemplateValue(['imageData', 'text', 'fi'], e.target.value)
                }
              />
              <div className="form-control">
                <div className="stampcard-template-add-background-image">
                  <InputImage
                    label="Upload a new background image"
                    instructions="The recommended image size is 1000 x 540 pixels."
                    constraints={{ minWidth: 1000, minHeight: 540 }}
                    acceptTypes="image/png,image/jpg,image/jpeg"
                    onChange={({ src }) => setTemplateImage(src)}
                    value={{ src: templateImage }}
                    uploadDirectory="stampcard"
                  />
                </div>
              </div>
              <div className="stampcard-template-add-background-actions">
                <Button color="default" onClick={() => handleAddTemplateImage()} disabled={!templateImage}>
                  Add
                </Button>
              </div>
            </div>
          </div>
          <div className="stampcard-template-image-backgrounds">
            <InputText
              textarea
              rows={10}
              additionalClasses="form-control"
              key="text"
              name="text"
              label="Image URLs for available backgrounds (each on own line)"
              placeholder="http://www.example.org/1.png"
              value={_.join(backgroundUrls, '\n')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const updatedBackgroundUrls = _.compact(_.split(e.target.value, '\n'));
                stampCardStore.updateTemplateValue(['imageData', 'backgroundUrls'], updatedBackgroundUrls);

                const validBackgroundUrls = updatedBackgroundUrls;
                if (!_.includes(validBackgroundUrls, backgroundUrl)) {
                  stampCardStore.updateTemplateValue(['imageData', 'backgroundUrl'], _.first(validBackgroundUrls));
                }
              }}
            />
            <div className="form-control">
              <label>Default background image</label>
              <div className="stampcard-template-image-background-selection">
                {_.map(_.filter(backgroundUrls), (background, i) => {
                  return (
                    <div key={`card-background-${background}`} className="stampcard-image-image-background">
                      <InputRadio
                        label={`<img src="${background}" />`}
                        name="backgroundUrl"
                        value={background}
                        checked={backgroundUrl === background}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          stampCardStore.updateTemplateValue(['imageData', 'backgroundUrl'], e.target.value)
                        }
                      />

                      <div className="stampcard-background-image-controls">
                        <div className="replace-item">
                          <label htmlFor={`fileReplace-${background}`}>
                            <input
                              // hide the input field
                              style={{ display: 'none' }}
                              type="file"
                              id={`fileReplace-${background}`}
                              name="file"
                              accept={'image/*'}
                              className="upload-content"
                              onChange={(e) => handleReplaceTemplateImage(background, e)}
                            />
                            <IconEdit title="Replace background image" />
                          </label>
                        </div>
                        <div
                          className="remove-item"
                          onClick={() =>
                            // delete the current background
                            handleDeleteTemplateImage(background)
                          }
                        >
                          <IconDelete title="Delete background image from storage" />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderEditorStatus = () => {
    const { currentTemplateState, isLoadingStampCardTemplate } = stampCardStore;

    let statusClass = '';
    let message = null;
    let icon = null;

    switch (currentTemplateState) {
      default:
      case EditState.Pristine:
        break;
      case EditState.Saved:
        statusClass = 'ok';
        message = 'All changes saved!';
        icon = <HappyIcon />;
        break;
      case EditState.Changed:
        statusClass = 'warning';
        message = 'There are unsaved changes!';
        icon = <SurprisedIcon />;
        break;
      case EditState.Error:
        statusClass = 'error';
        message = 'Error saving changes';
        icon = <UnhappyIcon />;
        break;
    }

    return (
      <div className={`editor-status ${statusClass}`}>
        {isLoadingStampCardTemplate && <Spinner />}
        {!isLoadingStampCardTemplate && (
          <span>
            {message} {icon}
          </span>
        )}
      </div>
    );
  };

  const currentCardTemplate = stampCardStore.currentTemplate;

  if (!currentCardTemplate) {
    return <Spinner />;
  }

  const disabled = !stampCardStore.isCurrentTemplateValid || stampCardStore.isLoadingStampCardTemplate;

  return (
    <div className="stampcard-template-editor">
      <header className="editor-header">
        <div className="editor-header__controls">
          <button className="go-back" onClick={back}>
            <img src={require('images/arrow-back.svg').default} alt="arrow-back" />
          </button>
          <div className="editor-header__details">
            <h3>{mode === StampCardTemplateEditorMode.Create ? 'Create' : 'Edit'} collection stamp card template</h3>
          </div>
          <div className="editor-header__actions">
            {renderEditorStatus()}
            <Dropdown
              data={states}
              notSelectedText="State"
              select={(value) => stampCardStore.updateTemplateValue(['published'], value)}
              selectedItem={_.find(states, (state2) => state2.value === stampCardStore.currentTemplate.published)}
            />
            <button className={`save${disabled ? ' disabled' : ''}`} onClick={(e) => save()} disabled={disabled}>
              Save
            </button>
          </div>
        </div>
        <nav className="editor-header__tabs">
          {tabs.map(({ name, tab: tab2 }) => (
            <button
              key={`tab-${tab2}`}
              className={`tab-selector ${tab === tab2 ? 'is-selected' : ''}`}
              onClick={() => setTab(tab2)}
            >
              {name}
            </button>
          ))}
        </nav>
      </header>
      <div className="content">
        {tab === 0 && renderGeneral()}
        {tab === 1 && renderImageEditor()}
      </div>
    </div>
  );
};

export default page(inject('stampCardStore', 'storeStore')(observer(CollectionStampCardTemplateEditor)));
